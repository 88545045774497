export default {
  en: [
    {
      title: 'What do the different on the voucher mean?',
      body: [
        '"Free" means that you will get the product for free.',
        '"X,-" means that you have to pay the shown amount.',
        '"-X%" means that this % will be reduced from your price.',
        '"-X,-" means that your price will be reduced with this amount.',
      ],
    },
    {
      title: 'What do the different types of vouchers mean?',
      body: [
        '"Normal" means that this is a normal voucher issued by a golf club.',
        '"Offer" means that a golf club has issued an offer via a voucher in a certain period.',
        '"Loyalty" means that the club has made a special offer to you, because you have paid green fee in their club.',
      ],
    },
  ],
  de: [
    {
      title: 'WAS BEDEUTEN DIE UNTERSCHIEDLICHEN WERTEINSTELLUNGEN AUF DEM GUTSCHEIN?',
      body: [
        '"Kostenlos" bedeutet, dass Sie das Produkt kostenlos erhalten.',
        '"X,-" bedeutet, dass Sie den angezeigten Betrag bezahlen müssen.',
        '"-X%" bedeutet, dass dieser % von Ihrem Preis reduziert wird.',
        '"-X,-" bedeutet, dass Ihr Preis um diesen Betrag reduziert wird.',
      ],
    },
    {
      title: 'WAS BEDEUTEN DIE VERSCHIEDENEN ARTEN VON GUTSCHEINEN?',
      body: [
        '"Normal" bedeutet, dass es sich um einen normalen Gutschein eines Golfclubs handelt. ',
        '”Angebot“ bedeutet, dass ein Golfclub in einem bestimmten Zeitraum ein Angebot über einen Gutschein abgegeben hat.',
        '"Treue" bedeutet, dass Ihnen der Club ein besonderes Angebot gemacht hat, weil Sie in seinem Club Greenfee bezahlt haben.',
      ],
    },
  ],
  dn: [
    {
      title: 'Hvad betyder de forskellige former for værdisætning på værdikuponen?',
      body: [
        '"Gratis" betyder, at du ikke skal betale for produktet.',
        '"X,-" betyder, at du skal betale det viste beløb.',
        '"-X%" betyder, at denne procent fratrækkes din pris.',
        '"-X,-" betyder, at dette beløb fratrækkes din pris.',
      ],
    },
    {
      title: 'Hvad betyder de forskellige typer af værdikuponer?',
      body: [
        '"Normal" betyder, at værdikuponen er udstedt af en golfklub, og at den vil fungerer som en normal værdikupon.',
        '"Tilbud" betyder, at klubben har udstedt et tilbud i en begrænset periode via en værdikupon.',
        '"Loyalitet" betyder, at du har betalt greenfee i en klub, og denne klub gerne vil give dig et nyt tilbud.',
      ],
    },
  ],
};
