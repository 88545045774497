import React from 'react';
import urls from '../../../../constants/config/urls';

const termsDenmark = () => (
  <div className="b-terms__content container grid-xl">
    <div className="columns">
      <div className="column">
        <h1 className="b-terms__title">VILLKOR OCH FÖRUTSÄTTNINGAR</h1>
        <h2 className="b-terms__sub-title">
          <span className="g-uppercase">GOLFMORE.SE ANVÄNDARVILLKOR </span> (Uppdaterad juni 2018)
        </h2>
        <p className="b-terms__paragraph">
          Dessa Användarvillkor (nedan kallade "Användningsrättigheter") gäller för alla
          användningar av GolfMores Webbplats och applikationer (nedan kallad "Webbplatsen"),
          inklusive - men inte begränsat till -
          <a
            className="b-privacy-policy__link"
            href={urls.swedish}
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            www.GolfMore.se
          </a>
          .
        </p>
        <p className="b-terms__paragraph">
          Webbplatsen erbjuds av ProBox24 ApS, Rugaardsvej 5, 8680 Ry, Danmark, info@probox24.com. I
          användarvillkoren hänvisar orden "vi", "oss" och "vår" till GolfMore.
        </p>
        <p className="b-terms__paragraph">
          Dessa användarvillkor inkluderar alla regler, policys och riktlinjer som avses i detta
          dokument eller på annat sätt publiceras på Webbplatsen (sådana policys och riktlinjer kan
          komma att ändras från tid till annan), inklusive utan undantag:
        </p>
        <ul className="b-terms__sub-inner-list">
          <li className="b-terms__sub-inner-item">
            • Vår integritetspolicy ("Sekretesspolicy") finns på{' '}
            <a
              className="b-privacy-policy__link"
              href={urls.swedishPrivacyPolicy}
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              golfmore.se/privacy-policy
            </a>
          </li>
        </ul>
        <p className="b-terms__paragraph">
          Genom att använda sajten accepterar du att vara underställd användarvillkoren. Om du inte
          accepterar villkoren, vänligast använd inte av webbplatsen ytterligare. Din registrering
          som användare kräver att du utan förbehåll accepterar användarvillkoren.
        </p>
        <h2 className="b-terms__sub-title">
          <span className="g-uppercase">WEBBPLATSENS FÖRDELAR</span>
        </h2>
        <h3 className="b-terms__sub-title">1 Registrerad användare</h3>
        <p className="b-terms__paragraph">
          1.1 För att få tillgång till, och använda hela sajten måste du skapa en profil och
          registrera dig som användare (nedan kallad "Registrerad användare").
        </p>
        <p className="b-terms__paragraph">
          1.2 Det är bara tillåtet att skapa en profil per person på Webbplatsen. Profilen är
          personlig och får inte överlåtas till andra.
        </p>
        <p className="b-terms__paragraph">
          1.3 För att bli registrerad användare måste du ha ett lösenord. Du väljer ditt lösenord,
          vilket måste användas med din e-postadress när du loggar in på Webbplatsen. Alternativt
          skickar vi lösenordet till dig. Lösenordet är personligt och får inte överlåtas eller på
          annat sätt göras tillgängligt för andra. Det är ditt ansvar att se till att lösenordet
          inte når tredje part. Om du blir medveten om att lösenordet är eller kan komma att
          äventyras, är du skyldig att meddela oss. Vi kan och kommer att ändra lösenordet om det
          finns risk för att det äventyras eller används i strid med användarvillkoren.
        </p>
        <p className="b-terms__paragraph">
          1.4 Vi kan, när som helst, utan förvarning och utan ansvar, radera, upphäva eller ändra
          din profil om du bryter mot användarvillkoren eller gällande lag. När du tar bort din
          profil har du inte tillgång till tjänsterna på Webbplatsen som kräver din registrering och
          / eller logga in som registrerad användare.
        </p>
        <p className="b-terms__paragraph">
          1.5 Vid misstänkt missbruk eller liknande, förbehåller vi oss också rätten att ta bort din
          profil när som helst och utan förvarning eller motivering. I sådana fall gäller vår
          ansvarsfrihet obegränsat.
        </p>
        <p className="b-terms__paragraph">
          1.6 Vi förbehåller oss också rätten att ta bort din profil när som helst och utan
          förvarning eller motivering. I sådana fall gäller vår ansvarsfrihet obegränsat.
        </p>
        <p className="b-terms__paragraph">
          1.7 Det är inte tillåtet att få tillgång till eller försöka få tillgång till de delar av
          Webbplatsen som kräver användarregistrering om du inte är registrerad användare.
        </p>
        <h2 className="b-terms__title">
          <span className="g-uppercase">GENERELLT</span>
        </h2>
        <h3 className="b-terms__sub-title">2 rättigheter</h3>
        <p className="b-terms__paragraph">
          2.1 Webbplatsens innehåll, som till exempel - men inte begränsat till - immateriella
          rättigheter, text, funktioner, grafik, ikoner, bilder, beräkningar, referenser och
          programvara tillhör antingen GolfMore eller tredje part (annan än den registrerade
          användaren) och är skyddade av dansk och internationell rätt, inklusive upphovsrättslagen,
          varumärkeslagen, marknadsföringslagen etc. Om du bidrar med feedback, idéer eller förslag
          till Webbplatsen eller de tjänster som är tillgängliga via Webbplatsen ("Feedback"), kan
          vi fritt och utan begränsning använda denna feedback.
        </p>
        <p className="b-terms__paragraph">
          2.2 Obehörig kopiering, distribution, visning, prestanda eller annan användning av
          Webbplatsen eller någon del därav strider mot dansk och / eller annan lag och kan leda
          till civilrättsliga och straffrättsliga påföljder.
        </p>
        <p className="b-terms__paragraph">
          2.3 Nedladdning och annan digital kopiering av Webbplatsens innehåll eller delar därav är
          endast tillåtet för personlig, icke-kommersiell användning, om inte annat avtalats
          skriftligen med oss eller tillåtits enligt gällande lag.
        </p>
        <p className="b-terms__paragraph">
          2.4 Alla företagsnamn, varumärken och andra företagsegenskaper på Webbplatsen tillhör
          antingen GolfMore eller tredje part (annan än Registrerad användare) och får endast
          användas kommersiellt efter föregående tillstånd från oss respektive tredje part.
        </p>
        <h3 className="b-terms__sub-title">3 Personlig information</h3>
        <p className="b-terms__paragraph">
          3.1 Vi gör olika former av behandling av personuppgifter i samband med användningen av
          Webbplatsen. Vår behandling av personuppgifter sker enligt personuppgiftslagen och vår
          integritetspolicy, som du hittar här:{' '}
          <a
            className="b-privacy-policy__link"
            href={urls.swedishPrivacyPolicy}
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            golfmore.se/privacy-policy
          </a>
          .
        </p>
        <p className="b-terms__paragraph">
          Genom att acceptera användarvillkoren intygar du att du har läst och förstått vår
          sekretesspolicy.
        </p>
        <h3 className="b-terms__sub-title">4 Ansvars- och ersättningsfrihet</h3>
        <p className="b-terms__paragraph">
          4.1 I så stor utsträckning som möjligt enligt dansk lag kan vi under inga omständigheter
          hållas ersättningsansvariga för användningen av Webbplatsen, inklusive för (i)
          inkomstförlust, driftstörning, affärsmöjligheter, förlust eller förstörelse av data eller
          återställande av data, förlust av goodwill, brott mot säkerhet som orsakats av tredje
          parts kränkningar av telekommunikation och / eller Internet, inkomstförlust (direkt eller
          indirekt eller följdriktigt), (ii) förlust eller skada som härrör från ansvar gentemot
          tredje man (både direkt, indirekt eller skada som följd därav) eller någon indirekt
          förlust.
        </p>
        <p className="b-terms__paragraph">
          4.2 Inget i användarvillkoren utesluter eller begränsar vårt ansvar för dödsfall eller
          personskada som härrör från försumlighet eller ansvar för avsiktligt oriktig information
          eller oriktig information av en viktig natur eller annat ansvar som inte kan begränsas
          eller avskrivas enligt gällande lag.
        </p>
        <h3 className="b-terms__sub-title">5 Övriga villkor</h3>
        <p className="b-terms__paragraph">
          5.1 Vi kan, när som helst och utan förvarning, revidera eller ändra dessa användarvillkor
          eller efter eget beslut och utan förvarning, säga upp, ändra eller omstrukturera
          Webbplatsen. Som registrerad användare godkänner du när som helst att omfattas av gällande
          användarvillkor. Revidering eller ändring av villkoren vill bli noterat på webbplatsen.
          GolfMore strävar också efter att informera registrerade användare om ändringar av
          användarvillkoren. Som registrerad användare godkänner du att den fortsatta användningen
          av webbplatsen efter publiceringen av de ändrade användarvillkoren utgör ett godkännande
          av de ändrade användarvillkoren.
        </p>
        <p className="b-terms__paragraph">
          5.2 Om någon del av dessa användarvillkor anses vara olaglig eller ogiltig och på så sätt
          inte verkställas, ska detta inte påverka giltigheten och verkställigheten av resten av
          användarvillkoren.
        </p>
        <h3 className="b-terms__sub-title">6 Varaktighet och uppsägning</h3>
        <p className="b-terms__paragraph">
          6.1 Vi kan när som helst säga upp din rätt att få tillgång till och använda de tjänster
          som tillhandahålls via Webbplatsen av någon anledning och utan ansvar. Om vi gör det,
          eller om du väljer att ta bort din profil, upphör eventuella rättigheter som du har fått
          genom detta att upphöra omedelbart.{' '}
        </p>
        <h3 className="b-terms__sub-title">7 Val av lag och plats</h3>
        <p className="b-terms__paragraph">
          7.1 Användarvillkoren är föremål för dansk lag och om inte annat anges, gäller den danska
          lagens allmänna regler för alla förhållanden mellan GolfMore, Registrerade användare och
          användare av Webbplatsen. Eventuella tvister och meningsskiljaktigheter måste väckas inför
          domstolen i GolfMores danska hemort, såvida inte detta strider mot obligatoriska
          behörighetsregler.
        </p>
      </div>
    </div>
  </div>
);

export default termsDenmark;
