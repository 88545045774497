import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Switch, Router, Route, Redirect } from 'react-router-dom';
import ScrollToTop from './components/scrollToTop';
import store from './store';
import history from './history';

import './fonts/popins/poppins-regular-webfont.ttf';
import './fonts/popins/poppins-bold-webfont.ttf';
import './fonts/popins/poppins-light-webfont.ttf';
import './fonts/popins/poppins-medium-webfont.ttf';
import './fonts/popins/poppins-semibold-webfont.ttf';

/* components */
import App from './App';
import { ThemeProvider } from 'styled-components/native';
import themes from './themes';
/* components */

import './index.css';

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={themes.main}>
      <Router history={history}>
        <Route path="/" component={App}></Route>
      </Router>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root'),
);
