import React, { Component } from 'react';
import propTypes from 'prop-types';

import texts from '../../../../constants/languages';

class ChangeProfileSuccess extends Component {
  static propTypes = {
    togglePopup: propTypes.func.isRequired,
    popupName: propTypes.string.isRequired,
  };

  closePopupOnClickOutSide = (event) => {
    if (!this.popup.contains(event.target)) {
      const { togglePopup, popupName } = this.props;
      togglePopup(popupName, false);
    }
  };

  render() {
    const { togglePopup, popupName, text, title } = this.props;

    return (
      <div className="g-popup-wrap"
           onClick={(event) => this.closePopupOnClickOutSide(event)} // close popup
      >
        <div
          className="g-popup-overlay"
        >
          &nbsp;
        </div>
        <div className="g-popup-wrap">
          <table className="g-popup-wrap__container">
            <tbody>
            <tr>
              <td className="g-popup-wrap__container-inner">
                <div className="g-popup"
                     ref={(popup) => this.popup = popup}
                >
                  <div
                    className="g-popup__close-btn"
                    onClick={() => togglePopup(popupName, false)} // close popup
                  >
                    &nbsp;
                  </div>
                  <div className="g-popup__content">
                    <form className="g-form" action="#">
                      <h2 className="g-form__title m-form__title_small">{title}!</h2>
                      <p className="g-form__paragraph">{text}.</p>
                      <div className="g-form__btn-holder m-form__btn-holder_confirm g-clear-fix">
                        <button
                          className="g-form__btn m-form__btn_confirm g-submit-btn"
                          onClick={() => togglePopup(popupName, false)}
                        >{texts.GOT_IT}</button>
                      </div>
                    </form>
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default ChangeProfileSuccess;


