import { combineReducers } from 'redux';

import appReducer from './appReducer';
import authReducer from '../domains/auth/reducer';
import voucherReducer from '../domains/vouchers/module.js';
import faqReducer from '../domains/faq/faqReducer';
import userReducer from '../react-native/user/modules/index';
import clubReducer from '../react-native/clubs/modules/index';
import locale from '../react-native/store/modules/locale';
import country from '../react-native/register/modules/country';
import session from '../react-native/auth/modules/session';
import connection from '../react-native/auth/modules/connection';

export default combineReducers({
  appReducer,
  authReducer,
  voucherReducer,
  faqReducer,
  user: userReducer,
  club: clubReducer,
  locale,
  country,
  connection,
  session,
});
