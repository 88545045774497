// @flow
import React, { Component } from 'react';
import { View, Image, Text, TouchableHighlight, TouchableOpacity } from 'react-native';
import i18n from 'react-native-i18n';
import LoadingImage from '../../../components/LoadingImage/LoadingImage';
import styles from './styles';
import type { VoucherType } from '../../api/voucher';
import convertTimeFromTo from '../helpers/convertTimeFromTo';

const ICON_TRANSFER = require('../assets/ic_transfer.png');
const VOUCHER_IMAGE = require('../../../assets/voucher.png');

type Props = {
  voucher: VoucherType,
  isTransferIcon?: boolean,
  transfer?: boolean,
  onPressVoucher: () => void,
};

class Voucher extends Component<Props> {
  static defaultProps = {
    isTransferIcon: false,
    transfer: false,
  };

  voucherPressHandler = () => {
    const { transfer, voucher, onPressVoucher } = this.props;
    if (!transfer) {
      const { order_id: orderId } = voucher;
      onPressVoucher(orderId);
    }
  };

  defineStatus = (status) => {
    // console.log('Status', status);
    if (status === 'personal') {
      return i18n.t('vouchers.personal').toUpperCase();
    } else if (status === 'introcard') {
      return 'INTRO';
    } else if (status === 'golf_circle') {
      return i18n.t('vouchers.card');
    } else if (status === 'loyalty') {
      return i18n.t('vouchers.loyalty').toUpperCase();
    } else if (status === 'offer') {
      return i18n.t('vouchers.offer').toUpperCase();
    } else if (status === 'ecard') {
      return i18n.t('vouchers.dr_ecard').toUpperCase();
    }
    return '';
  };

  renderText = () => {
    const {
      type,
      owner_name: ownerName,
      name,
      valid_times: validTimes,
      valid_from: validFrom,
      valid_to: validTo,
    } = this.props.voucher;
    const isIntrocard = type === 'introcard' || type === 'golf_circle';
    return (
      <View style={styles.textContainer}>
        <Text style={styles.voucherTitle} numberOfLines={1}>
          {ownerName}
        </Text>
        <Text style={styles.ticketDesk} numberOfLines={1}>
          {name}
        </Text>
        {!isIntrocard && (
          <Text style={styles.validationTime} numberOfLines={1}>
            {i18n.t('vouchers.numberOfUse')}
            {validTimes}
          </Text>
        )}
        <Text style={styles.validationTime} numberOfLines={1}>
          {convertTimeFromTo(validFrom, validTo)}
        </Text>
      </View>
    );
  };

  renderPrice = (price: string) => {
    const { type, reduction_type: reductionType, value } = this.props.voucher;
    const isIntrocard = type === 'introcard';
    const isCard = type === 'golf_circle';
    if (isIntrocard && value === '0') {
      return 'Intro';
    }
    if (isCard) {
      return i18n.t('vouchers.card');
    }
    if (reductionType === 'setprice') {
      return price === '0' ? i18n.t('vouchers.free') : `${price},-`;
    } else if (reductionType === 'reducepercent') {
      return `-${price}%`;
    } else if (reductionType === 'reduceprice') {
      return `-${price},-`;
    }
    return '';
  };

  render() {
    const { image_url: imageUrl, type, count: orderSize, value } = this.props.voucher;

    return (
      <View style={styles.mainView}>
        <TouchableOpacity onClick={this.voucherPressHandler} activeOpacity={1}>
          <View style={styles.voucherView}>
            <View>
              <View style={styles.marginWrapper}>
                <Image
                  loadingIndicator={LoadingImage}
                  source={imageUrl ? { uri: imageUrl } : VOUCHER_IMAGE}
                  style={styles.image}
                  resizeMode="contain"
                />
                <TouchableHighlight style={styles.statusWrapper}>
                  <Text style={styles.voucherStatus}>{this.defineStatus(type)}</Text>
                </TouchableHighlight>
              </View>
              {orderSize > 1 ? (
                <View style={styles.validTimes}>
                  <Text style={styles.validTimesText}>{`x${orderSize}`}</Text>
                </View>
              ) : null}
            </View>
            {this.renderText()}
            {this.props.children}
            <TouchableOpacity
              onPress={this.voucherPressHandler}
              activeOpacity={1}
              style={[styles.price, this.props.transfer && styles.transferPrice]}
            >
              {this.props.isTransferIcon ? (
                <Image source={ICON_TRANSFER} resizeMode="contain" />
              ) : (
                <Text style={styles.priceText}>{this.renderPrice(value).toUpperCase()}</Text>
              )}
            </TouchableOpacity>
          </View>
        </TouchableOpacity>
      </View>
    );
  }
}

export default Voucher;
