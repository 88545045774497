import { connect } from 'react-redux';

import appActions from '../../../../domains/app/appActions';
import authActions from '../../../../domains/auth/actions';

import Home from './passwordChange';

function mapStateToProps(state) {
  return {
    changePasswordConfirmPopupIsOpen: state.authReducer.get('changePasswordConfirmPopupIsOpen'),
  };
}

const mapDispatchToProps = (dispatch) => ({
  togglePopup: (popupName, status) => dispatch(appActions.togglePopup(popupName, status)),
  changePassword: (data, setServerErrors) => dispatch(authActions.changePassword(data, setServerErrors)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
