import { createSelector } from 'reselect';

const getLocale = (state) => state.appReducer.get('location');

export const localeSelector = createSelector(getLocale, (locale) => {
  switch (locale) {
    case 'german':
      return 'de';
    case 'english':
      return 'en';
    case 'swedish':
      return 'sv';
    case 'norwegian':
      return 'no';
    case 'danish':
      return 'da';
    default:
      return 'da';
  }
});
