import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Vouchers from './vouchers';
import appActions from '../app/appActions';
import authActions from '../auth/actions';
import {
  setCurrentVoucher,
  changeSearchText,
  toggleTabFilter,
  onDonePress,
  getVouchers,
  printPDF,
  setSpinnerFlag,
  toggleFavourites,
  setSortOption,
  getPrintedVouchers,
  getFavouriteVouchers,
  getAllVouchers,
  setVouchers,
  setVouchersCount,
} from './module.js';

const { togglePopup, setCurrentPage } = appActions;
const { toggleLogOutWarn, logOut } = authActions;

const mapStateToProps = ({ voucherReducer, appReducer, authReducer }) => ({
  location: appReducer.get('location'),
  allVouchers: voucherReducer.get('allVouchers').toJS(),
  favouriteVouchers: voucherReducer.get('favouriteVouchers').toJS(),
  faqsItems: voucherReducer.get('faqsItems'),
  printedVouchers: voucherReducer.get('printedVouchers').toJS(),
  vouchers: voucherReducer.get('vouchers').toJS(),
  voucherDetailsPopupIsOpen: voucherReducer.get('voucherDetailsPopupIsOpen'),
  currentVoucher: voucherReducer.get('currentVoucher').toJS(),
  searchInput: voucherReducer.get('searchInput'),
  vouchersSortOption: voucherReducer.get('vouchersSortOption'),
  currentTab: voucherReducer.get('currentTab'),
  vouchersCount: voucherReducer.get('vouchersCount'),
  currentPage: appReducer.get('currentPage'),
  printRulesAccepted: authReducer.get('authUserData').print_rules_accepted,
  logOutWarn: authReducer.get('logOutWarn'),
});

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators(
    {
      togglePopup,
      setCurrentPage,
      toggleLogOutWarn,
      logOut,
      setCurrentVoucher,
      changeSearchText,
      toggleTabFilter,
      onDonePress,
      getVouchers,
      printPDF,
      setSpinnerFlag,
      getFavouriteVouchers,
      getPrintedVouchers,
      toggleFavourites,
      setSortOption,
      setVouchers,
      setVouchersCount,
      getAllVouchers,
    },
    dispatch,
  ),
)(Vouchers);
