import React from 'react';
import propTypes from 'prop-types';

import './popup.css';

import texts from '../../../constants/languages';

const { func, shape, any, string } = propTypes;

class VoucherTransferPopupSuccess extends React.Component {
  static propTypes = {
    togglePopup: func.isRequired,
    updateData: func.isRequired,
    authUserData: shape(any).isRequired,
    locale: string.isRequired,
  };

  closePopupOnClickOutSide = (event) => {
    if (!this.popup.contains(event.target)) {
      this.props.togglePopup('voucherPrintNotification', false);
    }
  };

  printNotificationAnswer = async (selectedAnswer, event) => {
    event.preventDefault();
    const { togglePopup, authUserData, updateData } = this.props;
    if (selectedAnswer === 'agree') {
      const data = {
        ...authUserData,
        print_rules_accepted: true,
      };
      await updateData(data); // TODO: 2nd parameter is setServerErrors;
    }

    togglePopup('voucherPrintNotification', false);
  };

  render() {
    const { togglePopup, locale } = this.props;

    return (
      <div
        className="g-popup-wrap"
        onClick={event => this.closePopupOnClickOutSide(event)} // close popup
      >
        <div
          className="g-popup-overlay"
        >
          &nbsp;
        </div>
        <div className="g-popup-wrap">
          <table className="g-popup-wrap__container">
            <tbody>
              <tr>
                <td className="g-popup-wrap__container-inner">
                  <div className="b-transfer-success g-popup m-popup_more_wide"
                       ref={(popup) => this.popup = popup}
                  >
                    <div
                      className="g-popup__close-btn"
                      onClick={() => togglePopup('voucherPrintNotification', false)} // close popup
                    >
                      &nbsp;
                    </div>
                    <div className="b-transfer-success__content g-popup__content">
                      <form className="b-transfer-success__form g-form">
                        <h3 className="b-transfer-success__title g-form__title m-form__title_transfer notification-text remove-capitalize">
                          {texts.PRINT_MESSAGE}
                        </h3>
                        <div
                          className="b-transfer-success__btn-holder g-form__btn-holder g-clear-fix btn_container"
                        >
                          <button
                            className="b-transfer-success__btn printNotification cancel-btn"
                            style={locale === 'danish' ? { left: '-10%' } : { left: '-15%' }}
                            onClick={event => this.printNotificationAnswer('cancel', event)}
                          >{texts.CANCEL}</button>
                          <button
                            className="b-transfer-success__btn printNotification accept-btn"
                            style={locale === 'danish' ? { right: '-10%' } : { right: '-15%' }}
                            onClick={event => this.printNotificationAnswer('agree', event)}
                          >{texts.GOT_IT}</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default VoucherTransferPopupSuccess;

