// @flow
import React, { useState, useCallback } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { withFormik } from 'formik';
import i18n from 'react-native-i18n';
import yup from 'yup';

import compose from 'lodash/fp/compose';
import throttle from 'lodash/throttle';

import { useHistory } from 'react-router-dom';

import { logIn } from '../../modules/session';

import {
  Root,
  Touchable,
  ContentContainer,
  EmailImageContainer,
  EmailImage,
  Input,
  ErrorText,
  LogInButton,
  LinkText,
  CreateAccountContainer,
  Text,
} from './styles';

const LogIn = ({
  navigation,
  values,
  errors,
  touched,
  session,
  handleSubmit,
  togglePopup,
  setFieldValue,
  setFieldTouched,
}) => {
  const [submitClicked, setSubmitClicked] = useState(false);
  const history = useHistory();

  const emailErrorText = errors.email ? i18n.t(errors.email) : null;
  const passwordErrorText = errors.password ? i18n.t(errors.password) : null;

  const setEmail = useCallback(
    (text) => {
      setFieldValue('email', text);
      setFieldTouched('email', true);
    },
    [setFieldValue, setFieldTouched],
  );

  const setPassword = useCallback(
    (text) => {
      setFieldValue('password', text);
      setFieldTouched('password', true);
    },
    [setFieldValue, setFieldTouched],
  );

  const handleLogin = useCallback(
    throttle(
      (evt) => {
        setSubmitClicked(true);
        handleSubmit(evt);
      },
      5000,
      { trailing: false },
    ),
    [handleSubmit, togglePopup],
  );

  const handleForgotPress = useCallback(() => {
    navigation.navigate('ForgotPassword');
  }, [navigation]);

  const handleCreatePress = useCallback(() => {
    history.push('/register');
    togglePopup('logInPopupIsOpen', false);
    navigation.navigate('SignUpSelect');
  }, [navigation]);

  return (
    <Root>
      <EmailImageContainer>
        <EmailImage />
      </EmailImageContainer>
      <ContentContainer>
        <Input
          label={i18n.t('logIn.email')}
          keyboardType="email-address"
          textContentType="username"
          autoCorrect={false}
          autoCapitalize="none"
          value={values.email}
          onChangeText={setEmail}
          error={submitClicked && touched.email && errors.email}
          errorText={emailErrorText}
        />
        <Input
          label={i18n.t('logIn.password')}
          secureTextEntry
          keyboardType="default"
          value={values.password}
          onChangeText={setPassword}
          error={submitClicked && touched.password && errors.password}
          errorText={passwordErrorText}
          multiline={false}
        />
        {!session.error ? null : <ErrorText text={session.error} />}
        <LogInButton text={i18n.t('signUpSelect.signIn')} onPress={handleLogin} />
        <Touchable onPress={handleForgotPress}>
          <LinkText text={i18n.t('register.forgotEmailOrPassword')} />
        </Touchable>
        <CreateAccountContainer>
          <Text text={i18n.t('register.newToGolfmore')} />
          <Touchable onPress={handleCreatePress}>
            <LinkText text={i18n.t('register.createAccountLowercase')} />
          </Touchable>
        </CreateAccountContainer>
      </ContentContainer>
    </Root>
  );
};

const validationSchema = yup.object().shape({
  email: yup.string().email('logIn.invalidEmail').required('logIn.emptyEmail'),
  password: yup.string().required('logIn.emptyPassword'),
});

export default compose(
  connect(
    ({ session }) => ({
      session: session.session,
    }),
    (dispatch) => bindActionCreators({ logIn }, dispatch),
  ),
  withFormik({
    mapPropsToValues() {
      return {
        // email: 'ps@probox24.com',
        // password: 'admin1234',
        email: '',
        password: '',
      };
    },
    validationSchema,
    validateOnChange: true,
    handleSubmit(values, { props }) {
      props.logIn(values);
    },
  }),
)(LogIn);
