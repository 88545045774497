// @flow
import { takeEvery, put, select, take, call } from 'redux-saga/effects';
import { LOCATION_UPDATED } from '../../geo/modules/geolocation';
import {
  UPDATE_CURRENT_CLUB_DETAILS,
  SET_CURRENT_CLUB,
  LOAD_MY_CLUBS_SUCCESS,
  LOAD_MY_CLUBS_FAILURE,
  SET_WAITING_FOR_NEARBY_FALSE,
  LOAD_NEARBY_CLUB_SUCCESS,
  loadClubLocationDetails,
  setCurrentClub,
} from '../modules';

import { findClosestClub } from '../../geo/helpers';
import { navigate } from '../../store/modules/navigation';
import { selectCurrentClub } from '../selectors/clubselectors';

/**
 * Saga takes current club from redux and updates it's details if needed.
 * If details (vouchers, driving_range) already present, saga does nothing, as details are already there.
 * Note: sagas are always called AFTER reducer, so at this moment current club will already be set.
 * @returns {Generator<PutEffect<unknown, null>, void, *>}
 */
function* updateCurrentClubDetails() {
  const currentClub = yield select(({ club }) => club.myclub.currentClub);
  if (!currentClub) {
    return;
  }
  const { clubDetails } = currentClub;
  if (!clubDetails) {
    // If no club details or all the data is already set (e.g. nearby club)
    return;
  }
  yield put(loadClubLocationDetails(clubDetails.club_id));
}

/**
 * This saga implements the following logic:
 * 1. If there is already a club showing, do nothing.
 * 2. If fetch of my clubs failed, set an empty club as current, so app will know nothing was fetched.
 * 3. If nearby club is not loaded yet, wait for it and if back end has sent a nearby club, do nothing.
 * 4. If there is a home club and it has vouchers, show it.
 * 5. If home club doesn't have vouchers or there is no home club at all, show closest club which has vouchers.
 * 6. If location is not available, just show first club with vouchers.
 * 7. Go to shop tab otherwise.
 * @param action
 * @returns {Generator<SelectEffect<void, []>|PutEffect<unknown, null>|TakeEffect<unknown, void, void>, void, *>}
 */
function* watchMyClubsResult(action) {
  const currentClub = yield select(selectCurrentClub);
  if (currentClub && currentClub.clubDetails) {
    // A club is already shown, do nothing.
    return;
  }
  const { type } = action;
  if (type === LOAD_MY_CLUBS_FAILURE) {
    yield put(setCurrentClub({}));
    return;
  }
  const { waitingForNearbyClub } = yield select(state => state.club.myclub);
  if (waitingForNearbyClub) {
    yield take(SET_WAITING_FOR_NEARBY_FALSE);
    const currentClub = yield select(selectCurrentClub);
    if (currentClub && currentClub.clubDetails) {
      // A club (probably the nearby) is already shown, do nothing.
      return;
    }
  }
  // Waited for nearby club and there is still no current club
  yield call(setDefaultClub);
}

function* setDefaultClub() {
  const clubs = yield select(state => state.club.myclub.data);
  if (!clubs) {
    return;
  }
  const homeClub = clubs.find(({ is_home_club }) => is_home_club);

  if (homeClub?.has_products) {
    yield put(setCurrentClub({ clubDetails: homeClub }));
    return;
  }

  const clubsWithVouchers = clubs.filter(({ has_products }) => has_products);
  if (clubsWithVouchers.length) {
    const geo = yield select(state => state.location);
    if (geo && geo.hasLocation) {
      const { closest } = findClosestClub(geo.lat, geo.lng, clubsWithVouchers);
      yield put(setCurrentClub({ clubDetails: closest }));
    } else {
      yield put(setCurrentClub({ clubDetails: clubsWithVouchers[0] })); // Just set the first one if there is no location.
    }
  } else {
    navigate('Shop');
  }
}

function* setNearbyClub(action) {
  const { data } = action.result;
  if (data.name) {
    yield put(setCurrentClub({ clubDetails: { ...data }, isNearby: true }));
    return;
  }

  // No nearby club.
  // If current club was nearby and it's not in myclub list,
  // we should remove it and go with default club logic.
  const currentClub = yield select(selectCurrentClub);
  if (!currentClub?.clubDetails) { // Should never happen, just in case.
    yield call(setDefaultClub);
    return;
  }

  const { clubDetails } = currentClub;
  const clubs = yield select(state => state.club.myclub.data);
  const isInMyClubs = clubs.some(({ club_id }) => club_id === clubDetails.club_id);
  if (!isInMyClubs) {
    yield call(setDefaultClub);
  }
}

export default function*(): Generator<*, *, *> {
  yield takeEvery(
    [UPDATE_CURRENT_CLUB_DETAILS, LOCATION_UPDATED, SET_CURRENT_CLUB],
    updateCurrentClubDetails,
  );
  yield takeEvery([LOAD_MY_CLUBS_SUCCESS, LOAD_MY_CLUBS_FAILURE], watchMyClubsResult);
  yield takeEvery(LOAD_NEARBY_CLUB_SUCCESS, setNearbyClub);
}
