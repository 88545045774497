// @flow
import styled from 'styled-components/native';
import { color, layout, space, border } from 'styled-system';
// import LinearGradient from 'react-native-linear-gradient';
import { View } from 'react-native';
import { TextL, TextM, TextS, TextXs } from '../../../components/Text';
import NewButton from '../../../components/Button/NewButton';

const verifyEmailIcon = require('./assets/verify_email.png');

export const Touchable = styled.TouchableWithoutFeedback``;

export const Root = styled.SafeAreaView.attrs({
  flex: 1,
  bg: 'white',
})`
  width: 100%;
  max-width: 500px;
  ${color}
`;

export const ContentContainer = styled.View.attrs({
  flex: 1,
  bg: 'bgSecondary',
})`
  ${color}
`;

export const ContentScrollView = styled.ScrollView.attrs({
  flex: 1,
  contentContainerStyle: { padding: 20 },
})``;

export const EmailRow = styled.View.attrs({
  mt: 3,
})`
  flex-direction: row;
  align-items: center;
  ${space}
`;

export const EmailIcon = styled.Image.attrs({
  width: 66,
  height: 66,
  source: verifyEmailIcon,
  resizeMode: 'contain',
  mr: 4,
})`
  ${layout}
  ${space}
`;

export const VerticalContainer = styled.View``;

export const Title = styled(TextL).attrs({
  fontFamily: 'semibold',
})``;

export const EmailText = styled(TextS).attrs({
  fontFamily: 'italic',
  color: 'green',
})``;

export const Rationale = styled(TextM).attrs({
  color: 'button',
  fontFamily: 'light',
  mt: 8,
  mb: 8,
})``;

export const StepContainer = styled.View.attrs({
  mb: 6,
  ml: 3,
})`
  flex-direction: row;
  align-items: center;
  ${space}
`;

export const StepNumberContainer = styled.View.attrs({
  width: 24,
  height: 24,
  borderRadius: 12,
  borderWidth: 1,
  borderColor: 'button',
  mr: 4,
  bg: 'white',
})`
  align-items: center;
  justify-content: center;
  ${layout}
  ${border}
  ${space}
  ${color}
`;

export const StepNumberText = styled(TextXs).attrs({
  fontFamily: 'semibold',
  color: 'button',
})``;

export const StepText = styled(TextS).attrs({
  fontFamily: 'medium',
  color: 'button',
})``;

export const ContactSupportText = styled(TextS).attrs({
  fontFamily: 'medium',
  color: 'green',
  ml: 2,
})`
  text-decoration-line: underline;
`;

export const Button = styled(NewButton).attrs({
  mt: 6,
})``;

export const FooterContainer = styled.View.attrs({
  height: 56,
  bg: 'white',
})`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  ${color}
`;

export const FooterLabel = styled(TextS)``;

export const SignInLink = styled(TextS).attrs({
  fontFamily: 'semibold',
  ml: 2,
  color: 'green',
})`
  text-decoration-line: underline;
`;

export const FooterShadow = styled(View).attrs({
  height: 10,
  colors: ['#0000', '#0001'],
})`
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  ${layout}
`;
