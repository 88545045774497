// @flow
import React, { Component } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  Keyboard,
  ImageBackground,
  StyleSheet,
  Image,
  Platform,
  TouchableWithoutFeedback,
  ScrollView,
} from 'react-native';

import i18n from 'react-native-i18n';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withFormik } from 'formik';
import yup from 'yup';
import compose from 'lodash/fp/compose';
import throttle from 'lodash/throttle';

import { logIn } from '../../modules/session';
import type { SessionData } from '../../modules/session';
import Input from '../../../components/FormInput';
import Button from '../../../components/Button';
import styles, { colorGray } from './styles';

import { gotOAuthData } from '../../modules/session';
import HintArrows from '../../../components/HintArrows';
import { func } from 'prop-types';
import { OAuthPlugin } from '../../../../domains/auth/components/OAuthConsumer/index';
import { loginGit } from '../../api/OAuth';

type Dict = { [key: string]: * };

type Props = {
  navigation: {
    navigate: (route: string) => void,
  },
  setFieldValue: (name: string, value: *) => void,
  setFieldTouched: (name: string, value: *) => void,
  handleSubmit: (event: Event<>) => Promise<OAuthData>,
  values: Dict,
  errors: Dict,
  touched: Dict,
  session: SessionData,
};

type State = {
  submitClicked: boolean,
};

const bg = require('./assets/login.png');
const logo = require('./assets/logo.png');
const sgfLogo = require('../../../assets/sgf.png');
const iconBack = require('../../../assets/icon-back.png');

class LogIn extends Component<Props, State> {
  static navigationOptions = () => ({
    headerTransparent: true,
    title: '',
    headerBackImage: () => (
      <Image
        source={iconBack}
        style={{ width: 24, height: 24, tintColor: '#fff' }}
        tint={'#fff'}
        resizeMode="contain"
      />
    ),
  });

  static schema = yup.object().shape({
    email: yup.string().required('logIn.emptyEmail'),

    password: yup.string().required('logIn.emptyPassword'),
  });

  componentDidMount() {
    if (Platform.OS === 'android') {
      SoftInputMode.set(SoftInputMode.ADJUST_PAN);
    }
  }

  state = {
    submitClicked: false,
  };

  setEmail = (email) => {
    this.props.setFieldValue('email', email);
    this.props.setFieldTouched('email', true);
  };

  setPassword = (password) => {
    this.props.setFieldValue('password', password);
    this.props.setFieldTouched('password', true);
  };

  logIn = throttle(
    (evt) => {
      Keyboard.dismiss();
      this.setState({ submitClicked: true });
      this.props.handleSubmit(evt);
    },
    5000,
    { trailing: false },
  );

  forgotPass = () => {
    this.props.navigation.navigate('ForgotPassword');
  };

  renderLoginButton() {
    return (
      <Button
        style={{ backgroundColor: '#fff', marginTop: 30 }}
        textStyle={{ color: '#000' }}
        title={i18n.t('logIn.login')}
        onPress={this.logIn}
        disabled={this.props.session.loading}
      />
    );
  }

  renderRegisterHintView = () => {
    const { session } = this.props;
    const { submitClicked } = this.state;
    if (!submitClicked || session.status !== 404) {
      return null;
    }
    return (
      <View style={styles.registerHintContainer}>
        <HintArrows />
      </View>
    );
  };

  handleGolfBoxPress = () => {
    const { navigation } = this.props;
    navigation.navigate('LoginSelect');
  };

  handleRegisterPress = () => {
    const { navigation } = this.props;
    navigation.navigate('SignUpSelect');
  };

  render() {
    const { values, errors, touched, session } = this.props;
    const { submitClicked } = this.state;
    const emailErrorText = errors.email ? i18n.t(errors.email) : null;
    const passwordErrorText = errors.password ? i18n.t(errors.password) : null;

    return (
      <View style={styles.tint}>
        <Image source={sgfLogo} style={styles.logo} />

        <ScrollView style={styles.screen} keyboardShouldPersistTaps="handled" enableOnAndroid>
          <View style={styles.logInForm}>
            <Input
              theme="dark"
              label={'Golf-ID'}
              maskTemplate={{ mask: '999999-999' }}
              textContentType="username"
              textContentType="oneTimeCode"
              autoCorrect={false}
              autoCapitalize="none"
              value={values.email}
              placeholder={'______-___'}
              placeholderTextColor={colorGray}
              onChangeText={this.setEmail}
              error={submitClicked && touched.email && errors.email}
              errorText={emailErrorText}
            />

            <Input
              theme="dark"
              label={i18n.t('logIn.password')}
              secureTextEntry
              textContentType="oneTimeCode"
              // autoCompleteType="password"
              keyboardType="default"
              value={values.password}
              onChangeText={this.setPassword}
              //  placeholder={i18n.t('logIn.passwordPlaceholder')}
              placeholderTextColor={colorGray}
              error={submitClicked && touched.password && errors.password}
              errorText={passwordErrorText}
              multiline={false}
            />
            <OAuthPlugin data={this.props.OAuth} setError={alert} navigation={this.props.navigation} />
            {session.error ? <Text style={styles.errorMessage}>{session.error}</Text> : null}
            {this.renderLoginButton()}
          </View>
        </ScrollView>
      </View>
    );
  }
}

export default compose(
  connect(
    ({ session }) => ({
      session: session.session,
      OAuth: session.OAuth,
    }),
    { logIn, gotOAuthData },
  ),
  withFormik({
    mapPropsToValues() {
      return {
        // email: '910804-003',
        // password: '557733',
        email: '',
        password: '',
      };
    },
    validationSchema: LogIn.schema,
    validateOnChange: true,
    async handleSubmit(values, { props }) {
      console.log(values);
      const res = await loginGit(values.email, values.password);
      if (res.error) {
        alert(res.error);
      } else {
        props.gotOAuthData(res);
      }
    },
  }),
)(LogIn);

//const r = extractJson(sampleHtml23e2);
//console.log(r);
