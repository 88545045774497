// @flow

import { createSelector } from 'reselect';
import haversine from 'haversine';
import unionBy from 'lodash/unionBy';
import type { GlobalState } from '../../store/modules';

export const getClubs = (state: GlobalState) => state.club.myclub.data;
export const getClubsById = (state: GlobalState) => state.club.myclub.dataById;
export const selectCurrentClub = (state: GlobalState) => state.club.myclub.currentClub;

export const getSortedClubs = createSelector(
  (state: GlobalState) => state.club.myclub,
  (state: GlobalState) => state.club.myclub.currentClub,
  state => state.location,
  getClubs,
  (myclub, currentClub, location, clubs = []) => {
    // Nearby club (if it's current showing club) should be shown in the list.
    const sortedClubs = !currentClub?.clubDetails
      ? clubs
      : unionBy([currentClub.clubDetails], clubs, 'club_id');

    if (!sortedClubs) {
      return [];
    }
    if (location?.hasLocation) {
      // Clubs should be sorted by distance.
      const loc = { latitude: location.lat, longitude: location.lng };
      sortedClubs.sort((dep1, dep2) => haversine(loc, dep1) - haversine(loc, dep2));
    }
    return sortedClubs;
  },
);
