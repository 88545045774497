// @flow
import type { PasswordRecoveryState } from './password';
import type { UserState } from './user';
import type { RegisteringState } from './registering';

import passwordReducers, { initPasswordState } from './password';
import userReducers, { initUserState } from './user';
import registeringReducers, { initRegisteringState } from './registering';
import updatingReducers, { initUpdatingState } from './update';
import changeClubReducers, { initChangeState } from './changeClub';

export type State = {
  passwordRecovery: PasswordRecoveryState,
  user: UserState,
  registering: RegisteringState,
};

const initialState: State = {
  ...initPasswordState,
  ...initUserState,
  ...initRegisteringState,
  ...initUpdatingState,
  ...initChangeState,
};

const reducers = {
  ...passwordReducers,
  ...userReducers,
  ...registeringReducers,
  ...updatingReducers,
  ...changeClubReducers,
};

export default function userReducer(state: State = initialState, action: Object): State {
  const reducer = reducers[action.type];

  if (reducer) {
    return reducer(state, action);
  }

  return state;
}

export { recoveryPassword, goBack } from './password';
export {
  loadUser,
  loadLocalUser,
  loadLocalUserSuccess,
  LOAD_USER_SUCCESS,
  LOAD_LOCAL_USER,
  DELETE_USER,
  DELETE_USER_FAILED,
  DELETE_USER_SUCCESS,
} from './user';
export { setRegisteringData } from './registering';
export { updateUser } from './update';
export { changeUserClub } from './changeClub';
