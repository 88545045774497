// @flow
import api from '../../api';
import { API_REQUEST } from '../../store/apiAction';
import type { ApiRequest } from '../../store/apiAction';
import type { SessionType } from '../modules/session';

export const LOGIN: 'session/LOGIN' = 'session/LOGIN';
export const LOGIN_SUCCESS: 'session/LOGIN_SUCCESS' = 'session/LOGIN_SUCCESS';
const LOGIN_FAILED: 'session/LOGIN_FAILED' = 'session/LOGIN_FAILED';
export const LOGIN_AUTO: 'session/LOGIN_AUTO' = 'session/LOGIN_AUTO';
export const LOGIN_AUTO_SUCCESS: 'session/LOGIN_AUTO_SUCCESS' = 'session/LOGIN_AUTO_SUCCESS';
export const LOGIN_AUTO_FAILED: 'session/LOGIN_AUTO_FAILED' = 'session/LOGIN_AUTO_FAILED';

const LOGOUT: 'session/LOGOUT' = 'session/LOGOUT';
export const LOGOUT_SUCCESS: 'session/LOGOUT_SUCCESS' = 'session/LOGOUT_SUCCESS';
const LOGOUT_FAILED: 'session/LOGOUT_FAILED' = 'session/LOGOUT_FAILED';

export const LOGIN_IMPORTED_TOKEN: 'session/LOGIN_IMPORTED_TOKEN' = 'session/LOGIN_IMPORTED_TOKEN';
export const LOGIN_AND_UPDATE_ACCOUNT: 'session/LOGIN_AND_UPDATE_ACCOUNT' = 'session/LOGIN_AND_UPDATE_ACCOUNT';

export const UPDATE_WITH_SESSION_ID: 'session/UPDATE_WITH_SESSION_ID' = 'session/UPDATE_WITH_SESSION_ID';

export const GOT_OAUTH_DATA: 'GOT_OAUTH_DATA' = 'GOT_OAUTH_DATA';

type LoginActionT = {|
  type: typeof LOGIN,
  data: Object,
|};

type LoginAutoActionT = {|
  type: typeof LOGIN_AUTO,
|};

type LogoutActionT = {|
  type: typeof LOGOUT,
|};

type LoginSuccessActionT = {|
  type: typeof LOGIN_SUCCESS,
|};

type LoginAutoSuccessActionT = {|
  type: typeof LOGIN_AUTO_SUCCESS,
|};

type LogoutSuccessActionT = {|
  type: typeof LOGOUT_SUCCESS,
  result: SessionType,
|};

type LoginFailureActionT = {|
  type: typeof LOGIN_FAILED,
  error: ?string,
  status: number,
|};

type LoginAutoFailureActionT = {|
  type: typeof LOGIN_AUTO_FAILED,
  error: ?string,
|};

type LogoutFailureActionT = {|
  type: typeof LOGOUT_FAILED,
  error: string,
|};

type SessionAction =
  | LoginActionT
  | LoginSuccessActionT
  | LoginFailureActionT
  | LoginAutoActionT
  | LoginAutoSuccessActionT
  | LoginAutoFailureActionT
  | LogoutActionT
  | LogoutSuccessActionT
  | LogoutFailureActionT;

export type SessionData = {
  error: ?string,
  loading: boolean,
  autoLogin: {
    loading: boolean,
    success: boolean,
    error: ?string,
  },
};

export type SessionStateT = {
  session: SessionData,
};

const initialState: SessionStateT = {
  session: {
    error: null,
    loading: false,
    autoLogin: {
      loading: false,
      success: false,
      error: null,
    },
  },
};

export default function sessionReducer(state: SessionStateT = initialState, action: SessionAction) {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        session: {
          ...initialState.session,
          loading: true,
        },
      };

    case GOT_OAUTH_DATA:
      return {
        ...state,
        OAuth: action.payload,
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        session: {
          ...initialState.session,
        },
      };

    case LOGIN_FAILED:
      return {
        ...state,
        session: {
          ...initialState.session,
          error: action.error,
          status: action.status,
        },
      };

    case LOGIN_AUTO:
      return {
        ...state,
        session: {
          ...initialState.session,
          autoLogin: {
            ...initialState.session.autoLogin,
            loading: true,
          },
        },
      };

    case LOGIN_AUTO_SUCCESS:
      return {
        ...state,
        session: {
          ...initialState.session,
          autoLogin: {
            ...initialState.session.autoLogin,
            success: true,
          },
        },
      };

    case LOGIN_AUTO_FAILED:
      return {
        ...state,
        session: {
          ...initialState.session,
          autoLogin: {
            ...initialState.session.autoLogin,
            error: action.error,
          },
        },
      };

    default:
      return state;
  }
}

export function gotOAuthData(data: OAuthData) {
  return {
    type: GOT_OAUTH_DATA,
    payload: data,
  };
}

export function logIn(data: Object): LoginActionT {
  return {
    type: LOGIN,
    data,
  };
}

export function loginFailed(error: ?string, status: number): LoginFailureActionT {
  return {
    type: LOGIN_FAILED,
    error,
    status,
  };
}

export function loginSuccess(): LoginSuccessActionT {
  return {
    type: LOGIN_SUCCESS,
  };
}

export function loginAuto(): LoginAutoActionT {
  return {
    type: LOGIN_AUTO,
  };
}

export function loginAutoFailed(error: ?string): LoginFailureActionT {
  return {
    type: LOGIN_AUTO_FAILED,
    error,
  };
}

export function loginAutoSuccess(): LoginSuccessActionT {
  return {
    type: LOGIN_AUTO_SUCCESS,
  };
}

export function loginImportedToken(token: string) {
  return {
    type: LOGIN_IMPORTED_TOKEN,
    payload: token,
  };
}

export function patchSession(session: Object): ApiRequest<*> {
  return {
    type: API_REQUEST,
    types: [LOGOUT, LOGOUT_SUCCESS, LOGOUT_FAILED],
    call: () => api().session.patch(session),
    promisified: true,
  };
}

export const GET_MOREBOX_TOKEN = 'GET_MOREBOX_TOKEN';
export const GET_MOREBOX_TOKEN_SUCCESS = 'GET_MOREBOX_TOKEN_SUCCESS';
export const GET_MOREBOX_TOKEN_FAILURE = 'GET_MOREBOX_TOKEN_FAILURE';

export function moreboxToken(): ApiRequest<*> {
  return {
    type: API_REQUEST,
    types: [GET_MOREBOX_TOKEN, GET_MOREBOX_TOKEN_SUCCESS, GET_MOREBOX_TOKEN_FAILURE],
    call: () => api().session.accessToken(),
    promisified: true,
  };
}

export function logOut(): ApiRequest<*> {
  return {
    type: API_REQUEST,
    types: [LOGOUT, LOGOUT_SUCCESS, LOGOUT_FAILED],
    call: () => api().session.delete(),
  };
}

export function loginAndUpdateAccount(email, password, accountUpdateInfo) {
  return {
    type: LOGIN_AND_UPDATE_ACCOUNT,
    payload: { email, password, accountUpdateInfo },
  };
}

export function updateWithSessionId(updateSessionId, accountUpdateInfo) {
  return {
    type: UPDATE_WITH_SESSION_ID,
    payload: { updateSessionId, accountUpdateInfo },
  };
}
