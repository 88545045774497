// @flow
import API from '../base/api';
import type { ReqType } from '../index';

export type HistoryT = {
  history_id: number,
  user_id: number,
  subject: string,
  voucher_id: number,
  created_timestamp: number,
  image: string,
};

class HistoryAPI extends API {
  getHistory() {
    return this.r({
      method: 'GET',
      url: '/history',
    });
  }
}

export default function historyApi(request: ReqType) {
  return new HistoryAPI(request);
}
