import styled from 'styled-components/native';
import { space, color, layout, border, position, flexbox } from 'styled-system';

import { TextS, TextN, TextXs, TextXl } from '../../../../react-native/components/Text';
import NewButton from '../../../../react-native/components/Button/NewButton';

export const Text = TextS;
export const CountryText = TextN;

const golfBox = require('../../../../react-native/auth/screens/LogIn/assets/golfbox.png');
const backgroundImage = require('../../../../react-native/auth/screens/LogIn/assets/login.jpg');
const logo = require('../../../../react-native/auth/screens/LogIn/assets/logo.png');
const backArrow = require('../../../../react-native/components/Search/assets/back@3x.png');
const swedishGolf = require('../../../../react-native/assets/sgf.png');

const BUTTON_HEIGHT = 65;

export const BlackPane = styled.View`
  flex-direction: row;
  background-color: #282e36;
  width: 100%;
  height: 100%;
`;

export const LeftPanel = styled.View`
  width: 500px;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const BackgroundImage = styled.Image.attrs({
  source: backgroundImage,
  resizeMode: 'cover',
})`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`;

export const BackgroundImageCover = styled.View`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #282e36;
  opacity: 0.9;
`;

export const LogoContainer = styled.View`
  flex-direction: row;
  align-items: center;
`;

export const Logo = styled.Image.attrs({
  source: logo,
  mr: 8,
})`
  width: 80px;
  height: 80px;
  ${space}
`;

export const LogoText = styled(Text).attrs({
  fontFamily: 'bold',
  fontSize: 48,
  color: 'white',
})`
  margin-top: 16px;
`;

export const ContactLinksContainer = styled.View`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const ContactLinkText = styled(TextXs).attrs({
  color: 'white',
})``;

export const ContactLinkSeparator = styled.View.attrs({
  ml: 4,
  mr: 4,
})`
  width: 1px;
  background-color: white;
  align-self: stretch;
  ${space}
`;

export const BackContainer = styled.View.attrs(({ floating }) => ({
  left: floating ? -17 : 0,
  width: floating ? 34 : 44,
  height: floating ? 34 : 44,
  top: floating ? 23 : 18,
}))`
  justify-content: center;
  align-items: center;
  position: absolute;
  ${position}
  ${layout}
  ${({ floating }) =>
    !floating
      ? ''
      : `
  box-shadow: 0 3px 6px #00000029;
  background-color: white;
  border-radius: 17px;
  `}
`;

export const BackIcon = styled.Image.attrs({
  source: backArrow,
  resizeMode: 'contain',
})`
  width: 16px;
  height: 16px;
`;

export const RightPanel = styled.View.attrs(({ centerContent }) => ({
  alignItems: centerContent ? 'center' : 'stretch',
}))`
  flex: 1;
  background-color: #f6f6f7;
  ${flexbox}
`;

export const HeaderContainer = styled.View.attrs({
  pl: 40,
  pr: 6,
})`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  height: 80px;
  background-color: white;
  ${space}
`;

export const TitleContainer = styled.View`
  justify-content: center;
  align-self: stretch;
`;

export const TitleText = TextXl;

export const MembershipText = styled(TextXs).attrs({
  color: 'green',
})`
  position: absolute;
  left: 0;
  bottom: 8px;
`;

export const SignInContainer = styled.View`
  flex-direction: row;
  align-items: center;
`;

export const AlreadyHaveAccountLabel = styled(TextXs).attrs({
  color: '#8e8ea5',
})``;

export const SignInButton = styled(NewButton).attrs({
  color: 'green',
  ml: 3,
  borderRadius: 5,
  fontSize: 12,
})`
  height: 27px;
  box-shadow: 0 3px 7px #0000002b;
  ${border}
`;

export const Touchable = styled.TouchableWithoutFeedback``;

export const GolfBoxContainer = styled.View.attrs({
  flex: 1,
  borderRadius: 5,
  ml: 4,
  bg: 'golfBox',
})`
  box-shadow: 0 4px 10px #0000004f;
  flex-direction: row;
  flex-grow: 1;
  height: 100%;
  ${color}
  ${border}
  ${space}
`;

export const SGFContainer = styled.View.attrs({
  flex: 1,
  borderRadius: 5,
  ml: 4,
  bg: '#004a87',
})`
  box-shadow: 0 4px 10px #0000004f;
  flex-direction: row;
  flex-grow: 1;
  height: 100%;
  ${color}
  ${border}
  ${space}
`;

export const EmailContainer = styled.View.attrs({
  flex: 1,
  borderRadius: 5,
  bg: 'green',
  mt: 7,
})`
  box-shadow: 0 4px 10px #0000004f;
  height: 70px;
  flex-direction: row;
  z-index: -1;
  ${color}
  ${border}
    ${space}
`;

export const ButtonIconContainer = styled.View.attrs({
  width: BUTTON_HEIGHT,
  mr: 5,
  borderTopLeftRadius: 5,
  borderBottomLeftRadius: 5,
  bg: '#00000014',
})`
  justify-content: center;
  align-items: center;
  ${layout}
  ${space}
  ${color}
`;

export const GolfBoxIcon = styled.Image.attrs({
  width: 36,
  height: 36,
  resizeMode: 'contain',
  source: golfBox,
})`
  ${layout}
`;

export const FooterContainer = styled.View`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
  align-self: center;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const ButtonText = styled(TextS).attrs({
  fontFamily: 'medium',
  color: 'white',
})`
  align-self: center;
`;

export const ButtonsContainer = styled.View`
  justify-content: center;
  max-width: 500px;
  width: 100%;
  height: 100%;
  padding: 32px;
`;

export const GolfRow = styled.View.attrs({
  height: BUTTON_HEIGHT,
  mb: 8,
})`
  flex-direction: row;
  align-items: center;
  height: 70px;
  ${layout}
`;

export const PickerContainer = styled.View`
  box-shadow: 0 4px 10px #0000004f;
  width: 100px;
`;

export const SGFButtonIconContainer = styled.View.attrs({
  width: BUTTON_HEIGHT,
  mr: 5,
  borderTopLeftRadius: 5,
  borderBottomLeftRadius: 5,
})`
  justify-content: center;
  align-items: center;
  ${layout}
  ${space}
  ${color}
  ${flexbox}
`;

export const SGFIcon = styled.Image.attrs({
  width: 36,
  height: 36,
  resizeMode: 'contain',
  source: swedishGolf,
})`
  ${layout}
  ${flexbox}
`;
