import React from 'react';

import urls from '../../../../constants/config/urls';

const privacyPolicyEnglish = () => (
  <div className="b-privacy-policy__content grid-xl">
    <h1 className="b-privacy-policy__title">PRIVACY POLICY (VERSION 1.1, Updated June 2018)</h1>
    <p className="b-privacy-policy__paragraph">In this document, "GolfMore", "we" "us" and "our" refer to ProBox24
            ApS, located at Rugaardsvej
            5, 8680 Ry, Denmark and its affiliates.</p>
    <p className="b-privacy-policy__paragraph">
            By using the GolfMore app or otherwise using our sites, including but not limited to
      <a className="b-privacy-policy__link" href={urls.danish}> www.golfmore.dk</a>, or our applications
            (collectively referred to as "the Site"), you hereby agree
            that we process your personal information ("the policy ") as described in this Policy.
    </p>
    <ul className="b-privacy-policy__list">
      <li className="b-privacy-policy__item">
        <h2 className="b-privacy-policy__sub-title">1 Types of information we collect and what we use them
                    for</h2>
        <div className="b-privacy-policy__sub-section">
          <h3 className="b-privacy-policy__sub-inner-title">1.1 master data</h3>
          <p className="b-privacy-policy__paragraph">When you create a profile, we collect the following
                        master data about you ("master data"):</p>
          <ul className="b-privacy-policy__sub-inner-list">
            <li className="b-privacy-policy__sub-inner-item">Your first name,</li>
            <li className="b-privacy-policy__sub-inner-item">Your last name</li>
            <li className="b-privacy-policy__sub-inner-item">Your e-mail address</li>
            <li className="b-privacy-policy__sub-inner-item">Your mobile number.</li>
            <li className="b-privacy-policy__sub-inner-item">Your date of birth</li>
            <li className="b-privacy-policy__sub-inner-item">Your country</li>
            <li className="b-privacy-policy__sub-inner-item">Your gender</li>
            <li className="b-privacy-policy__sub-inner-item">Your membership number at a golf club</li>
            <li className="b-privacy-policy__sub-inner-item">Your home club</li>
            <li className="b-privacy-policy__sub-inner-item">Your member type</li>
            <li className="b-privacy-policy__sub-inner-item">Your login information</li>
          </ul>
        </div>
        <div className="b-privacy-policy__sub-section">
          <h3 className="b-privacy-policy__sub-inner-title">1.2 Information in connection with the use of
                        GolfMore</h3>
          <p className="b-privacy-policy__paragraph">In connection with the use of GolfMore, we collect data
                        from various golf booking systems (e.g.,
                        but not limited to GolfBox and GolfWorks), and store necessary information, such as:</p>
          <ul className="b-privacy-policy__sub-inner-list">
            <li className="b-privacy-policy__sub-inner-item">Where, when, with whom, and at what price, you
                            should play golf
            </li>
            <li className="b-privacy-policy__sub-inner-item">What discounts did you receive when you paid at
                            what price,
                            with which payment method in connection with greenfeeding
            </li>
            <li className="b-privacy-policy__sub-inner-item">Which vouchers you have used, where, when and
                            what, and at what original price
            </li>
            <li className="b-privacy-policy__sub-inner-item">Who you transfer / receive vouchers from/to
            </li>
            <li className="b-privacy-policy__sub-inner-item">What vouchers you have made digital via the
                            Upload feature
            </li>
            <li className="b-privacy-policy__sub-inner-item">Which vouchers you have generated PDFs.</li>
            <li className="b-privacy-policy__sub-inner-item">Your location</li>
          </ul>
        </div>
        <div className="b-privacy-policy__sub-section">
          <h3 className="b-privacy-policy__sub-inner-title">1.3 Cookies</h3>
          <p className="b-privacy-policy__paragraph">The site uses cookies. Cookies are digital information
                        stored on your computer. Cookies identify
                        your computer instead of you as an individual user, and are used for different purposes.
                        Read more about what cookies the site uses and for what purposes below (section 7).</p>
        </div>
        <div className="b-privacy-policy__sub-section">
          <h3 className="b-privacy-policy__sub-inner-title">1.4 Your IP address, browser settings and
                        location</h3>
          <p className="b-privacy-policy__paragraph">When you visit the Web site, we register your computer's
                        IP address and browser settings. The IP
                        address is the numeric address of the computer used to visit the site. Browser settings may
                        include the type of browser you are using, browser language, and time zone. We collect this
                        information in order to prevent and clarify any abuse or unlawful actions associated with visits
                        to
                        or use of the Site. In addition, we use the IP address to estimate your location (at city
                        level).</p>
        </div>
        <div className="b-privacy-policy__sub-section">
          <h3 className="b-privacy-policy__sub-inner-title">1.5 Information given when signing up for a
                        newsletter</h3>
          <p className="b-privacy-policy__paragraph">We collect the information you provide us when you sign
                        up for the receipt of our newsletters,
                        such as your name and your email address. If you no longer wish to receive our newsletters, you
                        can unsubscribe by logging in to your user profile and changing your personal settings.</p>
        </div>
        <div className="b-privacy-policy__sub-section">
          <h3 className="b-privacy-policy__sub-inner-title">1.6 How to use this information</h3>
          <p className="b-privacy-policy__paragraph">We may use the information you provide us with in order
                        to fulfill the contract with you including:</p>
          <ul className="b-privacy-policy__sub-inner-list">
            <li className="b-privacy-policy__sub-inner-item">
                            Deliver our services to you and give you access to our Website.
            </li>
            <li className="b-privacy-policy__sub-inner-item">
                            Identify yourself as a registered user when logging in to the Site.
            </li>
            <li className="b-privacy-policy__sub-inner-item">
                            Answer your questions and provide related customer service.
            </li>
            <li className="b-privacy-policy__sub-inner-item">
                            Contact us if we experience abuse of your account.
            </li>
          </ul>
          <p className="b-privacy-policy__paragraph">In addition, we may use your information to pursue
                        legitimate interests, including:</p>
          <ul className="b-privacy-policy__sub-inner-list">
            <li className="b-privacy-policy__sub-inner-item">
                            Improve the site and our services.
            </li>
            <li className="b-privacy-policy__sub-inner-item">
                            Invite you to use our services and offers.
            </li>
            <li className="b-privacy-policy__sub-inner-item">
                            Send us our newsletters.
            </li>
            <li className="b-privacy-policy__sub-inner-item">
                            Perform various internal business objectives, such as data analysis, audits,
                            monitoring and prevention of abuse, developing new products and services,
                            improving or changing the Site or our services, identifying usage trends, determining
                            the effectiveness of our advertising campaigns, and running and expanding our
                            business activities.
            </li>
            <li className="b-privacy-policy__sub-inner-item">
                            Disclose your information as described in Section 2.
            </li>
          </ul>
          <p className="b-privacy-policy__paragraph">
                        You have the right to object to processing your information for the above purposes. See more
                        under your rights under section 8.
          </p>
          <p className="b-privacy-policy__paragraph">
                        Finally, we can process your information in order to determine or defend a legal claim,
                        including
                        to:
          </p>
          <ul className="b-privacy-policy__sub-inner-list">
            <li className="b-privacy-policy__sub-inner-item">
                            Comply with applicable legal requirements and legal process, comply with
                            government and government requests, and comply with relevant industry standards
                            and our internal policies.
            </li>
            <li className="b-privacy-policy__sub-inner-item">
                            Enforce our Terms and Conditions.
            </li>
            <li className="b-privacy-policy__sub-inner-item">
                            Protect our business activities or those belonging to any of our affiliates.
            </li>
            <li className="b-privacy-policy__sub-inner-item">
                            Protect our rights, privacy, security or property and / or those of our affiliated
                            companies, you or others.
            </li>
            <li className="b-privacy-policy__sub-inner-item">
                            Allow us to pursue available remedies or limit any liability we may incur.
            </li>
          </ul>

          <p className="b-privacy-policy__paragraph">
                        We will only use your information for other purposes to the extent that we have provided you
                        with information on this at the time of collection.
          </p>

          <h3 className="b-privacy-policy__sub-inner-title">1.7 As long as we keep your information</h3>
          <p className="b-privacy-policy__paragraph">We will keep your information for the period during which
                        they are necessary for the above
                        purposes, including as long as your profile remains active with us.
                        We will delete your information if you have not used or logged in to your profile for more than
                        24
                        months and your account has no active vouchers. In other cases, we may delete your information
                        as described in section 8.2.</p>

        </div>

        <h2 className="b-privacy-policy__sub-title">2 Disclosure of Personal Information</h2>
        <div className="b-privacy-policy__sub-section">
          <h3 className="b-privacy-policy__sub-inner-title">2.1 Disclosure of Personal Information on the
                        Site</h3>
          <p className="b-privacy-policy__paragraph">In connection with your use of the Site, we will disclose
                        certain information about you.
                        Payment details. We forward the necessary data regarding the use of vouchers for the golf
                        booking system for the
                        respective club.
          </p>
        </div>
        <div className="b-privacy-policy__sub-section">
          <h3 className="b-privacy-policy__sub-inner-title">2.2 Transmission to other services and
                        companies</h3>
          <p className="b-privacy-policy__paragraph">One of GolfMore's main purposes is to help golf clubs
                        manage their vouchers. Therefore, we allow
                        clubs to access information that is relevant to those in use in their club.
                        When you receive vouchers from a club or have used GolfMore in a club, the following data is
                        available</p>
          <ul className="b-privacy-policy__sub-inner-list">
            <li className="b-privacy-policy__sub-inner-item">Your first name, last name, member number, home
                            club
            </li>
            <li className="b-privacy-policy__sub-inner-item">Which vouchers you have used and which original
                            price was at the time of redemption, redemption date, how much use is left on the voucher.
            </li>
          </ul>
        </div>
        <div className="b-privacy-policy__sub-section">
          <h3 className="b-privacy-policy__sub-inner-title">2.3 Other disclosures</h3>
          <p className="b-privacy-policy__paragraph">In addition to the above, we may disclose your personal
                        information to the following parties and
                        under the following circumstances:</p>
          <ul className="b-privacy-policy__sub-inner-list">
            <li className="b-privacy-policy__sub-inner-item">
                            To allow third-party vendors, consultants and other service providers to perform
                            services on our behalf.
            </li>
            <li className="b-privacy-policy__sub-inner-item">
                            To ProBox24 ApS subsidiaries and other companies in the ProBox24 Group.
            </li>
            <li className="b-privacy-policy__sub-inner-item"> To comply with laws or respond to claims,
                            legal process (including but not limited
                            to applications and court orders) and comply with requests from government and
                            government authorities.
            </li>
            <li className="b-privacy-policy__sub-inner-item">
                            To third parties in connection with the enforcement of our
              <a
                className="b-privacy-policy__link"
                href={urls.englishTermsAndConditions}
                target="_blank"
                rel="noopener noreferrer nofollow"
              > Terms and Conditions</a>.
            </li>
            <li className="b-privacy-policy__sub-inner-item">
                            To third parties to protect our operations or the operations of our affiliates.
            </li>
            <li className="b-privacy-policy__sub-inner-item">
                            To third parties to pursue available remedies, or limit liability, as we may incur.
            </li>
            <li className="b-privacy-policy__sub-inner-item">
                            To third parties to investigate, prevent or interfere with suspected or actual prohibited
                            activities,
                            including but not limited to abuse of our Website.
            </li>
          </ul>
        </div>
      </li>

      <li className="b-privacy-policy__item">
        <h2 className="b-privacy-policy__sub-title">3 Data Manager</h2>
        <div className="b-privacy-policy__sub-section">
          <p className="b-privacy-policy__paragraph">We are the data controller for Master Data that you enter
                        in connection with your creation of a
                        profile that includes but is not limited to your name, password and email address, as well as
                        the
                        registration of your IP address.</p>
          <p className="b-privacy-policy__paragraph">We are also the data controller for the information
                        communicated to other services (see section
                        2.2). GolfMore's collection of your personal data is governed by the personal data law currently
                        in
                        force in Denmark.</p>
        </div>
      </li>

      <li className="b-privacy-policy__item">
        <h2 className="b-privacy-policy__sub-title">4 Links to websites</h2>
        <div className="b-privacy-policy__sub-section">
          <p className="b-privacy-policy__paragraph">Our site contains links to other sites. Our inclusion of
                        such links does not reflect our approval of
                        the sites in question. We do not control the content of these third party sites and assume no
                        responsibility for the third party or their policies or practices.
                        We encourage you to review these third party websites&#39; personal data policies as their
                        procedures
                        for collecting, handling and processing personal information may be different from ours.</p>
        </div>
      </li>

      <li className="b-privacy-policy__item">
        <h2 className="b-privacy-policy__sub-title">5 Data processing and transfer of personal information</h2>
        <div className="b-privacy-policy__sub-section">
          <p className="b-privacy-policy__paragraph">We use external suppliers to maintain the technical
                        operation of the Site and our services. These
                        companies are data administrators for the personal information for which we are data
                        controllers.
                        The data servers must act only in accordance with our instructions. We will therefore give such
                        instructions to the data servers to process data in accordance with the Policy and in order to
                        use
                        the Site.</p>
          <p className="b-privacy-policy__paragraph">The data servers have taken reasonable technical and
                        organizational measures to protect the
                        information from accidental or illegal destruction, loss or deterioration, and to protect the
                        information against disclosure to unauthorized abuse or other processing in violation of the
                        Personal Data Act.</p>
        </div>
      </li>

      <li className="b-privacy-policy__item">
        <h2 className="b-privacy-policy__sub-title">6 Precautions</h2>
        <div className="b-privacy-policy__sub-section">
          <p className="b-privacy-policy__paragraph">We seek to apply appropriate organizational, technical
                        and administrative measures to protect
                        your personal information in our organization, and we regularly review our vulnerability system.
                        However, since the Internet is not a 100% safe environment, we can not guarantee or guarantee
                        the security of information you send to GolfMore. E-mails sent via the Site are not encrypted
                        and
                        we advise you not to include confidential or sensitive information in your e-mails to us.
          </p>
        </div>
      </li>

      <li className="b-privacy-policy__item">
        <h2 className="b-privacy-policy__sub-title">7 Cookies</h2>
        <div className="b-privacy-policy__sub-section">
          <p className="b-privacy-policy__paragraph">The site uses cookies and similar technologies
                        ("Cookies"). By using our Website, you agree that
                        we use Cookies as described below.</p>
          <div className="b-privacy-policy__sub-section">
            <h3 className="b-privacy-policy__sub-inner-title">7.1 What types of cookies do we use?</h3>
            <p className="b-privacy-policy__paragraph">Cookies are small pieces of information that the site
                            places on your computer's hard drive, on your
                            tablet or on your smartphone. Cookies contain information that the Web site uses to make
                            communication between you and your web browser more effective. Cookies identify your
                            computer instead of you as an individual user.</p>
            <p className="b-privacy-policy__paragraph">We use session cookies and persistent cookies as well
                            as technologies similar to session cookies
                            and persistent cookies. Session cookies are temporary extracts of information that is
                            deleted when
                            you exit your web browser. Persistent cookies are more permanent extracts of information
                            that is
                            stored and remains on your computer until deleted. Persistent cookies delete themselves
                            after a
                            certain period of time, but renew each time you visit the Site.</p>
            <p className="b-privacy-policy__paragraph">We use technologies that resemble session cookies and
                            ongoing cookies for storage and access
                            information in the browser or device that uses local devices and local storage, such as
                            HTML5
                            cookies, Flash, and other methods. These technologies can work across all of your browsers.
                            In
                            some cases, the use of these technologies can not be controlled by the browser, but requires
                            special tools. We use these technologies to store information to detect irregularities in
                            the use of
                            the site.</p>
          </div>

          <div className="b-privacy-policy__sub-section">
            <h3 className="b-privacy-policy__sub-inner-title">7.2 What do we use Cookies for?</h3>
            <p className="b-privacy-policy__paragraph">We use Cookies for:</p>
            <ul className="b-privacy-policy__sub-inner-list">
              <li className="b-privacy-policy__sub-inner-item">
                                Generation of statistics
              </li>
            </ul>
            <p className="b-privacy-policy__paragraph">Measurement of Site Traffic such as the number of
                            visits to the site, which domains
                            visitors come from, which pages visitors visit the site and in which overall
                            geographical areas visitors are located.</p>
            <ul className="b-privacy-policy__sub-inner-list">
              <li className="b-privacy-policy__sub-inner-item">
                                Monitoring Website Performance and Your Use of our Website
              </li>
            </ul>
            <p className="b-privacy-policy__paragraph">Monitoring the site, our applications, and widgets'
                            performance, and how to use our
                            website, applications, and widgets.</p>
            <ul className="b-privacy-policy__sub-inner-list">
              <li className="b-privacy-policy__sub-inner-item">
                                Approval and improvement of the functionality of our Website
              </li>
            </ul>
            <p className="b-privacy-policy__paragraph">Optimize your experience with the Site, which
                            includes remembering your username
                            and password when returning to the site, as well as remembering your browser and
                            preferences information (for example, which language you prefer).</p>
          </div>
          <div className="b-privacy-policy__sub-section">
            <h3 className="b-privacy-policy__sub-inner-title">7.3 Third party cookies</h3>
            <p className="b-privacy-policy__paragraph">Our Website uses Cookies from the following third
                            parties:</p>
            <ul className="b-privacy-policy__sub-inner-list">
              <li className="b-privacy-policy__sub-inner-item">
                                Google Analytics: In order to monitor the traffic on our site, such as general visits,
                                conversions, and the like.
              </li>
              <li className="b-privacy-policy__sub-inner-item">
                                Fabric Crashlytics: In order to track errors and crashes on our site.
              </li>
              <li className="b-privacy-policy__sub-inner-item">
                                Javascript: For monitoring purposes, including how to use our website, widgets and
                                applications, and to create rich web sites and widgets.
              </li>
            </ul>
          </div>
          <div className="b-privacy-policy__sub-section">
            <h3 className="b-privacy-policy__sub-inner-title">7.4 Deleting Cookies</h3>
            <p className="b-privacy-policy__paragraph">If you want to delete cookies already on your device,
                            you can: If you are using a PC and a recent
                            browser, press CTRL + SHIFT + DELETE simultaneously when you have the browser open and
                            active. If the shortcuts do not work in your browser, please visit the support page for that
                            browser. Please note, however, that if you do not accept cookies from our site, you may
                            experience some disadvantages in your use of the Site, and you may be prevented from
                            accessing
                            all of its features.</p>
          </div>
        </div>
      </li>

      <li className="b-privacy-policy__item">
        <h2 className="b-privacy-policy__sub-title">8 Access, correction and deletion</h2>
        <div className="b-privacy-policy__sub-section">
          <h3 className="b-privacy-policy__sub-inner-title">8.1 Access to information about your personal
                        information</h3>
          <p className="b-privacy-policy__paragraph">At your request, we will inform you about the purpose for
                        which we process your personal
                        information, what personal information we have about you, who receives your personal
                        information and from which your personal information originates.
                        A request for the above will initially only be processed if more than six (6) months have passed
                        any
                        previous request from you unless you can document a particular interest in obtaining the
                        information previously.</p>
          <h3 className="b-privacy-policy__sub-inner-title">8.2 Correction and deletion of your personal
                        information</h3>
          <p className="b-privacy-policy__paragraph">Please inform us if any of the personal information that
                        we, as the data controller, dealt with
                        about you, is incorrect, incomplete or misleading and we will correct them. You will be able to
                        correct most of this information on our website. If possible, we recommend that you correct your
                        information.
          </p>
          <p className="b-privacy-policy__paragraph">You may, at any time, correct or delete any content and
                        personal information on the Site. If your
                        personal information changes or you no longer wish to appear on the Site, you can update or
                        delete the information by logging in to your user profile.</p>
          <p className="b-privacy-policy__paragraph">Once your profile is deleted, all information associated
                        with your user profile will be deleted,
                        including your master data and reviews on the site.</p>
          <p className="b-privacy-policy__paragraph">We reserve the right to block access to your profile and
                        / or delete your profile if the profile or
                        content associated with your profile or your review (s) on the site is, in our opinion,
                        discriminatory, racist, sexually oriented, unethical, threatening, offensive, harassing or
                        otherwise
                        violating applicable laws, third party rights, User Guidelines or incompatible with the purpose
                        of
                        the Site. If we block access to or delete your user profile, we will inform you about the reason
                        for
                        blocking or deleting your profile by sending an email to the address you provided when you
                        created your account.</p>
        </div>
      </li>
      <li className="b-privacy-policy__item">
        <h2 className="b-privacy-policy__sub-title">9 Changes to the Policy</h2>
        <p className="b-privacy-policy__paragraph">We may change this Policy at any time. The date shown at the
                    beginning of this Policy indicates
                    when it was last revised. Any changes will take effect when we publish the revised Policy on the
                    Site. You agree that your continued use of the Site after any published changes constitute your
                    acceptance of the new version of the Policy.</p>
      </li>
      <li className="b-privacy-policy__item">
        <h2 className="b-privacy-policy__sub-title">10 Comments about the Website or the Policy</h2>
        <p className="b-privacy-policy__paragraph">If you have any questions or concerns about our Politics, how
                    we treat your personal information
                    or would like us to correct your personal information, please contact us at: GolfMore A / S,
                    Rugaardsvej 5, 8680 Ry, Denmark, CVR- no .: 33593082, telephone: +45 7070 7999, e-mail:
          <a className="b-privacy-policy__link" href="mailto:info@probox24.com"> info@probox24.com</a>.</p>
      </li>
    </ul>
  </div>
);

export default privacyPolicyEnglish;

