import React from 'react';
import urls from '../../../../constants/config/urls';

const termsDenmark = () => (
  <div className="b-terms__content container grid-xl">
    <div className="columns">
      <div className="column">
        <h1 className="b-terms__title">Vilkår og Betingelser</h1>
        <h2 className="b-terms__sub-title"><span className="g-uppercase">brugervilkår for golfmore.dk</span> (Opdateret juni 2018)</h2>
        <p className="b-terms__paragraph">Nærværende brugervilkår (herefter "Brugervilkår") finder anvendelse for enhver
            brug af GolfMores website  samt applikationer (herefter "Webstedet"), inklusive - men ikke begrænset til -
                      <a className="b-privacy-policy__link" href={urls.danish}
                         target="_blank" rel="noopener noreferrer nofollow">
                          www.GolfMore.dk
                      </a>.</p>
        <p className="b-terms__paragraph">Webstedet udbydes af ProBox24 ApS, Rugaardsvej 5, 8680 Ry, Danmark, info@probox24.com.
            I Brugervilkårene referer ordene "vi", "os" og "vores" til GolfMore.</p>
        <p className="b-terms__paragraph">Nærværende Brugervilkår omfatter alle regelsæt, politikker og retningslinjer,
            der henvises til i dette dokument, eller som vi på anden vis publicerer på Webstedet (sådanne regler politikker
            og retningslinjer kan ændres fra tid til anden), inklusive uden undtagelse:</p>
        <ul className="b-terms__sub-inner-list">
          <li className="b-terms__sub-inner-item">Vores Persondatapolitik ("Privacy Policy"), der kan findes på <a className="b-privacy-policy__link" href={urls.danishPrivacyPolicy}
                             target="_blank" rel="noopener noreferrer nofollow">
                              golfmore.dk/privacy-policy</a></li>
        </ul>
        <p className="b-terms__paragraph">Ved anvendelsen af Webstedet accepterer du at være underlagt Brugervilkårene.
            Hvis du ikke kan acceptere Vilkårene, bedes du undlade yderligere brug af Webstedet. Din oprettelse som bruger
            forudsætter din udtrykkelige accept af Brugervilkårene.</p>
        <h2 className="b-terms__sub-title"><span className="g-uppercase">ydelserne på websitet</span></h2>
        <h3 className="b-terms__sub-title">1 Registreret Bruger</h3>
        <p className="b-terms__paragraph">1.1 For at få adgang til at gøre fuld brug af Webstedet skal du oprette en
            profil og registrere dig som bruger (herefter "Registreret Bruger").</p>
        <p className="b-terms__paragraph">1.2 Det er kun tilladt at oprette én profil pr. person på Webstedet. Profilen
            er personlig og må ikke overdrages til andre.</p>
        <p className="b-terms__paragraph">1.3   For at blive Registreret Bruger skal du have et kodeord. Du vælger selv
            dit kodeord, som skal benyttes sammen med din e-mailadresse, når du logger på Webstedet. Alternativt sender
            vi kodeordet til dig. Kodeordet er personligt og må ikke overdrages eller på anden måde gøres tilgængeligt
            for andre. Det påhviler dig at sørge for, at kodeordet ikke kommer tredjemand i hænde. Såfremt du får kendskab
            til, at kodeordet er eller kan være kompromitteret, er du forpligtet til at underrette os herom. Vi kan og
            vil ændre kodeordet, såfremt der er risiko for, at det er kompromitteret eller anvendes i strid med
            Brugervilkårene.</p>
        <p className="b-terms__paragraph">1.4  Vi har ret til når som helst, uden varsel og uden ansvar, at slette,
            suspendere eller ændre din profil, hvis du handler i strid med Brugervilkårene eller gældende lovgivning.
            Ved sletning af din profil har du ikke adgang til de ydelser på Webstedet, som forudsætter din oprettelse
            og/eller login som Registreret Bruger.
        </p>
        <p className="b-terms__paragraph">1.5  Ved mistanke om misbrug eller lignende forbeholder vi os endvidere retten til på ethvert
            tidspunkt og uden varsel eller begrundelse at slette din profil. Vores ansvarsfraskrivelse vil i
            sådanne tilfælde gælde ubegrænset.</p>
        <p className="b-terms__paragraph">1.6 Vi forbeholder os endvidere retten til på ethvert tidspunkt og uden varsel
            eller begrundelse at slette din profil. Vores ansvarsfraskrivelse vil i sådanne tilfælde gælde ubegrænset.</p>
          <p className="b-terms__paragraph">1.7  Det er ikke tilladt at skaffe eller forsøge at skaffe sig adgang til at
              bruge de dele af Websitet, som forudsætter brugerregistrering, hvis man ikke er Registreret Bruger.</p>
        <h2 className="b-terms__title"><span className="g-uppercase">generelt</span></h2>
        <h3 className="b-terms__sub-title">2 Rettigheder</h3>
        <p className="b-terms__paragraph">2.1  Websitets indhold, såsom - men ikke begrænset til - immaterielle rettigheder,
            tekst, kendetegn, grafik, ikoner, billeder, beregninger, henvisninger og software tilhører enten GolfMore
            eller tredjemand (udover den Registrerede Bruger) og er beskyttet af dansk og international lovgivning,
            herunder ophavsretsloven, varemærkeloven, markedsføringsloven m.fl. Bidrager du med feedback, ideer eller
            forslag til Websitet eller de services, der er tilgængelige via Websitet ("Feedback"), kan vi frit og uden
            begrænsning anvende denne Feedback.</p>
        <p className="b-terms__paragraph">2.2  Uautoriseret kopiering, distribution, fremvisning, fremførelse eller
            anden anvendelse af Websitet eller dele heraf er i strid med dansk og/eller anden lovgivning og kan medføre
            civilretlige såvel som strafferetlige sanktioner.</p>
        <p className="b-terms__paragraph">2.3  Downloading og anden digital kopiering af Websitets indhold eller dele
            heraf er alene tilladt til personlig, ikkekommerciel brug, medmindre andet er aftalt skriftligt med os
            eller er tilladt ifølge gældende præceptiv lovgivning.</p>
        <p className="b-terms__paragraph">2.4  AAlle virksomhedsnavne, varemærker og andre forretningskendetegn på
            Websitet tilhører enten GolfMore eller tredjemand (udover den Registrerede Bruger) og må kun anvendes
            erhvervsmæssigt efter forudgående tilladelse fra henholdsvis os eller tredjemand.</p>
        <h3 className="b-terms__sub-title">3 Personoplysninger</h3>
        <p className="b-terms__paragraph">3.1  Vi foretager forskellige former for behandling af personoplysninger i
            forbindelse med brugen af Websitet. Vores behandling af persondata sker under iagttagelse af persondataloven
            samt vores Persondatapolitik, som du kan finde her: <a className="b-privacy-policy__link"
                         href={urls.danishPrivacyPolicy}
                         target="_blank" rel="noopener noreferrer nofollow">
                          golfmore.dk/privacy-policy
                      </a>.
        </p>
        <p className="b-terms__paragraph">Ved at acceptere Brugervilkårene erklærer du, at du har læst og forstået vores
            Persondatapolitik.</p>
        <h3 className="b-terms__sub-title">4 Ansvarsfraskrivelse og skadesløsholdelse</h3>
        <p className="b-terms__paragraph">4.1  I det omfang det er muligt efter dansk ret, kan vi i intet tilfælde gøres erstatningsansvarlig for brug af Websitet,
            herunder for (i) tab af indtægter, driftstab, forretningsmuligheder, tab eller ødelæggelse af data eller
            genskabelse af data, tab af goodwill, sikkerhedsbrud forårsaget af tredjeparts brud på telekommunikation
            og/eller internettet, omsætningstab (uanset om dette er direkte, indirekte eller følgetab), (ii) tab eller
            skade som følge af ansvar overfor tredjeparter (både direkte, indirekte tab eller følgeskade) eller (iii)
            ethvert indirekte tab.</p>
        <p className="b-terms__paragraph">4.2  Intet i Brugervilkårene udelukker eller begrænser vores ansvar for død
            eller personskade som følge af uagtsomhed eller ansvar for bevidst urigtige oplysninger eller urigtige
            oplysninger af vigtig karakter, eller andet ansvar, som ikke kan begrænses eller fraskrives ifølge gældende
            præceptiv lovgivning.</p>
        <h3 className="b-terms__sub-title">5 Andre vilkår</h3>
        <p className="b-terms__paragraph">5.1 Vi kan til enhver tid og uden varsel revidere eller ændre disse Brugervilkår
            eller efter eget skøn og uden varsel nedlægge, ændre eller omstrukturere Websitet. Som Registreret Bruger
            accepterer du at være omfattet af de til enhver tid gældende Brugervilkår. Revidering eller ændring af
            Vilkårene vil blive omtalt på Websitet. GolfMore bestræber sig endvidere på at orientere Registrerede Brugere
            om ændringen af Brugervilkårene. Som Registreret Bruger accepterer du, at den fortsatte brug af Websitet
            efter offentliggørelsen af de ændrede Brugervilkår udgør en accept af de ændrede Brugervilkår.
        </p>
        <p className="b-terms__paragraph">5.2 Hvis nogen del af disse Brugervilkår skulle blive anset for retsstridig
            eller ugyldig og dermed ikke kan håndhæves, påvirker dette ikke gyldigheden og håndhævelsen af den resterende
            del af Brugervilkårene.</p>
        <h3 className="b-terms__sub-title">6 Varighed og opsigelse</h3>
        <p className="b-terms__paragraph">6.1  Vi kan til enhver tid opsige din ret til at tilgå og bruge de services,
            der udbydes via Websitet af en hvilken som helst årsag og uden ansvar. Hvis vi gør det, eller hvis du vælger
            at slette din profil, vil alle rettigheder, du måttet have opnået herigennem, øjeblikkeligt ophøre. </p>
        <h3 className="b-terms__sub-title">7 Lovvalg og værneting</h3>
        <p className="b-terms__paragraph">7.1  Brugervilkårene er undergivet dansk ret, og hvor andet ikke er anført,
            gælder dansk rets almindelige regler i ethvert indbyrdes forhold mellem GolfMore og Registrerede Brugere
            samt brugere af Websitet. Eventuelle tvister og uoverensstemmelser skal indbringes for domstolen
            ved GolfMores danske hjemting, medmindre dette strider mod præceptive værnetingsregler.</p>
      </div>
    </div>
  </div>
  );

export default termsDenmark;
