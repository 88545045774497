import axios from 'axios';
import base64Encode from 'base-64';
import host from '../../../constants/config/host';
import { VOUCHERS_PER_PAGE } from '../../../constants/config/config';

const getVouchers = (offset, searchText) =>
  axios.get(
    `${host}/vouchers?with_pdf=true${offset ? `&offset=${offset}` : ''}${`${
      offset ? '&' : '?'
    }&limit=${VOUCHERS_PER_PAGE}`}
        ${searchText ? `&search=${searchText}` : ''}`,
  );

const getAllVouchers = () =>
  axios({
    method: 'GET',
    url: `${host}/vouchers`,
    headers: {
      Authorization: `Basic ${base64Encode.encode(`${localStorage.getItem('userToken')}:`)}`,
    },
    params: {
      with_pdf: true,
    },
  });

const getVouchersCount = (searchText) =>
  axios({
    method: 'GET',
    url: `${host}/vouchers/count${searchText ? `?search=${searchText}` : ''}`,
    headers: {
      Authorization: `Basic ${base64Encode.encode(`${localStorage.getItem('userToken')}:`)}`,
    },
    params: {
      with_pdf: true,
    },
  });

const toggleFavourites = (value, voucherId) =>
  axios.patch(`${host}/voucher/${voucherId}`, {
    favourite: value,
  });

const getHistory = (offset) => axios.get(`${host}/history?offset=${offset}&limit=10`);

const generatePDF = (voucherID) => {
  const sessionId = localStorage.getItem('userToken');
  return axios.patch(`${host}/voucher/pdf/${voucherID}`, {
    headers: {
      Authorization: `Basic ${base64Encode.encode(`${sessionId}:`)}`,
      'Accept-Language': 'da, en-gb;q=0.8, en;q=0.7',
    },
  });
};

const uploadVoucher = (voucherID) =>
  axios.post(`${host}/voucher/upload`, {
    voucher_number: voucherID,
  });

const transferVoucher = (voucherID, targetObject) => {
  let resultURL = '';

  if (typeof voucherID === 'object') {
    resultURL = voucherID.map((voucher) => voucher.voucher_id).join('%2C');
  } else {
    resultURL = voucherID;
  }

  return axios.post(`${host}/voucher/transfer/${resultURL}`, targetObject);
};

const getClubs = () => axios.get(`${host}/clubs`);

const getTransferProcess = (token, locale) => axios.get(`${host}/transfer-process/${token}?locale=${locale}`);

const sendTransferProcess = (token, locale, data) =>
  axios({
    method: 'patch',
    url: `${host}/transfer-process/${token}?locale=${locale}`,
    data,
    header: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

const getFavouriteVouchers = () =>
  axios({
    method: 'GET',
    url: `${host}/vouchers`,
    headers: {
      Authorization: `Basic ${base64Encode.encode(`${localStorage.getItem('userToken')}:`)}`,
    },
    params: {
      favourites: true,
      with_pdf: true,
    },
  });

const getPrintedVouchers = () =>
  axios({
    method: 'GET',
    url: `${host}/vouchers`,
    headers: {
      Authorization: `Basic ${base64Encode.encode(`${localStorage.getItem('userToken')}:`)}`,
    },
    params: {
      active: true,
      with_pdf: 0,
    },
  });

export default {
  getVouchers,
  getAllVouchers,
  getHistory,
  toggleFavourites,
  generatePDF,
  uploadVoucher,
  transferVoucher,
  getClubs,
  getVouchersCount,
  getTransferProcess,
  sendTransferProcess,
  getFavouriteVouchers,
  getPrintedVouchers,
};
