import React, { Component } from 'react';
import propTypes from 'prop-types';
import texts from '../../../../constants/languages';
import './logOutPopUp.css';

class VoucherDetails extends Component {
  closePopupOnClickOutSide = (event) => {
    if (!this.popup.contains(event.target)) {
      this.props.onCancel();
    }
  };

  render() {
    return (
      <div
        className="g-popup-wrap"
        onClick={(event) => this.closePopupOnClickOutSide(event)} // close popup
      >
        <div className="g-popup-overlay">&nbsp;</div>
        <div className="g-popup-wrap">
          <table className="g-popup-wrap__container">
            <tbody>
              <tr>
                <td className="g-popup-wrap__container-inner">
                  <div className="log-out-warn g-popup" ref={(popup) => (this.popup = popup)}>
                    <div className="g-popup__content">
                      <div className="log-out-warn__content">
                        <p>{texts.LOG_OUT_WARN}</p>
                        <div className="log-out-warn-button__container accept" onClick={this.props.onConfirm}>
                          <div className="log-out-warn__content">
                            <p>{texts.YES.toUpperCase()}</p>
                          </div>
                        </div>
                        <div className="log-out-warn-button__container dismiss" onClick={this.props.onCancel}>
                          <div className="log-out-warn__content">
                            <p>{texts.NO.toUpperCase()}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

VoucherDetails.propTypes = {
  onConfirm: propTypes.func.isRequired,
  onCancel: propTypes.func.isRequired,
};

export default VoucherDetails;
