// @flow
import type { ReqType } from '../../api/index';
import API from '../../api/base/api';
import type { VoucherType } from '../../vouchers/api/voucher';

export type Union = {
  union_id: number,
  name: string,
  tid: string,
  country: string,
  last_updated: number,
};

export type ClubType = {
  club_id: number, // In fact this is not the id of the club, but id of department. Name is club_id for backward-compatibility.
  name: string,
  club_guid: string,
  country: string,
  club_number: string,
  street: string,
  zip_code: string,
  phone: string,
  latitude: string,
  longitude: string,
  booking_system: string,
  logo: string,
  image: string,
  rating: string,
  last_updated: number,
  has_booking_system: boolean,
  club_img_url: string,
  is_home_club: boolean,
  has_products: boolean,
};

export type Department = {
  department_id: number,
  department_name: string,
  club_guid: string,
  country: string,
  latitude: number,
  longitude: number,
  products_summary_string: string,
  club_img_url: string,
  zip_code: string,
};

export type ClubWithVoucherExtra = {
  ...ClubType,
  vouchers: Array<VoucherType>,
};

export type Dispenser = {
  dispenser_name: string,
  dispenser_guid: string,
};

export type BasketSize = {
  price: String,
  number_of_balls: number,
  is_default?: boolean,
};

export type CurrentClubType = {
  ...ClubDetailsType,
  clubDetails: ClubType,
};

export type ClubDetailsType = {
  vouchers: Array<VoucherType>,
  driving_range?: {
    ball_money_balance?: number,
    uses_left?: number,
    basket_sizes?: Array<BasketSize>,
    dispensers?: Array<Dispenser>,
    latitude: number,
    longitude: number,
  },
};

class ClubAPI extends API {
  getUnions() {
    return this.r({
      method: 'GET',
      url: '/unions',
    });
  }

  getDepartments(params) {
    return this.r({
      method: 'GET',
      url: '/departments',
      params,
    });
  }

  getClubs(params) {
    return this.r({
      method: 'GET',
      url: '/clubs',
      params,
    });
  }

  getMyClubs(params) {
    return this.r({
      method: 'GET',
      url: '/my-clubs-list',
      params,
    });
  }

  getClubLocationDetails(params) {
    return this.r({
      method: 'GET',
      url: '/my-club-details',
      params,
    });
  }

  getNearbyClubs(params) {
    return this.r({
      method: 'GET',
      url: '/club-nearest',
      params,
    });
  }
}

export default function clubApi(request: ReqType) {
  return new ClubAPI(request);
}
