//  @flow
import React from 'react';
import { StyleSheet, TextInput, View } from 'react-native';
import { TextInputMask } from 'react-native-masked-text';
import type { StyleObj } from 'react-native/Libraries/StyleSheet/StyleSheetTypes';

import styles from '../../constants/styles';

const iStyle = StyleSheet.create({
  container: {
    height: 44,
    justifyContent: 'center',
    paddingHorizontal: 20,
    borderWidth: 1,
    borderColor: styles.colors.basic30,
    borderRadius: 5,
    backgroundColor: styles.colors.white,
  },
  disabledBg: { backgroundColor: '#00000008', borderColor: '#00000000' },
  containerDark: {
    height: 44,
    justifyContent: 'center',
    paddingHorizontal: 20,
    borderWidth: 1,
    borderColor: styles.colors.basic30,
    borderRadius: 5,
    backgroundColor: styles.colors.basic,
  },
  input: {
    padding: 0,
    color: styles.colors.basic,
    fontSize: styles.font.size.normal,
    ...styles.font.style.regular,
  },
  inputDark: {
    padding: 0,
    color: styles.colors.athens_gray,
  },
  disabled: {
    color: styles.colors.black,
  },
});

type Props = {
  style?: ?StyleObj,
  maskTemplate?: ?Object,
  editable?: boolean,
  numberOfLines?: number,
  multiline?: boolean,
};

export default function Input(props: Props) {
  const { style, inputComponentStyle, theme, maskTemplate, editable, multiline, numberOfLines, ...other } = props;

  const inputStyle = [
    iStyle.input,
    inputComponentStyle,
    theme === 'dark' ? iStyle.inputDark : {},
    editable ? null : iStyle.disabled,
  ];
  let mainStyle = [theme === 'dark' ? iStyle.containerDark : iStyle.container, style];
  if (!editable) {
    mainStyle = [mainStyle, iStyle.disabledBg];
  }

  if (maskTemplate) {
    return (
      <View style={mainStyle}>
        <TextInputMask
          style={inputStyle}
          underlineColorAndroid="transparent"
          type="custom"
          options={maskTemplate}
          editable={editable}
          multiline={multiline}
          numberOfLines={numberOfLines}
          refInput={input => {
            props.innerRef && props.innerRef(input);
          }}
          {...other}
        />
      </View>
    );
  }

  return (
    <View style={mainStyle}>
      <TextInput
        style={inputStyle}
        underlineColorAndroid="transparent"
        editable={editable}
        multiline={multiline}
        numberOfLines={numberOfLines}
        ref={input => {
          props.innerRef && props.innerRef(input);
        }}
        {...other}
      />
    </View>
  );
}

Input.defaultProps = {
  style: null,
  multiline: false,
  maskTemplate: undefined,
  editable: true,
  numberOfLines: 2,
};
