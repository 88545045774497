import styled from 'styled-components/native';
import { space, color, layout, border, flex } from 'styled-system';
import { arrow } from '../../../../react-native/assets/images';

import { TextS, TextN, TextL, TextXs } from '../../../../react-native/components/Text';

export const Label = TextXs;
export const Text = TextS;
export const CountryText = TextN;

const golfBox = require('../../../../react-native/auth/screens/LogIn/assets/golfbox.png');
const golfBoxLogo = require('./assets/GOLFBOX-LOGO.png');

const BUTTON_HEIGHT = 65;

export const Overlay = styled.View`
  background-color: #4f627acc;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  zindex: 1000;
  justify-content: center;
  align-items: center;
`;

export const LoginBox = styled.View`
  background-color: white;
  border-radius: 8px;
  width: 370px;
`;

export const HeaderText = styled(TextL).attrs({
  fontFamily: 'medium',
  mt: 8,
  mb: 8,
})`
  text-align: center;
  ${layout}
  ${space}
`;

export const LogoContainer = styled.View`
  justify-content: center;
  align-items: center;
  background-color: #f6f6f7;
  height: 90px;
`;

export const Logo = styled.Image.attrs({
  source: golfBoxLogo,
  flex: 1,
  resizeMode: 'contain',
})`
  width: 194px;
  ${flex}
`;

export const ContentContainer = styled.View.attrs({
  p: 7,
})`
  ${space}
`;

export const Touchable = styled.TouchableWithoutFeedback``;

export const CountryContainer = styled.View.attrs({
  width: '100%',
  bg: 'white',
  borderColor: '#4f627a40',
  mr: 2,
  mt: 3,
})`
  flex-direction: row;
  align-items: center;
  border-width: 1px;
  border-radius: 5px;
  ${layout}
  ${space}
  ${color}
  ${border}
`;

export const FlagImage = styled.Image.attrs({
  width: 40,
  height: 40,
  resizeMode: 'contain',
})`
  ${layout}
`;

export const DropdownArrow = styled.Image.attrs({
  width: 13,
  resizeMode: 'contain',
  source: arrow,
})`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 15px;
  ${layout}
`;

export const SGFContainer = styled.View.attrs({
  flex: 1,
  borderRadius: 5,
  bg: '#004a87',
  mt: 7,
})`
  box-shadow: 0 4px 10px #0000004f;
  height: 70px;
  flex-direction: row;
  ${color}
  ${border}
  ${space}
`;

export const GolfBoxContainer = styled.View.attrs({
  flex: 1,
  borderRadius: 5,
  bg: 'golfBox',
  mt: 7,
})`
  box-shadow: 0 4px 10px #0000004f;
  height: 70px;
  flex-direction: row;
  ${color}
  ${border}
  ${space}
`;

export const ButtonIconContainer = styled.View.attrs({
  width: BUTTON_HEIGHT,
  mr: 5,
  borderTopLeftRadius: 5,
  borderBottomLeftRadius: 5,
  bg: '#00000014',
})`
  justify-content: center;
  align-items: center;
  ${layout}
  ${space}
  ${color}
`;

export const GolfBoxIcon = styled.Image.attrs({
  width: 36,
  height: 36,
  resizeMode: 'contain',
  source: golfBox,
})`
  ${layout}
`;

export const FooterContainer = styled.View.attrs({
  mt: 25,
})`
  align-self: center;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  ${space}
`;

export const ButtonText = styled(TextS).attrs({
  fontFamily: 'medium',
  color: 'white',
})`
  align-self: center;
`;

export const LinkText = styled(TextS).attrs({
  fontFamily: 'medium',
  color: 'green',
  ml: 3,
  mr: 3,
})`
  align-self: center;
  text-decoration: underline;
  text-decoration-color: #759a58;
  ${color}
  ${space}
`;
