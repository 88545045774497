import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Spinner from 'react-spinkit';
import BrowserDetection from 'react-browser-detection';
import propTypes from 'prop-types';

import authAPI from './domains/auth/api/auth';

import authActions from './domains/auth/actions';
import { getClubs } from './domains/vouchers/module';

import Header from './components/templates/header';
import Footer from './components/templates/footer';

import { Switch, BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import ScrollToTop from './components/scrollToTop';

import './components/templates/header/header.css'; // import this css right here for the connection sequence of styles

import './App.css';
import './fonts.css';
import './reset.css';
import './global.css';

import PasswordChange from './domains/auth/components/passwordChange';

import LogIn from './domains/auth/components/logIn';
import LogOutWarn from './domains/auth/components/logOutPopUp';
import VoucherTransferPopupSuccess from './domains/vouchers/voucherTransfer/popupSuccess';
import VoucherPrintPDFPopupError from './domains/vouchers/popupError';
import VoucherPrintNotification from './domains/vouchers/voucherPrintNotification';
import NewsletterNotifications from './components/pages/newsletterNotifications';

import texts from './constants/languages';
import { localeSelector } from './selectors/locale';

import setupI18n from './react-native/i18n';

import Home from './components/pages/home';

import ContactUs from './components/pages/contactUs';
import VoucherTransfer from './domains/vouchers/voucherTransfer';
import PrivacyPolicy from './components/pages/privacyPolicy';
import PrivacyPolicyMobile from './components/pages/privacyPolicyMobile';
import Terms from './components/pages/terms';
import TermsMobile from './components/pages/termsMobile';
import Faq from './domains/faq/pages/faq';
import FaqMobile from './domains/faq/pages/faqMobile';
import Vouchers from './domains/vouchers';
import Profile from './components/pages/profile';
import SignUpEmailVerify from './domains/auth/components/signUpEmailVerify';

import RegisterPane from './domains/auth/components/RegisterPane';
import NotFound from './components/pages/notFound';
import appActions from './domains/app/appActions';

import { loadDepartments } from './react-native/clubs/modules';

if (window.location.pathname === '/driving-range') {
  window.location.href = 'https://driving-range.golfmore.eu/';
}

const { togglePopup } = appActions;
const { checkLoginInfo, toggleLogOutWarn, logOut, setUnions, handleLogIn, updateLoggedUserInfo } = authActions;

const { func, bool, element, string, shape, any } = propTypes;

const setViewPortForMobile = (params: Object) => {
  if (
    window.location.pathname === '/password-change' ||
    window.location.pathname === `/voucher-subtransfer/${params.id}`
  ) {
    const viewport = document.querySelector('meta[name=viewport]');
    viewport.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0');
  }
};

const browserHandler = {
  ie: () => (
    <div className="browser-detection__bar">
      <p>{texts.IE_NOTIFICATION_BAR}</p>
    </div>
  ),
};

const shouldDisplayHeader = (match) => {
  return (
    window.location.pathname !== '/password-change' &&
    !window.location.pathname.startsWith('/register') &&
    !window.location.pathname.startsWith('/oauth-consumer') &&
    window.location.pathname !== '/privacy-policy-mobile' &&
    window.location.pathname !== '/terms-and-conditions-mobile' &&
    window.location.pathname !== '/faq-mobile' &&
    window.location.pathname !== `/voucher-subtransfer/${match?.params.id}`
  );
};

const App = ({
  locale,
  history,
  signUpStepOnePopupIsOpen,
  signUpStepTwoPopupIsOpen,
  signUpConfirmPopupIsOpen,
  newsletterPopupIsOpen,
  logInPopupIsOpen,
  forgotPasswordPopupIsOpen,
  forgotPasswordConfirmPopupIsOpen,
  emailVerifySuccessPopupIsOpen,
  emailVerifyErrorPopupIsOpen,
  voucherTransferPopupIsOpen,
  voucherPDFPopUpIsOpen,
  voucherPrintNotification,
  logOutWarn,
  logOut: LogOut,
  userIsAuthenticated,
  togglePopup: TogglePopUp,
  toggleLogOutWarn: ToggleLogOutWarn,
  children,
  showSpinner,
  authUserData,
  updateLoggedUserInfo: updateData,
  location,
  match,
  setCountries,
  checkLoginInfo,
  toggleLogOutWarn,
  logOut,
  setUnions,
  handleLogIn,
  updateLoggedUserInfo,
  getClubs,
  togglePopup,
  loadDepartments,
}) => {
  const [browser, setBrowser] = useState(null);
  const [newsletterAsked, setNewsletterAsked] = useState(false);
  const [localeSet, setLocaleSet] = useState(false);

  useEffect(() => {
    setupI18n(locale);
    setViewPortForMobile(match.params);
    checkLoginInfo();
    setUnions();
    getClubs();
    if (localStorage.getItem('userToken')) {
      loadDepartments();
    }

    setLocaleSet(true);
  }, []);

  useEffect(() => {
    if (authUserData && authUserData.user_id && authUserData.email_confirmed && !newsletterAsked) {
      if (!authUserData.newsletter_asked) {
        setTimeout(() => togglePopup('newsletterPopup', true), 1000);
        setNewsletterAsked(true);
      }
    }
  }, [authUserData, newsletterAsked]);

  if (!localeSet) {
    return null;
  }

  return (
    <div className="app">
      <BrowserDetection once={false}>{browserHandler}</BrowserDetection>
      <div>
        {showSpinner && (
          <div className="app__spinner">
            <Spinner name="ball-scale-multiple" color="orange" />
          </div>
        )}
      </div>

      {shouldDisplayHeader(match) ? <Header /> : <div />}
      <div className="g-page">
        <ScrollToTop>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/contact-us" component={ContactUs} />
            <Route path="/register" component={RegisterPane} />
            <Route path="/vouchers" component={Vouchers} />
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            <Route path="/privacy-policy-mobile" component={PrivacyPolicyMobile} />
            <Route path="/terms-and-conditions" component={Terms} />
            <Route path="/terms-and-conditions-mobile" component={TermsMobile} />
            <Route path="/faq" component={Faq} />
            <Route path="/faq-mobile" component={FaqMobile} />
            <Route path="/profile" component={Profile} />
            <Route path="/email-verify" component={SignUpEmailVerify} />
            <Route path="/voucher-subtransfer/:id" component={VoucherTransfer} />
            <Route path="/password-change" component={PasswordChange} />
            <Route component={NotFound} />
          </Switch>
        </ScrollToTop>

        {signUpStepOnePopupIsOpen && <SignUpStepOne />}
        {signUpStepTwoPopupIsOpen && <SignUpStepTwo />}
        {signUpConfirmPopupIsOpen && <SignUpConfirm />}
        {logInPopupIsOpen && <LogIn />}
        {forgotPasswordPopupIsOpen && <ForgotPassword />}
        {forgotPasswordConfirmPopupIsOpen && <ForgotPasswordConfirm />}
        {voucherTransferPopupIsOpen && <VoucherTransferPopupSuccess togglePopup={TogglePopUp} />}
        {voucherPDFPopUpIsOpen && <VoucherPrintPDFPopupError togglePopup={TogglePopUp} />}
        {voucherPrintNotification && (
          <VoucherPrintNotification
            togglePopup={TogglePopUp}
            authUserData={authUserData}
            updateData={updateData}
            locale={location}
          />
        )}

        {newsletterPopupIsOpen && (
          <NewsletterNotifications
            togglePopup={TogglePopUp}
            authUserData={authUserData}
            updateData={updateData}
            locale={location}
          />
        )}

        {logOutWarn && !emailVerifySuccessPopupIsOpen && <LogOutWarn onConfirm={LogOut} onCancel={ToggleLogOutWarn} />}
        {shouldDisplayHeader() ? <Footer userIsAuthenticated={userIsAuthenticated} /> : <div />}
      </div>
    </div>
  );
};

App.propTypes = {
  newsletterPopupIsOpen: bool.isRequired,
  userIsAuthenticated: bool.isRequired,
  signUpStepOnePopupIsOpen: bool.isRequired,
  signUpStepTwoPopupIsOpen: bool.isRequired,
  signUpConfirmPopupIsOpen: bool.isRequired,
  logInPopupIsOpen: bool.isRequired,
  forgotPasswordPopupIsOpen: bool.isRequired,
  forgotPasswordConfirmPopupIsOpen: bool.isRequired,
  emailVerifySuccessPopupIsOpen: bool.isRequired,
  emailVerifyErrorPopupIsOpen: bool.isRequired,
  voucherTransferPopupIsOpen: bool.isRequired,
  voucherPDFPopUpIsOpen: bool.isRequired,
  voucherPrintNotification: bool.isRequired,
  logOutWarn: bool.isRequired,
  showSpinner: bool.isRequired,
  checkLoginInfo: func.isRequired,
  toggleLogOutWarn: func.isRequired,
  logOut: func.isRequired,
  handleLogIn: func.isRequired,
  // togglePopup: func.isRequired,
  getClubs: func.isRequired,
  setUnions: func.isRequired,
  updateLoggedUserInfo: func.isRequired,
  // authUserData: shape(any),
  locale: string.isRequired,
  location: string.isRequired,
};

App.defaultProps = {
  authUserData: {},
};

const mapStateToProps = (state) => {
  const { authReducer, voucherReducer, appReducer: app } = state;
  return {
    userIsAuthenticated: authReducer.get('userIsAuthenticated'),
    signUpStepOnePopupIsOpen: authReducer.get('signUpStepOnePopupIsOpen'),
    signUpStepTwoPopupIsOpen: authReducer.get('signUpStepTwoPopupIsOpen'),
    signUpConfirmPopupIsOpen: authReducer.get('signUpConfirmPopupIsOpen'),
    forgotPasswordPopupIsOpen: authReducer.get('forgotPasswordPopupIsOpen'),
    forgotPasswordConfirmPopupIsOpen: authReducer.get('forgotPasswordConfirmPopupIsOpen'),
    logInPopupIsOpen: authReducer.get('logInPopupIsOpen'),
    emailVerifySuccessPopupIsOpen: authReducer.get('emailVerifySuccessPopupIsOpen'),
    newsletterPopupIsOpen: authReducer.get('newsletterPopup'),
    emailVerifyErrorPopupIsOpen: authReducer.get('emailVerifyErrorPopupIsOpen'),
    voucherTransferPopupIsOpen: voucherReducer.get('voucherTransferPopupIsOpen'),
    voucherPDFPopUpIsOpen: voucherReducer.get('voucherPDFPopUpIsOpen'),
    voucherPrintNotification: voucherReducer.get('voucherPrintNotification'),
    authUserData: authReducer.get('authUserData'),
    showSpinner: voucherReducer.get('showSpinner'),
    logOutWarn: authReducer.get('logOutWarn'),
    location: app.get('location'),
    locale: localeSelector(state),
  };
};

const mapDispatchToProps = {
  checkLoginInfo,
  toggleLogOutWarn,
  logOut,
  handleLogIn,
  setUnions,
  togglePopup,
  getClubs,
  updateLoggedUserInfo,
  loadDepartments,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
