import React, { useState, useEffect, useCallback } from 'react';
import { Route } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import i18n from 'react-native-i18n';

import authActions from '../../actions.js';
import { connect } from 'react-redux';
import SS from '../../../../react-native/register/screens/SignUpSelect';
import UnionSelect from '../../../../react-native/register/screens/UnionSelect';
import SignUp from '../../../../react-native/register/screens/SignUp';
import LoginSweden from '../../../../react-native/auth/screens/LogInSweden/LogIn';
import PasswordEntry from '../../../../react-native/register/screens/PasswordEntry';
import UpdateGolfIdModal from '../../../../react-native/register/screens/UpdateGolfIdModal';
import ImportGolfMoreUser from '../../../../react-native/register/screens/ImportGolfmoreUser/ImportGolfmoreuser';
import GolfIdSelectModal from '../../../../react-native/register/screens/GolfIdSelect/index';
import VerifyAccount from '../../../../react-native/auth/screens/VerifyAccount';
import ForgotPassword from '../../../../react-native/auth/screens/ForgotPassword/ForgotPassword';
import OAuthConsumer from '../../../../domains/auth/components/OAuthConsumer/index';
import RegisterMethodSelect from './RegisterMethodSelect';
import browserHistory from '../../../../history';

import appActions from '../../../../domains/app/appActions';
const { togglePopup } = appActions;

import {
  HeaderContainer,
  TitleContainer,
  TitleText,
  MembershipText,
  SignInContainer,
  AlreadyHaveAccountLabel,
  SignInButton,
  BlackPane,
  LeftPanel,
  RightPanel,
  Touchable,
  BackgroundImage,
  BackgroundImageCover,
  LogoContainer,
  Logo,
  LogoText,
  ContactLinksContainer,
  ContactLinkText,
  ContactLinkSeparator,
  BackContainer,
  BackIcon,
} from './styles';

function getJsonFromUrl(url) {
  if (!url) url = location.search;
  const query = url.substr(1);
  return query.split('&').reduce((data, param) => {
    const [name, value] = param.split('=');
    data[name] = decodeURIComponent(value);
    return data;
  }, {});
}

export function navigateTo(route) {
  switch (route?.routeName || route) {
    case 'UnionSelect':
      browserHistory.push('/register/union');
      break;
    case 'SignUp':
      browserHistory.push('/register/non-union');
      break;
    case 'LogInSweden':
      browserHistory.push('/register/loginSweden');
      break;
    case 'PasswordEntry':
      browserHistory.push('/register/password');
      break;
    case 'VerifyAccount':
      browserHistory.push('/register/verify');
      break;
    case 'UpdateGolfIdModal':
      browserHistory.push('/register/update');
      break;
    case 'GolfIdSelectModal':
      browserHistory.push('/register/golf-id');
      break;
    case 'Tabs':
      browserHistory.replace('/vouchers');
      break;
    default:
      console.error('Route not defined!', route);
  }
}

const NonUnionMemberRoutes = ['/register/non-union', '/register/password'];

// TODO add all routes for union members
const UnionMemberRoutes = ['/register/union'];

const getMembershipText = () => {
  const { pathname } = window.location;
  if (NonUnionMemberRoutes.includes(pathname)) {
    return i18n.t('signUpSelect.nonUnionMember');
  } else if (UnionMemberRoutes.includes(pathname)) {
    return i18n.t('signUpSelect.unionMember');
  } else {
    return null;
  }
};

const Pane = ({ togglePopup, match, location, setReferral }) => {
  const [params, setParams] = useState(null);

  const isNarrow = useMediaQuery({ maxWidth: 1000 });

  const handleBack = useCallback(() => browserHistory.goBack(), []);
  const handleTermsPress = useCallback(() => browserHistory.push('/terms-and-conditions'), []);
  const handlePrivacyPress = useCallback(() => browserHistory.push('/privacy-policy'), []);
  const handleContactPress = useCallback(() => browserHistory.push('/contact-us'), []);
  const handleLogoPress = useCallback(() => browserHistory.push('/'), []);
  const handleSignIn = useCallback(() => {
    browserHistory.push('/');
    togglePopup('logInPopupIsOpen', true);
  }, []);

  useEffect(() => {
    const search = getJsonFromUrl();
    if (search.data) {
      const data = decodeURIComponent(search.data);
      setParams(JSON.parse(data));
    }
    if (search.referral) {
      setReferral(search.referral);
      console.log('Setting referral', search.referral);
      localStorage.setItem('referral', search.referral);
    }
  }, []);

  const getParam = (name) => params?.[name];

  // navigation adapter for screens, that rely on react-navigation
  const navigate = (route, params) => {
    console.log('Navigate', route, params);
    setParams(params);
    navigateTo(route);
  };

  const NavigationAdapter = {
    navigate,
    replace: navigate,
    getParam,
    goBack: () => browserHistory.goBack(),
  };

  return (
    <BlackPane>
      {!isNarrow && (
        <LeftPanel>
          <BackgroundImage />
          <BackgroundImageCover />
          <Touchable onPress={handleLogoPress}>
            <LogoContainer>
              <Logo />
              <LogoText text="GolfMore" />
            </LogoContainer>
          </Touchable>
          <ContactLinksContainer>
            <Touchable onPress={handleTermsPress}>
              <ContactLinkText text={i18n.t('register.termsAndConditions')} />
            </Touchable>
            <ContactLinkSeparator />
            <Touchable onPress={handlePrivacyPress}>
              <ContactLinkText text={i18n.t('register.privacyCookiePolicy')} />
            </Touchable>
            <ContactLinkSeparator />
            <Touchable onPress={handleContactPress}>
              <ContactLinkText text={i18n.t('register.contactUs')} />
            </Touchable>
          </ContactLinksContainer>
        </LeftPanel>
      )}
      <RightPanel centerContent={isNarrow}>
        <HeaderContainer>
          <TitleContainer>
            <TitleText text={i18n.t('register.createAccount')} />
            <MembershipText text={getMembershipText()} />
          </TitleContainer>
          <SignInContainer>
            <AlreadyHaveAccountLabel text={i18n.t('verifyAccount.alreadyHaveAccount')} />
            <SignInButton text={i18n.t('signUpSelect.signIn')} onPress={handleSignIn} />
          </SignInContainer>
        </HeaderContainer>
        <Route exact path={'/register'}>
          <RegisterMethodSelect navigation={NavigationAdapter} />
        </Route>
        <Route path="/register/email">
          <SS navigation={NavigationAdapter} />
        </Route>
        <Route path={`/register/union`}>
          <UnionSelect navigation={NavigationAdapter} isNarrow={isNarrow} />
        </Route>
        <Route path={`/register/non-union`}>
          <SignUp navigation={NavigationAdapter} isNarrow={isNarrow} />
        </Route>
        <Route path={`/register/loginSweden`}>
          <LoginSweden navigation={NavigationAdapter} isNarrow={isNarrow} />
        </Route>
        <Route path={`/register/oauth-consumer`}>
          <OAuthConsumer navigation={NavigationAdapter} isNarrow={isNarrow} />
        </Route>
        <Route path={`/register/password`}>
          {params && <PasswordEntry navigation={NavigationAdapter} isNarrow={isNarrow} />}
        </Route>
        <Route path={`/register/update`}>{params && <UpdateGolfIdModal navigation={NavigationAdapter} />}</Route>
        <Route path={`/register/golf-id`}>{params && <GolfIdSelectModal navigation={NavigationAdapter} />}</Route>
        <Route path={`/register/import`}>{params && <ImportGolfMoreUser navigation={NavigationAdapter} />}</Route>
        <Route path={'/register/verify'}>
          <VerifyAccount navigation={NavigationAdapter} />
        </Route>
        <Route path={'/register/forgot-password'}>
          <ForgotPassword navigation={NavigationAdapter} />
        </Route>
        <Touchable onPress={handleBack}>
          <BackContainer floating={!isNarrow}>
            <BackIcon />
          </BackContainer>
        </Touchable>
      </RightPanel>
    </BlackPane>
  );
};

export default connect(null, { setReferral: authActions.setReferral, togglePopup })(Pane);
