import React, { useState, useEffect, useCallback, useRef } from 'react';
import Header from './Header/Header';
import { useHistory } from 'react-router-dom';
import EmailLogin from '../../../../react-native/auth/screens/LogIn/Login.web';
import { setGolfBoxCountry } from '../../../../react-native/store/modules/locale';
import i18n from 'react-native-i18n';
import { connect } from 'react-redux';
import getImageForLocale from '../../../../react-native/screens/More/languageIcon';

import appActions from '../../../../domains/app/appActions';
const { togglePopup } = appActions;

import {
  Text,
  Label,
  LoginBox,
  HeaderText,
  LogoContainer,
  Overlay,
  Touchable,
  CountryContainer,
  DropdownArrow,
  FlagImage,
  GolfBoxContainer,
  ButtonIconContainer,
  GolfBoxIcon,
  ButtonText,
  ContentContainer,
  Logo,
  FooterContainer,
  LinkText,
  CountryText,
  SGFContainer,
} from './styles';
import { SGFButtonIconContainer, SGFIcon } from '../../../../domains/auth/components/RegisterPane/styles';
import Picker from '../../../../react-native/components/Picker/Picker';
import { getGeoIp } from '../../../../react-native/register/modules/country';
import golfboxUnions from '../../../../react-native/constants/unions';
import { redirectGolfBox } from '../../api/auth';

import browserHistory from '../../../../history';

const LogIn = ({ togglePopup, geoIp, country, getGeoIp, setGolfBoxCountry, unions }) => {
  const secondHeaderOptions = {
    golfbox: i18n.t('register.tabGolfbox'),
    email: i18n.t('register.tabEmail'),
  };

  const history = useHistory();

  const [option, setOption] = useState('golfbox');
  const handleCountryPress = () => null;
  const handleGolfBoxPress = () => {
    if (country == 'sv') {
      window.location = '/register/loginSweden';
    } else {
      redirectGolfBox(country);
    }
  };
  const handleCreateAccount = () => {
    //  window.location = '/register';
    history.push('/register');
    togglePopup('logInPopupIsOpen', false);
  };

  const availableTranslations = golfboxUnions.map(({ countryName, locale }) => {
    return {
      title: countryName,
      key: locale,
      icon: getImageForLocale(locale),
    };
  });

  useEffect(() => {
    getGeoIp();
  }, []);

  const { country_code: countryCode } = geoIp.data;

  const navigate = (route, params) => {
    togglePopup('logInPopupIsOpen', false);
    console.log(route, params);
    if (route === 'ForgotPassword') {
      browserHistory.push('/register/forgot-password');
    }
  };

  const NavigationAdapter = {
    navigate,
    goBack: () => {
      browserHistory.goBack();
    },
  };

  const handleClose = useCallback((event) => {
    if (event == null) {
      togglePopup('logInPopupIsOpen', false);
    }

    if (overlay?.current.firstChild.isEqualNode(event.target)) {
      togglePopup('logInPopupIsOpen', false);
    }
  }, []);

  useEffect(() => {
    // Detect by country code
    if (countryCode) {
      const ccLow = countryCode.toLowerCase();
      if (golfboxUnions.some(({ locale }) => locale === ccLow)) {
        setGolfBoxCountry(ccLow);
      } else {
        setGolfBoxCountry('da'); // Default
      }
    }
  }, [countryCode]);

  const overlay = useRef(null);

  return (
    <div onMouseDown={handleClose} ref={overlay}>
      <Overlay>
        <LoginBox>
          <HeaderText text={i18n.t('signUpSelect.signIn')} />
          <Header options={secondHeaderOptions} onPress={setOption} selected={option} />
          {option === 'golfbox' && (
            <>
              {country !== 'sv' && (
                <LogoContainer>
                  <Logo alt="GolfBox" />
                </LogoContainer>
              )}
              <ContentContainer>
                <Picker
                  label={i18n.t('register.countryOfResidence')}
                  keyName="key"
                  labelName="title"
                  data={availableTranslations}
                  value={country}
                  onChange={(value) => setGolfBoxCountry(value.key)}
                  defaultText={i18n.t('picker.select')}
                  titleText={i18n.t('signUpStepOne.chose')}
                  confirmText={i18n.t('picker.confirm')}
                  cancelText={i18n.t('picker.cancel')}
                />
                <Touchable onPress={handleGolfBoxPress}>
                  {country == 'sv' ? (
                    <SGFContainer>
                      <SGFButtonIconContainer>
                        <SGFIcon />
                      </SGFButtonIconContainer>
                      <ButtonText text={'MinGolf'} />
                    </SGFContainer>
                  ) : (
                    <GolfBoxContainer>
                      <ButtonIconContainer>
                        <GolfBoxIcon />
                      </ButtonIconContainer>
                      <ButtonText text={i18n.t('register.signInWithGolfbox')} />
                    </GolfBoxContainer>
                  )}
                </Touchable>
                <FooterContainer>
                  <Text text={i18n.t('register.newToGolfmore')} />
                  <Touchable onPress={handleCreateAccount}>
                    <LinkText text={i18n.t('register.createAccountLowercase')} />
                  </Touchable>
                </FooterContainer>
              </ContentContainer>
            </>
          )}
          {option === 'email' && (
            <>
              <EmailLogin togglePopup={togglePopup} navigation={NavigationAdapter} />
            </>
          )}
        </LoginBox>
      </Overlay>
    </div>
  );
};

export default connect(
  ({ country, locale }) => ({
    geoIp: country.geoIp,
    country: locale.golfBoxCountry,
  }),
  { getGeoIp, setGolfBoxCountry, togglePopup },
)(LogIn);
