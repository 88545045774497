import React from 'react';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import './faq.css';
import data from './data';
import { ARROW_DOWN } from '../../../../constants/config/images';

class FAQ extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    let lang = 'en';
    console;
    switch (this.props.location) {
      case 'danish':
        lang = 'dn';
        break;
      case 'german':
        lang = 'de';
        break;
    }

    return (
      <div className="b-faqs">
        <div className="b-faqs__container-mobile">
          {data[lang].map((info) => (
            <div key={Math.random()} className="b-faqs__content">
              <div className="b-faqs__header">
                <h1 className="b-faqs__title">{info.title}</h1>
                <div className="b-faqs__collapse">
                  <img src={ARROW_DOWN} alt="collapse" className="b-faqs-arrow" />
                </div>
              </div>
              {info.body.map((text) => (
                <p key={Math.random()} className="b-faqs__paragraph">
                  {text}
                </p>
              ))}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

const { string } = propTypes;

FAQ.defaultProps = {
  location: 'denmark',
};

FAQ.propTypes = {
  location: string,
};

export default connect(({ appReducer }) => ({
  location: appReducer.get('location'),
}))(FAQ);
