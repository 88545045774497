import axios from 'axios';
import host from '../../../constants/config/host';

import { PDF_PER_PAGE } from '../../../constants/config/config';

const getPDFsCount = () => axios.get(`${host}/vouchers/count`);

const getPDFs = (offset) =>
  axios.get(`${host}/vouchers${offset ? `?offset=${offset}` : ''}${`${offset ? '&' : '?'}&limit=${PDF_PER_PAGE}`}`);

const getGeoIp = () => axios.get(`${host}/geoip`);

export default {
  getPDFsCount,
  getPDFs,
  getGeoIp,
};
