// @flow
import React from 'react';
import { Root, Title, CheckIcon } from './styles';
import checkIcon from '../../../../../assets/green-check.png';

type Props = {
  title: string,
  selected: boolean,
  onPress: function,
};

const SelectableItem = ({ title, selected, onPress }: Props) => {
  return (
    <Root selected={selected} onPress={onPress}>
      <Title>{title}</Title>
      {selected ? <CheckIcon source={checkIcon} /> : null}
    </Root>
  );
};

export default SelectableItem;
