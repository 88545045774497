// @flow
import React, { useCallback, useState } from 'react';
import i18n from 'react-native-i18n';
import { connect } from 'react-redux';
import type { NavigationStateRoute, NavigationScreenProp } from 'react-navigation';
import ReplaceButton from './components/ReplaceButton';
import SelectableItem from './components/SelectableItem';
import {
  Root,
  Header,
  HeaderTop,
  HeaderBottom,
  HeaderTitle,
  CloseButton,
  Content,
  ContentTitle,
  ContentSimpleText,
  BlueText,
  GolfIdList,
  CloseIcon,
} from './styles';
import closeIcon from '../../../assets/close_x.png';
import { registerUser } from '../../../user/modules/registering';
import { loginAndUpdateAccount, updateWithSessionId } from '../../../auth/modules/session';

type Params = {
  params: {
    data: {
      golfId: string,
      clubId: string,
      memberGuid: string,
      memberType: string,
    },
    userCredentials: {
      email: string,
      password: string,
    },
    ids: Array<string>,
  },
};

type Props = {
  navigation: NavigationScreenProp<NavigationStateRoute & Params>,
  loginAndUpdateAccount: typeof loginAndUpdateAccount,
};

const GolfSelect = ({ navigation, loginAndUpdateAccount, updateWithSessionId }: Props) => {
  const [selectedGolfId, setSelectedGolfId] = useState(null);

  const ids = navigation.getParam('ids');
  const data = navigation.getParam('data');
  const userCredentials = navigation.getParam('userCredentials');
  const updateSessionId = navigation.getParam('updateSessionId');

  const handleClose = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  const handleReplaceGolfId = () => {
    const updateData = {
      member_number: data.member_number,
      existing_golf_id: selectedGolfId,
      club_id: data.club_id,
      member_guid: data.member_guid,
      member_type: data.member_type,
    };

    console.log(updateData);
    if (updateSessionId) {
      updateWithSessionId(updateSessionId, updateData);
    } else {
      console.log(userCredentials);
      loginAndUpdateAccount(userCredentials.email, userCredentials.password, updateData);
    }
  };

  const renderGoldId = ({ item }) => (
    <SelectableItem title={item} selected={item === selectedGolfId} onPress={() => setSelectedGolfId(item)} />
  );

  const renderReplaceButton = () =>
    selectedGolfId ? <ReplaceButton title={i18n.t('register.replaceSelected')} onPress={handleReplaceGolfId} /> : null;

  return (
    <Root>
      <Header>
        <HeaderTop>
          <CloseButton onPress={handleClose}>
            <CloseIcon source={closeIcon} />
          </CloseButton>
          {renderReplaceButton()}
        </HeaderTop>
        <HeaderBottom>
          <HeaderTitle>{i18n.t('register.replaceGolfmore')}</HeaderTitle>
        </HeaderBottom>
      </Header>
      <Content>
        <ContentTitle>{i18n.t('register.pleaseSelectGolfId')}</ContentTitle>
        <ContentSimpleText mh={20}>
          {i18n.t('register.youAboutToReplaceStart')}
          &nbsp;
          <BlueText>{i18n.t('register.updateAccountPartGolfId', { golfboxId: data.member_number })}</BlueText>
          &nbsp;
          {i18n.t('register.youAboutToReplaceEnd')}
        </ContentSimpleText>
        <GolfIdList data={ids} renderItem={renderGoldId} keyExtractor={(item) => item} />
      </Content>
    </Root>
  );
};

const mapDispatchToProps = { loginAndUpdateAccount, updateWithSessionId };

export default connect(null, mapDispatchToProps)(GolfSelect);
