// @flow
import { useState } from 'react';
import moment from 'moment';
import api from '../../../../react-native/api';
import { unionByIsoCode } from '../../../../react-native/constants/unions';

function fixPhone(phone, country) {
  if (phone[0] === '+') {
    return phone;
  }
  const union = unionByIsoCode[country.toLowerCase()];
  if (!union) {
    return phone;
  }
  const prefix = union.phonePrefix || '+45';

  return `${prefix} ${phone}`;
}

// eslint-disable-next-line import/prefer-default-export

function convertSex(sex: string): string {
  if (sex == 'm' || sex == 'f') {
    // dont' change sex if it's correctly encoded
    return sex;
  }
  return sex === 'male' ? 'm' : 'f';
}

export const prepareRegisterData = (data: OAuthData) => {
  const regData = {
    member_number: data.golfId,
    union_id: data.golfUnionId,
    birth_date: data.birthDate ? moment(data.birthDate, 'YYYYMMDD').format('YYYY-MM-DD') : null,
    first_name: data.firstName,
    last_name: data.lastName,
    email: data.email,
    // dgu_email: data.email,
    country: data.country,
    sex: convertSex(data.sex),
  };

  if (data.memberGuid) {
    regData.member_guid = data.memberGuid;
  }

  if (data.phoneNumber) {
    regData.phone = fixPhone(data.phoneNumber, data.country);
  }

  if (data.clubId) {
    regData.club_id = data.clubId;
  }

  if (data.memberType) {
    regData.member_type = data.memberType;
  }

  if (data.clubName) {
    regData.club_name = data.clubName;
  }

  if (data.nfcCardNumber) {
    regData.nfc_card_number = data.nfcCardNumber;
  }
  console.log('RegData', regData);

  const dataSet = { ...regData };

  return dataSet;
};
