import React from 'react';
import Phone from 'react-icons/lib/md/contact-phone';
import Email from 'react-icons/lib/md/contact-mail';
import texts from '../../../constants/languages';

import './contactUs.css';

const ContactUs = () => (
  <div className="b-terms g-auth-content">
    <div className="container grid-xl b-contact__container g-container">
      <div className="columns">
        <div className="column col-12">
          <h2 className="b-contact__sub-title">{texts.CONTACT_US_HEADLINE}</h2>
          <div className="b-contact__paragraph">{texts.CONTACT_US_TEXT}:</div>
          <div className="b-contact__paragraph columns">
            <div className="column col-1 col-md-2 col-sm-6 b-contact__icons"><Phone /></div>
            <div className="column col-11 col-md-10 col-sm-6 b-contact__href telephone">
              <a href="tel:4570707999">+ 45 70 70 79 99</a>
            </div>
          </div>
          <div className="b-contact__paragraph columns">
            <div className="column col-1 col-md-2 col-sm-6 b-contact__icons"><Email /></div>
            <div className="column col-11 col-md-10 col-sm-6 b-contact__href telephone">
              <a href="mailto: info@golfmore.eu">info@golfmore.eu</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);


export default ContactUs;
