import React, { Component } from 'react';
import propTypes from 'prop-types';
import './textField.css';

class TextField extends Component {

  renderErrorNotification = (isEmpty, isInvalid, haveServerError, errorText) => {
    if (isEmpty) {
      return (
        <div className="b-text-field__error">
            {errorText[0]}
        </div>
      );
    } else if (isInvalid) {
      return (
        <div className="b-text-field__error">
          {errorText[1]}
        </div>
      );
    } else if (haveServerError) {
      return (
        <div className="b-text-field__error">
          {errorText[2]}
        </div>
      );
    }
    return null;
  };

  handleChangeInput = (e) => {
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(this.props.valueName, e.target.value);
    }
  };

  render() {
    return (
      <div className="b-text-field">
        <label className={`b-text-field__label ${this.props.disabled ? 'disabled' : ''}`}>
          {this.props.label}
        </label>
        <div className="b-text-field__input-container">
          {
            this.props.disabled
            ?
            (
              <input
                className="b-text-field__input disabled"
                type={this.props.type}
                value={this.props.value}
                disabled
              />
            )
            :
            this.props.inputMask
            ?
            (
              <input
                className="b-text-field__input"
                type={this.props.type}
                placeholder={this.props.placeholder}
                value={this.props.value}
                onChange={this.handleChangeInput}
              />
            )
            :
            (
              <input
                className="b-text-field__input"
                type={this.props.type}
                placeholder={this.props.placeholder ? this.props.placeholder : ''}
                value={this.props.value}
                onChange={this.handleChangeInput}
              />
            )
          }
          { this.renderErrorNotification(this.props.isEmpty, this.props.isInvalid, this.props.haveServerError, this.props.errorText) }
        </div>
      </div>
    );
  }
}

export default TextField;

TextField.propTypes = {
  onChange: propTypes.func,
  valueName: propTypes.string.isRequired,
  isEmpty: propTypes.bool,
  isInvalid: propTypes.bool,
  haveServerError: propTypes.bool,
  label: propTypes.string,
  type: propTypes.string,
  placeholder: propTypes.string,
  value: propTypes.string.isRequired,
  errorText: propTypes.array,
  disabled: propTypes.bool,
};

