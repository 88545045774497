// @flow
import styles from './react-native/constants/styles';

export default {
  main: {
    space: [0, 4, 6, 8, 12, 16, 20, 24, 32, 48, 64],
    fontSizes: Object.values(styles.font.size),
    colors: styles.colors,
    fonts: styles.font.style,
  },
};
