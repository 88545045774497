import { connect } from 'react-redux';

import appActions from '../../../domains/app/appActions';

import Home from './home';

function mapStateToProps(state) {
  return {
    geoIp: state.appReducer.get('geoIp'),
  };
}

const mapDispatchToProps = (dispatch) => ({
  getGeoIp: () => dispatch(appActions.getGeoIp()),
  togglePopup: (popupName, status) => dispatch(appActions.togglePopup(popupName, status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
