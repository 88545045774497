import React from 'react';

import urls from '../../../../constants/config/urls';

const privacyPolicyDenmark = () => (
  <div className="b-privacy-policy__content">
    <h1>PERSONVERNERKLÆRING (VERSJON 1.1, OPPDATERT JUNI 2018)</h1>
    <p>
      I dette dokumentet henviser "GolfMore", "vi", "oss" og "vår" til ProBox24 ApS, beliggende på
      Rugaardsvej 5, 8680 Ry, Danmark, og assosierte virksomheter.
    </p>
    <p>
      Når du bruker GolfMore appen eller på annen måte bruker våre nettsteder, inkludert men ikke
      begrenset til&nbsp;
      <a href={urls.norwegian}>no.golfmore.dk</a>, eller våre applikasjoner (samlet betegnet som
      "Nettstedet"), erklærer du deg innforstått med, at vi behandler dine personopplysninger som
      beskrevet i denne personvernerklæringen ("Erklæringen").
    </p>
    <ul className="b-privacy-policy__list">
      <li className="b-privacy-policy__item">
        <h2 className="b-privacy-policy__sub-title">
          1 Typer av opplysninger som vi samler inn, og hva vi bruker dem til
        </h2>
        <div className="b-privacy-policy__sub-section">
          <h3>1.1 Stamdata</h3>
          <p>Når du oppretter en profil samler vi inn følgende stamdata om deg ("Stamdata"):</p>
          <ul>
            <li>Ditt fornavn,</li>
            <li>Ditt etternavn</li>
            <li>Din e-postadresse</li>
            <li>Ditt mobilnummer</li>
            <li>Din fødselsdato</li>
            <li>Ditt land</li>
            <li>Ditt kjønn</li>
            <li>Ditt medlemsnummer hos en golfklubb</li>
            <li>Din hjemmeklubb</li>
            <li>Din medlemsstatus i golfklubben</li>
            <li>Dine innloggingsopplysninger</li>
          </ul>
        </div>
        <div className="b-privacy-policy__sub-section">
          <h3>1.2 Opplysninger i forbindelse med bruk av GolfMore</h3>
          <p>
            I forbindelse med bruk av GolfMore samler vi inn data fra diverse golfbookingsystemer
            (f.eks., men ikke begrenset til, GolfBox), og lagrer nødvendige opplysninger som:
          </p>
          <ul>
            <li>Hvor, når, med hvem, og til hvilken pris, du skal spille golf</li>
            <li>
              Hvilke verdikuponger du har brukt, hvor, når og hva, og til hvilken opprinnelig pris
            </li>
            <li>Hvem du overfører/mottar verdikuponger til/fra</li>
            <li>Hvilke verdikuponger, du har gjort digitale via last opp funksjonen</li>
            <li>Hvilke verdikuponger du har generert PDF’er av</li>
            <li>Din lokasjon</li>
          </ul>
        </div>
        <div className="b-privacy-policy__sub-section">
          <h3>1.3 Informasjonskapsler (Cookies)</h3>
          <p>
            Nettstedet bruker informasjonskapsler. Informasjonskapsler er digitale opplysninger, som
            lagres på din datamaskin. Informasjonskapsler identifiserer din datamaskin i stedet for
            deg som en individuell bruker, og anvendes til forskjellige formål.
          </p>
          <p>
            Les mere om hvilke informasjonskapsler nettstedet bruker og til hvilke formål nedenfor
            (avsnitt 7).
          </p>
        </div>
        <div className="b-privacy-policy__sub-section">
          <h3>1.4 Din IP-adresse, nettleserinnstillinger og plassering</h3>
          <p>
            Når du besøker nettstedet, registrerer vi din datamaskins IP-adresse og
            nettleserinnstillinger. IP-adressen er den numeriske adressen for datamaskinen som
            brukes til å besøke nettstedet. Nettleserinnstillinger kan omfatte den type nettleser du
            bruker, nettleserspråk, og tidssone. Vi samler inn disse opplysningene for å kunne
            forebygge og oppklare eventuell misbruk eller ulovlige handlinger i forbindelse med
            besøk på eller bruk av nettstedet. Videre bruker vi IP-adressen til å estimere din
            lokasjon (på bynivå).
          </p>
        </div>
        <div className="b-privacy-policy__sub-section">
          <h3>1.5 Opplysninger som samles inn ved påmelding til nyhetsbrev</h3>
          <p>
            Vi samler inn opplysningene som du gir oss, når du registrerer deg for mottakelse av
            våre nyhetsbrev, f.eks. ditt navn og din e-postadresse. Hvis du ikke lenger ønsker å
            motta våre nyhetsbrev, kan du melde deg av ved å logge inn på din brukerprofil og endre
            dine personlige innstillinger.
          </p>
        </div>
        <div className="b-privacy-policy__sub-section">
          <h3>1.6 Slik bruker vi opplysningene</h3>
          <p>
            Vi kan bruke de opplysningene som du gir oss til for å oppfylle kontrakten med deg,
            herunder:
          </p>
          <ul>
            <li>Levere våre tjenester til deg og gi deg adgang til vårt nettsted.</li>
            <li>Identifisere deg som en registrert bruker, når du logger inn på nettstedet.</li>
            <li>Svare på dine spørsmål og levere relatert kundeservice.</li>
            <li>Kontakte deg, hvis vi opplever misbruk av din konto.</li>
          </ul>
          <p>
            I tillegg kan vi bruke dine opplysninger til å forfølge berettigede interesser,
            herunder:
          </p>
          <ul>
            <li>Forbedre nettstedet og våre tjenester.</li>
            <li>Invitere deg til å bruke våre løsninger og tilbud.</li>
            <li>Sende deg våre nyhetsbrev.</li>
            <li>
              Utføre diverse interne forretningsformål, som dataanalyse, revisjoner, monitorering og
              forebygging av misbruk, utvikling av nye produkter og tjenester, forbedre eller endre
              nettstedet eller våre tjenester, identifisere brukstendenser, bestemme effektiviteten
              av våre reklamekampanjer, samt drive og utvide våre forretningsaktiviteter.
            </li>
            <li>Videregi dine opplysninger som beskrevet i avsnitt 2.</li>
          </ul>
          <p>
            Du har rett til å gjøre innsigelse mot, at vi behandler dine personopplysninger til
            ovenstående formål. Se mer under dine rettigheter i avsnitt 8.
          </p>
          <p>
            Endelig kan vi behandle dine opplysninger for å fastslå eller forsvare et rettskrav,
            herunder for å:
          </p>
          <ul>
            <li>
              Overholde gjeldende lovkrav og juridisk prosess, etterkomme anmodninger fra offentlige
              og statlige myndigheter, samt overholde relevante bransjestandarder og våre interne
              retningslinjer.
            </li>
            <li>
              Håndheve våre <a href={urls.norwegianTermsAndConditions}>Vilkår og Betingelser</a>.
            </li>
            <li>
              Beskytte våre forretningsaktiviteter eller forretningsaktiviteter tilhørende ethvert
              av våre assosierte selskaper.
            </li>
            <li>
              Beskytte våre rettigheter, personopplysninger, sikkerhet eller eiendom og/eller de
              tilhørende våre assosierte selskaper, deg eller andre.
            </li>
            <li>
              Gi oss mulighet for å forfølge tilgjengelige rettsmidler eller begrense eventuelle
              erstatningsansvar som vi kan pådra oss.
            </li>
          </ul>
        </div>
      </li>
      <li className="b-privacy-policy__item">
        <h2 className="b-privacy-policy__sub-title">2 Overføring av personopplysninger</h2>
        <div className="b-privacy-policy__sub-section">
          <h3>2.1 Overføring av personlige opplysninger på nettstedet</h3>
          <p>Vi vil i forbindelse med din bruk av nettstedet overføre visse opplysninger om deg.</p>
          <p>Betalingsopplysninger</p>
          <p>
          Vi overfører nødvendige data vedrørende bruk av verdikuponger til golfbookingsystemet for den respektive klubb.
          </p>
        </div>
        <div className="b-privacy-policy__sub-section">
          <h3>2.2 Overføring til andre tjenester og virksomheter</h3>
          <p>
          Ett av hovedformålene til GolfMore er å hjelpe golfklubber med å administrere deres verdikuponger. Derfor gir vi klubbene lov til å få adgang til personopplysninger, som er relevante for dem i forbindelse med bruk i deres klubb.
          </p>
          <p>
          Når du mottar verdikuponger fra en klubb eller har brukt GolfMore i en klubb er følgende data tilgjengelige:
          </p>
          <ul>
            <li>•	Ditt fornavn, etternavn, medlemsnummer og hjemmeklubb.</li>
            <li>
            •	Hvilke verdikuponger du har brukt, og hvilken originalpris det var på innløsningstidspunktet, tidspunkt for innløsning, hvor mange klipp det er igjen på verdikupongen.
            </li>
          </ul>
        </div>
        <div className="b-privacy-policy__sub-section">
          <h3>2.3 Andre overførsler</h3>
          <p>
          I tillegg til overstående, kan vi overføre dine personlige opplysninger til følgende parter og under de følgende omstendigheter:
          </p>
          <ul>
            <li>
            For å gi tredjepartsleverandører, konsulenter og andre tjenesteutbydere mulighet for å utføre tjenester på våre vegne.
            </li>
            <li>Til ProBox24 ApS datterselskaper og andre selskaper i ProBox24-konsernet.</li>
            <li>
            For å overholde lover eller svare på krav, juridisk prosess (herunder, men ikke begrenset til stevninger og rettskjennelser) samt etterkomme anmodninger fra offentlige og statlige myndigheter.
            </li>
            <li>
            Til tredjeparter i forbindelse med håndhevelse av våre
              <a
                className="b-privacy-policy__link"
                href={urls.norwegianTermsAndConditions}
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                {' '}
                Vilkår og Betingelser
              </a>
              .
            </li>
            <li>
            Til tredjeparter for å beskytte vår drift eller våre assosierte selskapers drift.
            </li>
            <li>
            Til tredjeparter for å forfølge tilgjengelige rettsmidler, eller begrense erstatningsansvar, som vi kan pådra oss.
            </li>
            <li>
            Til tredjeparter slik at vi kan undersøke, forebygge eller gripe inn overfor antatte eller faktiske forbudte aktiviteter, herunder men ikke begrenset til misbruk av vårt nettsted.
            </li>
          </ul>
        </div>
      </li>
      <li className="b-privacy-policy__item">
        <h2 className="b-privacy-policy__sub-title">3 Behandlingsansvarlig</h2>
        <div className="b-privacy-policy__sub-section">
          <p>
          Vi er behandlingsansvarlig for stamdata, som du taster inn i forbindelse med din opprettelse av en profil. Stamdata omfatter, men ikke er begrenset til ditt navn, ditt passord og e-postadresse, samt registrering av din IP-adresse.
          </p>
          <p>
          Vi er også den behandlingsansvarlig for de opplysninger som overføres til andre tjenester (se avsnitt 2.2). GolfMore sin innsamling av dine personopplysninger er regulert av den til enhver tid gjeldende personvernlovgivning i Danmark.
          </p>
        </div>
      </li>
      <li className="b-privacy-policy__item">
        <h2 className="b-privacy-policy__sub-title">4 4 Lenker til nettsteder</h2>
        <div className="b-privacy-policy__sub-section">
          <p>
          Vårt nettsted inneholder lenker til andre nettsteder. Vår bruk av slike lenker er ikke et uttrykk for vår godkjennelse av de aktuelle nettstedene. Vi kontrollerer ikke innholdet på disse tredjepartsnettsteder, og påtar oss intet ansvar for tredjeparten, deres policy eller praksiser.
          </p>
          <p>
          Vi oppfordrer deg til å lese gjennom disse tredjepartsnettsteders personvernerklæringer, da deres prosedyrer for innsamling, håndtering og behandling av personopplysninger kan være annerledes enn vår.
          </p>
        </div>
      </li>
      <li className="b-privacy-policy__item">
        <h2 className="b-privacy-policy__sub-title">
          5 Databehandler og overførsel av personopplysninger
        </h2>
        <div className="b-privacy-policy__sub-section">
          <p>
          Vi bruker eksterne for å opprettholde den tekniske driften av nettstedet og våre tjenester. Disse virksomhetene er databehandlere for de personopplysninger, som vi er behandlingsansvarlige for. Ved å akseptere denne Erklæringen aksepterer du at vi også kan gjøre det mulig for de personopplysninger som vi er dataansvarlig for, å bli behandlet av de samme databehandlere.
          </p>
          <p>
          Databehandlerne skal utelukkende handle i overensstemmelse med instrukser fra oss. Vi vil derfor sende slike instrukser til databehandlerne slik at personopplysninger blir behandlet i overensstemmelse med Erklæringen og med hensyn til å bruke nettstedet.
          </p>
          <p>
          Databehandlerne har iverksatt rimelige tekniske og organisatoriske tiltak for å beskytte personopplysningene mot utilsiktet eller ulovlig tilintetgjørelse, tap eller forringelse, og for å beskytte personopplysningene mot videreformidling til uvedkommende, misbruk, eller annen behandling i strid med personvernlovgivningen.
          </p>
        </div>
      </li>
      <li className="b-privacy-policy__item">
        <h2 className="b-privacy-policy__sub-title">6 Sikkerhetsforanstaltninger</h2>
        <div className="b-privacy-policy__sub-section">
          <p>
          Vi søker å anvende passende organisatoriske, tekniske og administrative tiltak for å beskytte dine personopplysninger i vår organisasjon, og vi reviderer jevnlig våre systemer for sårbarheter. Men ettersom internett ikke er et 100 % sikkert miljø, kan vi ikke sikre eller garantere sikkerheten for opplysninger som du sender til GolfMore. E-post som sendes via nettstedet er ikke krypterte, og vi råder deg derfor til å ikke inkludere fortrolige eller sensitive personopplysninger i dine e-poster til oss.
          </p>
        </div>
      </li>
      <li className="b-privacy-policy__item">
        <h2 className="b-privacy-policy__sub-title">7 Informasjonskapsler</h2>
        <div className="b-privacy-policy__sub-section">
          <p>
          Nettstedet bruker informasjonskapsler og lignende teknologier ("Cookies"). Ved å bruke vårt nettsted aksepterer du at vi bruker informasjonskapsler som beskrevet nedenfor.
          </p>
          <div className="b-privacy-policy__sub-section">
            <h3>7.1 Hvilke typer av informasjonskapsler bruker vi?</h3>
            <p>
            Informasjonskapsler er små tekstfiler med informasjon som nettstedet lagrer på din datamaskins harddisk, på ditt nettbrett eller på din smarttelefon. Informasjonskapsler inneholder opplysninger, som nettstedet bruker for å gjøre kommunikasjonen mellom deg og din nettleser mer effektiv. Informasjonskapsler identifiserer din datamaskin i stedet for deg som en individuell bruker.
            </p>
            <p>
            Vi bruker sesjonsavhengige og faste informasjonskapsler, samt teknologier som ligner sesjonsavhengige og faste informasjonskapsler. Sesjonsavhengige informasjonskapsler er midlertidige utdrag av opplysninger som slettes når du lukker din nettleser. Faste informasjonskapsler er mer permanente utdrag av opplysninger som lagres og blir på din datamaskin inntil de slettes. Faste informasjonskapsler sletter seg selv etter en viss periode, men fornyes hver gang du besøker nettstedet.
            </p>
            <p>
            Vi bruker teknologier som ligner sesjonsavhengige og faste informasjonskapsler til lagring og til å få adgang til opplysninger i nettleseren eller enheten som bruker lokale enheter og lokal lagring, f.eks. HTML5-informasjonskapsler, Flash og andre metoder. Disse teknologiene kan fungere på tvers av alle dine nettlesere. I noen tilfeller kan bruken av disse teknologiene ikke styres av nettleseren, men krever særlige verktøy. Vi bruker disse teknologiene for å lagre opplysninger og oppdage uregelmessigheter i bruken av nettstedet.
            </p>
          </div>

          <div className="b-privacy-policy__sub-section">
            <h3>7.2 Hva bruker vi informasjonskapsler til?</h3>
            <p>Vi bruker informasjonskapsler til:</p>
            <ul>
              <li>Generering av statistikk</li>
            </ul>
            <p>
            Måling av nettstedstrafikk slik som antall besøk på nettstedet, hvilke domener de besøkende kommer fra, hvilke sider de besøkende besøker på nettstedet, og i hvilke overordnede geografiske områder de besøkende er lokalisert.
            </p>
            <ul>
              <li>Monitorering av nettstedets yteevne og din bruk av vårt nettsted</li>
            </ul>
            <p>
            Monitorering av nettstedet, våre applikasjoner og widgets yteevne, og hvordan du bruker vårt nettsted, applikasjoner og widgets.
            </p>
            <ul>
              <li>Godkjennelse, samt forbedring av funksjonaliteten på vårt nettsted</li>
            </ul>
            <p>
            Optimering av din opplevelse med nettstedet, som omfatter å huske ditt brukernavn og passord når du returnerer til nettstedet, samt huske opplysninger om din nettleser og preferanser (f.eks. hvilket språk du foretrekker).
            </p>
          </div>
          <div className="b-privacy-policy__sub-section">
            <h3>7.3 Tredjeparts informasjonskapsler</h3>
            <p>Vårt nettsted bruker informasjonskapsler fra følgende tredjeparter:</p>
            <ul>
              <li>
              Google Analytics: For å monitorere trafikken på vårt nettsted, slik som generelle besøkstall, konverteringer og liknende.
              </li>
              <li>
              Fabric Crashlytics: For å spore feil og nedetid på vårt nettsted.
              </li>
              <li>
              Javascript: Til monitoreringsformål, herunder hvordan du bruker vårt nettsted, widgets og applikasjoner, og for å skape et godt websted og widgets.
              </li>
            </ul>
          </div>
          <div className="b-privacy-policy__sub-section">
            <h3>7.4 Sletting av informasjonskapsler</h3>
            <p>
            Hvis du vil slette informasjonskapsler som allerede er lagret på din enhet kan du: Hvis du bruker en PC med en oppdatert nettleser kan du trykke på CTRL + SHIFT + DELETE samtidig, når du har nettleseren åpen og aktiv. Hvis snarveien ikke virker i din nettleser, bes du om å besøke supportsiden for den aktuelle nettleseren. Bemerk at om du ikke aksepterer informasjonskapsler fra vårt nettsted, kan du oppleve noen ulemper ved din bruk av nettstedet, og du kan bli forhindret fra å få adgang til alle dens funksjoner.
            </p>
          </div>
        </div>
      </li>
      <li className="b-privacy-policy__item">
        <h2 className="b-privacy-policy__sub-title">8 Adgang, rettelse og sletting</h2>
        <div className="b-privacy-policy__sub-section">
          <h3>8.1 Adgang til dine personopplysninger</h3>
          <p>
          På din anmodning vil vi informere deg om til hvilke formål vi behandler dine personopplysninger, hvilke personopplysninger vi har om deg, hvem som mottar dine personopplysninger, og hvor personopplysningene stammer fra.
          </p>
          <p>
          Anmodning om ovenstående vil som utgangspunkt kun bli behandlet hvis det har gått mer enn seks (6) måneder siden eventuell tidligere anmodning fra deg, medmindre du kan dokumentere en særlig interesse i å få opplysningene tidligere.
          </p>
          <h3>8.2 Rettelse og sletting av dine personopplysninger</h3>
          <p>
          Du bes informere oss hvis noen av de personopplysningene som vi, som den dataansvarlige, behandler om deg, er uriktige, ufullstendige eller villedende, slik at vi kan rette dem. Du vil selv kunne rette de fleste av disse opplysningene på vårt nettsted. Hvis det er mulig, anbefaler vi at du selv retter dine opplysninger.
          </p>
          <p>
          Du kan til enhver tid rette eller slette eventuelt innhold og personopplysninger på nettstedet. Hvis dine personopplysninger endrer seg, eller hvis du ikke lenger ønsker å bli vist på nettstedet, kan du oppdatere eller slette opplysningene ved å logge inn på din brukerprofil.
          </p>
          <p>
          Når din profil er slettet, blir alle opplysninger knyttet til din brukerprofil slettet, herunder dine stamdata og anmeldelser på nettstedet.
          </p>
          <p>
          Vi forbeholder oss retten til å blokkere adgangen til din profil og/eller slette din profil, hvis profilen eller innholdet forbundet med din profil eller din(e) anmeldelse(r) på nettstedet etter vår vurdering er diskriminerende, rasistiske, seksuelt orientert, uetisk, truende, støtende, sjikanerende eller på annen måte overtreder gjeldende lover, tredjepartsrettigheter, retningslinjene for brukere eller er uforenelig med formålet med nettstedet. Hvis vi blokkerer adgangen til eller sletter din brukerprofil, vil vi informere deg om årsaken til blokkeringen eller slettingen av din profil ved å sende en e-post til den adresse som du oppga da du opprettet din konto.
          </p>
        </div>
      </li>
      <li className="b-privacy-policy__item">
        <h2 className="b-privacy-policy__sub-title">9 Endringer i personvernerklæringen</h2>
        <p>
        Vi kan endre denne Erklæringen til enhver tid. Datoen som vises i starten av denne Erklæringen angir når den sist ble revidert. Eventuelle endringer trer i kraft, når vi offentliggjør den reviderte Erklæringen på nettstedet. Du aksepterer at din fortsatte bruk av nettstedet etter eventuelle offentliggjorte endringer utgjør ditt samtykke til den nye versjonen av Erklæringen.
        </p>
      </li>
      <li className="b-privacy-policy__item">
        <h2 className="b-privacy-policy__sub-title">
          10 Henvendelser vedrørende nettstedet eller personvern-erklæringen
        </h2>
        <p>
        Hvis du har spørsmål eller betenkeligheter vedrørende vår Erklæring, hvordan vi behandler dine personopplysninger, eller gjerne vil at vi skal rette dine personopplysninger, bes du kontakte oss på: GolfMore ApS, Rugaardsvej 5, 8680 Ry, Danmark,
          CVR-nr.: 33593082, telefon: +45 7070 7999, e-mail:
          <a className="b-privacy-policy__link" href="mailto:info@probox24.com">
            {' '}
            info@probox24.com
          </a>
          .
        </p>
      </li>
    </ul>
  </div>
);

export default privacyPolicyDenmark;
