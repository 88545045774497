import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import appActions from '../../../domains/app/appActions';
import enTexts from '../../../constants/languages/english';
import dkTexts from '../../../constants/languages/denmark';
import logo from '../../../images/logo.png';

import './notFound.css';

const { setLocation } = appActions;
const { string, func } = propTypes;

class NotFound extends Component {
  static propTypes = {
    locale: string.isRequired,
    setLocation: func.isRequired,
  };

  componentDidMount() {
    this.checkLocation();
  }

  checkLocation = () => {
    const { hostname } = window.location;
    if (hostname === 'dev.golfmore.club' || hostname === 'golfmore.club') {
      this.props.setLocation('english');
    } else {
      this.props.setLocation('danish');
    }
  };

  redirectHomePage = () => {
    window.location.href = '/';
  };
  redirectContactPage = () => {
    window.location.href = '/contact-us';
  };
  redirectToFaqPage = () => {
    window.location.href = '/faq';
  };

  render() {
    const checkLocation = this.props.locale === 'danish' ? dkTexts : enTexts;
    return (
      <div className="container notFoundPage">
        <div className="notFoundPage__logo">
          <img
            className="notFoundPage__logo--transparent"
            alt="GolfMore Logo"
            src={logo}
            onClick={this.redirectHomePage}
          />
        </div>
        <div className="notFoundPage__header">
          <div className="columns">
            <div className="column col-7 hide-sm" />
            <div className="column col-5 col-sm-12">{checkLocation.PAGE_NOT_FOUND}</div>
          </div>
        </div>
        <div className="notFoundPage__header--notification">
          <div className="columns">
            <div className="column col-7 hide-sm" />
            <p className="column col-5 col-sm-12">{checkLocation.PAGE_NOT_FOUND_INFO}</p>
          </div>
        </div>
        <div className="notFoundPage__buttons">
          <div className="columns">
            <div className="column col-7" />
            <div className="column col-5 col-ml-auto col-sm-10">
              <button className="notFoundPage__buttons--cta" onClick={this.redirectHomePage}>
                {checkLocation.GO_TO_MAIN_PAGE}
              </button>
            </div>
          </div>
          <div className="columns">
            <div className="column col-7" />
            <div className="column col-5 col-ml-auto col-sm-10">
              <button className="notFoundPage__buttons--cta" onClick={this.redirectToFaqPage}>
                {checkLocation.FAQ}
              </button>
            </div>
          </div>
          <div className="columns">
            <div className="column col-7" />
            <div className="column col-5 col-ml-auto col-sm-10">
              <button className="notFoundPage__buttons--cta" onClick={this.redirectContactPage}>
                {checkLocation.CONTACT_US}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ appReducer: app }) => ({
  locale: app.get('location'),
});

export default connect(mapStateToProps, (dispatch) => bindActionCreators({ setLocation }, dispatch))(NotFound);
