// @flow
const da = require('./assets/da.png');
const no = require('./assets/no.png');
const sv = require('./assets/sv.png');
const en = require('./assets/uk.png');
const is = require('./assets/is.png');
const fi = require('./assets/fi.png');

const localeMap = {
  da,
  no,
  sv,
  en,
  is,
  fi,
};

const getImageForLocale = (locale: string) => {
  return localeMap[locale];
};

export default getImageForLocale;
