import { composeWithDevTools } from 'redux-devtools-extension';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import apiMiddleware from './react-native/store/middleware/api';

import configureApi from './react-native/api';
import config from './react-native/constants/config';

import reducer from './reducers';

import sagas from './react-native/store/sagas';

configureApi(config.baseURL);

const sagaMiddleware = createSagaMiddleware();
const mw = applyMiddleware(apiMiddleware, thunk, sagaMiddleware);

const store = createStore(reducer, composeWithDevTools(mw));

sagas.forEach((saga) => sagaMiddleware.run(saga));

export default store;
