const { REACT_APP_STAGE = 'production' } = process.env;

const urls = {
  english: 'https://golfmore.club',
  englishTermsAndConditions: 'https://golfmore.club/terms-and-conditions',
  englishPrivacyPolicy: 'https://golfmore.club/privacy-policy',
  danish: 'https://golfmore.dk',
  danishTermsAndConditions: 'https://golfmore.dk/terms-and-conditions',
  danishPrivacyPolicy: 'https://golfmore.dk/privacy-policy',
  norwegian: 'https://no.golfmore.dk',
  norwegianTermsAndConditions: 'https://no.golfmore.dk/terms-and-conditions',
  norwegianPrivacyPolicy: 'https://no.golfmore.dk/privacy-policy',
  swedish: 'https://golfmore.se',
  swedishTermsAndConditions: 'https://golfmore.se/terms-and-conditions',
  swedishPrivacyPolicy: 'https://golfmore.se/privacy-policy',
  german: 'https://golfmore.de',
  germanTermsAndConditions: 'https://golfmore.se/terms-and-conditions',
  germanPrivacyPolicy: 'https://golfmore.se/privacy-policy',
};

export default urls;
