// @flow
import { StyleSheet } from 'react-native';
import styles from '../../../constants/styles';
import { elevation8 } from '../../../helpers/styles';

const { colors, font, size } = styles;

export default StyleSheet.create({
  screen: {
    flex: 1,
  },
  tint: { flex: 1 },
  logInForm: {
    padding: 20,
    borderTopRightRadius: size.borderRadius,
    borderBottomRightRadius: size.borderRadius,
    marginRight: 20,
    //  marginTop: 100,
  },
  btnLogInLoading: {
    opacity: 0.5,
  },
  BtnTextLogIn: {
    color: colors.basic,
    ...font.style.medium,
    fontSize: font.size.normal,
  },
  forgot: {
    textAlign: 'center',
  },
  errorMessage: {
    top: 10,
    color: colors.errorRed,
    alignSelf: 'flex-start',
  },

  forgotText: {
    color: colors.slate_gray,
    fontSize: font.size.normal,
    ...font.style.regular,
  },
  inputText: {
    marginTop: 15,
    color: colors.slate_gray,
    fontSize: font.size.normal,
    ...font.style.regular,
  },
  bottomField: {
    flex: 1,
    alignItems: 'center',
  },
  logoContainer: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
  },
  logoSubContainer: {
    backgroundColor: colors.green,
    padding: 25,
    borderRadius: 13,
    alignContent: 'center',
    alignItems: 'center',
  },
  logo: {
    width: 130,
    height: 130,
  },
  logoText: {
    marginTop: 8,
    color: '#fff',
    ...font.style.bold,
    fontSize: 20,
    marginBottom: -8,
  },
  greenUnderline: {
    color: colors.green,
    textDecorationLine: 'underline',
    ...font.style.regular,
    fontSize: 14,
  },
  bottomBar: {
    alignItems: 'center',
    backgroundColor: colors.basic,
    elevation: 8,
    height: 48,
    justifyContent: 'center',
  },
  shadow: {
    width: '100%',
    height: 10,
    position: 'absolute',
    left: 0,
    right: 0,
    top: -10,
  },
  registerContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  registerHintContainer: {
    position: 'absolute',
    top: 0,
    right: 8,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    color: '#fff',
    fontSize: font.size.normal,
    ...font.style.regular,
  },
  orSignInContainer: {
    marginHorizontal: 20,
    marginTop: 35,
    flexDirection: 'row',
    alignItems: 'center',
    ...elevation8,
  },
  horizontalLine: {
    flex: 1,
    height: 1,
    backgroundColor: '#fff',
    opacity: 0.35,
  },
  orSignInText: {
    marginHorizontal: 10,
    color: 'white',
    fontSize: font.size.normal,
    ...font.style.regular,
  },
  golfBoxContainer: {
    alignSelf: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    height: 38,
    marginTop: 20,
    marginBottom: 30,
    borderRadius: 5,
    paddingLeft: 10,
    paddingRight: 20,
    backgroundColor: colors.golfBox,
  },
  golfBoxIcon: {
    width: 22,
    height: 22,
    resizeMode: 'contain',
    marginRight: 10,
  },
  golfBoxText: {
    color: 'white',
    fontSize: font.size.small,
    ...font.style.medium,
  },
});

export const colorGray = colors.grey;
