// @flow
import api from '../../api/index';
import { API_REQUEST } from '../../store/apiAction';
import type { ApiRequest } from '../../store/apiAction';

const FORGOT_PASSWORD: 'user/FORGOT_PASSWORD' = 'user/FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS: 'user/FORGOT_PASSWORD_SUCCESS' = 'user/FORGOT_PASSWORD_SUCCESS';
const FORGOT_PASSWORD_FAILED: 'user/FORGOT_PASSWORD_FAILED' = 'user/FORGOT_PASSWORD_FAILED';
export const FORGOT_PASSWORD_SUCCESS_GO_BACK: 'user/FORGOT_PASSWORD_SUCCESS_GO_BACK' =
  'user/FORGOT_PASSWORD_SUCCESS_GO_BACK';

type ForgotPasswordFailed = {|
  type: typeof FORGOT_PASSWORD_FAILED,
  error: {
    response: {
      data: {
        message: string,
      },
    },
  },
|};

export type PasswordRecoveryState = {
  error: ?string,
  loading: boolean,
};

type State = {
  passwordRecovery: PasswordRecoveryState,
  showVerifyModal: boolean,
};

export const initPasswordState: State = {
  passwordRecovery: {
    error: null,
    loading: false,
  },
  showVerifyModal: false,
};

export default {
  [FORGOT_PASSWORD](state: State) {
    return {
      ...state,
      passwordRecovery: {
        ...initPasswordState.passwordRecovery,
        loading: true,
      },
      showVerifyModal: false,
    };
  },
  [FORGOT_PASSWORD_SUCCESS](state: State) {
    return {
      ...state,
      showVerifyModal: true,
    };
  },
  [FORGOT_PASSWORD_FAILED](state: PasswordRecoveryState, action: ForgotPasswordFailed) {
    return {
      ...state,
      passwordRecovery: {
        ...initPasswordState.passwordRecovery,
        error: action.error.response.data.message,
      },
      showVerifyModal: false,
    };
  },
  [FORGOT_PASSWORD_SUCCESS_GO_BACK](state: State) {
    return {
      ...state,
      showVerifyModal: false,
    };
  },
};

export function recoveryPassword(email: string): ApiRequest<*> {
  return {
    type: API_REQUEST,
    types: [FORGOT_PASSWORD, FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORD_FAILED],
    call: () => api().user.recoverPassword(email),
  };
}

export function goBack() {
  return {
    type: FORGOT_PASSWORD_SUCCESS_GO_BACK,
  };
}
