// @flow

import React from 'react';
import { View, Text } from 'react-native';

import pick from 'lodash/pick';
import omit from 'lodash/omit';
import keys from 'lodash/keys';
import noop from 'lodash/noop';
import difference from 'lodash/difference';

import { Touchable, Container, Title, Image } from './styles';

export type ButtonProps = {
  disabled?: boolean,
  color?: string,
  textColor?: string,
  fontSize?: number,
  text: string,
  imageLeft?: any,
  imageRight?: any,
  onPress: Function,
};

const allViewProps = keys(View.propTypes);
const allTextProps = keys(Text.propTypes);
const onlyTextProps = difference(allTextProps, allViewProps);

const NewButton = ({
  disabled = false,
  color,
  textColor,
  fontSize,
  text,
  imageLeft,
  imageRight,
  onPress,
  ...restProps
}: ButtonProps) => {
  const containerProps = {
    ...omit(restProps, onlyTextProps),
    bg: color,
    disabled,
  };
  const textProps = {
    ...pick(restProps, onlyTextProps),
    text,
    fontSize,
    color: textColor,
  };
  return (
    <Touchable onPress={disabled ? noop : onPress}>
      <Container {...containerProps}>
        {imageLeft && <Image source={imageLeft} tintColor={textColor} mr={3} />}
        <Title {...textProps} />
        {imageRight && <Image source={imageRight} tintColor={textColor} ml={3} />}
      </Container>
    </Touchable>
  );
};

NewButton.defaultProps = {
  color: 'button',
  textColor: 'white',
};

export default NewButton;
