// @flow
import React from 'react';

import {
  Root,
  StepContainer,
  StepText,
  StepCheckImage,
  StepCompletedLine,
  StepPendingLine,
  EdgeSpace,
  Container,
  Title,
} from './styles';

export type RegisterStepsProps = {
  stepCount: number,
  step: number,
  title: string,
};

const RegisterSteps = ({ stepCount, step, title }: RegisterStepsProps) => {
  const stepViews = new Array(stepCount).fill(null).reduce((acc, item, index) => {
    let stepState = 'pending';
    if (step === index + 1) {
      stepState = 'inProgress';
    } else if (step > index + 1) {
      stepState = 'completed';
    }
    const stepText =
      stepState === 'completed' ? null : <StepText text={String(index + 1)} state={stepState} />;
    const stepImage = stepState !== 'completed' ? null : <StepCheckImage />;

    const stepView = (
      <StepContainer state={stepState}>
        {stepText}
        {stepImage}
      </StepContainer>
    );

    if (index === stepCount - 1) {
      acc.push(stepView);
    } else {
      acc.push(stepView);
      acc.push(stepState === 'completed' ? <StepCompletedLine /> : <StepPendingLine />);
    }
    return acc;
  }, []);
  return (
    <Root>
      {title && <Title text={title}/>}
      <Container>
        <EdgeSpace />
        {stepViews}
        <EdgeSpace />
      </Container>
    </Root>
  );
};

export default RegisterSteps;
