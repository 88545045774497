import React, { Component } from 'react';
import propTypes from 'prop-types';
import texts from '../../../constants/languages';
import VoucherRN from '../../../react-native/vouchers/components/Voucher/Voucher';

import './voucher.css';

const { string, func, bool, number } = propTypes;

class Voucher extends Component {
  static propTypes = {
    voucherTitle: string.isRequired,
    ticketDesk: string,
    image: string,
    validationTime: string,
    price: string,
    status: string,
    voucherID: number.isRequired,
    setCurrentVoucher: func.isRequired,
    printPDF: func.isRequired,
    togglePopup: func.isRequired,
    updatePrintRules: func,
    printed: string,
    count: number,
    printRules: bool.isRequired,
    displayed: bool,
  };

  static defaultProps = {
    updatePrintRules: () => null,
  };

  state = {
    checked: this.props.printed,
    printId: null,
    showNewWindow: false,
  };

  componentWillReceiveProps(nextProps) {
    if (typeof this.props.printRules !== 'undefined' && typeof nextProps.printRules !== 'undefined') {
      if (!this.props.printRules && nextProps.printRules) {
        if (this.props.voucherID === this.state.printId) {
          // check if selected voucherID is equal to props voucherID
          this.printPDF(this.state.printId);
          this.setState({
            printId: null,
          });
        }
      }
    }
  }

  setVoucherDetailsPromise = (action, currentVoucher) =>
    new Promise((resolve, reject) => {
      resolve('result');
      action(currentVoucher);
    });

  voucherPressHandler = (event) => {
    if (!this.voucherCheckbox.contains(event.target)) {
      this.props.vouchers.forEach((currentVoucher) => {
        if (this.props.voucherID === currentVoucher.voucher_id) {
          this.setVoucherDetailsPromise(this.props.setCurrentVoucher, currentVoucher).then((result) => {
            this.props.togglePopup('voucherDetailsPopupIsOpen', true);
          });
        }
      });
    }
  };

  renderPrice = (price) => {
    const { reductionType } = this.props;

    if (reductionType === 'setprice') {
      return price === '0' ? texts.FREE : `${price}, -`;
    } else if (reductionType === 'reducepercent') {
      return `-${price}%`;
    } else if (reductionType === 'reduceprice') {
      return `-${price}`;
    }
  };

  defineStatus = (status) => {
    if (status === 'personal') {
      return texts.PERSONAL;
    } else if (status === 'loyalty') {
      return texts.LOYALTY;
    } else if (status === 'offer') {
      return texts.OFFER;
    }
  };

  createAvatarUrl = () => ({
    backgroundImage: `url('${this.props.image}')`,
  });

  printPDF = async (voucherID) => {
    const { setSpinnerFlag, printPDF, togglePopup } = this.props;

    // console.log('PRINTPDF - ', voucherID);

    setSpinnerFlag(true);
    const voucherResponse = await printPDF(voucherID);
    if (voucherResponse) {
      this.setState({ checked: true });
      setSpinnerFlag(false); // stop showing spinner
    } else {
      setSpinnerFlag(false);
      togglePopup('voucherPDFPopUpIsOpen', true); // this pop up shows to user that something went wrong
    }
  };

  onPrintPDFClick = async (voucherID) => {
    const { togglePopup, printRules } = this.props;
    if (!printRules && window.navigator.onLine) {
      togglePopup('voucherPrintNotification', true);
      // set current voucherID to state to handle correct printing
      this.setState({
        printId: voucherID,
      });
      return;
    }
    await this.printPDF(voucherID);
    setTimeout(window.location.reload.bind(window.location), 1500);
  };

  render() {
    return (
      <div className="column col-6 col-md-12 col-sm-12 col-xs-12">
        <VoucherRN voucher={this.props.voucher} onPressVoucher={this.voucherPressHandler}>
          <div
            className="b-voucher__checkbox"
            style={{ flex: 1, justifyContent: 'flex-end', margin: 10 }}
            ref={(voucherCheckbox) => (this.voucherCheckbox = voucherCheckbox)}
            onClick={(evt) => {
              evt.stopPropagation();
              this.onPrintPDFClick(this.props.voucherID);
              return true;
            }}
          >
            <div className="b-voucher__checkbox-container">
              <div className="b-voucher__checkbox-label">{texts.REPRINT}</div>
              <div className="b-voucher__checkbox-img" />
            </div>
          </div>
          <div className="b-voucher__printed-label">{texts.PRINTED_NOT_VISIBLE_IN_MOBILE_APP}</div>
        </VoucherRN>
      </div>
    );
  }
}

export default Voucher;
