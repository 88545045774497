// @flow
import api from './api';
import auth from '../../auth/sagas/auth';
import user from '../../user/sagas/user';
import forgotPassword from '../../auth/sagas/forgotPassword';
import registering from '../../register/sagas/registering';
// import { pinVerified, revokePinGrant } from './verifications';
import locale from './locale';
import connection from '../../auth/sagas/connection';
import authWeb from '../../../domains/auth/sagas';
//import voucher from '../../vouchers/sagas/voucher';

import country from '../../register/sagas/country';
import geolocation from '../../geo/sagas/geosaga';
import clubs from '../../clubs/sagas';

export default [locale, api, auth, user, forgotPassword, registering, connection, country, geolocation, clubs, authWeb];
