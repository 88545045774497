// @flow
import { StyleSheet } from 'react-native';

import styles from '../../constants/styles';

export default StyleSheet.create({
  flex: {
    flex: 1,
  },
  input: {
    height: 44,
    flex: 1,
    justifyContent: 'center',
    paddingLeft: 20,
    paddingRight: 8,
    borderWidth: 1,
    borderColor: styles.colors.basic30,
    borderRadius: 5,
    color: styles.colors.basic,
    backgroundColor: styles.colors.white,
  },
  label: {
    marginTop: 20,
    color: styles.colors.grey,
    fontSize: styles.font.size.xSmall,
    marginBottom: 8,
  },
  selectStyle: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    padding: 8,
    paddingLeft: 0,
  },
  selectTextStyle: {
    color: styles.colors.slate_gray,
    fontSize: styles.font.size.normal,
    ...styles.font.style.regular,
  },
  selectedTextStyle: {
    color: styles.colors.basic,
    fontSize: styles.font.size.normal,
    ...styles.font.style.regular,
  },
  carret: {
    width: 24,
    height: 24,
    resizeMode: 'contain',
  },
});
