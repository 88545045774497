import React from 'react';

import urls from '../../../../constants/config/urls';

const privacyPolicyDenmark = () => (
  <div className="b-privacy-policy__content">
    <h1 className="b-privacy-policy__title">persondatapolitik (version 1.1, Opdateret juni 2018)</h1>
    <p className="b-privacy-policy__paragraph">
              I dette dokument, henviser "GolfMore", "vi" "os" og "vores" til ProBox24 ApS, beliggende på Rugaardsvej 5, 8680
              Ry, Danmark og dets associerede virksomheder.
    </p>
    <p className="b-privacy-policy__paragraph">
        Når du bruger GolfMore appen eller på anden måde bruger vores websteder, herunder men ikke begrænset til&nbsp;
      <a className="b-privacy-policy__link" href={urls.danish}>www.golfmore.dk</a>,
        eller vores applikationer (samlet benævnt "Webstedet"),
      <span className="b-privacy-policy__bold">erklærer du dig indforstået med,</span>
        at vi behandler dine personlige oplysninger ("Politikken") som beskrevet i denne Politik.
    </p>

    <ul className="b-privacy-policy__list">

      <li className="b-privacy-policy__item">
        <h2 className="b-privacy-policy__sub-title">1 Typer af oplysninger, som vi indsamler, og hvad vi bruger dem til</h2>
        <div className="b-privacy-policy__sub-section">
          <h3 className="b-privacy-policy__sub-inner-title">1.1 Stamdata</h3>
          <p className="b-privacy-policy__paragraph">Når du opretter en profil indsamler vi følgende stamdata om dig ("Stamdata"):</p>
          <ul className="b-privacy-policy__sub-inner-list">
            <li className="b-privacy-policy__sub-inner-item">Dit fornavn,</li>
            <li className="b-privacy-policy__sub-inner-item">Dit efternavn</li>
            <li className="b-privacy-policy__sub-inner-item">Din e-mailadresse</li>
            <li className="b-privacy-policy__sub-inner-item">Dit mobilnr.</li>
            <li className="b-privacy-policy__sub-inner-item">Din fødselsdato</li>
            <li className="b-privacy-policy__sub-inner-item">Dit land</li>
            <li className="b-privacy-policy__sub-inner-item">Dit køn</li>
            <li className="b-privacy-policy__sub-inner-item">Dit medlemsnummer hos en golfunion</li>
            <li className="b-privacy-policy__sub-inner-item">Din hjemmeklub</li>
            <li className="b-privacy-policy__sub-inner-item">Din medlemstype</li>
            <li className="b-privacy-policy__sub-inner-item">Dine adgangsoplysninger</li>
          </ul>
        </div>
        <div className="b-privacy-policy__sub-section">
          <h3 className="b-privacy-policy__sub-inner-title">1.2 Oplysninger i forbindelse med brug af GolfMore</h3>
          <p className="b-privacy-policy__paragraph">
              I forbindelse med brug af GolfMore indsamler vi data fra diverse golfbookingsystemer (f.eks., men ikke
              begrænset til, GolfBox og GolfWorks), og gemmer nødvendige oplysninger, såsom:
          </p>
          <ul className="b-privacy-policy__sub-inner-list">
            <li className="b-privacy-policy__sub-inner-item">Hvor, hvornår, med hvem, og til hvilken pris, du skal spille golf</li>
            <li className="b-privacy-policy__sub-inner-item">Hvilke værdikuponer, du har brugt, hvor, hvornår og hvad, og til hvilken oprindelig pris
            </li>
            <li className="b-privacy-policy__sub-inner-item">Hvem du overfører/modtager værdikuponer til/fra</li>
            <li className="b-privacy-policy__sub-inner-item">Hvilke værdikuponer, du har gjort digitale via Upload funktionen</li>
            <li className="b-privacy-policy__sub-inner-item">Hvilke værdikuponer du har generet PDF’er af.</li>
            <li className="b-privacy-policy__sub-inner-item">Din placering</li>
          </ul>
        </div>
        <div className="b-privacy-policy__sub-section">
          <h3 className="b-privacy-policy__sub-inner-title">1.3 Cookies</h3>
          <p className="b-privacy-policy__paragraph">
              Webstedet bruger cookies. Cookies er digitale oplysninger, som gemmes på din computer. Cookies
              identificerer din computer i stedet for dig som en individuel bruger, og anvendes til forskellige formål.
          </p>
          <p className="b-privacy-policy__paragraph">
              Læs mere om hvilke cookies Webstedet bruger og til hvilke formål nedenfor (afsnit 7).
          </p>
        </div>
        <div className="b-privacy-policy__sub-section">
          <h3 className="b-privacy-policy__sub-inner-title">1.4 Din IP-adresse, browserindstillinger og placering</h3>
          <p className="b-privacy-policy__paragraph">
              Når du besøger Webstedet, registrerer vi din computers IP-adresse
              og browserindstillinger. IP-adressen er den numeriske adresse for den computer, der bruges til at besøge
              Webstedet. Browserindstillinger kan omfatte den type browser, du bruger, browsersprog, og tidszone.
              Vi indsamler disse oplysninger, med henblik på at afforebygge og opklare eventuelle misbrug
              eller ulovlige handlinger i forbindelse med besøg på eller brug af Webstedet. Endvidere bruger vi
              IP-adressen til at estimere din placering (på byniveau).
          </p>
        </div>
        <div className="b-privacy-policy__sub-section">
          <h3 className="b-privacy-policy__sub-inner-title">1.5 Oplysninger, der gives ved tilmelding til nyhedsbrev</h3>
          <p className="b-privacy-policy__paragraph">
              Vi indsamler de oplysninger, som du giver os, når du tilmelder
              dig modtagelse af vores nyhedsbreve, såsom dit navn og din e-mailadresse. Hvis du ikke længere ønsker
              at modtage vores nyhedsbreve, kan du afmelde ved at logge ind på din brugerprofil og ændre dine
              personlige indstillinger.
          </p>
        </div>
        <div className="b-privacy-policy__sub-section">
          <h3 className="b-privacy-policy__sub-inner-title">1.6 Sådan bruger vi disse oplysninger</h3>
          <p className="b-privacy-policy__paragraph">Vi kan bruge de oplysninger, som du giver os til med henblik på at opfylde kontrakten med dig,
              herunder at:</p>
          <ul className="b-privacy-policy__sub-inner-list">
            <li className="b-privacy-policy__sub-inner-item">Levere vores tjenester til dig og give dig adgang til vores Websted.</li>
            <li className="b-privacy-policy__sub-inner-item">Identificere dig som en registreret bruger, når du logger ind på Webstedet.</li>
            <li className="b-privacy-policy__sub-inner-item">Svare på dine spørgsmål og levere relateret kundeservice.</li>
            <li className="b-privacy-policy__sub-inner-item">Kontakte dig, hvis vi oplever misbrug af din konto.</li>
          </ul>
          <p className="b-privacy-policy__sub-inner-item">Herudover kan vi bruge dine oplysninger til at forfølge berettigede interesser, herunder:</p>
          <ul className="b-privacy-policy__sub-inner-list">
            <li className="b-privacy-policy__sub-inner-item">Forbedre Webstedet og vores tjenester</li>
            <li className="b-privacy-policy__sub-inner-item">Invitere dig til bruge vores services og tilbud</li>
            <li className="b-privacy-policy__sub-inner-item">Sende dig vores nyhedsbreve.</li>
            <li className="b-privacy-policy__sub-inner-item">
                Udføre diverse interne forretningsformål, såsom dataanalyse, revisioner, monitorering og forebyggelse
                af misbrug, udvikling af nye produkter og tjenester, forbedre eller ændre Webstedet eller vores tjenester,
                identificere brugstendenser, bestemme effektiviteten af vores reklamekampagner, samt drive og udvide
                vores forretningsaktiviteter.
            </li>
            <li className="b-privacy-policy__sub-inner-item">Videregive dine oplysninger som beskrevet under afsnit 2</li>
          </ul>

          <p className="b-privacy-policy__sub-inner-item">Du har ret til at gøre indsigelse mod, at vi behandler dine oplysninger til ovenstående formål. Se
                mere under dine rettigheder under afsnit 8</p>
          <p className="b-privacy-policy__sub-inner-item">Endelig kan vi behandle dine oplysninger med henblik på at fastslå eller forsvare et retskrav,
                herunder for at:</p>

          <ul className="b-privacy-policy__sub-inner-list">
            <li className="b-privacy-policy__sub-inner-item">
                Overholde gældende lovkrav og juridisk proces, efterkomme anmodninger fra offentlige og statslige
                myndigheder, samt overholde relevante branchestandarder og vores interne politikker.
            </li>
            <li className="b-privacy-policy__sub-inner-item">
                Håndhæve vores
              <a
                className="b-privacy-policy__link"
                href={urls.danishTermsAndConditions}
                target="_blank"
                rel="noopener noreferrer nofollow"
              > Vilkår og Betingelser
              </a>.
            </li>
            <li className="b-privacy-policy__sub-inner-item">
                Beskytte vores forretningsaktiviteter eller dem tilhørende ethvert af vores associerede selskaber.
            </li>
            <li className="b-privacy-policy__sub-inner-item">
                Beskytte vores rettigheder, personlige oplysninger, sikkerhed eller ejendom og/eller dem tilhørende
                vores associerede selskaber, dig eller andre.
            </li>
            <li className="b-privacy-policy__sub-inner-item">
                Give os mulighed for at forfølge tilgængelige retsmidler eller begrænse eventuelt erstatningsansvar,
                som vi kan pådrage os.
            </li>
          </ul>
          <p className="b-privacy-policy__paragraph">
              Vi vil kun bruge dine oplysninger til andre formål i det omfang, at vi har givet dig oplysninger herom på indsamlingstidspunktet.
          </p>
        </div>
      </li>


      <div className="b-privacy-policy__sub-section">
        <h3 className="b-privacy-policy__sub-inner-title">1.7 Så længe opbevarer vi dine oplysninger</h3>
        <p className="b-privacy-policy__paragraph">Vi vil opbevare dine oplysninger i den periode, hvor de er nødvendige til ovenstående
                formål, herunder så længe din profil fortsat er aktiv hos os.</p>
        <p className="b-privacy-policy__paragraph">Vi sletter dine oplysninger, hvis du ikke har gjort brug af eller logget på din profil i
                mere end. 24 måneder, og din konto ikke har aktive værdikuponer. I andre
                tilfælde kan vi slette dine oplysninger som beskrevet i afsnit 8.2.</p>
      </div>

      <li className="b-privacy-policy__item">
        <h2 className="b-privacy-policy__sub-title">2 Videregivelse af personlige oplysninger</h2>
        <div className="b-privacy-policy__sub-section">
          <h3 className="b-privacy-policy__sub-inner-title">2.1 Videregivelse af personlige oplysninger på Webstedet</h3>
          <p className="b-privacy-policy__paragraph">
              Vi vil i forbindelse med din brug af Webstedet videregive visse oplysninger om dig.
          </p>
          <p className="b-privacy-policy__paragraph">Betalingsoplysninger</p>
          <p className="b-privacy-policy__paragraph">
              Vi videresender nødvendige data omkring brug af værdikuponer til
              golfbookingsystemet for den respektive klub.
          </p>
        </div>
        <div className="b-privacy-policy__sub-section">
          <h3 className="b-privacy-policy__sub-inner-title">2.2 Videregivelse til andre tjenester og virksomheder</h3>
          <p className="b-privacy-policy__paragraph">
              Et af GolfMores hovedformål er at hjælpe golfklubber med at
              administrere deres værdikuponer. Derfor giver vi klubberne lov til at få adgang til
              oplysninger, som er relevant for dem i forbindelse med brug i deres klub.
          </p>
          <p className="b-privacy-policy__paragraph">
              Når du modtager værdikuponer fra en klub eller har brugt GolfMore
              i en klub er følgende data tilgængelige
          </p>
          <ul className="b-privacy-policy__sub-inner-list">
            <li className="b-privacy-policy__sub-inner-item">Dit fornavn, efternavn, medlemsnummer, hjemmeklub</li>
            <li className="b-privacy-policy__sub-inner-item">
                Hvilke værdikuponer du har brugt, og hvilken originalpris
                der var ved indløsningstidspunkt, tidspunkt for indløsning, hvor mange brug der er tilbage på værdikuponen.
            </li>
          </ul>
        </div>
        <div className="b-privacy-policy__sub-section">
          <h3 className="b-privacy-policy__sub-inner-title">2.3 Andre videregivelser</h3>
          <p className="b-privacy-policy__paragraph">
              I tillæg til ovenstående, kan vi videregive dine personlige
              oplysninger til følgende parter og under de følgende omstændigheder:
          </p>
          <ul className="b-privacy-policy__sub-inner-list">
            <li className="b-privacy-policy__sub-inner-item">
                For at give tredjepartsleverandører, konsulenter og andre
                tjenesteudbydere mulighed for at udføre tjenester på vores vegne.
            </li>
            <li className="b-privacy-policy__sub-inner-item">
                Til ProBox24 ApS datterselskaber og andre selskaber i ProBox24-koncernen.
            </li>
            <li className="b-privacy-policy__sub-inner-item">
                For at overholde love eller svare på krav, juridisk proces (herunder men ikke begrænset til stævninger
                og retskendelser) samt efterkomme anmodninger fra offentlige og statslige myndigheder.
            </li>
            <li className="b-privacy-policy__sub-inner-item">
                Til tredjeparter i forbindelse med håndhævelse af vores
              <a
                className="b-privacy-policy__link"
                href={urls.danishTermsAndConditions}
                target="_blank"
                rel="noopener noreferrer nofollow"
              > Vilkår og Betingelser
              </a>.
            </li>
            <li className="b-privacy-policy__sub-inner-item">
                Til tredjeparter for at beskytte vores drift eller vores associerede selskabers drift.
            </li>
            <li className="b-privacy-policy__sub-inner-item">
                Til tredjeparter for at forfølge tilgængelige retsmidler, eller begrænse erstatningsansvar, som vi kan pådrage os.
            </li>
            <li className="b-privacy-policy__sub-inner-item">
                Til tredjeparter for at vi kan undersøge, forebygge eller gribe ind over for formodede eller faktiske
                forbudte aktiviteter, herunder men ikke begrænset til misbrug af vores Websted.
            </li>
          </ul>
        </div>
      </li>

      <li className="b-privacy-policy__item">
        <h2 className="b-privacy-policy__sub-title">3 Dataansvarlig</h2>
        <div className="b-privacy-policy__sub-section">
          <p className="b-privacy-policy__paragraph">
              Vi er den dataansvarlige for Stamdata, som du indtaster i
              forbindelse med din oprettelse af en profil, som omfatter, men ikke er begrænset til dit navn,
              din adgangskode og e-mailadresse, samt registreringen af din IP adresse.
          </p>
          <p className="b-privacy-policy__paragraph">Vi er også den dataansvarlige for de oplysninger, der videregives
              til andre tjenester (se afsnit 2.2). GolfMores indsamling af datadine personoplysninger er
              reguleret af den til enhver tid gældende persondatalovgivning i Danmark.
          </p>
        </div>
      </li>
      <li className="b-privacy-policy__item">
        <h2 className="b-privacy-policy__sub-title">4 Links til websteder</h2>
        <div className="b-privacy-policy__sub-section">
          <p className="b-privacy-policy__paragraph">
              Vores Websted indeholder links til andre websteder.
              Vores medtagelse af sådanne links er ikke udtryk for vores godkendelse af de pågældende websteder.
              Vi kontrollerer ikke indholdet af disse tredjepartswebsteder, og påtager os intet ansvar
              for tredjeparten eller deres politik eller praksisser.
          </p>
          <p className="b-privacy-policy__paragraph">
              Vi opfordrer dig til at gennemlæse disse tredjepartswebsteders
              persondatapolitikker, da deres procedurer til indsamling, håndtering og behandling af personlige
              oplysninger kan være anderledes end vores.
          </p>
        </div>
      </li>
      <li className="b-privacy-policy__item">
        <h2 className="b-privacy-policy__sub-title">5 Databehandler og overførsel af personlige oplysninger</h2>
        <div className="b-privacy-policy__sub-section">
          <p className="b-privacy-policy__paragraph">
              Vi bruger eksterne til at opretholde den tekniske drift af Webstedet
              og vores tjenester. Disse virksomheder er databehandlere for de personlige oplysninger, som vi er
              dataansvarlige for. Ved at acceptere denne Politik, accepterer du, at vi også kan gøre det muligt for
              de oplysninger, som du er dataansvarlig for, at blive behandlet af de samme databehandlere.
          </p>
          <p className="b-privacy-policy__paragraph">
              Databehandlerne skal udelukkende handle i overensstemmelse med
              instrukser fra os. Vi vil derfore sådanne instrukser til
              databehandlerne for at behandle data i overensstemmelse med Politikken og med henblik på at bruge Webstedet.
          </p>
          <p className="b-privacy-policy__paragraph">
              Databehandlerne har truffet rimelige tekniske og organisatoriske
              foranstaltninger for at beskytte oplysningerne mod hændelig eller ulovlig tilintetgørelse, tab eller
              forringelse, og for at beskytte oplysningerne mod videregivelse til uvedkommende, misbrug, eller anden
              behandling i strid med Persondataloven.
          </p>

        </div>
      </li>

      <li className="b-privacy-policy__item">
        <h2 className="b-privacy-policy__sub-title">6 Sikkerhedsforanstaltninger</h2>
        <div className="b-privacy-policy__sub-section">
          <p className="b-privacy-policy__paragraph">Vi søger at anvende passende organisatoriske, tekniske og
                          administrative foranstaltninger for at beskytte dine personlige oplysninger i vores organisation,
                          og vi reviderer jævnligt vores system for sårbarheder. Men da internettet ikke er et 100 % sikkert miljø,
                          kan vi ikke sikre eller garantere sikkerheden af oplysninger, som du sender til GolfMore. E-mails,
                          der sendes via Webstedet, er ikke krypteret, og vi råder dig derfor til ikke at inkludere fortrolige eller følsomme
                          oplysninger i dine e-mails til os.
          </p>
        </div>
      </li>

      <li className="b-privacy-policy__item">
        <h2 className="b-privacy-policy__sub-title">7 Cookies</h2>
        <div className="b-privacy-policy__sub-section">
          <p className="b-privacy-policy__paragraph">Webstedet bruger cookies og lignende teknologier ("Cookies").
                          Ved at bruge vores Websted accepterer du, at vi bruger Cookies som beskrevet nedenfor.
          </p>
          <div className="b-privacy-policy__sub-section">
            <h3 className="b-privacy-policy__sub-inner-title">7.1 Hvilke typer af Cookies bruger vi?</h3>
            <p className="b-privacy-policy__paragraph">Cookies er små stykker information, som Webstedet placerer på
                              din computers harddisk, på din tablet eller på din smartphone. Cookies indeholder oplysninger,
                              som Webstedet bruger til at gøre kommunikationen mellem dig og din webbrowser mere effektiv.
                              Cookies identificerer din computer i stedet for dig som en individuel bruger.
            </p>
            <p className="b-privacy-policy__paragraph">Vi bruger sessionscookies og vedvarende cookies samt teknologier,
                              der ligner sessionscookies og vedvarende cookies. Sessionscookies er midlertidige uddrag af oplysninger,
                              der slettes, når du afslutter din webbrowser. Vedvarende cookies er mere permanente uddrag af oplysninger,
                              der gemmes og forbliver på din computer, indtil de slettes. Vedvarende cookies sletter sig selv efter
                              en vis periode, men fornyes, hver gang du besøger Webstedet.
            </p>
            <p className="b-privacy-policy__paragraph">Vi bruger teknologier, der ligner sessionscookies og vedvarende
                              cookies til lagring og til at få adgang til oplysninger i browseren eller enheden, som bruger lokale
                              enheder og lokal lagring, såsom HTML5-cookies, Flash og andre metoder. Disse teknologier kan fungere
                              på tværs af alle dine browsere. I nogle tilfælde kan anvendelsen af disse teknologier ikke styres af
                              browseren, men kræver særlige værktøjer. Vi bruger disse teknologier til at gemme oplysninger at opdage uregelmæssigheder i anvendelsen af Webstedet.
            </p>
          </div>

          <div className="b-privacy-policy__sub-section">
            <h3 className="b-privacy-policy__sub-inner-title">7.2 Hvad bruger vi Cookies til?</h3>
            <p className="b-privacy-policy__paragraph">Vi bruger Cookies til:</p>
            <ul className="b-privacy-policy__sub-inner-list">
              <li className="b-privacy-policy__sub-inner-item">Generering af statistik</li>
            </ul>
            <p className="b-privacy-policy__paragraph">Måling af Webstedstrafik såsom antallet af besøg på Webstedet,
                              hvilke domæner de besøgende kommer fra, hvilke sider de besøgende besøger på Webstedet, og i hvilke
                              overordnede geografiske områder de besøgende er placeret.
            </p>
            <ul className="b-privacy-policy__sub-inner-list">
              <li className="b-privacy-policy__sub-inner-item">Monitorering af Webstedets ydeevne og din brug af vores Websted</li>
            </ul>
            <p className="b-privacy-policy__paragraph">Monitorering af webstedet, vores applikationer og widgets' ydeevne,
                              og hvordan du bruger vores Websted, applikationer og widgets.
            </p>
            <ul className="b-privacy-policy__sub-inner-list">
              <li className="b-privacy-policy__sub-inner-item">Godkendelse samt forbedring af funktionaliteten af vores Websted</li>
            </ul>
            <p className="b-privacy-policy__paragraph">Optimering af din oplevelse med Webstedet, som omfatter at huske
                              dit brugernavn og din adgangskode, når du vender tilbage til Webstedet, samt huske oplysninger om din
                              browser og præferencer (f.eks. hvilket sprog du foretrækker).
            </p>
          </div>
          <div className="b-privacy-policy__sub-section">
            <h3 className="b-privacy-policy__sub-inner-title">7.3 Tredjepartscookies</h3>
            <p className="b-privacy-policy__paragraph">Vores Websted bruger Cookies fra de følgende tredjeparter:</p>
            <ul className="b-privacy-policy__sub-inner-list">
              <li className="b-privacy-policy__sub-inner-item">Google Analytics: Med henblik på at monitorere trafikken på
                  vores Websted, såsom generelle besøgstal, konverteringer og lignende.</li>
              <li className="b-privacy-policy__sub-inner-item">Fabric Crashlytics: Med. henblik på at spore fejl og nedbrud
                  på vores Websted.</li>
              <li className="b-privacy-policy__sub-inner-item">
                                  Javascript: Til monitoreringsformål, herunder hvordan du
                                  bruger vores Websted, widgets og applikationer, og for at skabe et rigt websted og widgets.
              </li>
            </ul>
          </div>
          <div className="b-privacy-policy__sub-section">
            <h3 className="b-privacy-policy__sub-inner-title">7.4 Sletning af cookies</h3>
            <p className="b-privacy-policy__paragraph">Hvis du vil slette cookies, der allerede er på dit udstyr, kan
                              du: Hvis du bruger en pc og en nylig browser, trykke på CTRL + SHIFT + DELETE samtidigt, når du har browseren åben og aktiv. Hvis genvejene
                              ikke virker i din browser, bedes du besøge supportsiden for den pågældende browser. Bemærk dog, at
                              hvis du ikke accepterer cookies fra vores Websted, kan du opleve nogle ulejligheder i din brug af
                              Webstedet, og du kan blive forhindret i at få adgang til alle dets funktioner.
            </p>
          </div>
        </div>
      </li>

      <li className="b-privacy-policy__item">
        <h2 className="b-privacy-policy__sub-title">8 Adgang, rettelse og sletning</h2>
        <div className="b-privacy-policy__sub-section">
          <h3 className="b-privacy-policy__sub-inner-title">8.1 Adgang til oplysninger om dine personlige oplysninger</h3>
          <p className="b-privacy-policy__paragraph">På din anmodning, vil vi informere dig om det formål, til hvilket
                          vi behandler dine personlige oplysninger, hvilke personlige oplysninger, vi har om dig, hvem, der modtager
                          dine personlige oplysninger, og hvorfra de personlige oplysninger stammer.
          </p>
          <p className="b-privacy-policy__paragraph">Anmodning om ovenstående vil som udgangspunkt kun blive behandlet, hvis der er gået mere end
              seks (6) måneder siden eventuel tidligere anmodning fra dig, medmindre du kan dokumentere en
              særlig interesse i at få oplysningerne tidligere.
          </p>
          <h3 className="b-privacy-policy__sub-inner-title">8.2 Rettelse og sletning af dine personlige oplysninger</h3>
          <p className="b-privacy-policy__paragraph">Du bedes informere os, hvis nogen af de personlige oplysninger, som vi, som den dataansvarlige,
              behandler om dig, er urigtige, ufuldstændige eller vildledende, og så vil vi rette dem. Du vil selv
              kunne rette de fleste af disse oplysninger på vores Websted. Hvis det er muligt, anbefaler vi, at du
              selv retter dine oplysninger.
          </p>
          <p className="b-privacy-policy__paragraph">Du kan til enhver tid rette eller slette eventuelt indhold og
                          personlige oplysninger på Webstedet. Hvis dine
                          personlige oplysninger ændrer sig, eller hvis du ikke længere ønsker at blive vist på Webstedet, kan
                          du opdatere eller slette oplysningerne ved at logge ind på din brugerprofil.
          </p>
          <p className="b-privacy-policy__paragraph">Når din profil er slettet, bliver alle oplysninger i forbindelse
                          med din brugerprofil slettet, herunder dine Stamdata og anmeldelser på Webstedet.
          </p>
          <p className="b-privacy-policy__paragraph">Vi forbeholder os retten til at blokere adgangen til din profil
                          og/eller slette din profil, hvis profilen eller indholdet forbundet med din profil eller din(e)
                          anmeldelse(r) på webstedet er, efter vores vurdering, diskriminerende, racistisk, seksuelt orienteret,
                          uetisk, truende, stødende, chikanerende eller på anden måde overtræder gældende love, tredjepartsrettigheder,
                          Retningslinjerne for brugere eller er uforenelig med formålet med Webstedet. Hvis vi blokerer adgangen
                          til eller sletter din brugerprofil, vil vi informere dig om årsagen til blokeringen eller sletningen
                          af din profil ved at sende en e-mail til den adresse, som du oplyste, da du oprettede din konto.
          </p>
        </div>
      </li>
      <li className="b-privacy-policy__item">
        <h2 className="b-privacy-policy__sub-title">9 Ændringer til Politikken</h2>
        <p className="b-privacy-policy__paragraph">Vi kan ændre denne Politik til enhver tid. Datoen, der vises i
                      starten af denne Politik angiver, hvornår den sidst blev revideret. Eventuelle ændringer træder i kraft,
                      når vi offentliggør den reviderede Politik på Webstedet. Du accepterer, at din fortsatte brug af
                      Webstedet efter eventuelle offentliggjorte ændringer udgør din accept af den nye version af Politikken.
        </p>
      </li>
      <li className="b-privacy-policy__item">
        <h2 className="b-privacy-policy__sub-title">10 Henvendelser vedrørende Webstedet eller Politikken</h2>
        <p className="b-privacy-policy__paragraph">Hvis du har spørgsmål eller betænkeligheder vedrørende vores Politik,
                      hvordan vi behandler dine personlige oplysninger, eller gerne vil have os til at rette dine personlige
                      oplysninger, bedes du kontakte os på: GolfMore A/S, Rugaardsvej 5, 8680 Ry, Danmark, CVR-nr.: 33593082,
                      telefon: +45 7070 7999, e-mail:
          <a className="b-privacy-policy__link" href="mailto:info@probox24.com"> info@probox24.com</a>.</p>
      </li>
    </ul>
  </div>
  );

export default privacyPolicyDenmark;
