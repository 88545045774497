import { connect } from 'react-redux';

import appActions from '../../../domains/app/appActions';
import authActions from '../../../domains/auth/actions';

import Header from './header';

function mapStateToProps(state) {
  return {
    userIsAuthenticated: state.authReducer.get('userIsAuthenticated'),
    location: state.appReducer.get('location'),
  };
}

const mapDispatchToProps = (dispatch) => ({
  togglePopup: (popupName, status) => dispatch(appActions.togglePopup(popupName, status)),
  handleLogIn: (flag) => dispatch(authActions.handleLogIn(flag)),
  toggleLogOutWarn: () => dispatch(authActions.toggleLogOutWarn()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
