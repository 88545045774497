// @flow
import CRUD from '../../api/base/crud';

import type { ReqType } from '../../api/index';

export type SessionType = {
  session_id: string,
  user_id: number,
  language: string,
  expires: number,
  created_at: number,
  push_notifications_enabled: boolean,
  device_token: string,
  device_platform: string,
};

class SessionAPI extends CRUD {
  accessToken() {
    return this.r({
      method: 'GET',
      url: '/access-token',
    });
  }
}
export default function sessionCrud(request: ReqType) {
  const crud: CRUD<SessionType> = new SessionAPI({
    url: '/session',
    request,
    id: 'session_id',
  });

  return crud;
}
