// @flow
import styled from 'styled-components/native';
import { color } from 'styled-system';
import styles from '../../../constants/styles';

const { colors, font } = styles;

const HEADER_HEIGHT = 56;

export const Root = styled.SafeAreaView.attrs({
  flex: 1,
  bg: 'bgDark',
})`
  ${color}
`;

export const Header = styled.View`
  background-color: ${colors.bgDark};
`;

export const HeaderTop = styled.View`
  height: ${HEADER_HEIGHT}px;
  paddingHorizontal: 20px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderBottom = styled.View`
  padding-bottom: 20px;
  alignItems: center;
`;

export const HeaderTitle = styled.Text`
  text-transform: uppercase;
  ${font.style.medium}
  font-size: ${font.size.large}px;
  color: ${colors.white};
`;

export const CloseButton = styled.TouchableOpacity`
  height: 21px;
  width: 21px;
`;

export const CloseIcon = styled.Image`
  height: 15px;
  width: 15px;
`;

export const Content = styled.View`
  backgroundColor: ${colors.maingBg};
  flex: 1;
  padding-vertical: 20px;
`;

export const ContentTitle = styled.Text`
  font-size: ${font.size.medium}px;
  color: ${colors.basic};
  margin-bottom: 15px;
  marginHorizontal: 20px;
  ${font.style.medium};
`;

export const ContentSimpleText = styled.Text`
  font-size: ${font.size.small}px;
  marginHorizontal: ${(props) => props.mh || 0}px;
  ${font.style.regular};
`;

export const BlueText = styled.Text`
  color: ${colors.golfBox};
`;

export const GolfIdList = styled.FlatList`
  margin-top: 30px;
  padding-horizontal: 10px;
`;
