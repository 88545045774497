import { connect } from 'react-redux';

import appActions from '../../../domains/app/appActions';
import * as vouchersActions from '../../../domains/vouchers/module.js';

import voucherTransfer from './voucherTransfer';

function mapStateToProps(state) {
  return {
    location: state.appReducer.get('location'),
    voucherTransferPopupIsOpen: state.voucherReducer.get('voucherTransferPopupIsOpen'),
    accountEmail: state.voucherReducer.get('accountEmail'),
    userIsAuthenticated: state.authReducer.get('userIsAuthenticated'),
  };
}

const mapDispatchToProps = (dispatch) => ({
  togglePopup: (popupName, status) => dispatch(appActions.togglePopup(popupName, status)),
  getAccountEmail: (token, locale) => dispatch(vouchersActions.getAccountEmail(token, locale)),
  sendTransferProcess: (token, locale, data, setServerErrors, openPopup) =>
    dispatch(vouchersActions.sendTransferProcess(token, locale, data, setServerErrors, openPopup)),
});

export default connect(mapStateToProps, mapDispatchToProps)(voucherTransfer);
