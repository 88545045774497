import appConstants from '../app/appConstants';
import appAPI from './api/app';
import { localeSelector } from '../../selectors/locale';

export const REQUEST_FETCH_FAQ_ITEMS = 'REQUEST_FETCH_FAQ_ITEMS';
export const FETCH_FAQ_ITEMS_FAILED = 'FETCH_FAQ_ITEMS_FAILED';
export const RECEIVED_FAQ_ITEMS = 'RECEIVED_FAQ_ITEMS';

const requestFetchFaqItems = () => ({ type: REQUEST_FETCH_FAQ_ITEMS });
const fetchFaqItemsFailed = () => ({ type: FETCH_FAQ_ITEMS_FAILED });
const receivedFaqItems = (faqItems) => ({ type: RECEIVED_FAQ_ITEMS, faqItems });

export const setLocation = (location) => ({
  type: appConstants.SET_LOCATION,
  location,
});

export const getGeoIp = () => (dispatch) => {
  appAPI
    .getGeoIp()
    .then((response) => {
      dispatch({
        type: appConstants.SET_GEO_IP,
        payload: response.data,
      });
    })
    .catch((error) => {});
};

const togglePopup = (popupName, status) => {
  if (status) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'inherit';
  }
  return {
    type: appConstants.TOGGLE_POPUP,
    popup: {
      popupName,
      status,
    },
  };
};

const getPDFsCount = () => (dispatch) => {
  appAPI
    .getPDFsCount()
    .then((data) => {
      dispatch({
        type: appConstants.SET_ARCHIVES_COUNT,
        payload: data.data.count,
      });
    })
    .catch((error) => {});
};

const setCurrentPage = (page) => ({
  type: appConstants.SET_CURRENT_PAGE,
  payload: page,
});

const setCurrentArchivePage = (page) => ({
  type: appConstants.SET_CURRENT_PDF_PAGE,
  payload: page,
});

const getPDFs = (offset) => (dispatch) => {
  appAPI
    .getPDFs(offset)
    .then((data) => {
      dispatch({
        type: appConstants.SET_PDF_ARRAY,
        payload: data.data,
      });
    })
    .catch((error) => {});
};

const setAllFaqsItems = (faqs) => ({
  type: appConstants.SET_FAQS_ITEMS,
  faqs,
});

const setAllFaqsCategories = (faqs) => ({
  type: appConstants.SET_FAQS_CATEGORIES,
  faqs,
});

const setAllFaqsByCategory = (faqs) => ({
  type: appConstants.SET_FAQS_BY_CATEGORIES,
  faqs,
});

export const getFaqsItems = () => (dispatch, getState) => {
  const locale = localeSelector(getState());
  appAPI
    .getFaqsItems(locale)
    .then((response) => {
      console.warn('GetFaqItems - ', response.data);
      dispatch(setAllFaqsItems(response.data));
    })
    .catch(() => {
      dispatch(setAllFaqsItems([]));
    });
};

export const getFaqsCategories = () => (dispatch, getState) => {
  const locale = localeSelector(getState());
  appAPI
    .getFaqsCategories(locale)
    .then((response) => {
      console.warn('getFaqCategories - ', response.data);
      dispatch(setAllFaqsCategories(response.data));
    })
    .catch(() => {
      dispatch(setAllFaqsCategories([]));
    });
};

export const getFaqsByCategory = () => (dispatch, getState) => {
  const locale = localeSelector(getState());
  appAPI
    .getFaqsCategories(locale)
    .then((response) => {
      console.warn('getFaqByCategory - ', response.data);
      dispatch(setAllFaqsByCategory(response.data));
    })
    .catch(() => {
      dispatch(setAllFaqsByCategory([]));
    });
};

export function fetchFaqItems() {
  return async (dispatch, getState) => {
    const locale = localeSelector(getState());
    dispatch(requestFetchFaqItems());
    try {
      const { data } = await appAPI.getFaqsItems(locale);
      return dispatch(receivedFaqItems(data));
    } catch (e) {
      console.error(e);
      return dispatch(fetchFaqItemsFailed());
    }
  };
}

const initState = {
  isLoading: false,
  entries: [],
};

export default {
  setLocation,
  getGeoIp,
  togglePopup,
  setCurrentPage,
  getPDFsCount,
  setCurrentArchivePage,
  getPDFs,
  getFaqsItems,
  getFaqsCategories,
};
