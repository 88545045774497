import React, { Component } from 'react';
import propTypes from 'prop-types';
import { Accordion, AccordionItem, AccordionItemTitle, AccordionItemBody } from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import texts from '../../../../constants/languages';

import './faq.css';

const { object, string, arrayOf } = propTypes;

class Faq extends Component {
  static propTypes = {
    locale: string.isRequired,
    faqsCategories: arrayOf(object).isRequired,
  };

  state = {
    visibleItems: [],
    activeCategoryId: null,
  };

  async componentDidMount() {
    await this.props.getFaqsItems(this.props.locale);
    await this.props.getFaqsCategories(this.props.locale);
    this.getItemsByCategoryId(3)();
  }

  getItemsByCategoryId = (id) => () => {
    const visibleItems = this.props.faqsItems
      .filter((item) => item.category_id === id)
      .sort((a, b) => (a.category_id > b.category_id ? 1 : a.category_id < b.category_id ? -1 : 0));
    this.setState({
      visibleItems,
      activeCategoryId: id,
    });
  };

  get faqItems() {
    return this.state.visibleItems.map((item) => (
      <Accordion accordion={false}>
        <AccordionItem>
          <AccordionItemTitle>
            <h3 className="u-position-relative">
              {item.question}
              <div className="accordion__arrow" role="presentation" />
            </h3>
          </AccordionItemTitle>
          <AccordionItemBody>
            <p>{item.answer}</p>
          </AccordionItemBody>
        </AccordionItem>
      </Accordion>
    ));
  }

  get faqCategories() {
    return this.props.faqsCategories.map((category) => {
      const isActive = category.category_id === this.state.activeCategoryId;
      return (
        <div className="b-faqs__content-categories">
          <p
            className={`b-faqs__paragraph-categories ${isActive ? 'isActive' : ''}`}
            onClick={this.getItemsByCategoryId(category.category_id)}
            key={category.category_id}
          >
            {category.title}
          </p>
        </div>
      );
    });
  }

  render() {
    const checkLocation = texts;
    return (
      <div className="b-faqs">
        <div className="b-faqs__container container grid-xl">
          <div className="columns">
            <div className="column col-12 col-sm-12 b_faqs__title">{checkLocation.FAQS_HEADER_ENGLISH}</div>
            <div className="column b-faqs__container-categories col-3 col-sm-12">{this.faqCategories}</div>
            <div className="column col-1 hide-sm" />
            <div className="column col-8 col-sm-12">
              {this.faqItems.category_title}
              {this.faqItems}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Faq;
