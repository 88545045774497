import React from 'react';

import urls from '../../../../constants/config/urls';

const privacyPolicyDenmark = () => (
  <div className="b-privacy-policy__content">
    <h1 className="b-privacy-policy__title">
      Integritetspolicy (version 1.1, Opdateret juni 2018)
    </h1>
    <p className="b-privacy-policy__paragraph">
      II detta dokument, hänvisar "GolfMore", "vi" "oss" och "vår" till ProBox24 ApS, beläget på
      Rugaardsvej 5, 8680 Ry, Danmark och dess associerade verksamheter.
    </p>
    <p className="b-privacy-policy__paragraph">
      När du använder GolfMore appen eller på annat sätt använder våra webbplatser, härunder men
      inte begränsat till &nbsp;
      <a className="b-privacy-policy__link" href={urls.swedish}>
        www.golfmore.se
      </a>
      , eller våra applikationer (samlat benämnt "Webbplatsen"),
      <span className="b-privacy-policy__bold">accepterar du behandlingen,</span>
      av dina personliga data ("Policyn") som beskrivit i denna Policy.
    </p>

    <ul className="b-privacy-policy__list">
      <li className="b-privacy-policy__item">
        <h2 className="b-privacy-policy__sub-title">
          1 Typ av data, som vi samlar in, och vad vi använder den till.
        </h2>
        <div className="b-privacy-policy__sub-section">
          <h3 className="b-privacy-policy__sub-inner-title">1.1 Stamdata</h3>
          <p className="b-privacy-policy__paragraph">
            Når du upprättar en profil samlar vi in följande stamdata om dig ("Stamdata"):
          </p>
          <ul className="b-privacy-policy__sub-inner-list">
            <li className="b-privacy-policy__sub-inner-item">Ditt förnamn,</li>
            <li className="b-privacy-policy__sub-inner-item">Ditt efternamn</li>
            <li className="b-privacy-policy__sub-inner-item">Din e-mailadress</li>
            <li className="b-privacy-policy__sub-inner-item">Ditt mobilnr.</li>
            <li className="b-privacy-policy__sub-inner-item">Dint födelsedatum</li>
            <li className="b-privacy-policy__sub-inner-item">Ditt land</li>
            <li className="b-privacy-policy__sub-inner-item">Ditt kön</li>
            <li className="b-privacy-policy__sub-inner-item">
              Ditt medlemsnummer hos ett golfförbund
            </li>
            <li className="b-privacy-policy__sub-inner-item">Din hemmaklubb</li>
            <li className="b-privacy-policy__sub-inner-item">Din medlemstype</li>
            <li className="b-privacy-policy__sub-inner-item">Dine adgangsoplysninger</li>
          </ul>
        </div>
        <div className="b-privacy-policy__sub-section">
          <h3 className="b-privacy-policy__sub-inner-title">
            1.2 Data i samband med användning av GolfMore
          </h3>
          <p className="b-privacy-policy__paragraph">
            I samband med användningen av GolfMore samlar vi in data från diverse golf-
            bokningssystem (tex, men inte begränsat till GIT), och sparar nödvändiga data, såsom:
          </p>
          <ul className="b-privacy-policy__sub-inner-list">
            <li className="b-privacy-policy__sub-inner-item">
              Var, när, med vem och till vilket pris, du skall spela golf
            </li>
            <li className="b-privacy-policy__sub-inner-item">
              Vilka rabatter du fick, när du betalade, till vilket pris, med vilket betalmedel i
              samband med köp av greenfee
            </li>
            <li className="b-privacy-policy__sub-inner-item">
              Vem du överför/mottar värdebevis till/från
            </li>
            <li className="b-privacy-policy__sub-inner-item">
              Vilka värdebevis du har gjort digitala via ”Upload” funktionen
            </li>
            <li className="b-privacy-policy__sub-inner-item">
              Vilka värdebevis du har genererat PDF av.
            </li>
            <li className="b-privacy-policy__sub-inner-item">Din plats</li>
          </ul>
        </div>
        <div className="b-privacy-policy__sub-section">
          <h3 className="b-privacy-policy__sub-inner-title">1.3 Cookies</h3>
          <p className="b-privacy-policy__paragraph">
            Webbplatsen använder cookies. Cookies är digitala data, som sparas på din computer.
            Cookies identifierar din computer i stället för dig som en individuell användare, och
            används för olika ändamål.
          </p>
          <p className="b-privacy-policy__paragraph">
            Läs mer om vilka cookies Webbplatsen använder och till vilka ändamål nedanför (avsnitt
            7).
          </p>
        </div>
        <div className="b-privacy-policy__sub-section">
          <h3 className="b-privacy-policy__sub-inner-title">
            1.4 Din IP-adress, browserinställningar och plats
          </h3>
          <p className="b-privacy-policy__paragraph">
            När du besöker Webbplatsen, registrerar vi din computer- IP-adress och
            browserinställningar. IP-adressen är den numeriska adress för den computer, som används
            för att besöka Webbplatsen. Browserinställningar kan omfatta den typ av browser, du
            använder, browsersprogram och tidzon. Vi samlar in denna information, så at vi kan spåra
            den computer, som används, i fall av missbruk eller olagliga handlingar i samband med
            besök på eller bruk av Webbplatsen. Dessutom använder vi IP-adressen för att estimera
            din plats (på stadsnivå.
          </p>
        </div>
        <div className="b-privacy-policy__sub-section">
          <h3 className="b-privacy-policy__sub-inner-title">
            1.5 Information, som anges vid anmälan till nyhetsbrev
          </h3>
          <p className="b-privacy-policy__paragraph">
            Vi samlar in de upplysningarsom du ger oss, när du anmäler dig till att få våra
            nyhetsbrev, så som ditt namn och din e-mailadress. Om du inte längre önskar at ta emot
            våra nyhetsbrev, kan du avanmäla dig genom att logga in på din användarprofil och ändra
            dina personliga inställningar.
          </p>
        </div>
        <div className="b-privacy-policy__sub-section">
          <h3 className="b-privacy-policy__sub-inner-title">
            1.6 Sådan bruger vi disse oplysninger
          </h3>
          <p className="b-privacy-policy__paragraph">
            Vi kan använda upplysningarna du ger oss för att:
          </p>
          <ul className="b-privacy-policy__sub-inner-list">
            <li className="b-privacy-policy__sub-inner-item">
              Leverera våra tjänster till dig och ge dig tillgång till vår Webbplats.
            </li>
            <li className="b-privacy-policy__sub-inner-item">
              Identifiera dig som en registrerad användare, när du loggar in på Webbplatsen.
            </li>
            <li className="b-privacy-policy__sub-inner-item">
              Svara på dina frågor och leverera relaterat kundservice.
            </li>
            <li className="b-privacy-policy__sub-inner-item">
              Kontakta dig, om vi upplever missbruk av ditt konto.
            </li>
          </ul>
          <p className="b-privacy-policy__sub-inner-item">
            Herudover kan vi bruge dine oplysninger til at forfølge berettigede interesser,
            herunder:
          </p>
          <ul className="b-privacy-policy__sub-inner-list">
            <li className="b-privacy-policy__sub-inner-item">
              Förbättra Webbplatsen och våra tjänster.
            </li>
            <li className="b-privacy-policy__sub-inner-item">
              Invitere dig til bruge vores services og tilbud
            </li>
            <li className="b-privacy-policy__sub-inner-item">Sende dig vores nyhedsbreve.</li>
            <li className="b-privacy-policy__sub-inner-item">
              Udføre diverse interne forretningsformål, såsom dataanalyse, revisioner, monitorering
              og forebyggelse af misbrug, udvikling af nye produkter og tjenester, forbedre eller
              ændre Webstedet eller vores tjenester, identificere brugstendenser, bestemme
              effektiviteten af vores reklamekampagner, samt drive og udvide vores
              forretningsaktiviteter.
            </li>
            <li className="b-privacy-policy__sub-inner-item">
              Videregive dine oplysninger som beskrevet under afsnit 2
            </li>
          </ul>

          <p className="b-privacy-policy__sub-inner-item">
            Du har ret til at gøre indsigelse mod, at vi behandler dine oplysninger til ovenstående
            formål. Se mere under dine rettigheder under afsnit 8
          </p>
          <p className="b-privacy-policy__sub-inner-item">
            Endelig kan vi behandle dine oplysninger med henblik på at fastslå eller forsvare et
            retskrav, herunder for at:
          </p>

          <ul className="b-privacy-policy__sub-inner-list">
            <li className="b-privacy-policy__sub-inner-item">
              Overholde gældende lovkrav og juridisk proces, efterkomme anmodninger fra offentlige
              og statslige myndigheder, samt overholde relevante branchestandarder og vores interne
              politikker.
            </li>
            <li className="b-privacy-policy__sub-inner-item">
              Håndhæve vores
              <a
                className="b-privacy-policy__link"
                href={urls.swedishTermsAndConditions}
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                {' '}
                Vilkår og Betingelser
              </a>
              .
            </li>
            <li className="b-privacy-policy__sub-inner-item">
              Beskytte vores forretningsaktiviteter eller dem tilhørende ethvert af vores
              associerede selskaber.
            </li>
            <li className="b-privacy-policy__sub-inner-item">
              Beskytte vores rettigheder, personlige oplysninger, sikkerhed eller ejendom og/eller
              dem tilhørende vores associerede selskaber, dig eller andre.
            </li>
            <li className="b-privacy-policy__sub-inner-item">
              Give os mulighed for at forfølge tilgængelige retsmidler eller begrænse eventuelt
              erstatningsansvar, som vi kan pådrage os.
            </li>
          </ul>
          <p className="b-privacy-policy__paragraph">
            Vi vil kun bruge dine oplysninger til andre formål i det omfang, at vi har givet dig
            oplysninger herom på indsamlingstidspunktet.
          </p>
        </div>
      </li>

      <div className="b-privacy-policy__sub-section">
        <h3 className="b-privacy-policy__sub-inner-title">
          1.7 Så længe opbevarer vi dine oplysninger
        </h3>
        <p className="b-privacy-policy__paragraph">
          Vi vil opbevare dine oplysninger i den periode, hvor de er nødvendige til ovenstående
          formål, herunder så længe din profil fortsat er aktiv hos os.
        </p>
        <p className="b-privacy-policy__paragraph">
          Vi sletter dine oplysninger, hvis du ikke har gjort brug af eller logget på din profil i
          mere end. 24 måneder, og din konto ikke har aktive værdikuponer. I andre tilfælde kan vi
          slette dine oplysninger som beskrevet i afsnit 8.2.
        </p>
      </div>

      <li className="b-privacy-policy__item">
        <h2 className="b-privacy-policy__sub-title">2 Upplysande av personlig information</h2>
        <div className="b-privacy-policy__sub-section">
          <h3 className="b-privacy-policy__sub-inner-title">
            2.1 Upplysnade av personlig information på Webbplatsen
          </h3>
          <p className="b-privacy-policy__paragraph">
            Vi vil i forbindelse med din brug af Webstedet videregive visse oplysninger om dig.
          </p>
          <p className="b-privacy-policy__paragraph">Betalningsinformation</p>
          <p className="b-privacy-policy__paragraph">
            Vi vidarebefordrar nödvändiga betalningsmetoddata till golfbokningssystemet för
            respektive klubb.
          </p>
        </div>
        <div className="b-privacy-policy__sub-section">
          <h3 className="b-privacy-policy__sub-inner-title">
            2.2 Information om tjänster som du ansluter till din profil till.
          </h3>
          <p className="b-privacy-policy__paragraph">
            En av GolfMores främsta syften är att öka hjälpklubbarna att hantera sina värdebevis och
            öka försäljningen. Därför tillåter vi klubbarna att få tillgång till information, som är
            relevant för dem för användning i deras klubb.
          </p>
          <p className="b-privacy-policy__paragraph">
            När du mottar ett värdebevis från en klubb eller har använt GolfMore i en klubb är
            följande data tillgänglige:
          </p>
          <ul className="b-privacy-policy__sub-inner-list">
            <li className="b-privacy-policy__sub-inner-item">
              o Ditt förnamn, efternamn, medlemsnummer, hemmaklubb
            </li>
            <li className="b-privacy-policy__sub-inner-item">
              Vilka värdebevis du har använt och vilket ursprungspriset var vid inlösen tidpunkten,
              tidpunkt för inlösen, hur många användnings-tillfällen som finns kvar på värdebeviset.
            </li>
          </ul>
        </div>
        <div className="b-privacy-policy__sub-section">
          <h3 className="b-privacy-policy__sub-inner-title">2.3 Övriga upplysningar.</h3>
          <p className="b-privacy-policy__paragraph">
            Utöver ovanstående kan vi ge din personliga information vidare till följande parter och
            under följande omständigheter:
          </p>
          <ul className="b-privacy-policy__sub-inner-list">
            <li className="b-privacy-policy__sub-inner-item">
              För att möjliggöra för tredje parts levetantörer, konsulter och andra
              tjänsteleverantörer att tillhandahålla tjänster på vår vägnar.
            </li>
            <li className="b-privacy-policy__sub-inner-item">
              Til ProBox24 ApS dotterbolag och andra företag i ProBox24-koncernen.
            </li>
            <li className="b-privacy-policy__sub-inner-item">
              Att följa lagar eller svara på fordringar, rättslig process (inklusive men inte
              begränsat till kallelser och domstolsuppdrag) och att följa förfrågningar från
              offentliga och statliga myndigheter.
            </li>
            <li className="b-privacy-policy__sub-inner-item">
              Till tredje part i samband med verkställigheten av våra användarvillkor.
              <a
                className="b-privacy-policy__link"
                href={urls.swedishTermsAndConditions}
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                {' '}
                Vilkår og Betingelser
              </a>
              .
            </li>
            <li className="b-privacy-policy__sub-inner-item">
              Till tredje part för att skydda vår verksamhet eller verksamheten hos våra
              dotterbolag.
            </li>
            <li className="b-privacy-policy__sub-inner-item">
              Til tredjeparter for at forfølge tilgængelige retsmidler, eller begrænse
              erstatningsansvar, som vi kan pådrage os.
            </li>
            <li className="b-privacy-policy__sub-inner-item">
              Till tredje part så att vi kan undersöka, förebygga eller ingripa i misstänkta eller
              faktiska förbjudna aktiviteter, inklusive men inte begränsat till missbruk av vår
              webbplats.
            </li>
          </ul>
        </div>
      </li>

      <li className="b-privacy-policy__item">
        <h2 className="b-privacy-policy__sub-title">3 Datakontroll</h2>
        <div className="b-privacy-policy__sub-section">
          <p className="b-privacy-policy__paragraph">
            • Vi är ansvariga för den Stamdata, som du anger i samband med att du skapar en profil,
            som inkluderar, men är inte begränsat till, ditt namn, lösenord och e-postadress samt
            registrering av din IP-adress.
          </p>
          <p className="b-privacy-policy__paragraph">
            Vi är också datakontrollant för den information som vidarebefordras till andra tjänster
            (se avsnitt 2.3). Danska dataskyddslagar reglerar GolfMores datainsamling.
          </p>
        </div>
      </li>
      <li className="b-privacy-policy__item">
        <h2 className="b-privacy-policy__sub-title">4 Länkar till webbplatser</h2>
        <div className="b-privacy-policy__sub-section">
          <p className="b-privacy-policy__paragraph">
            Vår Webbplats innehåller länkar till andra webbplatser. Vårt medtag av sådana länkar
            uttrycker inte vårt godkännande av dessa webbplatser. Vi kontrollerar inte innehållet på
            dessa tredje parts webbplatser och tar inget ansvar för tredje part eller deras policyer
            eller praxis.
          </p>
          <p className="b-privacy-policy__paragraph">
            Vi uppmanar dig att granska sekretesspolicyerna för dessa tredje parts webbplatser
            eftersom deras personuppgifter, hanterings- och behandlingsprocedurer kan skilja sig
            från våra.
          </p>
        </div>
      </li>
      <li className="b-privacy-policy__item">
        <h2 className="b-privacy-policy__sub-title">
          5 Databehandling och överföring av personlig information
        </h2>
        <div className="b-privacy-policy__sub-section">
          <p className="b-privacy-policy__paragraph">
            Vi använder externa för att upprätthålla den tekniska driften av Webbplatsen och våra
            tjänster. Dessa företag är datainsamlare för den personliga information som vi är
            dataansvariga för. Genom att acceptera denna policy godkänner du också att vi kan
            tillåta den information du ansvarar för att behandlas av samma dataansvariga.
          </p>
          <p className="b-privacy-policy__paragraph">
            Datainsamlarna får endast agera i enlighet med instruktioner från oss. Genom att
            acceptera policyn tillåter du oss att ge sådana processer till datainsamlarna för att
            behandla data i enlighet med policyn och att använda Webbplatsen.
          </p>
          <p className="b-privacy-policy__paragraph">
            Datainsamlarna har vidtagit rimliga tekniska och organisatoriska åtgärder för att skydda
            informationen från oavsiktlig eller olaglig förstörelse, förlust eller försämring och
            för att skydda informationen mot avslöjande för obehöriga personer, missbruk eller annan
            behandling som strider mot personuppgiftslagen. På din begäran - och eventuellt mot
            eventuell ersättning för datortillverkarnas nuvarande timprisersättning för sådant
            arbete - måste dataprocessorerna ge dig tillräcklig information för att bevisa att
            ovanstående tekniska och organisatoriska säkerhetsåtgärder har vidtagits.
          </p>
        </div>
      </li>

      <li className="b-privacy-policy__item">
        <h2 className="b-privacy-policy__sub-title">6 Säkerhetsåtgärder</h2>
        <div className="b-privacy-policy__sub-section">
          <p className="b-privacy-policy__paragraph">
            Vi strävar efter att använda rimliga organisatoriska, tekniska och administrativa
            åtgärder för att skydda din personliga information i vår organisation, och vi granskar
            regelbundet vårt system för sårbarheter. Men eftersom Internet inte är en 100% säker
            miljö kan vi inte säkra eller garantera säkerheten för information du skickar till
            GolfMore. E-postmeddelanden som skickas via webbplatsen är inte krypterade och vi
            rekommenderar därför att du inte inkluderar konfidentiell information i dina
            e-postmeddelanden till oss.
          </p>
        </div>
      </li>

      <li className="b-privacy-policy__item">
        <h2 className="b-privacy-policy__sub-title">7 Cookies</h2>
        <div className="b-privacy-policy__sub-section">
          <p className="b-privacy-policy__paragraph">
            Webbplatsen använder cookies och liknande teknik ("Cookies"). Genom att använda vår
            webbplats accepterar du att vi använder Cookies enligt beskrivningen nedan.
          </p>
          <div className="b-privacy-policy__sub-section">
            <h3 className="b-privacy-policy__sub-inner-title">
              7.1 Vilka typer av Cookies använder vi?
            </h3>
            <p className="b-privacy-policy__paragraph">
              Cookies är små bitar av information som webbplatsen placerar på datorns hårddisk, på
              din surfplatta eller på din smartphone. Cookies innehåller information som webbplatsen
              använder för att effektivisera kommunikationen mellan dig och din webbläsare. Coolies
              identifierar din dator istället för dig som enskild användare.
            </p>
            <p className="b-privacy-policy__paragraph">
              Vi använder sessionscookies och ihållande cookies samt teknik som liknar
              session-cookies och ihållande cookies. Session-cookies är tillfälliga utdrag av
              information som raderas när du avslutar din webbläsare. Hållbara cookies är mer
              permanenta utdrag av information som lagras och återstår på din dator tills de
              raderas. Ihållande cookies tar bort sig efter en viss period, men förnyas varje gång
              du besöker Webbplatsen.
            </p>
            <p className="b-privacy-policy__paragraph">
              Vi använder tekniker som liknar session-cookies och ihållande cookies för lagring och
              tillgång till information i webbläsaren eller enheten som använder lokala enheter och
              lokalt lagringsutrymme, till exempel HTML5-cookies, Flash och andra metoder. Dessa
              tekniker kan fungera över alla dina webbläsare. I vissa fall kan användningen av dessa
              tekniker inte styras av webbläsaren, men kräver speciella verktyg. Vi använder denna
              teknik för att lagra information för att säkerställa kvaliteten på anmälningar och att
              upptäcka oegentligheter vid användningen av sajten.
            </p>
          </div>

          <div className="b-privacy-policy__sub-section">
            <h3 className="b-privacy-policy__sub-inner-title">7.2 Vad använder vi Cookies till?</h3>
            <p className="b-privacy-policy__paragraph">Vi använder Cookies till:</p>
            <ul className="b-privacy-policy__sub-inner-list">
              <li className="b-privacy-policy__sub-inner-item">Att generera statistik</li>
            </ul>
            <p className="b-privacy-policy__paragraph">
              Mätning, såsom antalet besök på webbplatsen, vilka domäner besökarna kommer ifrån,
              vilka sidor besökarna besöker på Webbplatsen och de övergripande geografiska platser
              besökarna befinner sig på.
            </p>
            <ul className="b-privacy-policy__sub-inner-list">
              <li className="b-privacy-policy__sub-inner-item">
                Övervaka webbplatsens prestanda och din användning av vår hemsida
              </li>
            </ul>
            <p className="b-privacy-policy__paragraph">
              Övervaka webbplatsen, våra applikationer och widgets prestanda och använder vår
              webbplats, program och widgets.
            </p>
            <ul className="b-privacy-policy__sub-inner-list">
              <li className="b-privacy-policy__sub-inner-item">
                Godkännande och förbättring av funktionaliteten på vår Webbplats
              </li>
            </ul>
            <p className="b-privacy-policy__paragraph">
              Optimera din erfarenhet med webbplatsen, som inkluderar det att komma ihåg ditt
              användarnamn och lösenord när du kommer tillbaka till webbplatsen, samt komma ihåg
              information om din webbläsare och preferenser (t.ex. vilket språk du föredrar).
            </p>
          </div>
          <div className="b-privacy-policy__sub-section">
            <h3 className="b-privacy-policy__sub-inner-title">7.3 Cookies från tredje part</h3>
            <p className="b-privacy-policy__paragraph">
              Vår Webbplats använder Cookies från fäljande tredje parter:
            </p>
            <ul className="b-privacy-policy__sub-inner-list">
              <li className="b-privacy-policy__sub-inner-item">
                Google Analytics: Med henblik på at monitorere trafikken på vores Websted, såsom
                generelle besøgstal, konverteringer og lignende.
              </li>
              <li className="b-privacy-policy__sub-inner-item">
                Fabric Crashlytics: Med. henblik på at spore fejl og nedbrud på vores Websted.
              </li>
              <li className="b-privacy-policy__sub-inner-item">
                Javascript: Til monitoreringsformål, herunder hvordan du bruger vores Websted,
                widgets og applikationer, og for at skabe et rigt websted og widgets.
              </li>
            </ul>
          </div>
          <div className="b-privacy-policy__sub-section">
            <h3 className="b-privacy-policy__sub-inner-title">7.4 Radera cookies</h3>
            <p className="b-privacy-policy__paragraph">
              Om du vill radera cookies som redan finns på din enhet kan du: Om du använder en PC
              och en ny webbläsare trycker du på CTRL + SHIFT + DELETE samtidigt. Om genvägarna inte
              fungerar i din webbläsare, besök supportsidan för den här webbläsaren. Observera dock
              att om du inte accepterar cookies från vår Webbplats kan du uppleva några besvär vid
              användningen av Webbplatsen, och du kan hindras från att komma åt alla dess
              funktioner.
            </p>
          </div>
        </div>
      </li>

      <li className="b-privacy-policy__item">
        <h2 className="b-privacy-policy__sub-title">8 Åtkomst, korrigering och radering</h2>
        <div className="b-privacy-policy__sub-section">
          <h3 className="b-privacy-policy__sub-inner-title">
            8.1 Tillgång till information om din personliga information
          </h3>
          <p className="b-privacy-policy__paragraph">
            På din förfrågan informerar vi dig om det syfte för vilket vi behandlar din personliga
            information, den personliga information vi håller om dig, vem som tar emot din
            personliga information och från vilken personuppgifterna kommer från.
          </p>
          <p className="b-privacy-policy__paragraph">
            Begäran om ovanstående kommer endast att behandlas om mer än sex (6) månader har gått
            sedan någon tidigare förfrågan från dig, om du inte kan dokumentera ett särskilt
            intresse för att få informationen tidigare.
          </p>
          <h3 className="b-privacy-policy__sub-inner-title">
            8.2 Korrigera och ta bort din personliga information.
          </h3>
          <p className="b-privacy-policy__paragraph">
            Vänligen meddela oss om någon av de personuppgifter som vi, som den dataansvarige,
            behandlar om dig, är felaktiga eller vilseledande, och vi kommer att rätta till det. Du
            kommer att kunna rätta till det mesta av denna information på vår Webbplats. Om möjligt
            rekommenderar vi att du korrigerar din information själv.
          </p>
          <p className="b-privacy-policy__paragraph">
            Du kan när som helst korrigera eller radera innehåll och personuppgifter på den
            webbplats där du själv är dataansvarig (se avsnitt 3.2). Om din personliga information
            ändras eller om du inte längre vill visas på sajten kan du uppdatera eller radera
            informationen genom att logga in på din användarprofil.
          </p>
          <p className="b-privacy-policy__paragraph">
            När din profil har raderats, raderas all information som är associerad med din
            användarprofil, inklusive dina uppgifter om masterdata och Webbplatser.
          </p>
          <p className="b-privacy-policy__paragraph">
            Vi förbehåller oss rätten att blockera åtkomst till din profil och / eller ta bort din
            profil om profilen eller innehållet som är kopplat till din profil eller din
            (a)recension (er) på webbplatsen enligt vår uppfattning är diskriminerande, rasistisk,
            sexuellt orienterad, oetisk, hotande, stötande, trakasserande eller på annat sätt bryter
            mot tillämpliga lagar, tredje parts rättigheter, användarriktlinjer eller oförenliga med
            syftet med webbplatsen. Om vi blockerar åtkomst till eller raderar din användarprofil
            meddelar vi dig orsaken till att vi blockerar eller raderar din profil genom att skicka
            ett mail till adressen du angav när du skapade ditt konto.
          </p>
        </div>
      </li>
      <li className="b-privacy-policy__item">
        <h2 className="b-privacy-policy__sub-title">9 Ändringar i Policyn.</h2>
        <p className="b-privacy-policy__paragraph">
          Vi kan ändra denna policy när som helst. Datumet som visas i början av denna policy anger
          när den senast reviderades. Eventuella förändringar träder i kraft när vi publicerar den
          reviderade policyn på Webbplatsen. Du godkänner din fortsatta användning av webbplatsen
          efter eventuella publicerade ändringar utgör din godkännande av den nya versionen av
          policy.
        </p>
      </li>
      <li className="b-privacy-policy__item">
        <h2 className="b-privacy-policy__sub-title">
          10 Förfrågningar angående webbplatsen eller policyn.
        </h2>
        <p className="b-privacy-policy__paragraph">
          Om du har några frågor eller problem angående vår policy, hur vi behandlar din personliga
          information eller vill att vi ska rätta till din personliga information, kontakta oss på:
          GolfMore A/S, Rugaardsvej 5, 8680 Ry, Danmark, CVR-nr.: 33593082, telefon: +45 7070 7999,
          e-mail:
          <a className="b-privacy-policy__link" href="mailto:info@probox24.com">
            {' '}
            info@probox24.com
          </a>
          .
        </p>
      </li>
    </ul>
  </div>
);

export default privacyPolicyDenmark;
