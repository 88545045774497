// @flow
export const CONNECTION_CHANGE = 'appState/CONNECTION_CHANGE';

export type AppStateType = {
  hasConnection: boolean,
};

const initialState: AppStateType = {
  hasConnection: true,
};

export default function(state: AppStateType = initialState, action: Object): AppStateType {
  switch (action.type) {
    case CONNECTION_CHANGE:
      return {
        ...state,
        hasConnection: action.status,
      };
    default:
      return state;
  }
}
