import axios from 'axios';
import host from '../../../constants/config/host';

const headers = (locale) => ({ 'Accept-language': locale });

const getFaqsItems = (locale) =>
  axios({
    method: 'GET',
    url: `${host}/faq/items`,
    params: {
      locale,
    },
    headers: headers(locale),
  });

const getFaqsCategories = (locale) =>
  axios({
    method: 'GET',
    url: `${host}/faq/categories`,
    params: {
      locale,
    },
    headers: headers(locale),
  });

const getFaqsByCategory = (categoryId, locale) =>
  axios({
    method: 'GET',
    url: `${host}/faq/${categoryId}/items`,
    params: {
      locale: { locale },
    },
    headers: headers(locale),
  });

export default {
  getFaqsItems,
  getFaqsCategories,
  getFaqsByCategory,
};
