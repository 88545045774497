// @flow
import api from '../../api';
import type { ChangeUserClubDataT, User } from '../api/user';
import { API_REQUEST } from '../../store/apiAction';

const CHANGE_CLUB: 'changeClub/CHANGE_CLUB' = 'changeClub/CHANGE_CLUB';
const CHANGE_CLUB_SUCCESS: 'changeClub/CHANGE_CLUB_SUCCESS' = 'changeClub/CHANGE_CLUB_SUCCESS';
const CHANGE_CLUB_FAILED: 'changeClub/CHANGE_CLUB_FAILED' = 'changeClub/CHANGE_CLUB_FAILED';

type ChangeClubSuccess = {|
  type: typeof CHANGE_CLUB_SUCCESS,
  result: {
    data: User,
  },
|};

type ErrorT = {|
  message: string,
  field: string,
|};

type ChangeClubFailed = {|
  type: typeof CHANGE_CLUB_FAILED,
  error: {
    response: {
      data: Array<ErrorT>,
    },
  },
|};

export type ChangeState = {
  errors: ?Array<ErrorT>,
  loading: boolean,
};

type State = {
  changeClub: ChangeState,
};

export const initChangeState: State = {
  changeClub: {
    errors: null,
    loading: false,
  },
};

export default {
  [CHANGE_CLUB](state: State) {
    return {
      ...state,
      changeClub: {
        ...initChangeState.changeClub,
        loading: true,
      },
    };
  },
  [CHANGE_CLUB_SUCCESS](state: State, action: ChangeClubSuccess) {
    return {
      ...state,
      changeClub: {
        ...initChangeState.changeClub,
      },
      user: {
        data: action.result.data,
        errors: null,
      },
    };
  },
  [CHANGE_CLUB_FAILED](state: State, action: ChangeClubFailed) {
    return {
      ...state,
      changeClub: {
        ...initChangeState.changeClub,
        errors: action.error.response.data,
      },
    };
  },
};

export function changeUserClub(data: ChangeUserClubDataT) {
  return {
    type: API_REQUEST,
    types: [CHANGE_CLUB, CHANGE_CLUB_SUCCESS, CHANGE_CLUB_FAILED],
    call: () => api().user.changeClub(data),
  };
}
