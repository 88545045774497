// @flow
import styled from 'styled-components/native';
import { color, space, layout } from 'styled-system';

export const Touchable = styled.TouchableWithoutFeedback``;

export const Image = styled.Image.attrs({
  resizeMode: 'contain',
})`
  ${space}
  ${layout}
  ${color}
`;
