import React, { Component } from 'react';
import moment from 'moment';
import propTypes from 'prop-types';
import appConstants from '../../../domains/app/appConstants';

import TextField from '../../templates/form/textField';
import FieldBtn from '../../templates/form/fieldBtn';
import AddUnion from './addUnion';
import AddUnionSuccess from './addUnionSuccess';
import ChangeProfileSuccess from './changeProfileSuccess';
import ReactSelect from '../../templates/form/reactSelect';

import texts from '../../../constants/languages';
import urls from '../../../constants/config/urls';
import { patterns } from '../../../patterns/patterns';

import './profile.css';

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldsPatterns: {
        first_name: patterns.name,
        last_name: patterns.name,
        email: patterns.email,
        phone: patterns.phone,
        phone2: patterns.phone,
        password: patterns.password,
        repeat_password: patterns.password,
      },
      languagesData: [
        { label: texts.ENGLISH_HEADER, link: urls.english },
        { label: texts.DENMARK, link: urls.danish },
        { label: texts.NORWEGIAN, link: urls.norwegian },
        { label: texts.SWEDEN, link: urls.swedish },
      ],
      userData: {
        first_name: '', // [MEMBER][NON-MEMBER]
        last_name: '', // [MEMBER][NON-MEMBER]
        sex: '', // [NON-MEMBER]
        // hcp: '', // [NON-MEMBER]
        // country: '', // [NON-MEMBER]
        // country_full_name: '', // only for rendering
        club_name: '', // [NON-MEMBER]
        birth_date: '', // [NON-MEMBER]
        email: '', // [MEMBER][NON-MEMBER]
        phone: '', // [MEMBER][NON-MEMBER]
        phone2: '', // [MEMBER][NON-MEMBER]
        language_default: '', // [MEMBER][NON-MEMBER]
        member_number: '',
        push_notifications_enabled_default: false, // [MEMBER][NON-MEMBER]
        password: '', // [MEMBER][NON-MEMBER]
        repeat_password: '',
        // member_type: '',
        currentLanguageLink: urls.english,
        accept_newsletter: 0,
        accept_offers: 0,
      },
      fieldsIsEmpty: {
        first_name: false,
        last_name: false,
        email: false,
        phone: false,
        accept_newsletter: false,
        accept_offers: false,
      },
      fieldsIsInvalid: {
        first_name: false,
        last_name: false,
        email: false,
        phone: false,

        accept_newsletter: false,
        accept_offers: false,
      },
      fieldsHaveServerErrors: {
        first_name: false,
        last_name: false,
        email: false,
        phone: false,
        accept_newsletter: false,
        accept_offers: false,
      },
      serverErrorsMessages: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
      },
    };
  }

  requiredFields = ['first_name', 'last_name', 'email', 'phone'];

  optionalFields = ['password', 'repeat_password'];

  componentDidMount() {
    this.props.setUnions(); // we get and set unions for 'add/change union' component
    this.setDefaultValuesToFields(this.props);
    this.setDefaultValuesToLanguages();
  }

  componentWillReceiveProps(nextProps) {
    this.setDefaultValuesToFields(nextProps);
  }

  setDefaultValuesToLanguages = () => {
    if (this.props.location === 'english') {
      this.changeInputsState('userData', 'currentLanguageLink', urls.english);
    } else if (this.props.location === 'norwegian') {
      this.changeInputsState('userData', 'currentLanguageLink', urls.norwegian);
    }
    if (this.props.location === 'swedish') {
      this.changeInputsState('userData', 'currentLanguageLink', urls.swedish);
    } else {
      this.changeInputsState('userData', 'currentLanguageLink', urls.danish);
    }
  };

  setDefaultValuesToFields = (nextProps) => {
    for (const field in nextProps.authUserData) {
      if (this.state.userData.hasOwnProperty(field)) {
        /* if (field === 'country') {
          this.changeInputsState('userData', field, nextProps.authUserData[field]);
          this.changeInputsState('userData', 'country_full_name', this.setCountryFullName(nextProps.authUserData[field], nextProps.countries));
        } */ if (
          nextProps.authUserData[field] === null
        ) {
          this.changeInputsState('userData', field, '');
        } else if (field === 'birth_date') {
          const newFormatBirthDay = moment(nextProps.authUserData[field], appConstants.SERVER_DATE_FORMAT).format(
            appConstants.VIEW_DATE_FORMAT,
          );
          this.changeInputsState('userData', field, newFormatBirthDay);
        } else {
          this.changeInputsState('userData', field, nextProps.authUserData[field]);
        }
      }
    }
  };

  /* setCountryFullName = (currentCountry, countries) => {
    let countryFullName = '';
    countries.forEach((item) => {
      if(item.country_code === currentCountry) {
        countryFullName = item.country_name;
      }
    });
    return countryFullName;
  }
*/
  changeInputsState = (dataName, valueName, value) => {
    if (valueName === 'phone' && value.length > 5) {
      this.setState((state) => {
        const obj = { ...state };
        if (value === undefined) {
          obj.userData[valueName] = '';
        } else {
          obj.userData[valueName] = value.replace(' ', '');
        }
        return obj;
      });
    } else {
      this.setState((state) => {
        const obj = { ...state };
        if (value === undefined) {
          obj[dataName][valueName] = '';
        } else {
          obj[dataName][valueName] = value;
        }
        return obj;
      });
    }
  };

  validateField = (fieldName, fieldsValueData, fieldsIsEmptyData, fieldsIsInvalidData, patternsData, errorsCounter) => {
    if (this.requiredFields.indexOf(fieldName) >= 0) {
      if (fieldsValueData[fieldName].length) {
        this.changeInputsState('fieldsIsEmpty', fieldName, false); // field is not empty

        if (!patternsData[fieldName].test(fieldsValueData[fieldName])) {
          this.changeInputsState('fieldsIsInvalid', fieldName, true); // field is invalid
          errorsCounter.push(fieldName);
        } else {
          this.changeInputsState('fieldsIsInvalid', fieldName, false); // field is valid
        }
      } else {
        this.changeInputsState('fieldsIsEmpty', fieldName, true); // field is empty
        errorsCounter.push(fieldName);
      }
    } else if (this.optionalFields.indexOf(fieldName) >= 0) {
      // validate optional fields
      if (fieldsValueData[fieldName].length > 1) {
        if (fieldName === 'repeat_password') {
          if (fieldsValueData[fieldName] === fieldsValueData.password) {
            this.changeInputsState('fieldsIsInvalid', fieldName, false); // repeat password is valid
          } else {
            this.changeInputsState('fieldsIsInvalid', fieldName, true); // repeat password is invalid
            errorsCounter.push(fieldName);
          }
        } else if (!patternsData[fieldName].test(fieldsValueData[fieldName])) {
          this.changeInputsState('fieldsIsInvalid', fieldName, true); // field is invalid
          errorsCounter.push(fieldName);
        } else {
          this.changeInputsState('fieldsIsInvalid', fieldName, false); // field is valid
        }
      } else if (fieldName === 'repeat_password' && fieldsValueData.password.length) {
        this.changeInputsState('fieldsIsEmpty', fieldName, true); // password
        errorsCounter.push(fieldName);
      } else {
        // if field has less than one symbol, we do not validate and clean errors
        this.changeInputsState('fieldsIsEmpty', fieldName, false);
        this.changeInputsState('fieldsIsInvalid', fieldName, false);
      }
    }
  };

  validateForm = (fieldsValueData, fieldsIsEmptyData, fieldsIsInvalidData, patternsData, errorsCounter) => {
    for (const field in fieldsValueData) {
      this.validateField(field, fieldsValueData, fieldsIsEmptyData, fieldsIsInvalidData, patternsData, errorsCounter);
    }
  };

  createDataOfNewUser = (registerData, fieldsForRequest) => {
    let fields = [];

    // if field password is empty it clears from fieldsForRequest list
    if (!registerData.password.length) {
      fields = fieldsForRequest.filter((key) => key !== 'password');
    }

    return Object.keys(registerData).reduce((data, key) => {
      if (fields.includes(key)) {
        return {
          ...data,
          [key]: registerData[key],
        };
      }

      return data;
    }, {});
  };

  setServerErrors = (serverErrorsData) => {
    serverErrorsData.forEach((errorData) => {
      if (this.state.fieldsHaveServerErrors.hasOwnProperty(errorData.field)) {
        this.changeInputsState('fieldsHaveServerErrors', errorData.field, true);
        this.changeInputsState('serverErrorsMessages', errorData.field, errorData.message);
      }
    });
  };

  checkPhonesFieldsOnChanges = (fieldsForSendToServer) => {
    if (this.state.userData.phone !== this.props.authUserData.phone) {
      fieldsForSendToServer.push('phone');
    } else if (this.state.userData.phone2 !== this.props.authUserData.phone2) {
      fieldsForSendToServer.push('phone2');
    }
  };

  submitForm = () => {
    if (navigator.onLine) {
      const errorsCounter = [];
      let currentUserData = {};
      const fieldsUnionMemberForSendToServer = [
        'first_name',
        'last_name',
        'email',
        'language_default',
        'push_notifications_enabled_default',
        'accept_newsletter',
        'accept_offers',
      ];
      const fieldsNonUnionMemberForSendToServer = [
        'first_name',
        'last_name',
        'sex',
        'hcp',
        'country',
        'club_name',
        'birth_date',
        'email',
        'language_default',
        'push_notifications_enabled_default',
        'accept_newsletter',
        'accept_offers',
      ];

      this.validateForm(
        this.state.userData,
        this.state.fieldsIsEmpty,
        this.state.fieldsIsInvalid,
        this.state.fieldsPatterns,
        errorsCounter,
      );

      if (!errorsCounter.length) {
        // we create data og new user for send request to server
        if (this.props.isUnionMember) {
          // we add phone and phone2, if this fields has been changed
          this.checkPhonesFieldsOnChanges(fieldsUnionMemberForSendToServer);
          currentUserData = this.createDataOfNewUser(this.state.userData, fieldsUnionMemberForSendToServer);
        } else {
          // we add phone and phone2, if this fields has been changed
          this.checkPhonesFieldsOnChanges(fieldsNonUnionMemberForSendToServer);
          currentUserData = this.createDataOfNewUser(this.state.userData, fieldsNonUnionMemberForSendToServer);
        }

        if ('birth_date' in currentUserData) {
          currentUserData.birth_date = moment(currentUserData.birth_date, appConstants.VIEW_DATE_FORMAT).format(
            appConstants.SERVER_DATE_FORMAT,
          );
        }

        currentUserData.phone = [...currentUserData.phone.slice(0, 3), ' ', ...currentUserData.phone.slice(3)].join('');
        this.props.updateLoggedUserInfo(currentUserData, this.setServerErrors);
      }
    } else {
      this.props.togglePopup('updateNotificationErrorPopup', true);
    }
  };

  handlePhoneChange = (value) => {
    if (/^((?=(\+\d{0,3})?\s?(\d{0,9}$)))/g.test(value)) {
      if (this.state.userData.phone.length === 0) {
        this.setState({
          ...this.state,
          userData: {
            ...this.state.userData,
            phone: `+${value}`,
          },
        });
        return;
      }
      this.setState({
        ...this.state,
        userData: {
          ...this.state.userData,
          phone: value,
        },
      });
    }
  };

  render() {
    return (
      <div className="b-profile g-auth-content">
        <div className="container b-profile__container">
          <div className="columns b-profile__form g-flex">
            <div className="column col-6 col-sm-12 col-xs-12">
              <TextField
                valueName="first_name"
                value={this.state.userData.first_name}
                label={texts.FIRST_NAME}
                type="text"
                placeholder={texts.ENTER_YOUR_FIRST_NAME}
                onChange={(name, value) => this.changeInputsState('userData', name, value)}
                errorText={[
                  texts.PLEASE_ENTER_YOUR_FIRST_NAME,
                  texts.PLEASE_ENTER_VALID_FIRST_NAME,
                  this.state.serverErrorsMessages.first_name,
                ]}
                isEmpty={this.state.fieldsIsEmpty.first_name}
                isInvalid={this.state.fieldsIsInvalid.first_name}
                haveServerError={this.state.fieldsHaveServerErrors.first_name}
              />
              <TextField
                valueName="last_name"
                value={this.state.userData.last_name}
                label={texts.LAST_NAME}
                type="text"
                placeholder={texts.ENTER_YOUR_LAST_NAME}
                onChange={(name, value) => this.changeInputsState('userData', name, value)}
                errorText={[
                  texts.PLEASE_ENTER_YOUR_LAST_NAME,
                  texts.PLEASE_ENTER_VALID_LAST_NAME,
                  this.state.serverErrorsMessages.last_name,
                ]}
                isEmpty={this.state.fieldsIsEmpty.last_name}
                isInvalid={this.state.fieldsIsInvalid.last_name}
                haveServerError={this.state.fieldsHaveServerErrors.last_name}
              />
              <TextField
                valueName="email"
                value={this.state.userData.email}
                label={texts.EMAIL}
                type="text"
                placeholder={texts.ENTER_EMAIL}
                onChange={(name, value) => this.changeInputsState('userData', name, value)}
                errorText={[
                  texts.PLEASE_ENTER_EMAIL,
                  texts.PLEASE_ENTER_VALID_EMAIL,
                  this.state.serverErrorsMessages.email,
                ]}
                isEmpty={this.state.fieldsIsEmpty.email}
                isInvalid={this.state.fieldsIsInvalid.email}
                haveServerError={this.state.fieldsHaveServerErrors.email}
              />
              <TextField
                valueName="phone"
                value={this.state.userData.phone}
                label={texts.MOBILE_NO}
                type="text"
                placeholder={texts.ENTER_YOUR_MOBILE_NUMBER}
                onChange={(name, value) => this.handlePhoneChange(value)}
                errorText={[
                  texts.PLEASE_ENTER_YOUR_MOBILE_NUMBER,
                  texts.PLEASE_ENTER_A_VALID_MOBILE_NUMBER,
                  this.state.serverErrorsMessages.phone,
                ]}
                isEmpty={this.state.fieldsIsEmpty.phone}
                isInvalid={this.state.fieldsIsInvalid.phone}
                haveServerError={this.state.fieldsHaveServerErrors.phone}
                inputMask
                maskTemplate="+11 11111111"
              />
              <TextField
                valueName="birth_date"
                value={this.state.userData.birth_date}
                label={texts.BIRTHDAY}
                type="text"
                disabled
              />
            </div>
            <div className="column col-6 col-sm-12 col-xs-12">
              {this.props.isUnionMember ? (
                <FieldBtn
                  label={texts.CLUB_NAME}
                  value={this.state.userData.club_name}
                  btnText={texts.CHANGE}
                  btn
                  action={() => this.props.togglePopup('addUnionPopupIsOpen', true)}
                />
              ) : (
                <TextField
                  valueName="club_name"
                  value={this.state.userData.club_name}
                  label={texts.CLUB_NAME}
                  type="text"
                  onChange={(name, value) => this.changeInputsState('userData', name, value)}
                />
              )}
              {!this.props.isUnionMember ? (
                <FieldBtn
                  label={texts.UNION}
                  value={texts.ADD_UNION}
                  btnText={texts.CHANGE}
                  placeholder={texts.ADD_UNION}
                  action={() => this.props.togglePopup('addUnionPopupIsOpen', true)}
                />
              ) : null}

              {this.props.isUnionMember && (
                <TextField
                  valueName="club_name"
                  value={this.state.userData.member_number}
                  label={texts.MEMBER_NUMBER}
                  type="text"
                  disabled
                />
              )}
              <ReactSelect
                label={texts.LANGUAGE}
                data={this.state.languagesData}
                nameOfValueProp="link"
                nameOfTitleProp="label"
                changeNameOfValueProp={(value) => this.changeInputsState('userData', 'currentLanguageLink', value)}
                defaultValue={this.state.userData.currentLanguageLink}
                links
              />
            </div>
            <form>
              <label>
                <input
                  className="checkbox_profile"
                  name="accept_newsletter"
                  type="checkbox"
                  checked={this.state.userData.accept_newsletter == 1}
                  onChange={(evt) => {
                    const selected = !(this.state.userData.accept_newsletter == 1);
                    this.changeInputsState('userData', 'accept_newsletter', selected ? 1 : 0);
                  }}
                />
                {texts.newsletter.receiveEmailInfo}
              </label>
              <br />
              <label>
                <input
                  className="checkbox_profile"
                  name="accept_offers"
                  type="checkbox"
                  checked={this.state.userData.accept_offers}
                  onChange={this.handleInputChange}
                  onChange={(evt) => {
                    const selected = !(this.state.userData.accept_offers == 1);
                    this.changeInputsState('userData', 'accept_offers', selected ? 1 : 0);
                  }}
                />
                {texts.newsletter.receiveClubInfo}
              </label>
            </form>
          </div>

          <div className="b-profile__control g-clear-fix">
            <button className="b-profile__btn g-form__btn g-submit-btn" onClick={this.submitForm}>
              {texts.SAVE}
            </button>
          </div>
        </div>

        {this.props.addUnionPopupIsOpen && <AddUnion />}
        {this.props.addUnionSuccessPopupIsOpen && (
          <AddUnionSuccess togglePopup={(popup, flag) => this.props.togglePopup(popup, flag)} />
        )}
        {this.props.changeProfileSuccessPopupIsOpen && (
          <ChangeProfileSuccess
            popupName="changeProfileSuccessPopupIsOpen"
            title={texts.SUCCESS}
            text={texts.YOURE_INFO_HAS_BEEN_CHANGED}
            togglePopup={(popup, flag) => this.props.togglePopup(popup, flag)}
          />
        )}
        {this.props.updateNotificationErrorPopup && (
          <ChangeProfileSuccess
            popupName="updateNotificationErrorPopup"
            title={'OOOPS'}
            text={texts.NO_INTERNET}
            togglePopup={(popup, flag) => this.props.togglePopup(popup, flag)}
          />
        )}
      </div>
    );
  }
}

export default Profile;

Profile.propTypes = {
  userData: propTypes.shape({
    first_name: propTypes.string.isRequired,
    last_name: propTypes.string.isRequired,
    sex: propTypes.string.isRequired,
    hcp: propTypes.string.isRequired,
    country: propTypes.string.isRequired,
    club_name: propTypes.string.isRequired,
    birth_date: propTypes.string.isRequired,
    email: propTypes.string.isRequired,
    phone: propTypes.string.isRequired,
    phone2: propTypes.string.isRequired,
    language_default: propTypes.string.isRequired,
    push_notifications_enabled_default: propTypes.bool.isRequired,
    password: propTypes.string.isRequired,
  }),
  isUnionMember: propTypes.bool.isRequired,
  updateLoggedUserInfo: propTypes.func.isRequired,
  togglePopup: propTypes.func.isRequired,
  setUnions: propTypes.func.isRequired,
  addUnionPopupIsOpen: propTypes.bool.isRequired,
  addUnionSuccessPopupIsOpen: propTypes.bool.isRequired,
  changeProfileSuccessPopupIsOpen: propTypes.bool.isRequired,
  updateNotificationErrorPopup: propTypes.bool.isRequired,
  location: propTypes.string.isRequired,
};
