// @flow
import i18n from 'react-native-i18n';

export default (type: string, val: string) => {
  if (type === 'setprice') {
    return val === '0' ? i18n.t('voucherDetails.price') : `${val},-`;
  } else if (type === 'reducepercent') {
    return `-${val}%`;
  } else if (type === 'reduceprice') {
    return `-${val},-`;
  }

  return '';
};
