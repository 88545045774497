import React, { Component } from 'react';
import texts from '../../../constants/languages';
import './voucherDetails.css';
import Criterias from '../../../react-native/vouchers/screens/VoucherDetails/components/VoucherConstraints';
import i18n from 'react-native-i18n';
import renderPrice from '../../../react-native/vouchers/components/helpers/renderPrice';
import LoadingImage from '../../../react-native/components/LoadingImage/LoadingImage';
const voucherImage = require('../../../react-native/vouchers/screens/VoucherDetails/assets/logo.png');
import {
  voucherIcon,
  golfUse,
  calendar,
  arrow,
  categoryDrivingRange,
  frownEmoji,
} from '../../../react-native/assets/images';

import {
  Touchable,
  Root,
  ContentContainer,
  LoadingContainer,
  LoadingIndicator,
  HeaderContainer,
  HeaderText,
  InfoContainer,
  LogoContainer,
  Logo,
  PriceContainer,
  PriceText,
  TypeImage,
  OwnerName,
  VoucherName,
  ProductNameContainer,
  ProductName,
  DetailsRow,
  DetailContainer,
  DetailImageContainer,
  DetailImage,
  DetailText,
  VoucherBubbleContainer,
  VoucherBubbleText,
  VoucherContainer,
  VoucherNumber,
  VoucherInfo,
  VoucherListContainer,
  VouchersSwiper,
  VoucherItemContainer,
  VoucherBarcode,
  BarcodeText,
  BarcodeArrowContainer,
  BarcodeArrow,
  SwiperWrapper,
  TransferButton,
  FavoritesCheck,
  RedeemButton,
  RedeemText,
  TimeLeft,
  VoucherActive,
  VoucherActiveText,
  VoucherActiveTimeLeftText,
  ShowIfRequired,
} from '../../../react-native/vouchers/screens/VoucherDetails/styles';

class VoucherDetails extends Component {
  createAvatarUrl = () => ({
    backgroundImage: `url('${this.props.image}')`,
  });

  renderPrice = (price) => {
    const { reductionType } = this.props;

    if (reductionType === 'setprice') {
      return price === '0' ? texts.FREE : `${price}, -`;
    } else if (reductionType === 'reducepercent') {
      return `-${price}%`;
    } else if (reductionType === 'reduceprice') {
      return `-${price}`;
    }
  };

  closePopupOnClickOutSide = (event) => {
    if (!this.popup.contains(event.target)) {
      this.props.togglePopup('voucherDetailsPopupIsOpen', false);
      this.props.forceUpdate(); // call a cb to update the favourite vouchers page
    }
  };

  toggleFavourite = () => {
    this.props.toggleFavourites(!this.props.favourite, this.props.voucherId);
  };

  handleTogglePopup = () => {
    this.props.togglePopup('voucherDetailsPopupIsOpen', false);
    this.props.forceUpdate(); // call a cb to update the favourite vouchers page
  };

  render() {
    const { currentVoucher, departments } = this.props;

    const {
      owner_name: ownerName,
      name,
      product_name: productName,
      logo_url: logoUrl,
      valid_times: validTimes,
      valid_to: validTo,
      valid_from: validFrom,
      reduction_type: reductionType,
      type,
      value,
      info,
      can_be_self_redeemed: canBeSelfRedeemed,
    } = currentVoucher || {};

    const logoImage = logoUrl ? { uri: logoUrl } : voucherImage;

    let price;
    if (type === 'introcard' && value === '0') {
      price = 'Intro';
    } else if (type === 'golf_circle') {
      price = i18n.t('vouchers.card');
    } else {
      price = renderPrice(reductionType, value);
    }

    const priceView = !reductionType ? null : (
      <PriceContainer>
        <PriceText text={price} />
      </PriceContainer>
    );

    const typeImageView = type !== 'ecard' ? null : <TypeImage source={categoryDrivingRange} />;
    const titleText = i18n.t(`voucherDetails.${type === 'ecard' ? 'titleDrivingRange' : 'titleVoucher'}`);
    const totalVouchers = 1;

    const validFromText = i18n.l('date.formats.voucherDetailDate', validFrom * 1000).toUpperCase();
    const validToText = i18n.l('date.formats.voucherDetailDate', validTo * 1000).toUpperCase();

    const numberOfUsesDetails =
      type === 'introcard' ? null : (
        <DetailContainer>
          <DetailImageContainer>
            <DetailImage source={golfUse} width={20} height={27} />
          </DetailImageContainer>
          <DetailText text={i18n.t('voucherDetails.numberOfUse', { count: validTimes })} />
        </DetailContainer>
      );

    return (
      <div
        className="g-popup-wrap"
        onClick={(event) => this.closePopupOnClickOutSide(event)} // close popup
      >
        <div className="g-popup-overlay">&nbsp;</div>
        <div className="g-popup-wrap">
          <table className="g-popup-wrap__container">
            <tbody>
              <tr>
                <td className="g-popup-wrap__container-inner">
                  <div className="b-voucher-details g-popup" ref={(popup) => (this.popup = popup)}>
                    <div
                      className="g-popup__close-btn"
                      onClick={() => this.handleTogglePopup()} // close popup
                    >
                      &nbsp;
                    </div>
                    <div
                      className={`b-favorites-icon ${this.props.favourite ? 'favourite' : ''}`}
                      onClick={() => this.toggleFavourite()}
                    >
                      &nbsp;
                    </div>

                    <InfoContainer>
                      <LogoContainer>
                        <Logo loadingIndicator={LoadingImage} source={logoImage} />
                        {priceView}
                        {typeImageView}
                      </LogoContainer>
                      <OwnerName text={ownerName} />
                      <VoucherName text={name} />
                      <ProductNameContainer>
                        <ProductName text={productName} />
                      </ProductNameContainer>
                      <DetailsRow>
                        <DetailContainer>
                          <DetailImageContainer>
                            <DetailImage source={voucherIcon} width={30} height={18} />
                            <VoucherBubbleContainer>
                              <VoucherBubbleText text={`x${totalVouchers}`} />
                            </VoucherBubbleContainer>
                          </DetailImageContainer>
                          <DetailText
                            text={`${i18n.t('voucherDetails.identicalVouchers', { count: totalVouchers })}`}
                          />
                        </DetailContainer>
                        {numberOfUsesDetails}
                        <DetailContainer>
                          <DetailImageContainer>
                            <DetailImage source={calendar} width={24} height={24} />
                          </DetailImageContainer>
                          <DetailText text={`${validFromText}\n${validToText}`} />
                        </DetailContainer>
                      </DetailsRow>
                    </InfoContainer>
                    <Criterias voucher={currentVoucher} departments={departments} />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default VoucherDetails;
