// @flow
import styled from 'styled-components/native';
import { space, color, layout, border, flex } from 'styled-system';
import { TextXs } from '../../../components/Text';
import styles from '../../../constants/styles';
import { check } from '../../../assets/images';

const HEIGHT = 24;

export const Root = styled.View``;

export const Title = styled(TextXs).attrs({
  fontFamily: 'italic',
  color: 'grey',
  mb: 2,
})`
  ${color}
  ${space}
  text-align: center;
`;

export const Container = styled.View.attrs({
  // height: HEIGHT,
})`
  flex-direction: row;
  align-items: center;
`;

export const StepContainer = styled.View.attrs(({ state }) => {
  let borderColor = 'transparent';
  if (state === 'pending') {
    borderColor = 'stepDisabled';
  } else if (state === 'inProgress') {
    borderColor = 'textBlack';
  }
  return {
    width: HEIGHT,
    height: HEIGHT,
    bg: state === 'completed' ? 'green' : 'transparent',
    borderWidth: state === 'completed' ? 0 : 1,
    borderRadius: HEIGHT / 2,
    borderColor,
  };
})`
  justify-content: center;
  align-items: center;
  ${border}
  ${color}
  ${layout}
`;

export const StepText = styled(TextXs).attrs(({ state }) => ({
  fontFamily: 'semibold',
  color: state === 'pending' ? 'stepDisabled' : 'textBlack',
}))``;

export const StepCheckImage = styled.Image.attrs({
  width: 16,
  height: 16,
  resizeMode: 'contain',
  source: check,
})`
  ${layout}
`;

const STEP_LINE_HEIGHT = 1.5;

export const StepCompletedLine = styled.View.attrs({
  flex: 1,
  height: STEP_LINE_HEIGHT,
  ml: 4,
  mr: 4,
  bg: 'green',
})`
  flex-grow: 1;
  ${space}
  ${layout}
  ${color}
`;

export const StepPendingLine = styled.View.attrs({
  ml: 4,
  mr: 4,
})`
  height: 1px;
  flex-direction: row;
  flex-grow: 1;
  background-image:  
  linear-gradient(to right, ${styles.colors.stepDisabled} 50%, transparent 0%); 
  background-size: 8px 1px;
  background-repeat: repeat-x;
  ${space}
`;


export const EdgeSpace = styled.View`
  flex-grow: 1;
`;
