// @flow
import React from 'react';
import { Root, Title } from './styles';

type Props = {
  title: string,
  onPress: function,
};

export const ReplaceButton = ({ title, onPress }: Props) => (
  <Root onPress={onPress}>
    <Title>{title}</Title>
  </Root>
);

export default ReplaceButton;
