const CHANGE_SEARCH_TEXT = 'CHANGE_SEARCH_TEXT';
const SORT_VOUCHERS_VIEW = 'SORT_VOUCHERS_VIEW';
const DISABLE_SORT_VIEW = 'DISABLE_SORT_VIEW';
const STAR_VOUCHER = 'STAR_VOUCHER';
const SET_VOUCHERS = 'SET_VOUCHERS';
const SET_CURRENT_VOUCHER = 'SET_CURRENT_VOUCHER';
const VOUCHER_PENDING_FOR_OPENING = 'VOUCHER_PENDING_FOR_OPENING';
const TOGGLE_TAB_FILTER = 'TOGGLE_TAB_FILTER';
const SET_VOUCHERS_COUNT = 'SET_VOUCHERS_COUNT';
const SET_CLUBS = 'SET_CLUBS';
const GET_ACCOUNT_EMAIL = 'GET_ACCOUNT_EMAIL';
const SET_VOUCHER_TRANSFER_DATA = 'SET_VOUCHER_TRANSFER_DATA';
const SET_SPINNER_FLAG = 'SET_SPINNER_FLAG';
const CHANGE_SORT_TAB = 'CHANGE_SORT_TAB';
const SET_SORT_OPTION = 'SET_SORT_OPTION';
const SET_FAVOURITE_VOUCHERS = 'SET_FAVOURITE_VOUCHERS';
const SET_PRINTED_VOUCHERS = 'SET_PRINTED_VOUCHERS';
const SET_ALL_VOUCHERS = 'SET_ALL_VOUCHERS';
const CURRENT_TAB_FILTER = 'CURRENT_TAB_FILTER';

export default {
  CHANGE_SEARCH_TEXT,
  SORT_VOUCHERS_VIEW,
  DISABLE_SORT_VIEW,
  STAR_VOUCHER,
  SET_VOUCHERS,
  SET_CURRENT_VOUCHER,
  VOUCHER_PENDING_FOR_OPENING,
  TOGGLE_TAB_FILTER,
  SET_VOUCHERS_COUNT,
  SET_CLUBS,
  GET_ACCOUNT_EMAIL,
  SET_VOUCHER_TRANSFER_DATA,
  SET_SPINNER_FLAG,
  CHANGE_SORT_TAB,
  SET_SORT_OPTION,
  SET_FAVOURITE_VOUCHERS,
  SET_PRINTED_VOUCHERS,
  CURRENT_TAB_FILTER,
  SET_ALL_VOUCHERS,
};
