// @flow
import type { ReqType } from '../../api/index';
import API from '../../api/base/api';

export type Country = {
  country_code: string,
  country_name: string,
  calling_code: string,
};

class CountryAPI extends API {
  getCountries() {
    return this.r({
      method: 'GET',
      url: '/countries',
    });
  }

  getGeoIp() {
    return this.r({
      method: 'GET',
      url: '/geoip',
    });
  }
}

export default function countryApi(request: ReqType) {
  return new CountryAPI(request);
}
