// @flow
import uniqBy from 'lodash/uniqBy';
import api from '../../api';
import type { ClubType } from '../api/club';
import { API_REQUEST } from '../../store/apiAction';

const LOAD_DEPARTMENTS: 'club/LOAD_DEPARTMENTS' = 'club/LOAD_DEPARTMENTS';
const LOAD_DEPARTMENTS_SUCCESS: 'club/LOAD_DEPARTMENTS_SUCCESS' = 'club/LOAD_DEPARTMENTS_SUCCESS';
const LOAD_DEPARTMENTS_FAILURE: 'club/LOAD_DEPARTMENTS_FAILURE' = 'club/LOAD_DEPARTMENTS_FAILURE';

const initialState = {
  data: [],
  dataById: {},
  loaded: false,
  error: null,
};

type LoadClubs = {
  type: typeof LOAD_DEPARTMENTS,
};
type LoadClubsSuccess = {
  type: typeof LOAD_DEPARTMENTS_SUCCESS,
  result: {
    data: Array<ClubType>,
  },
};
type LoadClubsFailure = {
  type: typeof LOAD_DEPARTMENTS_FAILURE,
  error: {
    message: string,
  },
};

type ClubAction = LoadClubs | LoadClubsFailure | LoadClubsSuccess;

export type DepartmentsState = {
  data: Array<ClubType>,
  dataById: { [string]: ClubType },
  loaded: boolean,
  error: ?string,
};

export default function(state: DepartmentsState = initialState, action: ClubAction) {
  switch (action.type) {
    case LOAD_DEPARTMENTS: // let's disable this setting to avoid loading flicker
      return state;

    case LOAD_DEPARTMENTS_FAILURE:
      return {
        ...initialState,
        error: action.error.message,
      };

    case LOAD_DEPARTMENTS_SUCCESS: {
      return {
        ...initialState,
        data: uniqBy([...state.data, ...action.result.data], 'department_id'),
        dataById: action.result.data.reduce(
          (acc, val) => {
            acc[val.department_id] = val;
            return acc;
          },
          { ...state.dataById },
        ),
        loaded: true,
      };
    }
    default: {
      return state;
    }
  }
}

export function loadDepartments(params): ApiRequest<Array<ClubType>> {
  return {
    type: API_REQUEST,
    types: [LOAD_DEPARTMENTS, LOAD_DEPARTMENTS_SUCCESS, LOAD_DEPARTMENTS_FAILURE],
    call: () => api().club.getDepartments(params),
  };
}
