import React from 'react';
import { Link } from 'react-router-dom';
import propTypes from 'prop-types';

import texts from '../../../constants/languages';
import { LOGO_NOT_LOGIN, LOGO_LOGIN } from '../../../constants/config/images';
import urls from '../../../constants/config/urls';

function getLanguage(location) {
  switch (location) {
    case 'norwegian':
      return texts.NORWEGIAN;
    case 'english':
      return texts.ENGLISH_HEADER;
    case 'swedish':
      return texts.SWEDEN;
    default:
      return texts.DENMARK;
  }
}

class Header extends React.Component {
  state = {
    isOpen: false,
  };

  componentDidMount() {
    this.forceUpdate(); // it is for translate
  }

  logOut = () => {
    this.props.toggleLogOutWarn();
  };

  blur = () => {
    this.setState({ isOpen: false });
    document.removeEventListener('click', this.closeOnBlur);
  };

  closeOnBlur = (event) => {
    if (!this.dropDown.contains(event.target) && !this.dropDownTrigger.contains(event.target)) {
      this.blur();
    }
  };

  handleDropDown = () => {
    this.setState({ isOpen: true });
    document.addEventListener('click', this.closeOnBlur);
  };

  renderLanguages() {
    const { location } = this.props;
    return (
      <ul className="b-language__list">
        <li className="b-language__item">
          {location === 'danish' ? <span>{texts.DENMARK}</span> : <a href={urls.danish}>{texts.DENMARK}</a>}
        </li>
        <li className="b-language__item">
          {location === 'english' ? <span>{texts.ENGLISH}</span> : <a href={urls.english}>{texts.ENGLISH_HEADER}</a>}
        </li>
        <li className="b-language__item">
          {this.props.location === 'norwegian' ? (
            <span>{texts.NORWEGIAN}</span>
          ) : (
            <a href={urls.norwegian}>{texts.NORWEGIAN}</a>
          )}
        </li>
        <li className="b-language__item">
          {this.props.location === 'swedish' ? <span>{texts.SWEDEN}</span> : <a href={urls.swedish}>{texts.SWEDEN}</a>}
        </li>
      </ul>
    );
  }

  render() {
    return (
      <header className={`b-header ${this.props.userIsAuthenticated ? 'm-header_auth' : ''}`}>
        {!this.props.userIsAuthenticated ? (
          <div className="container grid-xl b-header__container g-container g-flex">
            <Link className="b-header__logo" to={'/'}>
              <img src={LOGO_NOT_LOGIN} width={197} height={48} alt="Golf More" />
            </Link>
            <nav className="b-header__nav">
              <ul className="b-header__list g-clear-fix">
                <li className="b-header__item m-header__item_language">
                  <div
                    className="b-language"
                    ref={(dropDownTrigger) => (this.dropDownTrigger = dropDownTrigger)}
                    onClick={this.handleDropDown}
                  >
                    <span className="b-language__label">{getLanguage(this.props.location)}</span>
                    <div className="b-language__arrow">&nbsp;</div>
                  </div>
                  <div
                    className={`b-language__drop-down ${this.state.isOpen ? 'opened' : ''}`}
                    ref={(dropDown) => (this.dropDown = dropDown)}
                  >
                    {this.renderLanguages()}
                  </div>
                </li>

                <li className="b-header__item">
                  <Link to={'/register'}>
                    <span>{texts.SIGN_UP_FOR_FREE_HEADER}</span>
                  </Link>
                </li>

                <li className="b-header__item">
                  <span onClick={() => this.props.togglePopup('logInPopupIsOpen', true)}>{texts.LOGIN_HEADER}</span>
                </li>
              </ul>
            </nav>
          </div>
        ) : (
          <div className="b-header__container m-header__container_auth g-container g-flex">
            <Link className="m-header__logo_auth" to={'/vouchers'}>
              <img src={LOGO_LOGIN} width={163} height={32} alt="Golf More" />
            </Link>
            <nav className="b-header__nav">
              <ul className="b-header__list g-clear-fix">
                <li className="b-header__item m-header__item_auth">
                  <Link activeClassName={'active'} to={'vouchers'}>
                    {texts.MY_VOUCHERS}
                  </Link>
                </li>
                <li className="b-header__item m-header__item_auth">
                  <Link activeClassName={'active'} to={'profile'}>
                    {texts.MY_PROFILE}
                  </Link>
                </li>
                <li className="b-header__item m-header__item_auth">
                  <span onClick={this.logOut}>{texts.SIGN_OUT}</span>
                </li>
              </ul>
            </nav>
          </div>
        )}
      </header>
    );
  }
}

export default Header;

Header.propTypes = {
  togglePopup: propTypes.func.isRequired,
  location: propTypes.string.isRequired,
  handleLogIn: propTypes.func.isRequired,
  userIsAuthenticated: propTypes.bool.isRequired,
};
