import React, { Component } from 'react';
import propTypes from 'prop-types';

import texts from '../../constants/languages';

import Voucher from './voucher';
import VoucherDetails from './voucherDetails';
import Sort from '../../components/templates/sort';
import { formatDate } from '../../utils/datesFormats';
import { printedVouchersToDisplay, notPrintedVouchersToDisplay } from '../../utils/vouchersCounter';

import './vouchers.css';

const { number, func, bool, string, arrayOf, object, shape } = propTypes;

class Vouchers extends Component {
  static propTypes = {
    currentTab: string.isRequired,
    location: string.isRequired,
    // vouchersCount: number.isRequired,
    // currentPage: number.isRequired,
    // getVouchers: func.isRequired,
    // setCurrentPage: func.isRequired,
    changeSearchText: func.isRequired,
    onDonePress: func.isRequired,
    toggleTabFilter: func.isRequired,
    // setSpinnerFlag: func.isRequired,
    // setCurrentVoucher: func.isRequired,
    setVouchers: func.isRequired,
    setVouchersCount: func.isRequired,
    // togglePopup: func.isRequired,
    // printPDF: func.isRequired,
    // toggleFavourites: func.isRequired,
    getFavouriteVouchers: func.isRequired,
    getPrintedVouchers: func.isRequired,
    getAllVouchers: func.isRequired,
    setSortOption: func.isRequired,
    // sortAlphabet: func,
    // toggleLogOutWarn: func.isRequired,
    // logOut: func.isRequired,
    // logOutWarn: bool.isRequired,
    // isSearchActive: bool,
    voucherDetailsPopupIsOpen: bool.isRequired,
    printRulesAccepted: bool,
    // searchInput: string.isRequired,
    vouchersSortOption: string.isRequired,
    vouchers: arrayOf(object).isRequired,
    allVouchers: arrayOf(object).isRequired,
    favouriteVouchers: arrayOf(object).isRequired,
    printedVouchers: arrayOf(object).isRequired,
    currentVoucher: shape({}).isRequired,
    currentLanguage: string,
    // displayed: bool,
  };

  static defaultProps = {
    printRulesAccepted: false,
    currentLanguage: 'danish',
    sortAlphabet: () => null,
    isSearchActive: false,
    currentVoucher: {},
    userData: {},
  };
  state = {
    allFavourites: [],
    currentFavourites: [],
    currentPrinted: [],
    currentPageAll: '',
    currentPageFavourite: '',
    currentPagePrinted: '',
    searchValue: '',
    printId: null,
  };

  componentDidMount() {
    const { getAllVouchers, getFavouriteVouchers, getPrintedVouchers, toggleTabFilter } = this.props;
    getFavouriteVouchers();
    getAllVouchers();
    getPrintedVouchers();
    toggleTabFilter('printed');
  }

  componentWillUnmount() {
    this.props.changeSearchText('');
  }

  convertTime = (validFrom, validTo) =>
    texts.formatString(
      texts.VOUCHER_VALIDATION_TIME,
      formatDate(validFrom, this.props.currentLanguage),
      formatDate(validTo, this.props.currentLanguage),
    );

  handleVouchersSet = (vouchersList) => {
    const { onDonePress, setVouchersCount, setVouchers, vouchersSortOption } = this.props;
    setVouchersCount(vouchersList.length); // set vouchers length for pagination
    setVouchers(vouchersList);
    onDonePress(vouchersSortOption, vouchersList);
  };

  toggleTabFilter = (tabName) => {
    const {
      toggleTabFilter,
      setSortOption,
      favouriteVouchers,
      printedVouchers,
      allVouchers,
      getFavouriteVouchers,
      getPrintedVouchers,
    } = this.props;

    toggleTabFilter(tabName);
    setSortOption('Club A-Z');
    getPrintedVouchers();
    getFavouriteVouchers();
    if (tabName === 'all') {
      this.handleVouchersSet(allVouchers);
    } else if (tabName === 'printed') {
      this.handleVouchersSet(printedVouchers);
    } else if (tabName === 'favourites') {
      this.handleVouchersSet(favouriteVouchers);
    }
    this.setState({
      currentPageFavourite: 0,
      currentPagePrinted: 0,
      currentPageAll: 0,
      searchValue: '',
    });
  };

  onChangeText = (event) => {
    const { value } = event.target;
    const { currentTab, allVouchers, printedVouchers } = this.props;
    const searchQuery = value.toLowerCase();
    const vouchers = currentTab === 'all' ? allVouchers : printedVouchers;
    const searchedVouchers = vouchers.filter((voucher) => {
      const searchValue = `${voucher.name}${voucher.owner_name}`;
      return searchValue.toLowerCase().indexOf(searchQuery) !== -1;
    });
    this.handleVouchersSet(searchedVouchers);
    this.setState({
      searchValue: value,
      currentPageAll: 0,
      currentPagePrinted: 0,
      currentPageFavourite: 0,
    });
  };

  handleForceUpdate = () => {
    if (this.props.currentTab === 'favourites') {
      this.toggleTabFilter('favourites');
    }

    if (this.props.currentTab === 'printed') {
      this.toggleTabFilter('printed');
    }

    if (this.props.currentTab === 'all') {
      this.toggleTabFilter('all');
    }
  };

  renderVoucherDetails = () => {
    const { currentVoucher } = this.props;

    return (
      <VoucherDetails
        voucherTitle={currentVoucher.owner_name}
        ticketDesk={currentVoucher.name}
        validationTime={this.convertTime(currentVoucher.valid_from, currentVoucher.valid_to)}
        validTimes={currentVoucher.valid_times}
        reductionType={currentVoucher.reduction_type}
        price={currentVoucher.value}
        status={currentVoucher.type}
        image={currentVoucher.logo_url}
        validTimeFrom={currentVoucher.valid_time_from}
        validTimeTo={currentVoucher.valid_time_to}
        validWeekdays={currentVoucher.validWeekdays}
        validTimesPerClub={currentVoucher.valid_times_per_club}
        requiresMemberNumber={currentVoucher.requires_member_number}
        favourite={currentVoucher.favourite}
        voucherId={currentVoucher.voucher_id}
        info={currentVoucher.info}
        printed={currentVoucher.pdf}
        active={currentVoucher.active}
        forceUpdate={this.handleForceUpdate}
      />
    );
  };

  renderVouchers = (vouchers, displayed, props) =>
    vouchers
      .filter(({ type }) => type !== 'ecard')
      .map((data) => {
        return (
          <Voucher
            key={data.voucher_id}
            voucher={data}
            count={data.count}
            voucherTitle={data.owner_name}
            ticketDesk={data.name}
            validationTime={this.convertTime(data.valid_from, data.valid_to)}
            validTimes={data.valid_times}
            reductionType={data.reduction_type}
            price={data.value}
            status={data.type}
            voucherID={data.voucher_id}
            image={data.image_url}
            vouchers={vouchers}
            printed={data.pdf}
            displayed={displayed}
            active={data.active}
            {...props}
            printRules={this.props.printRulesAccepted}
          />
        );
      });

  render() {
    const { onDonePress, vouchers, currentTab, vouchersSortOption, setSortOption, location } = this.props;

    // console.log(vouchers);

    return (
      <div className="b-vouchers g-auth-content">
        <div className="container grid-xl b-vouchers__container">
          <div className="columns b-vouchers__options g-flex">
            <div className="column col-2 col-md-6 col-sm-6 col-xs-10">
              <div className="b-search">
                <div className="b-search__icon" />
                <input
                  className="b-search__input"
                  type="text"
                  placeholder={texts.SEARCH}
                  value={this.state.searchValue}
                  onChange={this.onChangeText}
                />
              </div>
            </div>
            <div className="column col-10 col-md-12 col-sm-12 col-ml-auto b-vouchers__filters g-flex">
              <Sort
                onDonePress={(selectedOption) => onDonePress(selectedOption, vouchers)}
                selectedOption={vouchersSortOption}
                setSortOption={setSortOption}
                locale={location}
              />
              <div className="b-vouchers__filter-count g-flex">
                <input
                  type="button"
                  value={texts.NOT_PRINTED}
                  className={`b-vouchers__filter-count-btn ${currentTab === 'printed' ? 'active' : ''}`}
                  onClick={() => this.toggleTabFilter('printed')}
                />
                <input
                  type="button"
                  value={texts.ALL}
                  className={`b-vouchers__filter-count-btn ${currentTab === 'all' ? 'active' : ''}`}
                  onClick={() => this.toggleTabFilter('all')}
                />
                <input
                  type="button"
                  value={texts.FAVORITES}
                  className={`b-vouchers__filter-count-btn ${currentTab === 'favourites' ? 'active' : ''}`}
                  onClick={() => this.toggleTabFilter('favourites')}
                />
              </div>
            </div>
          </div>
          <div className="columns b-vouchers__list g-flex">
            {this.renderVouchers(notPrintedVouchersToDisplay(vouchers), false, this.props)}
            {this.renderVouchers(printedVouchersToDisplay(vouchers), true, this.props)}
          </div>
        </div>
        {this.props.voucherDetailsPopupIsOpen && this.renderVoucherDetails()}
      </div>
    );
  }
}

export default Vouchers;
