// @flow
import haversine from 'haversine';
import { NEARBY_RADIUS } from '../constants/geo';

export function findClubDistances(latitude: number, longitude: number, clubs: Array<Object>) {
  const res = {};
  const position = { latitude, longitude };
  clubs.forEach(club => {
    res[club.club_id] = haversine(position, club);
  });
  return res;
}

export function findDepartmentDistances(latitude: number, longitude: number, departments: Array<Object>) {
  const res = {};
  const position = { latitude, longitude };
  departments.forEach(club => {
    res[club.department_id] = haversine(position, club);
  });
  return res;
}

/**
 * Find the closest and nearby club ids.
 * closest club is the club with the smallest distance.
 * Nearby club is the same as closest if it's in NEARBY_RADIUS radius. Otherwise nearby club is null.
 * @param latitude
 * @param longitude
 * @param clubs
 * @returns {{closest: string, nearby: string}}
 */
export const findClosestClub = (latitude: number, longitude: number, clubs: Array<Object>) => {
  const distances = findClubDistances(latitude, longitude, clubs);
  let closest = null;
  let dist = Infinity;
  if (Object.values(distances).length === 0) {
    return { closest: null, nearby: null };
  }

  Object.keys(distances).forEach((clubId: string) => {
    if (distances[clubId] < dist) {
      closest = clubId;
      dist = distances[clubId];
    }
  });

  closest = clubs.find(({ club_id }) => {
    return String(club_id) === closest;
  });

  let nearby = null;
  if (dist <= NEARBY_RADIUS / 1000) {
    nearby = closest;
  }
  return { closest, nearby };
};

export function findMinDistance(clubsIds: Array<number>, distances: { [string]: number }): number {
  let minDistance = Infinity;
  clubsIds.forEach(clubId => {
    const distance = distances[clubId];
    if (distance < minDistance) {
      minDistance = distance;
    }
  });

  return minDistance;
}

export const sortVouchers = (clubs, vouchers, latitude, longitude) => {
  if (latitude == null) {
    return vouchers;
  }

  if (clubs.length > 0) {
    const distances = findDepartmentDistances(latitude, longitude, clubs);
    const voucherDistances = vouchers.map(v => {
      const voucher = v[0];
      const minDistance = findMinDistance(voucher.where || [], distances);
      voucher.distance = minDistance;
      return {
        minDistance,
        vouchers: v,
      };
    });
    return voucherDistances
      .sort((a, b) => a.minDistance - b.minDistance)
      .map(({ vouchers }) => vouchers);
  }
  return vouchers;
};

export const isCloseForDispense = ({ lat: latitude, lng: longitude }, club) => {
  return haversine({ latitude, longitude }, club, {
    unit: 'meter',
    threshold: NEARBY_RADIUS,
  });
};

/* for (let i = 0; i < clubsIds.length; i += 1) {
      const curClub = clubs.find(club => club.club_id === clubsIds[i]);
      if (curClub) {
        const latitudeClub = curClub.latitude;
        const longitudeClub = curClub.longitude;
        const newAlgo = true;
        if (latitudeClub && longitudeClub) {
          let clubDistanceKey;
          if (newAlgo) {
            const position = { latitude, longitude };
            clubDistanceKey = haversine(position, curClub);
          } else {
            const latitudeDelta = +latitude - latitudeClub;
            const longitudeDelta = +latitude - longitudeClub;
            clubDistanceKey = latitudeDelta * latitudeDelta + longitudeDelta * longitudeDelta;
          }
          //console.log('Curclub', curClub.name, clubDistanceKey);
          if (clubDistanceKey < minDistanceKey) {
            minDistanceKey = clubDistanceKey;
          }
        }
      } else {
        console.log("Warning, club not found");
      }
    } */
