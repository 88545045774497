// import DeviceInfo from 'react-native-device-info';
// import * as RNLocalize from 'react-native-localize';
import { supportedLocales } from '../constants/languages';

// @flow
export default function guessUnionByLocale(unions, values) {
  if (unions.length && !values.union_id) {
    let indexOfDgu;
    let indexOfSgf;
    let indexOfNgf;

    for (let i = 0; i < unions.length; i++) {
      unions[i].tid === 'dgu' ? (indexOfDgu = i) : unions[i].tid === 'sgf' ? (indexOfSgf = i) : (indexOfNgf = i);
    }

    const best = supportedLocales[0]; // RNLocalize.findBestAvailableLanguage(supportedLocales);

    const bestLocale = supportedLocales[0];

    console.log('deviceLocal = ', bestLocale);

    // if (Platform.OS === 'ios') {
    if (bestLocale === 'da') {
      return unions[indexOfDgu];
    } else if (bestLocale === 'sv') {
      return unions[indexOfSgf];
    } else if (bestLocale === 'nb') {
      return unions[indexOfNgf];
    } else if (bestLocale === 'no') {
      return unions[indexOfNgf];
    }
    return unions[indexOfDgu];
  } else if (bestLocale === 'sv-SE') {
    return unions[indexOfSgf];
  } else if (bestLocale === 'nb-NO') {
    return unions[indexOfNgf];
  }
  return unions[indexOfDgu];
}
