// @flow
import React, { Component } from 'react';
import { View, Text, Keyboard, Platform } from 'react-native';
import i18n from 'react-native-i18n';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import type { NavigationState, NavigationScreenProp } from 'react-navigation';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import yup from 'yup';
import compose from 'lodash/fp/compose';
import get from 'lodash/get';
import moment from 'moment';

import type { Union } from '../../../clubs/api/club';
import { getUnions } from '../../../clubs/modules';
import { setRegisteringData } from '../../../user/modules';
import api from '../../../api';
import config from '../../../constants/config';

import Picker from '../../../components/Picker/Picker';
import DatePicker from '../../../components/DatePicker/DatePicker';
import Input from '../../../components/FormInput';
import styles, { colorGray } from './styles';
import guessUnionByLocale from '../../../helpers/guessUnionByLocale';
import BigPane from '../../../components/BigPane/BigPane';
import NewButton from '../../../components/Button/NewButton';
import { arrowNext } from '../../../assets/images';
import RegisterStepsPane from '../../components/RegisterStepsPane';
import history from '../../../../history';

type Dict = { [key: string]: * };

type Params = NavigationState & {
  step: number,
  steps: number,
  title: string,
};

type Props = {
  navigation: NavigationScreenProp<Params>,
  unions: Array<Union>,

  setFieldValue: (name: string, value: *) => void,
  setFieldTouched: (name: string, value: *) => void,
  handleSubmit: (event: Event<>) => void,
  values: Dict,
  errors: Dict,
  touched: Dict,

  getUnions: typeof getUnions,
};

type State = {
  submitClicked: boolean,
};

const KEYBOARD_TYPE = Platform.OS === 'ios' ? 'numbers-and-punctuation' : 'default';

class UnionSelect extends Component<Props, State> {
  static navigationOptions = () => ({ header: null });
  static schema = yup.object().shape({
    member_number: yup
      .string()
      .required('signUpStepOne.emptyUnionNumberField')
      .matches(/^\d+-\d+$/, 'signUpStepOne.invalidMemberNumber'),
    birth_date: yup.string().required('signUpStepOne.emptyBirthDateField'),
  });
  state = {
    submitClicked: false,
  };

  idPicker: ?React$ElementRef<typeof Input>;
  numberInput: ?React$ElementRef<typeof Input>;
  bdayPicker: ?React$ElementRef<typeof DatePicker>;

  componentDidMount() {
    this.props.getUnions();
    const step = this.props.navigation.getParam('step');
    if (step == null) {
      console.error('UnionSelect: Missing params, most likely page was refreshed, going to regitsration root');
      history.push('/register');
    }
  }

  componentWillReceiveProps({ unions, values }) {
    if (unions.length && !values.union_id) {
      const union = guessUnionByLocale(unions, values);
      if (union !== null) {
        this.setUnionId(union);
      }
    }
  }

  setUnionMemberNo = (memberNumber: string) => {
    this.props.setFieldValue('member_number', memberNumber);
    this.props.setFieldTouched('member_number', true);
  };

  setUnionId = (union: Union) => {
    this.props.setFieldValue('union_id', union.union_id);
    this.props.setFieldTouched('union_id', true);
  };

  setBirthDate = (date: Date) => {
    this.props.setFieldValue('birth_date', date);
    this.props.setFieldTouched('birth_date', true);
  };

  next = (evt) => {
    Keyboard.dismiss();
    this.setState({ submitClicked: true });
    this.props.handleSubmit(evt);
  };

  handleInputFocus = (field) => {
    if (field !== 'union_number') {
      this.numberInput?.blur();
    }
    if (field !== 'birth_date') {
      this.bdayPicker?.close();
    }
    if (this.idPicker?.shown) {
      this.idPicker?.backPressHandler();
    }
  };

  render() {
    const { values, errors, touched, navigation, isNarrow } = this.props;
    const { submitClicked } = this.state;
    const step = navigation.getParam('step');
    const steps = navigation.getParam('steps');

    return (
      <View style={isNarrow ? styles.rootNarrow : null}>
        <RegisterStepsPane stepCount={steps} step={step} />
        <KeyboardAwareScrollView style={styles.screen} keyboardShouldPersistTaps="handled">
          <BigPane style={styles.registrationForm}>
            <Picker
              label={i18n.t('signUpStepOne.Union')}
              keyName="union_id"
              labelName="name"
              data={this.props.unions}
              value={values.union_id}
              onChange={this.setUnionId}
              defaultText={i18n.t('picker.select')}
              titleText={i18n.t('signUpStepOne.chose')}
              confirmText={i18n.t('picker.confirm')}
              cancelText={i18n.t('picker.cancel')}
              ref={(idPicker) => {
                this.idPicker = idPicker;
              }}
            />
            <Input
              label={i18n.t('signUpStepOne.golfId')}
              innerRef={(input) => {
                this.numberInput = input;
              }}
              placeholder={i18n.t('signUpStepOne.UnionNumberPlaceholder')}
              placeholderTextColor={colorGray}
              keyboardType={KEYBOARD_TYPE}
              autoCorrect={false}
              value={values.member_number}
              onChangeText={this.setUnionMemberNo}
              onFocus={() => {
                this.handleInputFocus('union_number');
              }}
            />
            {submitClicked && touched.member_number && errors.member_number ? (
              <Text style={styles.errorMessage}>
                {i18n.t(errors.member_number, { defaultValue: errors.member_number })}
              </Text>
            ) : null}
            <DatePicker
              label={i18n.t('signUpStepOne.Birthday')}
              value={values.birth_date}
              onChange={this.setBirthDate}
              maximumDate={new Date()}
              defaultDate={config.defaultRegistrationDate}
              defaultText={i18n.t('signUpStepOne.birthDateLabel')}
              ref={(datePicker) => {
                this.bdayPicker = datePicker;
              }}
              onOpen={() => {
                this.handleInputFocus('birth_date');
              }}
            />
            {submitClicked && touched.birth_date && errors.birth_date ? (
              <Text style={styles.errorMessage}>{i18n.t(errors.birth_date)}</Text>
            ) : null}
            <View style={{ padding: 16 }} />
            <View style={{ width: '100%', flexDirection: 'row-reverse' }}>
              <View style={{ width: '30%' }}>
                <NewButton text={i18n.t('signUpStepOne.bnNext')} imageRight={arrowNext} onPress={this.next} />
              </View>
            </View>
          </BigPane>
        </KeyboardAwareScrollView>
      </View>
    );
  }
}

const UnionMemberForm = UnionSelect;
export default compose(
  connect(
    ({ club, locale, user }) => ({
      unions: club.unions.data,
      locale: locale.locale,
      registeringData: user.registering.data,
    }),
    { getUnions, setRegisteringData },
  ),
  withFormik({
    mapPropsToValues({ registeringData }) {
      return {
        member_number: registeringData.member_number || null,
        union_id: registeringData.union_id || null,
        birth_date: registeringData.birth_date ? moment(registeringData.birth_date, 'YYYYMMDD').toDate() : null,
      };
    },
    validationSchema: UnionSelect.schema,
    validateOnChange: true,
    async handleSubmit(values, { props, setFieldError }) {
      try {
        const locale = props.locale === 'da' ? 'dk' : props.locale;
        const result = await api().user.checkUnion(values.union_id, values.member_number, values.birth_date, locale);
        console.log('CheckUnion', result);
        props.setRegisteringData({ ...result.data, ...props.registeringData, ...values });
        props.navigation.navigate('SignUp', {
          nonUnion: false,
          step: 2,
          steps: 3,
          title: i18n.t('signUpSelect.unionMember'),
        });
      } catch (err) {
        if (get(err, 'response.status') === 422) {
          const errors = get(err, 'response.data', []);
          for (const error of errors) {
            setFieldError(error.field, error.message);
          }
        } else {
          setFieldError('member_number', 'common.noInternetConnection');
        }
      }
    },
  }),
)(UnionMemberForm);
