import React, { Component } from 'react';
import propTypes from 'prop-types';
import browserHistory from '../../../../history';

import texts from '../../../../constants/languages';

class PasswordChangeConfirm extends Component {
  closePopupOnClickOutSide = (event) => {
    if (!this.popup.contains(event.target)) {
      this.props.togglePopup('changePasswordConfirmPopupIsOpen', false);
    }
  };

  pushToHomePage = () => {
    browserHistory.push('/');
    window.location.reload(); // for restart added view port content
  };

  render() {
    return (
      <div
        className="g-popup-wrap"
        onClick={(event) => this.closePopupOnClickOutSide(event)} // close popup
      >
        <div className="g-popup-overlay">&nbsp;</div>
        <div className="g-popup-wrap">
          <table className="g-popup-wrap__container">
            <tbody>
              <tr>
                <td className="g-popup-wrap__container-inner">
                  <div className="g-popup" ref={(popup) => (this.popup = popup)}>
                    <div
                      className="g-popup__close-btn"
                      onClick={() => this.props.togglePopup('changePasswordConfirmPopupIsOpen', false)} // close popup
                    >
                      &nbsp;
                    </div>
                    <div className="g-popup__content">
                      <form className="g-form" action="#">
                        <p className="g-form__paragraph">{texts.PASSWORD_CHANGE_CONFIRM_TEXT}</p>
                        <div className="g-form__btn-holder m-form__btn-holder_confirm g-clear-fix">
                          <button
                            className="g-form__btn m-form__btn_confirm g-submit-btn"
                            onClick={this.pushToHomePage}
                          >
                            {texts.GOT_IT}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default PasswordChangeConfirm;

PasswordChangeConfirm.propTypes = {
  togglePopup: propTypes.func.isRequired,
};
