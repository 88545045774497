import faqConstants from './faqConstants';
import faqService from './api/faqService';

const setAllFaqsItems = (faqsItems) => ({
  type: faqConstants.SET_FAQS_ITEMS,
  faqsItems,
});

const setAllFaqsCategories = (faqsCategories) => ({
  type: faqConstants.SET_FAQS_CATEGORIES,
  faqsCategories,
});

const setAllFaqsByCategory = (faqsByCategory) => ({
  type: faqConstants.SET_FAQS_BY_CATEGORY,
  faqsByCategory,
});

const getFaqsItems = (locale) => async (dispatch) => {
  try {
    const response = await faqService.getFaqsItems(locale);
    return dispatch(setAllFaqsItems(response.data));
  } catch (e) {
    return dispatch(setAllFaqsItems([]));
  }
};

const getFaqsCategories = (locale) => async (dispatch) => {
  try {
    const response = await faqService.getFaqsCategories(locale);
    return dispatch(setAllFaqsCategories(response.data));
  } catch (e) {
    return dispatch(setAllFaqsCategories([]));
  }
};

const getFaqsByCategory = () => async (dispatch) => {
  try {
    const response = await faqService.getFaqsByCategory();
    return dispatch(setAllFaqsByCategory(response.data));
  } catch (e) {
    return dispatch(setAllFaqsByCategory([]));
  }
};

export default {
  getFaqsItems,
  getFaqsCategories,
  getFaqsByCategory,
};
