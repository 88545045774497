import React from 'react';
import { Text, View, TouchableOpacity } from 'react-native';

import styles from './styles';

type Props = {
  options: { [string]: string },
  selected: string,
  onPress: (string) => void,
};

function SecondHeader({ options, selected, onPress }: Props) {
  const optionsArray = Object.keys(options);
  return (
    <View style={styles.secondHeader}>
      {optionsArray.map((k) => (
        <TouchableOpacity
          key={k}
          onPress={() => {
            onPress(k);
          }}
          style={[styles.tab, selected === k ? styles.activeTab : {}]}
        >
          <Text style={[styles.tabText, selected === k ? styles.activeTabText : {}]}>{options[k]}</Text>
        </TouchableOpacity>
      ))}
    </View>
  );
}

export default SecondHeader;
