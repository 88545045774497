import React, { Component } from 'react';
import propTypes from 'prop-types';
import './passwordChange.css';

import texts from '../../../../constants/languages';
import { patterns } from '../../../../patterns/patterns';

import PasswordChangeConfirm from '../passwordChangeConfirm';
import TextField from '../../../../components/templates/form/textField';

class PasswordChange extends Component {
  state = {
    fieldsPatterns: {
      password: patterns.password,
    },
    userData: {
      password: '',
      repeat_password: '',
      token: '', // only for send to server
    },
    fieldsIsEmpty: {
      password: false,
      repeat_password: false,
    },
    fieldsIsInvalid: {
      password: false,
      repeat_password: false,
    },
    fieldsHaveServerErrors: {
      password: false,
      repeat_password: false,
    },
    serverErrorsMessages: {
      password: '',
      repeat_password: '',
    },
  };

  requiredFields = ['password', 'repeat_password'];

  componentDidMount() {
    this.getTokenFromUrl();
  }

  getTokenFromUrl = () => {
    const urlArray = window.location.href.split('?key=');
    const token = urlArray[1];

    this.changeInputsState('userData', 'token', token);
  };

  changeInputsState = (dataName, valueName, value) => {
    this.setState((state) => {
      const obj = { ...state };
      if (value === undefined) {
        obj[dataName][valueName] = '';
      } else {
        obj[dataName][valueName] = value;
      }
      return obj;
    });
  };

  validateField = (fieldName, fieldsValueData, fieldsIsEmptyData, fieldsIsInvalidData, patternsData, errorsCounter) => {
    if (this.requiredFields.indexOf(fieldName) >= 0) {
      if (fieldsValueData[fieldName].length) {
        this.changeInputsState('fieldsIsEmpty', fieldName, false); // field is not empty
        if (fieldName === 'repeat_password') {
          if (fieldsValueData[fieldName] === fieldsValueData['password']) {
            this.changeInputsState('fieldsIsInvalid', fieldName, false); // repeat password is valid
          } else {
            this.changeInputsState('fieldsIsInvalid', fieldName, true); // repeat password is invalid
            errorsCounter.push(fieldName);
          }
        } else {
          if (!patternsData[fieldName].test(fieldsValueData[fieldName])) {
            this.changeInputsState('fieldsIsInvalid', fieldName, true); // field is invalid
            errorsCounter.push(fieldName);
          } else {
            this.changeInputsState('fieldsIsInvalid', fieldName, false); // field is valid
          }
        }
      } else {
        this.changeInputsState('fieldsIsEmpty', fieldName, true); // field is empty
        errorsCounter.push(fieldName);
      }
    }
  };

  cleanServerErrors = (serverErrorsData, serverErrorsDataName) => {
    for (let field in serverErrorsData) {
      this.changeInputsState(serverErrorsDataName, field, false);
    }
  };

  setServerErrors = (errorData) => {
    this.changeInputsState('fieldsHaveServerErrors', 'password', true);
    this.changeInputsState('serverErrorsMessages', 'password', errorData.message);
  };

  validateForm = (fieldsValueData, fieldsIsEmptyData, fieldsIsInvalidData, patternsData, errorsCounter) => {
    for (let field in fieldsValueData) {
      this.validateField(field, fieldsValueData, fieldsIsEmptyData, fieldsIsInvalidData, patternsData, errorsCounter);
    }
    this.cleanServerErrors(this.state.fieldsHaveServerErrors, 'fieldsHaveServerErrors');
  };

  createDataForRequest = (userData, fieldsForRequest, newUserData) => {
    for (const fieldName in userData) {
      if (fieldsForRequest.indexOf(fieldName) >= 0) {
        newUserData[fieldName] = userData[fieldName];
      }
    }
  };

  submitForm = (event) => {
    event.preventDefault();

    const fieldsForSendToServer = ['password', 'token'];
    const dataForRequest = {};
    const errorsCounter = [];
    this.validateForm(
      this.state.userData,
      this.state.fieldsIsEmpty,
      this.state.fieldsIsInvalid,
      this.state.fieldsPatterns,
      errorsCounter,
    );
    if (!errorsCounter.length) {
      this.createDataForRequest(this.state.userData, fieldsForSendToServer, dataForRequest);
      this.props.changePassword(dataForRequest, this.setServerErrors);
    }
  };

  render() {
    return (
      <div className="b-password-change">
        <div className="b-password-change__container">
          <form className="b-password-change__form g-form" action="#">
            <h2 className="b-password-change__title g-form__title">{texts.CHANGE_PASSWORD}</h2>
            <TextField
              valueName="password"
              value={this.state.userData.password}
              label={texts.PASSWORD}
              type="password"
              placeholder={texts.ENTER_YOUR_NEW_PASSWORD}
              onChange={(name, value) => this.changeInputsState('userData', name, value)}
              errorText={[
                texts.PLEASE_ENTER_PASSWORD,
                texts.PLEASE_ENTER_VALID_PASSWORD,
                this.state.serverErrorsMessages.password,
              ]}
              isEmpty={this.state.fieldsIsEmpty.password}
              isInvalid={this.state.fieldsIsInvalid.password}
              haveServerError={this.state.fieldsHaveServerErrors.password}
            />
            <TextField
              valueName="repeat_password"
              value={this.state.userData.repeat_password}
              label={texts.REPEAT_PASSWORD}
              type="password"
              placeholder={texts.REPEAT_YOUR_NEW_PASSWORD}
              onChange={(name, value) => this.changeInputsState('userData', name, value)}
              errorText={[texts.PLEASE_REPEAT_YOUR_PASSWORD, texts.CONFIRMATION_PASSWORD_DOESNT_MATCH_THE_PASSWORD]}
              isEmpty={this.state.fieldsIsEmpty.repeat_password}
              isInvalid={this.state.fieldsIsInvalid.repeat_password}
              haveServerError={this.state.fieldsHaveServerErrors.repeat_password}
            />
            <div className="b-password-change__options g-form__options g-clear-fix">
              <div className="b-password-change__btn-holder g-form__btn-holder m-form__btn-holder_right">
                <button className="b-password-change__btn g-form__btn g-submit-btn" onClick={this.submitForm}>
                  {texts.CONFIRM}
                </button>
              </div>
            </div>
          </form>
        </div>
        {this.props.changePasswordConfirmPopupIsOpen ? (
          <PasswordChangeConfirm togglePopup={this.props.togglePopup} />
        ) : (
          <div></div>
        )}
      </div>
    );
  }
}

export default PasswordChange;

PasswordChange.propTypes = {
  changePasswordConfirmPopupIsOpen: propTypes.bool.isRequired,
  changePassword: propTypes.func.isRequired,
  togglePopup: propTypes.func.isRequired,
};
