// @flow
import { takeEvery, put } from 'redux-saga/effects';

import { loginImportedToken } from '../../auth/modules/session';
import { REGISTER_USER_SUCCESS } from '../../user/modules/registering';
import history from '../../../history';
import { handleLogIn } from '../../../domains/auth/actions';

function* goToVerify(data) {
  console.log('GTV', data);
  const { session_id } = data?.result?.data;
  console.log(session_id);
  if (session_id) {
    localStorage.setItem('userToken', session_id);
    yield put(loginImportedToken(session_id));
    yield put(handleLogIn(true));
  }
}

export default function* (): Generator<*, *, *> {
  yield takeEvery(REGISTER_USER_SUCCESS, goToVerify);
}
