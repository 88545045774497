import React from 'react';
import { connect } from 'react-redux';

import privacyPolicyDenmark from '../privacyPolicy/privacyPolicyContent/privacyPolicyDenmark';
import privacyPolicyEnglish from '../privacyPolicy/privacyPolicyContent/privacyPolicyEnglish';
import privacyPolicyGerman from '../privacyPolicy/privacyPolicyContent/privacyPolicyGerman';
import privacyPolicyNorway from '../privacyPolicy/privacyPolicyContent/privacyPolicyNorway';
import privacyPolicySweden from '../privacyPolicy/privacyPolicyContent/privacyPolicySweden';

import './privacyPolicy.css';

class PrivacyPolicyMobile extends React.Component {
  render() {
    return (
      <div className="b-privacy-policy g-auth-content">
        <div className="b-privacy-policy-mobile__container g-container container grid-xl">
          <div className="columns">
            <div className="column col-12">
              {this.props.location === 'danish' && privacyPolicyDenmark()}
              {this.props.location === 'english' && privacyPolicyEnglish()}
              {this.props.location === 'norwegian' && privacyPolicyNorway()}
              {this.props.location === 'swedish' && privacyPolicySweden()}
              {this.props.location === 'german' && privacyPolicyGerman()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    location: state.appReducer.get('location'),
  };
}

export default connect(mapStateToProps, null)(PrivacyPolicyMobile);
