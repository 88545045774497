// @flow
import React, { Component } from 'react';
import { View, Text, Keyboard } from 'react-native';
import i18n from 'react-native-i18n';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import type { NavigationState, NavigationScreenProp } from 'react-navigation';
import DoneBar from 'done-bar';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import yup from 'yup';
import compose from 'lodash/fp/compose';
import memoize from 'lodash/memoize';
import pick from 'lodash/pick';
import get from 'lodash/get';
import throttle from 'lodash/throttle';

import Header from '../../../components/Header/Header';
import RegisterSteps from '../../components/RegisterSteps';
import Button from '../../../components/Button';
import type { User } from '../../../user/api/user';
import { registerUser } from '../../../user/modules/registering';
import Input from '../../../components/FormInput';
import styles, { colorGray, Separator } from './styles';
import RegisterStepsPane from '../../components/RegisterStepsPane';
import history from '../../../../history';

type Dict = { [key: string]: * };

type InputProps = {
  field: string,
  label: string,
  placeholder?: string,
  keyboardType?: string,
  autoCorrect?: string,
};

type Params = NavigationState & {
  step: number,
  steps: number,
  title: string,
  nonUnion: boolean,
};

type Props = {
  navigation: NavigationScreenProp<Params>,
  registeringData: $Shape<User>,
  setFieldValue: (name: string, value: *) => void,
  setFieldTouched: (name: string, value: *) => void,
  handleSubmit: (event: Event<>) => void,
  values: Dict,
  errors: Dict,
  touched: Dict,
};

type State = {
  flagKeyboardShown: boolean,
  keyboardType: string,
  submitClicked: boolean,
};

const ALLOWED_FIELDS = [
  'union_id',
  'member_number',
  'first_name',
  'last_name',
  'sex',
  'hcp',
  'country',
  'club_id',
  'club_name',
  'birth_date',
  'email',
  'phone',
  'phone2',
  'language_default',
  'password',
  'accept_newsletter',
  'accept_offers',
  'member_guid',
  'imported_union_user',
];

class SignUpFinish extends Component<Props, State> {
  keyboardDidShowListener: Function;
  keyboardDidHideListener: Function;
  showErrorMessage: boolean;
  emailInput: ?React$ElementRef<typeof Input>;
  passwordInput: ?React$ElementRef<typeof Input>;
  confirmationInput: ?React$ElementRef<typeof Input>;
  scroll: Object;

  static navigationOptions = () => {
    return {
      header: null,
    };
  };

  static schema = yup.object().shape({
    email: yup.string().email('signUpStepTwo.invalidEmailField').required('signUpStepTwo.emptyEmailField'),

    emailconfirm: yup
      .string()
      .email('signUpStepTwo.invalidEmailField')
      .required('signUpStepTwo.emptyEmailField')
      .test('sameAs', 'signUpStepTwo.confirmationEmailField', function sameAs(value: string) {
        return value === this.resolve(yup.ref('email'));
      }),

    password: yup.string().required('signUpStepTwo.emptyPasswordField'),
    confirmation: yup
      .string()
      .required('signUpStepTwo.repeatPassPlaceholder')
      .test('sameAs', 'signUpStepTwo.confirmationPasswordField', function sameAs(value: string) {
        return value === this.resolve(yup.ref('password'));
      }),
  });

  // eslint-disable-next-line react/sort-comp
  fieldsPositions = {};
  scrollEl: ?KeyboardAwareScrollView;

  state = {
    flagKeyboardShown: false,
    keyboardType: 'default',
    submitClicked: false,
  };

  componentDidMount() {
    this.keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', this.onKeyboardDidShow);
    this.keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', this.onKeyboardDidHide);

    const registerAutomatically = this.props.navigation.getParam('registerAutomatically');
    if (registerAutomatically) {
      // let's do this to trick validation!
      this.props.setFieldValue('emailconfirm', this.props.values.email);
      this.props.setFieldValue('password', '123456789');
      this.props.setFieldValue('confirmation', '123456789');
    }

    const step = this.props.navigation.getParam('step');
    if (step == null) {
      console.error(
        'PasswordEntry: Missing params, most likely page was refreshed, going to regitsration root',
        window.location.href,
      );
      history.push('/register');
    }
  }

  componentWillReceiveProps(props: Props) {
    this.showErrorMessage = false;
    if (Object.keys(props.errors).length > 0) {
      for (const errorKey of Object.keys(props.errors)) {
        if (props.touched[errorKey]) {
          this.showErrorMessage = true;
        }
      }
    }

    if (this.state.submitClicked && this.showErrorMessage && !this.state.flagKeyboardShown) {
      // console.log('props.errors', this.props.errors, props.errors);
      this.scroll.scrollTo({ x: 0, y: 0, animated: true });
    }

    if (Object.keys(this.props.errors).length || !Object.keys(props.errors).length) {
      return;
    }
    const firstError = Object.keys(props.errors).find((field) => props.touched[field]);
    if (!firstError) {
      return;
    }

    const y = this.fieldsPositions[firstError];
    if (y && this.scrollEl && this.scrollEl.scrollToPosition) {
      // this.scrollEl.scrollToPosition(0, y, true);
    }
  }
  componentWillUnmount() {
    this.keyboardDidShowListener.remove();
    this.keyboardDidHideListener.remove();
  }
  onKeyboardDidShow = () => {
    this.setState({ flagKeyboardShown: true });
  };

  onKeyboardDidHide = () => {
    this.setState({ flagKeyboardShown: false });
  };

  updateField = memoize((field: string, path: string) => {
    return (value) => {
      const val = path ? value[path] : value;
      this.props.setFieldValue(field, val);
      this.props.setFieldTouched(field, true);
    };
  });

  signUp = throttle(
    (evt) => {
      Keyboard.dismiss();
      this.setState({ submitClicked: true });
      this.props.handleSubmit(evt);
    },
    5000,
    { trailing: false },
  );

  setInputRef = (field: string, input) => {
    // console.log("SET INPUT REF", field);
    if (field === 'email') {
      this.emailInput = input;
    } else if (field === 'password') {
      this.passwordInput = input;
    } else if (field === 'confirmation') {
      this.confirmationInput = input;
    }
  };

  focusNextInput = (field: string) => {
    if (field === 'email') {
      this.passwordInput?.focus();
    } else if (field === 'password') {
      this.confirmationInput?.focus();
    } else if (field === 'confirmation') {
      Keyboard.dismiss();
    }
  };

  handleInputFocus = () => {};

  renderInput(props: InputProps) {
    const { values, errors, touched } = this.props;
    const { label, placeholder, field, keyboardType, autoCorrect, ...inputProps } = props;
    return (
      <View
        onLayout={({ nativeEvent }) => {
          this.fieldsPositions[field] = nativeEvent.layout.y;
        }}
      >
        <Input
          label={i18n.t(label)}
          placeholder={i18n.t(placeholder)}
          placeholderTextColor={colorGray}
          value={String(values[field])}
          onChangeText={this.updateField(field)}
          returnKeyType="next"
          innerRef={(input) => {
            this.setInputRef(field, input);
          }}
          onSubmitEditing={() => {
            this.focusNextInput(field);
          }}
          blurOnSubmit={false}
          keyboardType={keyboardType || 'default'}
          autoCorrect={autoCorrect || false}
          onFocus={() => {
            this.handleInputFocus(field);
          }}
          onBlur={() => {
            this.setState({ keyboardType: 'default' });
          }}
          {...inputProps}
        />
        {this.state.submitClicked && touched[field] && errors[field] ? (
          <Text style={styles.errorMessage}>{i18n.t(errors[field], { defaultValue: errors[field] })}</Text>
        ) : null}
      </View>
    );
  }

  renderLoginButton() {
    // TODO dont forget about loadingStyle
    return (
      <View style={{ marginHorizontal: 20 }}>
        <Button
          onPress={this.signUp}
          title={i18n.t('register.createMyAccount')}
          disabled={this.props.registeringData.loading}
          style={styles.buttonStyle}
        />
      </View>
    );
  }

  render() {
    const { isNarrow } = this.props;
    const step = this.props.navigation.getParam('step');
    const steps = this.props.navigation.getParam('steps');

    const registerAutomatically = this.props.navigation.getParam('registerAutomatically');

    return (
      <View style={isNarrow ? styles.rootNarrow : null}>
        <RegisterStepsPane stepCount={steps} step={step} />
        <KeyboardAwareScrollView
          ref={(el) => (this.scrollEl = el)}
          innerRef={(ref) => (this.scroll = ref)}
          style={styles.screen}
          keyboardShouldPersistTaps="handled"
          extraHeight={150}
          enableOnAndroid
        >
          {this.state.submitClicked && this.showErrorMessage ? (
            <Text style={styles.errorMessage}>{i18n.t('signUpStepTwo.errorFields')}</Text>
          ) : null}
          {!registerAutomatically ? (
            <>
              <Separator>
                {this.renderInput({
                  field: 'email',
                  label: 'register.email',
                  // placeholder: 'signUpStepTwo.logInEmailPlaceholder',
                  keyboardType: 'email-address',
                  autoCapitalize: 'none',
                })}

                {this.renderInput({
                  field: 'emailconfirm',
                  label: 'register.confirmEmail',
                  // placeholder: 'signUpStepTwo.logInEmailPlaceholder',
                  keyboardType: 'email-address',
                  autoCapitalize: 'none',
                })}
              </Separator>

              <Separator>
                {this.renderInput({
                  field: 'password',
                  label: 'signUpStepTwo.password',
                  // placeholder: 'signUpStepTwo.GMPasswordPlaceholder',
                  secureTextEntry: true,
                })}

                {this.renderInput({
                  field: 'confirmation',
                  label: 'signUpStepTwo.repeatPass',
                  // placeholder: 'signUpStepTwo.repeatPassPlaceholder',
                  secureTextEntry: true,
                })}
              </Separator>
            </>
          ) : (
            <View style={{ height: 32 }} />
          )}

          {this.renderLoginButton()}

          <View style={styles.agreementContainer}>
            <Text style={styles.agreement}>
              {i18n.t('signUpStepTwo.agreementText')}
              {'\n'}
              <Text style={styles.agreementBold} onPress={() => this.props.navigation.navigate('TermsConditions')}>
                {i18n.t('signUpStepTwo.agreementTC')}
              </Text>
              &nbsp;<Text>{i18n.t('signUpStepTwo.agreementAnd')}</Text>&nbsp;
              <Text style={styles.agreementBold} onPress={() => this.props.navigation.navigate('PrivacyPolicy')}>
                {i18n.t('signUpStepTwo.agreementPP')}
              </Text>
            </Text>
          </View>
        </KeyboardAwareScrollView>

        <DoneBar keyboardType={this.state.keyboardType} text={i18n.t('signUpStepTwo.done')} onDone={() => {}} />
      </View>
    );
  }
}

const StepTwoForm = SignUpFinish;
export default compose(
  connect(
    ({ user }) => ({
      registeringData: user.registering.data,
    }),
    {
      registerUser,
    },
  ),
  withFormik({
    mapPropsToValues({ registeringData }) {
      return {
        email: registeringData.email || '',
        emailconfirm: registeringData.email || '',
        password: '',
        confirmation: '',
      };
    },
    validationSchema: SignUpFinish.schema,
    validateOnChange: true,
    async handleSubmit(values, { props, setFieldError }) {
      console.log('PasswordEntry');
      // const nonUnion = get(props, 'navigation.state.params.nonUnion', false);
      let data = { ...props.registeringData, ...values };

      data = pick(data, ALLOWED_FIELDS);

      if (props.registeringData.member_guid) {
        data.member_guid = props.registeringData.member_guid;
      }

      try {
        const registerAutomatically = props.navigation.getParam('registerAutomatically');
        if (registerAutomatically) {
          await props.registerUser({
            ...data,
            birth_date: i18n.l('date.formats.server', data.birth_date),
            language_default: i18n.currentLocale(),
            imported_union_user: true,
            password: undefined,
            confirmation: undefined,
          });
        } else {
          await props.registerUser({
            ...data,
            birth_date: i18n.l('date.formats.server', data.birth_date),
            language_default: i18n.currentLocale(),
          });
        }
      } catch (err) {
        console.log(err);
        console.log('Registration error', err.response?.data);
        if (err?.response?.data) {
          const reason = err?.response?.data;
          reason.forEach(({ message }) => alert(message));
        }
        if (get(err, 'response.status') === 422) {
          const errors = get(err, 'response.data', []);
          for (const error of errors) {
            setFieldError(error.field, error.message);
          }
        } else {
          setFieldError('email', 'common.noInternetConnection');
        }
      }
    },
  }),
)(StepTwoForm);
