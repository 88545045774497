import { connect } from 'react-redux';

import appActions from '../../../domains/app/appActions';
import authActions from '../../../domains/auth/actions';

import Profile from './profile';

function mapStateToProps(state) {
  return {
    location: state.appReducer.get('location'),
    authUserData: state.authReducer.get('authUserData'),
    isUnionMember: state.authReducer.get('isUnionMember'),
    countries: state.authReducer.get('countries'),
    addUnionPopupIsOpen: state.authReducer.get('addUnionPopupIsOpen'),
    changeProfileSuccessPopupIsOpen: state.authReducer.get('changeProfileSuccessPopupIsOpen'),
    updateNotificationErrorPopup: state.authReducer.get('updateNotificationErrorPopup'),
    addUnionSuccessPopupIsOpen: state.authReducer.get('addUnionSuccessPopupIsOpen'),
  };
}

const mapDispatchToProps = (dispatch) => ({
  updateLoggedUserInfo: (data, setServerErrors) => dispatch(authActions.updateLoggedUserInfo(data, setServerErrors)),
  togglePopup: (popupName, status) => dispatch(appActions.togglePopup(popupName, status)),
  setUnions: () => dispatch(authActions.setUnions()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
