import { Map } from 'immutable';
import appConstants from '../domains/app/appConstants';
import getLocation from '../getLocation';

const initialState = Map({
  location: getLocation(),
  country: Map({}),
  currentPage: 1,
  currentPdfPage: 1,
  archivesCount: 0,
  PDFsArray: [],
});

export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case appConstants.SET_LOCATION:
      return state.set('location', action.location);
    case appConstants.TOGGLE_POPUP:
      return state.set(action.popup.popupName, action.popup.status);
    case appConstants.SET_CURRENT_PAGE:
      return state.set('currentPage', action.payload);
    case appConstants.SET_ARCHIVES_COUNT: {
      return state.set('archivesCount', action.payload);
    }
    case appConstants.SET_CURRENT_PDF_PAGE: {
      return state.set('currentPdfPage', action.payload);
    }
    case appConstants.SET_PDF_ARRAY: {
      return state.set('PDFsArray', action.payload);
    }
    case appConstants.SET_GEO_IP:
      return state.set('country', Map(action.payload));
    default:
      return state;
  }
}
