// @flow
import React, { Component } from 'react';
import { View, Text, TouchableOpacity, Keyboard } from 'react-native';
import i18n from 'react-native-i18n';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import yup from 'yup';
import compose from 'lodash/fp/compose';
import Modal from '../../../components/Modal/ModalAlert';

import { recoveryPassword, goBack } from '../../../user/modules/index';
import type { PasswordRecoveryState } from '../../../user/modules/password';

import Input from '../../../components/FormInput';
import Button from '../../../components/Button';
import styles, { colorGray } from './styles';

type Dict = { [key: string]: * };

type Props = {
  setFieldValue: (name: string, value: *) => void,
  setFieldTouched: (name: string, value: *) => void,
  handleSubmit: () => void,
  goBack: (value: *) => void,
  values: Dict,
  errors: Dict,
  touched: Dict,
  passwordRecovery: PasswordRecoveryState,
  showVerifyModal: boolean,
};

class ForgotPassword extends Component<Props> {
  static navigationOptions = () => ({
    title: i18n.t('forgotPassword.forgotPassword'),
  });

  static schema = yup.object().shape({
    email: yup
      .string()
      .email('forgotPassword.invalidEmail')
      .required('forgotPassword.emptyEmail'),
  });

  setEmail = email => {
    this.props.setFieldValue('email', email);
    this.props.setFieldTouched('email', true);
  };

  recoveryPassword = (evt: NativeEvent) => {
    Keyboard.dismiss();
    this.props.handleSubmit(evt);
  };

  render() {
    const { values, errors, touched, passwordRecovery } = this.props;

    return (
      <KeyboardAwareScrollView style={styles.screen} keyboardShouldPersistTaps="handled">
        <Text style={styles.information}>{i18n.t('forgotPassword.information')}</Text>
        <View style={styles.emailForm}>
          <Input
            label={i18n.t('logIn.email')}
            keyboardType="email-address"
            autoCorrect={false}
            autoCapitalize="none"
            value={values.email}
            placeholder={i18n.t('logIn.emailPlaceholder')}
            placeholderTextColor={colorGray}
            onChangeText={this.setEmail}
            error={touched.email && errors.email}
            errorText={i18n.t(errors.email)}
          />

          {passwordRecovery.error ? (
            <Text style={styles.errorMessage}>{passwordRecovery.error}</Text>
          ) : null}
          <View style={{ height: 16 }} />
          <Button
            title={i18n.t('forgotPassword.send')}
            onPress={this.recoveryPassword}
            style={styles.btnSend}
          />
        </View>

        <Modal
          visible={this.props.showVerifyModal}
          title={i18n.t('forgotPassword.modalMessageTitle')}
          text={i18n.t('forgotPassword.modalMessage')}
          options={[
            {
              text: i18n.t('forgotPassword.ok'),
              onPress: () => {
                this.props.goBack();
              },
            },
          ]}
        />
      </KeyboardAwareScrollView>
    );
  }
}

const EmailForm = ForgotPassword;

export default compose(
  connect(
    ({ user }) => ({
      passwordRecovery: user.passwordRecovery,
      showVerifyModal: user.showVerifyModal,
    }),
    { recoveryPassword, goBack },
  ),
  withFormik({
    mapPropsToValues() {
      return {
        email: '',
      };
    },
    validationSchema: ForgotPassword.schema,
    handleSubmit(values, { props }) {
      props.recoveryPassword(values.email);
    },
  }),
)(EmailForm);
