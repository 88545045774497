// @flow
import React, { Component } from 'react';
import { View, Text, Keyboard, ActivityIndicator } from 'react-native';
import type { NavigationState, NavigationScreenProp } from 'react-navigation';
import i18n from 'react-native-i18n';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import DoneBar from 'done-bar';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import yup from 'yup';
import compose from 'lodash/fp/compose';
import memoize from 'lodash/memoize';
import pick from 'lodash/pick';
import get from 'lodash/get';
import throttle from 'lodash/throttle';

import { setRegisteringData } from '../../../user/modules';
import Header from '../../../components/Header/Header';
import RegisterSteps from '../../components/RegisterSteps';
import type { Country } from '../../api/country';
import { getCountries, getGeoIp } from '../../modules/country';
import type { User } from '../../../user/api/user';
import { promisify } from '../../../store/apiAction';

import config from '../../../constants/config';
import Input from '../../../components/FormInput';
import Picker from '../../../components/Picker/Picker';
import DatePicker from '../../../components/DatePicker/DatePicker';
import styles, { colorGray } from './styles';
import { arrowNext } from '../../../assets/images';
import BigPane from '../../../components/BigPane/BigPane';
import NewButton from '../../../components/Button/NewButton';
import RegisterStepsPane from '../../components/RegisterStepsPane';
import history from '../../../../history';

type Dict = { [key: string]: * };

type InputProps = {
  field: string,
  label: string,
  placeholder?: string,
  keyboardType?: string,
  autoCorrect?: string,
};

type Params = NavigationState & {
  step: number,
  steps: number,
  title: string,
  nonUnion: boolean,
};

type Props = {
  navigation: NavigationScreenProp<Params>,
  countries: Array<Country>,
  geoIp: Country,
  registeringData: $Shape<User>,

  setFieldValue: (name: string, value: *) => void,
  setFieldTouched: (name: string, value: *) => void,
  handleSubmit: (event: Event<>) => void,
  values: Dict,
  errors: Dict,
  touched: Dict,

  getCountries: typeof getCountries,
  getGeoIp: typeof getGeoIp,

  countriesLoading: Boolean,
  geoIpLoading: Boolean,
};

type State = {
  phoneCC: string,
  phoneNumber: string,
  flagKeyboardShown: boolean,
  keyboardType: string,
  focusedPhoneField: string,
  submitClicked: boolean,
};

const DEFAULT_HCP = 54;

const UNION_MEMBER_FIELDS = [
  'union_id',
  'member_number',
  'first_name',
  'last_name',
  'sex',
  'country',
  'birth_date',
  'email',
  'phone',
  'pin_code',
];
const NONUNION_MEMBER_FIELDS = [
  'first_name',
  'last_name',
  'birth_date',
  'sex',
  'hcp',
  'country',
  'email',
  'phone',
  'pin_code',
];

// Add loading bugfix, to overcome android issue, when empty picker causes system crash
const addLoading = (elements) => {
  const el = elements || [];
  if (el.length === 0) {
    return [{ country_name: 'Loading...' }];
  }
  return el;
};

class SignUp extends Component<Props, State> {
  keyboardDidShowListener: Function;
  keyboardDidHideListener: Function;
  showErrorMessage: boolean;
  firstNameInput: ?React$ElementRef<typeof Input>;
  dguEmailInput: ?React$ElementRef<typeof Input>;
  emailInput: ?React$ElementRef<typeof Input>;
  phoneInput: ?React$ElementRef<typeof Input>;
  phoneCCInput: ?React$ElementRef<typeof Input>;
  lastNameInput: ?React$ElementRef<typeof Input>;
  bdayPicker: ?React$ElementRef<typeof DatePicker>;
  sexPicker: ?React$ElementRef<typeof Picker>;
  countryPicker: ?React$ElementRef<typeof Picker>;
  scroll: Object;

  static navigationOptions = () => {
    return {
      header: null,
    };
  };

  static schema = yup.object().shape({
    first_name: yup.string().required('signUpStepTwo.emptyFirstNameField'),
    last_name: yup.string().required('signUpStepTwo.emptyLastNameField'),
    phone: yup
      .string()
      .ensure()
      .required('signUpStepTwo.emptyPhoneField')
      .min(10, 'signUpStepTwo.invalidPhoneField')
      .max(15, 'signUpStepTwo.invalidPhoneField'),

    birth_date: yup.string().required('signUpStepTwo.emptyBirthDateField'),
  });

  // eslint-disable-next-line react/sort-comp
  fieldsPositions = {};
  scrollEl: ?KeyboardAwareScrollView;

  state = {
    phoneCC: '',
    phoneNumber: '',
    flagKeyboardShown: false,
    keyboardType: 'default',
    focusedPhoneField: '',
    submitClicked: false,
  };

  componentWillMount() {
    const { values } = this.props;

    const step = this.props.navigation.getParam('step');
    if (step == null) {
      console.error('SignUp: Missing params, most likely page was refreshed, going to regitsration root');
      history.push('/register');
    }

    if (values.phone.length > 0) {
      const numbers = values.phone.split(' ');
      if (numbers.length > 1) {
        this.setState({ phoneCC: numbers[0] });
        this.setState({ phoneNumber: numbers[1] });
      } else if (numbers[0].charAt(0) === '+') {
        this.setState({ phoneCC: numbers[0] });
      } else {
        this.setState({ phoneNumber: numbers[1] });
      }
    }
  }

  applyCountryAutoSelectValues(props) {
    if (!this.props.values.country && props.geoIp.country_code) {
      this.props.setFieldValue('country', props.geoIp.country_code);
    }

    if (!this.props.values.phone && props.geoIp.calling_code) {
      this.setState({ phoneCC: props.geoIp.calling_code });
    }
  }

  componentDidMount() {
    this.keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', this.onKeyboardDidShow);
    this.keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', this.onKeyboardDidHide);
    this.props.getCountries();
    this.props.getGeoIp();
    this.applyCountryAutoSelectValues(this.props);
  }

  componentWillReceiveProps(props: Props) {
    this.applyCountryAutoSelectValues(props);
    this.showErrorMessage = false;
    if (Object.keys(props.errors).length > 0) {
      for (const errorKey of Object.keys(props.errors)) {
        if (props.touched[errorKey]) {
          this.showErrorMessage = true;
        }
      }
    }

    if (this.state.submitClicked && this.showErrorMessage && !this.state.flagKeyboardShown) {
      // console.log('props.errors', this.props.errors, props.errors);
      this.scroll.scrollTo({ x: 0, y: 0, animated: true });
    }

    if (Object.keys(this.props.errors).length || !Object.keys(props.errors).length) {
      return;
    }
    const firstError = Object.keys(props.errors).find((field) => props.touched[field]);
    if (!firstError) {
      return;
    }

    const y = this.fieldsPositions[firstError];
    if (y && this.scrollEl && this.scrollEl.scrollToPosition) {
      // this.scrollEl.scrollToPosition(0, y, true);
    }
  }
  componentWillUnmount() {
    this.keyboardDidShowListener.remove();
    this.keyboardDidHideListener.remove();
  }
  onKeyboardDidShow = () => {
    this.setState({ flagKeyboardShown: true });
  };

  onKeyboardDidHide = () => {
    this.setState({ flagKeyboardShown: false });
  };
  setMembership = (isMember: boolean) => {
    this.props.setFieldValue('is_member', isMember);
    this.props.setFieldTouched('is_member', true);
    if (!isMember) {
      this.props.setFieldValue('hcp', DEFAULT_HCP);
      this.props.setFieldTouched('hcp', true);
    }
  };

  updatePhoneField() {
    // fix user registration problem, merge phone and then format it as required by backend
    function fixPhoneNumber(p) {
      let phone = p;
      phone = phone.replace(' ', '');
      if (phone[0] !== '+') {
        phone = `+${phone}`;
      }
      return `${phone.substring(0, 3)} ${phone.substring(3)}`;
    }
    const phone = fixPhoneNumber(this.state.phoneCC + this.state.phoneNumber);

    this.props.setFieldValue('phone', phone.trim());
    this.props.setFieldTouched('phone', true);
  }

  updateField = memoize((field: string, path: string) => {
    return (value) => {
      const val = path ? value[path] : value;
      this.props.setFieldValue(field, val);
      this.props.setFieldTouched(field, true);
    };
  });

  SEX = [
    { key: 'm', label: i18n.t('gender.m') },
    { key: 'f', label: i18n.t('gender.f') },
  ];

  signUp = throttle(
    (evt) => {
      Keyboard.dismiss();
      this.setState({ submitClicked: true });
      this.props.handleSubmit(evt);
    },
    5000,
    { trailing: false },
  );

  setInputRef = (field: string, input) => {
    // console.log("SET INPUT REF", field);
    if (field === 'first_name') {
      this.firstNameInput = input;
    } else if (field === 'last_name') {
      this.lastNameInput = input;
    } else if (field === 'dgu_email') {
      this.dguEmailInput = input;
    } else if (field === 'email') {
      this.emailInput = input;
    } else if (field === 'phone_cc') {
      this.phoneCCInput = input; // console.log("Phone_CC input ref set");
    } else if (field === 'phone') {
      this.phoneInput = input; // console.log("Phone input ref set");
    }
  };

  focusNextInput = (field: string) => {
    if (field === 'first_name') {
      this.lastNameInput?.focus();
    } else if (field === 'last_name') {
      this.phoneCCInput?.focus();
    } else if (field === 'birth_date') {
      this.sexPicker?.showPicker();
    } else if (field === 'sex') {
      if (!this.props.values.country) {
        this.countryPicker?.showPicker();
      } else {
        Keyboard.dismiss();
      }
    } else if (field === 'country') {
      Keyboard.dismiss();
    } else if (field === 'phone_cc') {
      this.phoneInput?.focus();
    } else if (field === 'phone') {
      this.lastNameInput?.blur();
      this.bdayPicker?.open();
    }
  };

  handleInputFocus = (field) => {
    if (field !== 'birth_date' && this.bdayPicker && this.bdayPicker.isVisible) {
      this.bdayPicker?.close();
    }
    if (this.sexPicker?.shown) {
      this.sexPicker?.backPressHandler();
    }
    if (this.countryPicker?.shown) {
      this.countryPicker.backPressHandler();
    }

    if (field === 'phone' || field === 'phone_cc') {
      this.setState({ keyboardType: 'numeric', focusedPhoneField: field });
    } else if (field === 'birth_date') {
      Keyboard.dismiss();
    } else {
      this.setState({ keyboardType: 'default' });
    }
  };

  renderInput(props: InputProps) {
    const { values, errors, touched } = this.props;
    const { label, placeholder, field, keyboardType, autoCorrect, ...inputProps } = props;
    return (
      <View
        onLayout={({ nativeEvent }) => {
          this.fieldsPositions[field] = nativeEvent.layout.y;
        }}
      >
        <Input
          label={i18n.t(label)}
          placeholder={i18n.t(placeholder)}
          placeholderTextColor={colorGray}
          value={String(values[field])}
          onChangeText={this.updateField(field)}
          returnKeyType="next"
          innerRef={(input) => {
            this.setInputRef(field, input);
          }}
          onSubmitEditing={() => {
            this.focusNextInput(field);
          }}
          blurOnSubmit={false}
          keyboardType={keyboardType || 'default'}
          autoCorrect={autoCorrect || false}
          onFocus={() => {
            this.handleInputFocus(field);
          }}
          onBlur={() => {
            this.setState({ keyboardType: 'default' });
          }}
          {...inputProps}
        />
        {this.state.submitClicked && touched[field] && errors[field] ? (
          <Text style={styles.errorMessage}>{i18n.t(errors[field], { defaultValue: errors[field] })}</Text>
        ) : null}
      </View>
    );
  }

  renderNonUnionFields() {
    const { values } = this.props;
    const { errors, touched } = this.props;

    const nonUnion = this.props.navigation.getParam('nonUnion');
    if (!nonUnion) {
      return null;
    }

    return (
      <View>
        <Text
          onLayout={({ nativeEvent }) => {
            this.fieldsPositions.birth_date = nativeEvent.layout.y;
          }}
        >
          {}
        </Text>
        <DatePicker
          label={i18n.t('signUpStepTwo.birthday')}
          value={values.birth_date}
          onChange={(value) => {
            this.props.setFieldValue('birth_date', value);
            this.props.setFieldTouched('birth_date', true);
            this.focusNextInput('birth_date');
          }}
          maximumDate={new Date()}
          defaultDate={config.defaultRegistrationDate}
          defaultText={i18n.t('signUpStepTwo.birthDateLabel')}
          ref={(datePicker) => {
            this.bdayPicker = datePicker;
          }}
          onOpen={() => {
            this.handleInputFocus('birth_date');
          }}
        />
        {this.state.submitClicked && touched.birth_date && errors.birth_date ? (
          <Text style={styles.errorMessage}>{i18n.t(errors.birth_date, { defaultValue: errors.birth_date })}</Text>
        ) : null}
        <Picker
          label={i18n.t('signUpStepTwo.gender')}
          data={this.SEX}
          value={values.sex}
          onChange={(value) => {
            this.props.setFieldValue('sex', value.key);
            this.props.setFieldTouched('sex', true);
            this.focusNextInput('sex');
          }}
          titleText={i18n.t('signUpStepTwo.pickerGender')}
          confirmText={i18n.t('picker.confirm')}
          cancelText={i18n.t('picker.cancel')}
          defaultText={i18n.t('signUpStepTwo.pickerGender')}
          ref={(sexPicker) => {
            this.sexPicker = sexPicker;
          }}
        />

        {this.props.countriesLoading || this.props.geoIpLoading ? (
          <ActivityIndicator />
        ) : (
          <Picker
            label={i18n.t('signUpStepTwo.country')}
            style={{ width: '100%' }}
            keyName="country_code"
            labelName="country_name"
            iconName="img_url"
            data={addLoading(this.props.countries).map((item) => ({ ...item, iconStyle: 'hexagon' }))}
            value={values.country}
            onChange={(value) => {
              if (!value) {
                return;
              }
              this.props.setFieldValue('country', value.country_code);
              this.props.setFieldTouched('country', true);
              this.focusNextInput('country');
            }}
            titleText={i18n.t('signUpStepTwo.pickerCountry')}
            confirmText={i18n.t('picker.confirm')}
            cancelText={i18n.t('picker.cancel')}
            defaultText={i18n.t('picker.select')}
            ref={(countryPicker) => {
              this.countryPicker = countryPicker;
            }}
          />
        )}
      </View>
    );
  }

  renderLoginButton() {
    // TODO dont forget about loadingStyle
    return (
      <View style={{ marginHorizontal: 20, width: '30%', marginBottom: 20 }}>
        <NewButton
          text={i18n.t('signUpStepOne.bnNext')}
          imageRight={arrowNext}
          disabled={this.props.registeringData.loading}
          onPress={this.signUp}
        />
      </View>
    );
  }

  render() {
    const { errors, touched, navigation, isNarrow } = this.props;

    const step = navigation.getParam('step');
    const steps = navigation.getParam('steps');

    return (
      <View style={isNarrow ? styles.rootNarrow : styles.root}>
        <RegisterStepsPane stepCount={steps} step={step} />
        <KeyboardAwareScrollView
          ref={(el) => (this.scrollEl = el)}
          innerRef={(ref) => (this.scroll = ref)}
          style={styles.screen}
          keyboardShouldPersistTaps="handled"
          extraHeight={150}
          enableOnAndroid
        >
          <BigPane style={styles.registrationForm}>
            {this.state.submitClicked && this.showErrorMessage ? (
              <Text style={styles.errorMessage}>{i18n.t('signUpStepTwo.errorFields')}</Text>
            ) : null}

            {this.renderInput({
              field: 'first_name',
              maxLength: 30,
              label: 'signUpStepTwo.firstName',
              // placeholder: 'signUpStepTwo.firstNamePlaceholder',
            })}

            {this.renderInput({
              field: 'last_name',
              maxLength: 30,
              label: 'signUpStepTwo.lastName',
              //  placeholder: 'signUpStepTwo.lastNamePlaceholder',
            })}

            <View
              onLayout={({ nativeEvent }) => {
                this.fieldsPositions.phone = nativeEvent.layout.y;
              }}
            >
              <Text style={styles.inputText}>{i18n.t('signUpStepTwo.mobileNo')}</Text>
              <View style={styles.phoneInputFields}>
                <Input
                  style={styles.phoneCC}
                  inputComponentStyle={{ width: 45 }}
                  placeholder={i18n.t('signUpStepTwo.cc')}
                  placeholderTextColor={colorGray}
                  value={this.state.phoneCC}
                  onChangeText={(text) => {
                    this.setState({ phoneCC: text });
                    this.updatePhoneField();
                  }}
                  returnKeyType="next"
                  innerRef={(input) => {
                    this.setInputRef('phone_cc', input);
                  }}
                  onSubmitEditing={() => {
                    this.focusNextInput('phone_cc');
                  }}
                  blurOnSubmit={false}
                  onFocus={() => {
                    this.handleInputFocus('phone_cc');
                  }}
                  onBlur={() => {
                    this.setState({ keyboardType: 'default', focusedPhoneField: '' });
                  }}
                  keyboardType="phone-pad"
                  maskTemplate={{ mask: '+999' }}
                />
                <Input
                  style={styles.phone}
                  placeholder={i18n.t('signUpStepTwo.mobileNoPlaceholder')}
                  placeholderTextColor={colorGray}
                  value={this.state.phoneNumber}
                  onChangeText={(text) => {
                    this.setState({ phoneNumber: text });
                    this.updatePhoneField();
                  }}
                  returnKeyType="next"
                  innerRef={(input) => {
                    this.setInputRef('phone', input);
                  }}
                  onSubmitEditing={() => {
                    this.focusNextInput('phone');
                  }}
                  blurOnSubmit={false}
                  onFocus={() => {
                    this.handleInputFocus('phone');
                  }}
                  onBlur={() => {
                    this.setState({ keyboardType: 'default', focusedPhoneField: '' });
                  }}
                  keyboardType="phone-pad"
                  maskTemplate={{ mask: '9999999999' }}
                />
              </View>
              {this.state.submitClicked && touched.phone && errors.phone ? (
                <Text style={styles.errorMessage}>{i18n.t(errors.phone, { defaultValue: errors.phone })}</Text>
              ) : null}
            </View>

            {this.renderNonUnionFields()}
          </BigPane>
          <View style={styles.bottomField}>{this.renderLoginButton()}</View>
        </KeyboardAwareScrollView>

        <DoneBar
          keyboardType={this.state.keyboardType}
          text={i18n.t('signUpStepTwo.done')}
          onDone={() => {
            if (this.state.focusedPhoneField === 'phone_cc') {
              this.phoneCCInput?.blur();
              this.focusNextInput('phone_cc');
            } else {
              this.phoneInput?.blur();
              this.focusNextInput('phone');
            }
          }}
        />
      </View>
    );
  }
}

const StepTwoForm = SignUp;
export default compose(
  connect(
    ({ user, country }) => ({
      registeringData: user.registering.data,
      countries: country.countries.data,
      geoIp: country.geoIp.data,
      countriesLoading: country.countries.loading,
      geoIpLoading: country.geoIp.loading,
    }),
    {
      setRegisteringData,
      getCountries,
      getGeoIp,
    },
  ),
  withFormik({
    mapPropsToValues({ registeringData }) {
      console.log('RED', registeringData);
      return {
        first_name: registeringData.first_name || '',
        last_name: registeringData.last_name || '',
        email: registeringData.email || '',
        phone: registeringData.phone || '',
        pin_code: '',
        use_touch_id: false,
        country: registeringData.country || '',
        sex: registeringData.sex || '',
        birth_date: registeringData.birth_date,
        hcp: registeringData.hcp === undefined ? DEFAULT_HCP : registeringData.hcp,
        is_member: false,
      };
    },
    validationSchema: SignUp.schema,
    validateOnChange: true,
    async handleSubmit(values, { props }) {
      const nonUnion = get(props, 'navigation.state.params.nonUnion', false);
      const data = pick(
        { ...props.registeringData, ...values },
        nonUnion ? NONUNION_MEMBER_FIELDS : UNION_MEMBER_FIELDS,
      );

      const step = props.navigation.getParam('step');
      const steps = props.navigation.getParam('steps');
      const title = props.navigation.getParam('title');

      console.log('handlesubmit', values, step, steps, title);
      console.log(data);

      props.setRegisteringData({ ...data });
      props.navigation.navigate('PasswordEntry', {
        step: step + 1,
        steps,
        title,
        nonUnion: props.navigation.getParam('nonUnion'),
      });
    },
  }),
)(StepTwoForm);
