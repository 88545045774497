import React from 'react';
import { connect } from 'react-redux';

import TermsDenmark from '../terms/termsContent/termsDenmark';
import TermsNorway from '../terms/termsContent/termsNorway';
import TermsEnglish from '../terms/termsContent/termsEnglish';
import TermsSweden from '../terms/termsContent/termsSweden';
import TermsGerman from '../terms/termsContent/termsGerman';

import './terms.css';

class TermsMobile extends React.Component {
  render() {
    return (
      <div className="b-terms g-auth-content">
        <div className="b-terms-mobile__container g-container">
          {this.props.location === 'danish' && TermsDenmark()}
          {this.props.location === 'english' && TermsEnglish()}
          {this.props.location === 'norwegian' && TermsNorway()}
          {this.props.location === 'swedish' && TermsSweden()}
          {this.props.location === 'german' && TermsGerman()}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    location: state.appReducer.get('location'),
  };
}

export default connect(mapStateToProps, null)(TermsMobile);
