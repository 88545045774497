// @flow
import styled from 'styled-components/native';
import { color, layout, space } from 'styled-system';
import { Text } from '../Text';
import { colorExt, elevation8 } from '../../helpers/styles';

export const Touchable = styled.TouchableOpacity``;

export const Container = styled.View.attrs(({ disabled, bg }) => ({
  height: 44,
  pl: 6,
  pr: 6,
  borderRadius: 5,
  bg: disabled ? 'grey' : bg,
  ...elevation8,
}))`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  ${space}
  ${layout}
  ${color}
`;

export const Title = styled(Text).attrs(({ fontSize }) => ({
  fontSize: fontSize || 2,
  fontFamily: 'medium',
}))``;

export const Image = styled.Image.attrs({
  height: 18,
  resizeMode: 'contain',
})`
  ${colorExt}
  ${layout}
  ${space}
`;
