import React from 'react';

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

import './styles.css';


class ReactSelect extends React.Component {

  handleChange = (event, index, value) => {
    if (this.props.links) {
      if (value !== this.props.defaultValue) {
        window.location.href = value;
      }
    } else {
      this.props.changeNameOfValueProp(value);
    }
  }

  renderList = (data) => {
    const listTemplate = data.map((item, index) => {
      return (
        <MenuItem
          key={index}
          value={item[this.props.nameOfValueProp]}
          primaryText={item[this.props.nameOfTitleProp]}
        />
      );
    });

    return listTemplate;
  }

  render() {
    return (
      <div className="b-custom-select">
        <label className="b-custom-select__label">{this.props.label}</label>
        <div className="b-custom-select__container">
          <MuiThemeProvider>
            <SelectField
              iconStyle={{ background: "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAUCAMAAACtdX32AAAAOVBMVEUAAAC/xs+/z8+/x8+/xc++xs6+xc6/xc+/xs+/xc+/x8+/xs++xs+/xc+/xs+/xs+/xs+/x8+/xs/6e6HDAAAAEnRSTlMA8BBAMODQz6CAYN/AsJBwUCAnExChAAAAkElEQVQoz3WOSRIDMQgDZWPPvub/j00UygUT4j4BomgAWV8dVgFQrQ85gNSPE4C5H8/4sLESPBDONpCceCdbZiNbHeEY7GDTH5YeKlaa/mrdxW73rkrXrc1Ncc1wFNWbuODBydnEamJ14oed0wVYnDjoS/HiqB+/S4joWVVE9Cl98D95YDpkdCjJiyNSq8DzBvyGEEsQzqbAAAAAAElFTkSuQmCC) center right no-repeat", backgroundSize: "14px 10px" }}
              value={this.props.defaultValue}
              onChange={this.handleChange}
              fullWidth={true}
              labelStyle={{ color: '#4F627A' }}
              menuItemStyle={{ color: '#4F627A' }}
              maxHeight={200}
            >
              {this.renderList(this.props.data)}
            </SelectField>
          </MuiThemeProvider>
        </div>
      </div>
    );
  }
}

export default ReactSelect;
