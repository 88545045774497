import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './footer.css';

import { FOOTER_LOGO } from '../../../constants/config/images';
import texts from '../../../constants/languages';

class Footer extends Component {
  componentDidMount() {
    this.forceUpdate();
  }

  render() {
    const link = this.props.userIsAuthenticated ? '/vouchers' : '/';
    return (
      <footer className={`b-footer ${this.props.userIsAuthenticated ? 'm-footer_auth' : ''}`}>
        <div className="container grid-xl b-footer__container g-container g-flex container">
          <Link className="b-footer__logo" to={link} onClick={() => window.scrollTo(0, 0)}>
            <img src={FOOTER_LOGO} width={163} height={32} alt="Golf More" />
          </Link>
          <div className="b-footer__nav g-clear-fix">
            <Link className="b-footer__social-link" to={link}>
              &nbsp;
            </Link>
            <ul className="b-footer__list g-clear-fix">
              <li className="b-footer__item">
                <Link to={'contact-us'}>{texts.CONTACT_US}</Link>
              </li>
              <li className="b-footer__item">
                <Link to={'privacy-policy'}>{texts.PRIVACY_POLICY}</Link>
              </li>
              <li className="b-footer__item">
                <Link to={'terms-and-conditions'}>{texts.TERMS_AND_CONDITIONS}</Link>
              </li>
              <li className="b-footer__item">
                <Link to={'faq'}>{texts.FAQ}</Link>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
