// @flow
import { PermissionsAndroid, Platform } from 'react-native';
import i18n from 'react-native-i18n';

async function requestLocationPermission() {
  try {
    const granted = await PermissionsAndroid.request(
      PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION,
      {
        title: i18n.t('permissions.locationTitle'),
        message: i18n.t('permissions.locationText'),
      },
    );
    if (granted === PermissionsAndroid.RESULTS.GRANTED) {
      console.log('You can use location');
      return true;
    }
    console.log('Location permission denied');
    return false;
  } catch (err) {
    console.warn(err);
    return false;
  }
}

async function checkPermissions() {
  if (Platform.OS === 'android') {
    const allowed = await PermissionsAndroid.check(PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION);
    if (allowed) {
      return true;
    }
    return requestLocationPermission();
  }
  return true;
}

export default checkPermissions;
