import React from 'react';
import urls from "../../../../constants/config/urls";

const termsEnglish = () => (
  <div className="b-terms__content container grid-xl">
    <div className="columns">
      <div className="column">
        <h1 className="b-terms__title">Terms and Conditions</h1>
        <h2 className="b-terms__sub-title"><span
          className="g-uppercase"
        >terms of use for golfmore.dk</span> (Updated June 2018)</h2>
        <p className="b-terms__paragraph">This Terms of Use (hereinafter "Terms of Use") apply to any use of
                    GolfMore's website as well as
                    applications (hereinafter "Website"), including - but not limited to -
          <a
            className="b-privacy-policy__link"
            href={urls.english}
            target="_blank"
            rel="noopener noreferrer nofollow"
          > www.GolfMore.dk</a>.</p>
        <p className="b-terms__paragraph">The website is offered by ProBox24 ApS, Rugaardsvej 5, 8680 Ry, Denmark, info@probox24.com.
            In the Terms of Use, the words "we", "us" and "our" refer to the words "GolfMore".</p>
        <p className="b-terms__paragraph">This Terms of Use includes all rules, policies and guidelines referred
                    to in this document or as
                    otherwise published on the Site (such policies and policies may be amended from time to time),
                    including without exception:</p>
        <ul className="b-terms__sub-inner-list">

          <li className="b-terms__sub-inner-item">Our Privacy Policy, which can be found at
            <a
              className="b-privacy-policy__link"
              href="https://golfmore.dk/privacy policy"
              target="_blank"
              rel="noopener noreferrer nofollow"
            > https://golfmore.dk/privacy policy</a></li>
        </ul>
        <p className="b-terms__paragraph">By using the Site you agree to be subject to the Terms of Use. If you
                    can not accept the Terms,
                    please do not further use the Site. Your creation as a user requires your express acceptance of the
                    Terms of Use.</p>
        <h2 className="b-terms__sub-title"><span className="g-uppercase">the supplies on the website</span></h2>
        <h3 className="b-terms__sub-title">1 Registered User</h3>
        <p className="b-terms__paragraph">1.1 In order to gain access to full use of the Site, you must create a
                    profile and register as a user
                    (hereinafter "Registered User").</p>
        <p className="b-terms__paragraph">1.2 It is only allowed to create one profile per. person on the site.
                    The profile is personal and may
                    not be transferred to others.</p>
        <p className="b-terms__paragraph">1.3 To become a registered user, you must have a password. You choose
                    your password, which
                    will be used with your email address when you log in to the Website. Alternatively, we will send
                    the password to you. The password is personal and may not be transferred or otherwise made
                    available to others. It is up to you to ensure that the password does not reach a third party. If
                    you
                    become aware that the password is or may be compromised, you are required to notify us. We
                    can and will change the password if there is a risk that it is compromised or used in violation of
                    the
                    Terms of Use.</p>
        <p className="b-terms__paragraph">1.4 We may, at any time without notice and without responsibility,
                    delete, suspend or change
                    your profile if you act in violation of the Terms of Use or applicable law. By deleting your
                    profile,
                    you do not have access to the services on the Site that require your creation and / or login as a
                    Registered User.</p>
        <p className="b-terms__paragraph">1.5 In case of suspicion of abuse or the like, we reserve the right to
                    delete your profile at any time
                    and without notice or reason. In such cases, our disclaimer will apply unlimited.</p>
        <p className="b-terms__paragraph">1.6 It is not allowed to obtain or attempt to obtain access to the
                    parts of the
                    Website that require user registration if you are not a Registered User.</p>
        <p className="b-terms__paragraph">1.7 It is not allowed to obtain or attempt to gain access to use those
                    parts of the Website that
                    require user registration if you are not a Registered User.</p>
        <h2 className="b-terms__title"><span className="g-uppercase">In General</span></h2>
        <h3 className="b-terms__sub-title">2 Rights</h3>
        <p className="b-terms__paragraph">2.1 The content of the website, such as - but not limited to -
                    intellectual property rights, text,
                    characteristics, graphics, icons, images, calculations, references, and software belong to either
                    GolfMore or any third party (in addition to the Registered User) and is protected by Danish and
                    international law, including Copyright Act, Trade Marks Act, Marketing Act etc. If you provide
                    feedback, ideas or suggestions to the Website or the services available through the Website
                    ("Feedback"), we may use this Feedback freely and without limitation.</p>
        <p className="b-terms__paragraph">2.2 Unauthorized copying, distribution, display, performance or other
                    use of the Site or any part of
                    it is in violation of Danish and / or other laws and may result in civil and penal sanctions.</p>
        <p className="b-terms__paragraph">2.3 Downloading and other digital copying of the Web site content or
                    parts thereof is permitted
                    for personal, non-commercial use only, unless otherwise agreed in writing with us or permitted by
                    applicable mandatory laws.</p>
        <p className="b-terms__paragraph">2.4 All company names, trademarks and other business features of the
                    Website belong to either
                    GolfMore or any third party (in addition to the Registered User) and may only be used
                    commercially upon prior permission from us or third parties.</p>
        <h3 className="b-terms__sub-title">3 Personal Information</h3>
        <p className="b-terms__paragraph">3.1 We undertake various forms of processing of personal data in
                    connection with the use of the
                    Website. Our processing of personal data is subject to compliance with the Personal Data Act and
                    our Privacy Policy, which can be found here:
          <a className="b-privacy-policy__link" href="https://golfmore.dk/privacy-policy"
             target="_blank"
             rel="noopener noreferrer nofollow">
              https://golfmore.dk/privacy-policy
          </a>
                    By accepting the Terms of Use you declare that you have read and understood our Privacy Policy.
        </p>
        <h3 className="b-terms__sub-title">4 Disclaimer and Indemnification</h3>
        <p className="b-terms__paragraph">4.1 To the extent possible under Danish law, we may in no case be
                    liable for the use of the
                    Website, including for (i) loss of revenue, operating loss, business opportunities, loss or
                    destruction of data or data recovery, loss of goodwill, security breach caused by third party breach
                    of telecommunications and / or the Internet, loss of turnover (whether direct, indirect or
                    consequential loss), (ii) loss or damage resulting from liability to third parties (either direct,
                    indirect, or consequential) any indirect loss.
        </p>
        <p className="b-terms__paragraph">4.2 Nothing in the Terms of Use excludes or limits our liability for
                    death or personal injury as a
                    result of negligence or responsibility for deliberate false information or incorrect information of
                    important nature or other liability that can not be limited or disclaimed in accordance with
                    applicable mandatory laws.</p>
        <h3 className="b-terms__sub-title">5 Other Terms</h3>
        <p className="b-terms__paragraph">5.1 We may revise or amend these Terms of Use at any time and without
                    notice or at our sole
                    discretion, without notice, to terminate, modify or restructure the Website. As a Registered User
                    you agree to be subject to the current Terms of Use. Revision or modification of the Terms will be
                    featured on the Website. GolfMore also strives to inform Registered Users of the change of the
                    Terms of Use. As a Registered User you agree that the continued use of the Website after the
                    publication of the changed User Terms constitutes acceptance of the changed User Terms.</p>
        <p className="b-terms__paragraph">5.2 If any part of these Terms of Use should be deemed to be unlawful
                    or invalid and thus not
                    enforceable, this does not affect the validity and enforcement of the remaining part of the Terms
                    of Use.</p>
        <h3 className="b-terms__sub-title">6 Duration and termination</h3>
        <p className="b-terms__paragraph">6.1 We may terminate your right to access and use any services offered
                    on the Website for any
                    reason and without liability. If we do so or if you choose to delete your profile, all rights you
                    should have obtained through it will immediately expire.</p>
        <h3 className="b-terms__sub-title">7 Law and venue</h3>
        <p className="b-terms__paragraph">7.1 The Terms of Use are governed by Danish law and unless otherwise
                    stated, Danish law is
                    governed by any relationship between GolfMore and Registered Users as well as Website Users.
                    Any disputes and discrepancies must be brought before the court at GolfMore's Danish residence,
                    unless this is contrary to mandatory jurisdiction.</p>
      </div>
    </div>
  </div>
);

export default termsEnglish;
