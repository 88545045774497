// @flow
import { Animated } from 'react-native';
import styled from 'styled-components/native';
import { layout } from 'styled-system';

import arrow from './assets/arrow.png';

export const ARROW_WIDTH = 13;

export const Root = styled(Animated.View)`
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const Image = styled(Animated.Image).attrs({
  width: ARROW_WIDTH,
  source: arrow,
  resizeMode: 'contain',
})`
  ${layout}
`;
