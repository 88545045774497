// @flow
import React from 'react';
import { View, Image, StyleSheet, Text, TouchableOpacity } from 'react-native';
import Modal from 'modal-enhanced-react-native-web';
import PropTypes from 'prop-types';
import iStyle from '../../constants/styles';

const { colors, font } = iStyle;

const buttonColors = {
  green: {
    bg: colors.white,
    fg: colors.green,
  },
};

const Button = ({ closeCallback, option }: { option: Option, closeCallback: ?Function }) => {
  const theme = buttonColors.green;
  const customBg = {
    backgroundColor: theme.bg, // use default background if button style not specified
  };
  const customFg = {
    color: theme.fg || '#fff',
    ...font.style.semibold,
    fontSize: 16,
  };
  return (
    <TouchableOpacity
      style={[styles.button, customBg]}
      onPress={() => {
        closeCallback?.();
        if (option.onPress) {
          option.onPress();
        }
      }}
    >
      <Text style={customFg}>{option.text}</Text>
    </TouchableOpacity>
  );
};

type Option = {
  text: string,
  onPress: Function,
};

type Props = {
  visible: boolean,
  cancelable: boolean,
  closeCallback?: Function,
  title: string,
  text: string,
  options: Array<Option>,
};

const ModalAlert = ({ visible, cancelable, closeCallback, title, icon, text, options, children }: Props) => {
  if (!visible) {
    return null;
  }

  let titleView = null;
  if (title || icon) {
    const iconView = !icon ? null : <Image source={icon} style={styles.icon} />;
    titleView = (
      <View style={styles.header}>
        {iconView}
        <Text style={styles.title}>{title}</Text>
      </View>
    );
  }
  const description = !text ? null : <Text style={styles.description}>{text}</Text>;

  return (
    <Modal
      animationType="fade"
      transparent
      visible={visible}
      style={{ margin: 0 }}
      onRequestClose={() => {
        if (cancelable) {
          closeCallback?.();
        }
      }}
    >
      <View style={styles.modalWrapper}>
        {children || (
          <View style={styles.modal}>
            {titleView}
            {description}
            <View style={[styles.center, { flexDirection: 'row' }]}>
              {options.map((option, key) => (
                <Button option={option} key={String(key)} closeCallback={closeCallback} />
              ))}
            </View>
          </View>
        )}
      </View>
    </Modal>
  );
};

ModalAlert.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  type: PropTypes.string,
  visible: PropTypes.bool,
  closeCallback: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      onPress: PropTypes.func,
      style: PropTypes.object, // eslint-disable-line
    }),
  ),
  cancelable: PropTypes.bool, //eslint-disable-line
  icon: PropTypes.object, // eslint-disable-line
};

ModalAlert.defaultProps = {
  type: 'error',
  visible: false,
  options: [
    {
      text: 'OK',
      style: {},
      onPress: null,
    },
  ],
  cancelable: false,
  icon: null,
};

const styles = StyleSheet.create({
  modalWrapper: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    padding: 16,
  },
  center: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  modal: {
    backgroundColor: 'white',
    width: '95%',
    borderRadius: 27,
    padding: 16,
  },
  header: {
    marginTop: 20,
    marginLeft: 18,
    alignItems: 'center',
    // height: 50,a
    flexDirection: 'row',
  },
  icon: {
    width: 21,
    height: 21,
    marginRight: 15,
  },
  title: {
    fontSize: 18,
    ...font.style.medium,
    width: '70%',
    color: colors.textBlack,
  },
  description: {
    marginTop: 20,
    marginLeft: 20,
    marginRight: 15,
    fontSize: 16,
    ...font.style.regular,
    color: colors.basic,
  },
  separator: {
    marginTop: 0,
    marginBottom: 15,
    backgroundColor: '#aaa',
    width: '90%',
    marginLeft: 16,
    marginRight: 16,
    height: 1,
  },
  button: {
    paddingVertical: 20,
    paddingRight: 30,
    paddingLeft: 15,
  },
});

export default ModalAlert;
