function isDev() {
  //return false; // remove this temp in prod
  const { hostname } = window.location;
  switch (hostname) {
    case 'localhost':
    case 'localhost.dk':
    case 'localhost.en':
    case 'localhost.no':
    case 'localhost.sv':
    case 'localhost.de':
    case 'dev.golfmore.dk':
    case 'dev.golfmore.club':
    case 'dev.golfmore.se':
    case 'dev.golfmore.de':
    case 'dev.no.golfmore.dk':
      return true;
    default:
      return false;
  }
}

export function isStaging() {
  const { hostname } = window.location;
  switch (hostname) {
    case 'stage.golfmore.dk':
    case 'stage.golfmore.club':
    case 'stage.golfmore.se':
    case 'stage.golfmore.de':
    case 'stage.no.golfmore.dk':
      return true;
    default:
      return false;
  }
}

export function isGM() {
  const { hostname } = window.location;
  switch (hostname) {
    case 'gm.app.golfmore.eu':
    case 'gm.app.golfmore.club':
    case 'gm.app.golfmore.se':
    case 'gm.app.golfmore.dk':
    case 'gm.app.golfmore.de':
      return true;
    default:
      return false;
  }
}

const API_ENDPOINT_DEV = 'https://api.dev.golfmore.eu/v1';
const API_ENDPOINT_STAGE = 'https://api.stage.golfmore.eu/v1';
const API_ENDPOINT_PROD = 'https://api.golfmore.eu/v1';

const API_MOREBOX_DEV = 'https://stage.app.golfmore.eu';
const API_MOREBOX_STAGE = 'https://stage.app.golfmore.eu';
const API_MOREBOX_PROD = 'https://app.golfmore.eu';

function getEndpoint() {
  let ENDPOINT = 'https://api.golfmore.eu/v1';

  if (isDev()) {
    ENDPOINT = API_ENDPOINT_DEV;
  }

  if (isStaging()) {
    ENDPOINT = API_ENDPOINT_STAGE;
  }

  if (isGM()) {
    ENDPOINT = API_ENDPOINT_PROD;
  }

  return ENDPOINT;
}

export function getMoreboxEndpoint() {
  if (isDev()) {
    return API_MOREBOX_DEV;
  }

  if (isStaging()) {
    return API_MOREBOX_STAGE;
  }

  if (isGM()) {
    return API_MOREBOX_PROD;
  }

  return API_MOREBOX_PROD;
}

export default getEndpoint();
