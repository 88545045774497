import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Text } from 'react-native';
import withModal from '../../../../react-native/components/Modal/withModal';
import { prepareRegisterData } from './hooks';
import history from '../../../../history';
import i18n from 'react-native-i18n';
import { handleLogIn } from '../../../../domains/auth/actions';
import { setRegisteringData } from '../../../../react-native/user/modules';
import { gotOAuthData, loginImportedToken } from '../../../../react-native/auth/modules/session';

function getJsonFromUrl(url) {
  if (!url) url = location.search;
  var query = url.substr(1);
  var result = {};
  query.split('&').forEach(function (part) {
    var item = part.split('=');
    result[item[0]] = decodeURIComponent(item[1]);
  });
  return result;
}

const _OAuthPlugin = ({
  navigation,
  setRegisteringData,
  showModal,
  gotOAuthData,
  data,
  setError,
  locale,
  loginImportedToken,
}: Props) => {
  const processOAuthData = (data: OAuthData) => {
    const dataSet = prepareRegisterData(data);
    console.log('Oauth retuned', data);
    if (data.session_id) {
      localStorage.setItem('userToken', data.session_id);
      history.replace(`/vouchers`);
      loginImportedToken(data.session_id);
      handleLogIn(true);
    } else {
      if (data.existing_golf_ids) {
        const ids = data.existing_golf_ids?.split(',');

        navigation.navigate('UpdateGolfIdModal', {
          ids,
          data: dataSet,
          updateSessionId: data.updateSessionId,
        });
        return;
      }
      showModal(i18n.t('signUpSelect.title'), null, {
        options: [
          {
            // if no, collect password and register new GolfMore user
            text: i18n.t('signUpSelect.no'),
            onPress: () => {
              try {
                const dataSet = prepareRegisterData(data);
                setRegisteringData(dataSet);
                if (data.email) {
                  navigation.replace('PasswordEntry', {
                    step: 3,
                    steps: 3,
                    title: i18n.t('signUpSelect.unionMember'),
                    registerAutomatically: true,
                  });
                } else {
                  navigation.replace('PasswordEntry', {
                    step: 3,
                    steps: 3,
                    title: i18n.t('signUpSelect.unionMember'),
                  });
                }
              } catch (err) {
                console.error('Error logging in', err);
                showModal('Error', 'Can not register you automatically');
                setError('Can not register you automatically. Please contact support');
              }
            },
          },
          {
            text: i18n.t('signUpSelect.yes'),
            onPress: () => navigate('/register/import', { data: dataSet }),
          },
        ],
      });
    }
  };
  useEffect(() => {
    if (data) {
      processOAuthData(data);
      gotOAuthData(null);
    }
  }, [data]);
  return null;
};

export const OAuthPlugin = connect(
  ({ locale }) => ({
    locale: locale.locale,
  }),
  { setRegisteringData, gotOAuthData, loginImportedToken },
)(withModal(_OAuthPlugin));

const Consumer = ({ navigation, location, handleLogIn }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const handleMessage = async (data) => {
    try {
      setData(data);
    } catch (err) {
      console.log('Error logging in', err);
      showModal('Error', 'Can not register you automatically');
      setError('Can not register you automatically. Please');
      setLoading(false);
    }
  };

  useEffect(() => {
    const data = getJsonFromUrl();
    console.log('Consumer loaded', data);
    handleMessage(data);
  }, []);
  return <OAuthPlugin data={data} setError={setError} navigation={navigation} />;
};

export default Consumer;
