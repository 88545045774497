import React from 'react';
import browserHistory from '../../../history';
import propTypes from 'prop-types';
import './voucherTransfer.css';
import vouchersAPI from '../api/vouchers';

import texts from '../../../constants/languages';
import { patterns } from '../../../patterns/patterns';

import TextField from '../../../components/templates/form/textField';

const { bool, func, string } = propTypes;

class voucherTransfer extends React.Component {
  static propTypes = {
    /**
     *  Booleans
     */
    voucherTransferPopupIsOpen: bool.isRequired,
    /**
     *  Functions
     */
    togglePopup: func.isRequired,
    getAccountEmail: func.isRequired,
    sendTransferProcess: func.isRequired,
    /**
     *  Strings
     */
    location: string.isRequired,
    accountEmail: string.isRequired,
  };

  state = {
    userData: {
      token: '', // only for send to server
    },
    transferData: undefined,
    transferError: false,

    serverErrorsMessages: {
      email: '',
      password: '',
    },
  };

  componentDidMount() {
    const token = this.getTokenFromUrl();

    vouchersAPI
      .getTransferProcess(token, this.translateLocation())
      .then((response) => {
        console.log(response.data);
        this.setState({ transferData: response.data });
      })
      .catch((err) => {
        console.log('TRansfer err', err);
        this.setState({ transferError: true });
      });
  }

  translateLocation = () => {
    if (this.props.location === 'danish') {
      return 'dk';
    } else {
      return 'en';
    }
  };

  getTokenFromUrl = () => {
    const urlArray = window.location.href.split('/voucher-subtransfer/');
    const token = urlArray[1];
    this.setState({ userData: { token } });

    return token;
  };

  submitForm = async (event) => {
    event.preventDefault();
    if (!this.props.userIsAuthenticated) {
      console.log('TP');
      localStorage.setItem('transferProcess', this.state.userData.token);
      this.props.togglePopup('logInPopupIsOpen', true);
      return;
    }

    const errorsCounter = [];

    const token = this.state.userData.token;

    const VOUCHERS_SUCCESSFULLY_ASSIGNED = await this.props.sendTransferProcess(
      token,
      this.translateLocation(),
      {},
      this.setServerErrors,
      this.props.togglePopup,
    );
    if (VOUCHERS_SUCCESSFULLY_ASSIGNED) browserHistory.push('/');
  };

  render() {
    console.log(this.state.transferData);
    return (
      <div className="b-voucher-transfer">
        <div className="b-voucher-transfer__container">
          <form className="b-voucher-transfer__form g-form" action="#">
            <h2 className="b-voucher-transfer__title g-form__title">{texts.VOUCHER_TRANSFER_TITLE}</h2>
            {this.state.transferError && 'Error transfering voucher'}
            {this.state.transferData && (
              <div className="b-voucher-transfer__checkbox-container">
                <div className="b-voucher-transfer__checkbox-description">{this.state.transferData.voucher_name}</div>
              </div>
            )}
            <div className="b-voucher-transfer__options g-form__options g-flex">
              <div className="b-voucher-transfer__btn-holder g-form__btn-holder">
                <button className="b-voucher-transfer__btn g-form__btn g-submit-btn" onClick={this.submitForm}>
                  {texts.CONFIRM}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default voucherTransfer;
