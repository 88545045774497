// @flow
import React from 'react';
import { View, TouchableOpacity, Image, Text, StyleSheet } from 'react-native';
import get from 'lodash/get';
import type { StyleObj } from 'react-native/Libraries/StyleSheet/StyleSheetTypes';

import styles from '../../constants/styles';

type Props = {
  navigation: {
    goBack: (key: ?string) => void,
  },
  style?: ?StyleObj,
  title: string,
  titleStyle?: ?StyleObj,
  noBack?: boolean,
  onBackPress?: ?() => void,
  rightButton?: ?React$Node,
};

const { colors, font, size } = styles;

const hStyles = StyleSheet.create({
  header: {
    paddingTop: size.statusbarHeight,
    height: size.statusbarHeight + size.appbarHeight,
    flexDirection: 'row',
    backgroundColor: colors.white,
  },
  back: {
    width: 50,
    justifyContent: 'center',
    alignItems: 'center',
  },
  backImg: {
    resizeMode: 'contain',
    width: 24,
    height: 24,
  },
  title: {
    flex: 1,
    justifyContent: 'center',
  },
  titleText: {
    textAlign: 'center',
    ...font.style.medium,
    color: colors.basic,
    fontSize: font.size.large,
    letterSpacing: 2,
  },
  rightButton: {
    width: 50,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const iconBack = require('../../assets/icon-back.png');

export default function Header(props: Props) {
  const { navigation, style, title, titleStyle, noBack, onBackPress, rightButton } = props;
  const backKey = get(navigation, 'state.params.backKey', undefined);

  const backPressHandler = onBackPress || (() => navigation.goBack(backKey));

  return (
    <View style={[hStyles.header, style]}>
      {noBack ? (
        <View style={hStyles.back} />
      ) : (
        <TouchableOpacity style={hStyles.back} onPress={backPressHandler}>
          <Image
            style={hStyles.backImg}
            source={iconBack}
            resizeMode="contain"
          />
        </TouchableOpacity>
      )}
      <View style={[hStyles.title, titleStyle]}>
        <Text style={hStyles.titleText}>{title}</Text>
      </View>
      <View style={hStyles.rightButton}>{rightButton}</View>
    </View>
  );
}

Header.defaultProps = {
  style: null,
  titleStyle: null,
  noBack: false,
  rightButton: null,
  onBackPress: null,
};
