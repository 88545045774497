// @flow
import { StyleSheet, View } from 'react-native';
import React from 'react';
import styles from '../../../constants/styles';

const { colors, font } = styles;

export const Separator = ({ children }) => (
  <View
    style={{
      borderBottomColor: '#ccc',
      borderBottomWidth: 1,
      marginVertical: 16,
      paddingBottom: 32,
      paddingHorizontal: 20,
      marginTop: -5,
    }}
  >
    {children}
  </View>
);

export default StyleSheet.create({
  rootNarrow: {
    flex: 1,
    alignSelf: 'stretch',
    alignItems: 'center',
    backgroundColor: colors.athens_gray,
  },
  screen: {
    width: 500,
    flex: 1,
  },
  registrationForm: {
    marginTop: 10,
    flex: 1,
  },

  btnLogInLoading: {
    opacity: 0.5,
  },
  DGUEmail: {
    color: colors.slate_gray,
  },
  bottomField: {
    alignItems: 'flex-end',
  },
  errorMessage: {
    top: 5,
    marginHorizontal: 20,
    color: colors.red,
    textAlign: 'center',
    fontSize: font.size.normal,
    ...font.style.regular,
    alignSelf: 'center',
  },
  switchRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 10,
    borderBottomWidth: 1,
    borderBottomColor: styles.colors.athens_gray,
  },
  switchText: {
    marginTop: 0,
  },
  buttonStyle: {
    backgroundColor: colors.button,
  },
  agreementContainer: {
    paddingTop: 20,
    alignItems: 'center',
  },
  agreement: {
    paddingRight: 40,
    paddingLeft: 40,
    marginBottom: 50,
    color: colors.black,
    fontSize: 14,
    ...font.style.light,
  },
  agreementBold: {
    color: colors.green,
    ...font.style.regular,
    textDecorationLine: 'underline',
  },
});

export const colorGray = colors.grey;
