import React from 'react';
import propTypes from 'prop-types';
import browserHistory from '../../../../history';
import './popupSuccess.css';

import texts from '../../../../constants/languages';

class VoucherTransferPopupSuccess extends React.Component {
  closePopupOnClickOutSide = (event) => {
    if (!this.popup.contains(event.target)) {
      this.props.togglePopup('voucherTransferPopupIsOpen', false);
    }
  };

  pushToHomePage = () => {
    browserHistory.push('/');
    window.location.reload();
  };

  render() {
    return (
      <div
        className="g-popup-wrap"
        onClick={(event) => this.closePopupOnClickOutSide(event)} // close popup
      >
        <div className="g-popup-overlay">&nbsp;</div>
        <div className="g-popup-wrap">
          <table className="g-popup-wrap__container">
            <tbody>
              <tr>
                <td className="g-popup-wrap__container-inner">
                  <div className="b-transfer-success g-popup m-popup_more_wide" ref={(popup) => (this.popup = popup)}>
                    <div
                      className="g-popup__close-btn"
                      onClick={() => this.props.togglePopup('voucherTransferPopupIsOpen', false)} // close popup
                    >
                      &nbsp;
                    </div>
                    <div className="b-transfer-success__content g-popup__content">
                      <form className="b-transfer-success__form g-form" action="#">
                        <h3 className="b-transfer-success__title g-form__title m-form__title_transfer remove-capitalize">
                          {texts.THANK_YOU}
                        </h3>
                        <div className="b-transfer-success__btn-holder g-form__btn-holder m-form__btn-holder_confirm g-clear-fix change-button-position__container">
                          <button
                            className="b-transfer-success__btn g-form__btn m-form__btn_home g-submit-btn change-button-position"
                            onClick={this.pushToHomePage}
                          >
                            {texts.GO_TO_MAIN_PAGE}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default VoucherTransferPopupSuccess;

VoucherTransferPopupSuccess.propTypes = {
  togglePopup: propTypes.func.isRequired,
};
