// @flow

import { combineReducers } from 'redux';
import departmentReducer from './departements';
import clubsReducer from './clubs';
import myClubsReducer from './myclubs';
import unionsReducer from './unions';

import type { DepartmentsState } from './departements';
import type { ClubsState } from './clubs';
import type { State as MyClubsState } from './myclubs';
import type { UnionsState } from './unions';

export default combineReducers({
  club: clubsReducer,
  departments: departmentReducer,
  myclub: myClubsReducer,
  unions: unionsReducer,
});

export * from './clubs';
export * from './departements';
export * from './myclubs';
export * from './unions';

export type ClubStateT = {
  departments: DepartmentsState,
  club: ClubsState,
  myclub: MyClubsState,
  unions: UnionsState,
};
