// @flow
import get from 'lodash/get';
import type { Union } from '../api/club';
import { API_REQUEST } from '../../store/apiAction';
import api from '../../api';

const GET_UNIONS: 'club/GET_UNIONS' = 'club/GET_UNIONS';
const GET_UNIONS_SUCCESS: 'club/GET_UNIONS_SUCCESS' = 'club/GET_UNIONS_SUCCESS';
const GET_UNIONS_FAILED: 'club/GET_UNIONS_FAILED' = 'club/GET_UNIONS_FAILED';

type GetUnionAction = {|
  type: typeof GET_UNIONS,
|};
type GetUnionSuccessAction = {|
  type: typeof GET_UNIONS_SUCCESS,
  result: {
    data: Array<Union>,
  },
|};
type GetUnionFailedAction = {|
  type: typeof GET_UNIONS_FAILED,
  error: {
    response: {
      data: {
        message: string,
      },
    },
  },
|};

export type UnionsState = {
  data: Array<Union>,
  loading: boolean,
  error: ?string,
};

const initialState = {
  data: [],
  loading: false,
  error: null,
};

type UnionActions = GetUnionAction | GetUnionSuccessAction | GetUnionFailedAction;

export default function(state: UnionsState = initialState, action: UnionActions) {
  switch (action.type) {
    case GET_UNIONS:
      return {
        ...initialState,
        loading: true,
      };

    case GET_UNIONS_SUCCESS:
      return {
        ...initialState,
        data: action.result.data,
      };

    case GET_UNIONS_FAILED:
      return {
        ...initialState,
        error: get(action.error, 'response.data.message', String(action.error)),
      };
    default:
      return state;
  }
}

export function getUnions() {
  return {
    type: API_REQUEST,
    types: [GET_UNIONS, GET_UNIONS_SUCCESS, GET_UNIONS_FAILED],
    call: () => api().club.getUnions(),
  };
}
