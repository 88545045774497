// @flow
import API from '../base/api';
import type { ReqType } from '../index';

export type pinCodeType = {
  pinCode: string,
};

class PinCodeAPI extends API {
  check(data) {
    return this.r({
      method: 'POST',
      url: '/user/check-pin',
      data: {
        pin_code: data,
      },
    });
  }
}

export default function(r: ReqType): PinCodeAPI {
  return new PinCodeAPI(r);
}
