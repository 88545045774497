// @flow
import React, { Component } from 'react';
import { View, Text, TouchableOpacity, Image } from 'react-native';
import { chain, get } from 'lodash';
import styles from './styles';
import FullScreenModal from '../FullScreenModal/index';
import GenericSelectScreen from '../../screens/GenericSelectScreen';
import Select from 'react-select';

const iconCarret = require('../../assets/arrow.png');

type KeyTypeT = string | number;

type OptionTypeT = {
  [key: string]: *,
};

type PropsT = {
  data: Array<OptionTypeT>,
  onChange: (item: OptionTypeT) => void,
  value: ?KeyTypeT,
  keyName: string,
  labelName: string,
  iconName?: string,
  defaultText?: string,
  titleText?: string,
  confirmText?: string,
  cancelText?: string,
  label: string,
  style: Object,
};

type StateT = {
  shown: boolean,
};

export default class PickerOld extends Component<PropsT, StateT> {
  static defaultProps = {
    data: [],
    onChange: () => {},
    value: null,
    keyName: 'key',
    labelName: 'label',
    iconName: 'icon',
    defaultText: 'Please select',
    titleText: 'Chose one',
    confirmText: 'confirm',
    cancelText: 'cancel',
    style: {},
  };

  constructor(props: PropsT) {
    super(props);
    this.state = {
      shown: false,
    };
  }

  get pickerOptions(): Array<string> {
    return chain(this.props.data).map(this.props.labelName).uniq().filter().value();
  }

  getSelectedValue(): ?OptionTypeT {
    if (this.props.value == null) {
      return null;
    }

    return this.props.data.find((el) => get(el, this.props.keyName) === this.props.value);
  }

  showPicker = () => {
    this.setState({ shown: true });
  };

  renderValue = () => {
    const selected = this.getSelectedValue();
    return (
      <Text style={selected ? styles.inputText : styles.placeholderText}>
        {selected ? selected[this.props.labelName] : this.props.defaultText}
      </Text>
    );
  };

  render() {
    const { label } = this.props;
    const selected = this.getSelectedValue();
    const byKey = {};
    const options = this.props.data.map((item) => {
      let label = item[this.props.labelName];
      if (item[this.props.iconName]) {
        label = (
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <img
              src={item[this.props.iconName]}
              style={{ width: 40, height: 40, objectFit: 'contain', marginRight: 16 }}
            />
            {label}
          </div>
        );
      }
      const val = {
        value: item[this.props.keyName],
        label,
      };
      byKey[item[this.props.keyName]] = val;

      return val;
    });

    const value = this.props.value ? byKey[this.props.value] : null;

    return (
      <>
        {label && <Text style={styles.label}>{label.toUpperCase()}</Text>}
        <Select
          placeholder={this.props.defaultText}
          value={value}
          onChange={({ value }) => {
            this.setState({ shown: false });
            const chosen = this.props.data.find((el) => get(el, this.props.keyName) === value);
            if (chosen) {
              this.props.onChange(chosen);
            } else {
              this.props.onChange(this.props.data[0]);
            }
          }}
          options={options}
          styles={{
            control: (provided, state) => ({
              ...provided,
              height: 42,
              ...this.props.style,
            }),
            menuList: (provided) => ({
              ...provided,
              zIndex: 1000001,
            }),
          }}
        />
      </>
    );
  }
}
