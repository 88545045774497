// @flow
import React, { useRef, useEffect } from 'react';
import { Animated, Easing } from 'react-native';

import { Root, Image, ARROW_WIDTH } from './styles';

const ARROW_SLIDE_TIME = 300;
const ARROW_SLIDE_DELAY = 150;
const FADE_DURATION = 500;
const FADE_OFFSET = 700;

const HintArrows = () => {
  const { current: translate0 } = useRef(new Animated.Value(0));
  const { current: translate1 } = useRef(new Animated.Value(0));
  const { current: fadeIn0 } = useRef(new Animated.Value(0));
  const { current: fadeIn1 } = useRef(new Animated.Value(0));

  const arrow0Styles = { transform: [{ translateX: translate0 }], opacity: fadeIn0 };
  const arrow1Styles = { transform: [{ translateX: translate1 }], opacity: fadeIn1 };
  useEffect(() => {
    let currentAnimation;
    const startIteration = ({ finished }) => {
      if (!finished) { // Was stopped as component was unmounted
        return;
      }
      // Reset values
      translate0.setValue(ARROW_WIDTH);
      translate1.setValue(ARROW_WIDTH);
      fadeIn0.setValue(0);
      fadeIn1.setValue(0);
      const slideIn = { toValue: 0, duration: ARROW_SLIDE_TIME, useNativeDriver: true, easing: Easing.ease };
      const fadeIn = { toValue: 1, duration: ARROW_SLIDE_TIME, useNativeDriver: true };
      const fadeOut = { toValue: 0, duration: FADE_DURATION, delay: FADE_OFFSET, useNativeDriver: true };
      currentAnimation = Animated.sequence([
        Animated.parallel([
          Animated.timing(fadeIn0, fadeIn),
          Animated.timing(translate0, slideIn),
          Animated.timing(fadeIn1, { ...fadeIn, delay: ARROW_SLIDE_DELAY }),
          Animated.timing(translate1, { ...slideIn, delay: ARROW_SLIDE_DELAY }),
        ]),
        Animated.parallel([
          Animated.timing(fadeIn0, fadeOut),
          Animated.timing(fadeIn1, fadeOut),
        ], { useNativeDriver: true }),
      ]);
      currentAnimation.start(startIteration);
    };
    startIteration({ finished: true });
    // Stop animation when component is unmount
    return () => currentAnimation.stop();
  }, []);
  return (
    <Root>
      <Image style={arrow0Styles}/>
      <Image style={arrow1Styles}/>
    </Root>
  );
};

export default HintArrows;
