export const patterns = {
  name: /^[а-яА-Я a-zA-Z æ ø å Æ Ø Å ü ä ö ß _ '-]{1,30}$/,
  firstName: /^[а-яА-Я a-zA-Z æ ø å Æ Ø Å ü ä ö ß _ '-]{1,30}$/,
  lastName: /^[а-яА-Я a-zA-Z æ ø å Æ Ø Å ü ä ö ß _ '-]{1,30}$/,
  email: /.+\@.+\..+/i,
  password: /.{6,30}$/,
  unionNumber: /^([0-9-]+)$/,
  phone: /^\+\d{10,12}$/,
  pinCode: /^[0-9]{4,30}$/,
  hcp: /^[0-9]+/,
};
