// @flow
import React, { Component } from 'react';
import { Animated, Easing } from 'react-native';
// import Icon from 'react-native-vector-icons/FontAwesome';
import styles from '../../constants/styles';

const { colors, font, size } = styles;

const Icon = () => null;

export default class LoadingImage extends Component {
  constructor(props) {
    super(props);
    this.spinValue = new Animated.Value(0);
  }
  componentDidMount() {
    this.doRotate();
  }
  doRotate() {
    this.spinValue.setValue(0);
    Animated.timing(this.spinValue, {
      toValue: 1,
      duration: 2000,
      easing: Easing.linear,
    }).start(() => this.doRotate());
  }
  render() {
    const rotate = this.spinValue.interpolate({
      inputRange: [0, 1],
      outputRange: ['0deg', '360deg'],
    });
    const iconSize = this.props.iconSize === 'large' ? 40 : 23;
    return (
      <Animated.View style={{ transform: [{ rotate }] }}>
        <Icon name="spinner" color={this.props.iconColor || colors.loadingImage} size={iconSize} />
      </Animated.View>
    );
  }
}
