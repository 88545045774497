// @flow
import { takeEvery, put } from 'redux-saga/effects';
import { Alert } from 'react-native';
import i18n from 'react-native-i18n';

import { FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORD_SUCCESS_GO_BACK } from '../../user/modules/password';

function showAlert() {
  return new Promise((resolve) => {
    Alert.alert(
      i18n.t('forgotPassword.checkEmail'),
      i18n.t('forgotPassword.backToLogIn'),
      [{ text: 'OK', onPress: () => resolve(true) }],
      {
        cancelable: false,
      },
    );
  });
}

function* goToLogin(): Generator<*, *, *> {
  yield showAlert();
  // yield put(NavigationActions.back());
}

function* goBack() {
  // yield put(NavigationActions.back());
}

export default function* (): Generator<*, *, *> {
  // yield takeEvery(FORGOT_PASSWORD_SUCCESS, goToLogin);
  yield takeEvery(FORGOT_PASSWORD_SUCCESS_GO_BACK, goBack);
}
