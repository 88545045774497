// @flow
import { StyleSheet } from 'react-native';
import styles from '../../../constants/styles';

const { colors, font, size } = styles;

export default StyleSheet.create({
  root: {
    flex: 1,
    alignSelf: 'stretch',
    backgroundColor: colors.athens_gray,
  },
  rootNarrow: {
    flex: 1,
    alignSelf: 'stretch',
    alignItems: 'center',
    backgroundColor: colors.athens_gray,
  },
  screen: {
    width: 500,
    flex: 1,
  },
  registrationForm: {
    marginTop: 10,
    flex: 1,
  },

  inputText: {
    marginTop: 20,
    color: colors.grey,
    fontSize: font.size.xSmall,
    marginBottom: 8,
  },
  phoneInputFields: {
    flexDirection: 'row',
    marginBottom: -8, // TODO: fix spacing between cells in more consistent way
  },
  phoneCC: {
    width: 65,
    marginRight: 10,
    paddingLeft: 0,
    paddingRight: 0,
    alignItems: 'center',
  },
  phone: {
    flex: 1,
  },
  btnSignUp: {
    alignItems: 'center',
    position: 'absolute',
    paddingVertical: 20,
    right: 0,
    top: -30,
    backgroundColor: colors.green,
    height: 60,
    width: '40%',
    borderTopLeftRadius: size.borderRadius,
    borderBottomLeftRadius: size.borderRadius,
    justifyContent: 'center',
  },
  btnLogInLoading: {
    opacity: 0.5,
  },
  btnSignUpText: {
    color: colors.basic,
    ...font.style.medium,
    fontSize: font.size.normal,
  },
  DGUEmail: {
    color: colors.slate_gray,
  },
  bottomField: {
    alignItems: 'flex-end',
  },
  errorMessage: {
    top: 5,
    color: colors.red,
    textAlign: 'center',
    fontSize: font.size.normal,
    ...font.style.regular,
    alignSelf: 'flex-start',
  },
  switchRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 10,
    borderBottomWidth: 1,
    borderBottomColor: styles.colors.athens_gray,
  },
  switchText: {
    marginTop: 0,
  },
  agreementContainer: {
    paddingTop: 20,
    alignItems: 'center',
  },
  agreement: {
    paddingRight: 40,
    paddingLeft: 40,
    marginBottom: 50,
    color: colors.grey,
    fontSize: font.size.small,
    ...font.style.regular,
  },
  agreementBold: {
    ...font.style.bold,
  },
});

export const colorGray = colors.grey;
