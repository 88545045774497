// @flow
import { StyleSheet } from 'react-native';
import styles from '../../../constants/styles';

const { colors, font, size } = styles;

export default StyleSheet.create({
  rootNarrow: {
    flex: 1,
    alignSelf: 'stretch',
    alignItems: 'center',
    backgroundColor: colors.athens_gray,
  },
  screen: {
    flex: 1,
  },
  registrationForm: {
    width: 500,
    flex: 1,
    // backgroundColor: colors.white,
    // padding: 40,
    // borderTopRightRadius: size.borderRadius,
    // borderBottomRightRadius: size.borderRadius,
    // marginRight: 20,
  },
  inputText: {
    marginTop: 15,
    color: colors.slate_gray,
    fontSize: font.size.normal,
    ...font.style.regular,
  },
  btnNext: {
    alignItems: 'center',
    position: 'absolute',
    paddingVertical: 20,
    right: 0,
    top: -30,
    backgroundColor: colors.green,
    height: 60,
    width: '40%',
    borderTopLeftRadius: size.borderRadius,
    borderBottomLeftRadius: size.borderRadius,
    justifyContent: 'center',
  },
  btnNextText: {
    color: colors.basic,
    ...font.style.medium,
    fontSize: font.size.normal,
  },
  bottomField: {
    paddingTop: 45,
  },
  textOr: {
    marginRight: 40,
    marginBottom: 20,
    textAlign: 'right',
    color: colors.grey,
    fontSize: font.size.normal,
    ...font.style.regular,
  },
  btnSignUpNonUnion: {
    marginLeft: 40,
    height: 50,
    justifyContent: 'center',
    borderTopLeftRadius: size.borderRadius,
    borderBottomLeftRadius: size.borderRadius,
    backgroundColor: colors.geyser,
  },
  btnSignUpNonUnionText: {
    color: colors.slate_gray,
    ...font.style.medium,
    fontSize: font.size.normal,
    textAlign: 'center',
  },
  errorMessage: {
    marginTop: 5,
    color: colors.red,
    fontSize: font.size.normal,
    ...font.style.regular,
    alignSelf: 'flex-start',
  },
});

export const colorGray = colors.grey;
