import React, { Component } from 'react';
import propTypes from 'prop-types';
import { CELL_PHONE, APP_STORE, GOOGLE_PLAY } from '../../../constants/config/images';
import texts from '../../../constants/languages';

import './home.css';

class Home extends Component {
  componentDidMount() {
    this.props.getGeoIp();
    this.openLogInPopupOnDefault();
  }

  openLogInPopupOnDefault = () => {
    if (window.localStorage.getItem('logInPopupIsOpen')) {
      // window.location.reload();

      this.props.togglePopup('logInPopupIsOpen', true);
      window.localStorage.removeItem('logInPopupIsOpen');
    }
  };

  render() {
    return (
      <div className="b-home">
        <div className="b-home__banner">
          <div className="container grid-xl b-home__banner-container g-flex g-container">
            <div className="column col-7 col-sm-12 col-mx-auto">
              <h1 className="b-home__banner-title">{texts.LANDING_TITLE}</h1>
            </div>

            <button
              className="columns dr-button col-5 col-sm-12"
              onClick={() => {
                window.location.href = 'https://driving-range.golfmore.eu ';
              }}
            >
              <div className="column col-12 col-sm-12 col-mx-auto">
                <h1 className="b-home__dr-title">{texts.DRIVING_RANGE_PROMO}</h1>
              </div>
            </button>
          </div>
        </div>
        <div className="container grid-xl b-profits">
          <div className="columns b-profits__container g-container g-flex">
            <div className="column col-6 col-mx-auto col-lg-6 col-md-6 col-sm-12 b-profits__points">
              <ul className="b-profits__list">
                <li className="b-profits__item">
                  <h2 className="b-profits__sub-title">{texts.LANDING_SUB_TITLE_1}</h2>
                  <p className="b-profits__description">{texts.LANDING_TEXT_1}</p>
                </li>
                <li className="b-profits__item">
                  <h2 className="b-profits__sub-title">{texts.LANDING_SUB_TITLE_2}</h2>
                  <p className="b-profits__description">{texts.LANDING_TEXT_2}</p>
                </li>
                <li className="b-profits__item">
                  <h2 className="b-profits__sub-title">{texts.LANDING_SUB_TITLE_3}</h2>
                  <p className="b-profits__description">{texts.LANDING_TEXT_3}</p>
                </li>
                <li className="b-profits__item">
                  <h2 className="b-profits__sub-title">{texts.LANDING_SUB_TITLE_4}</h2>
                  <p className="b-profits__description">{texts.LANDING_TEXT_4}</p>
                </li>
              </ul>
            </div>
            <div className="column col-6 col-mx-auto col-lg-6 col-md-6 col-sm-12 b-profits__image">
              <img className="iphone-image" src={CELL_PHONE} width={303} height={617} alt="phone" />
            </div>
          </div>
        </div>

        <div className="b-downloads">
          <div className="columns b-downloads__container g-flex">
            <div className="column col-6 col-mx-auto col-lg-6 col-md-6 col-sm-12 b-downloads__store m-downloads__store_ios">
              <div className="b-downloads__store-holder m-downloads__store-holder_ios">
                <h2 className="b-downloads__title">{texts.IOS_TITLE}</h2>
                <p className="b-downloads__description">{texts.IOS_DESCRIPTION}</p>
                <a className="b-downloads__link" href="//itunes.apple.com/app/id1318265063" target="blank">
                  <img src={APP_STORE} width={162} height={50} alt="golf-more-app-store" />
                </a>
              </div>
            </div>
            <div className="col-6 col-mx-auto col-lg-6 col-md-6 col-sm-12 b-downloads__store m-downloads__store_android">
              <div className="b-downloads__store-holder m-downloads__store-holder_android">
                <h2 className="b-downloads__title">{texts.ANDROID_TITLE}</h2>
                <p className="b-downloads__description">{texts.ANDROID_DESCRIPTION}</p>
                <a
                  className="b-downloads__link m-downloads__link_android"
                  href="//play.google.com/store/apps/details?id=com.golfmore"
                  target="blank"
                >
                  <img src={GOOGLE_PLAY} width={169} height={50} alt="golf-more-google-play" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;

Home.propTypes = {
  getGeoIp: propTypes.func.isRequired,
  //unions: propTypes.array.isRequired,
  //setUnions: propTypes.func.isRequired,
  // signUpStepOnePopupIsOpen: propTypes.bool.isRequired,
  //logInPopupIsOpen: propTypes.bool.isRequired,
  // forgotPasswordPopupIsOpen: propTypes.bool.isRequired,
  togglePopup: propTypes.func.isRequired,
};
