// @flow

import getEndpoint, { getMoreboxEndpoint } from '../../constants/config/host';

export const API_MOREBOX = getMoreboxEndpoint();

const projectId = 'dev';

export function getEnv() {
  return projectId;
}

export function getApiEndpointUrl() {
  return getEndpoint;
}

export default {
  baseURL: getEndpoint,
  defaultRegistrationDate: '1970-06-15',
};
