// @flow
import styled from 'styled-components/native';
import { space, color, border, layout, flex } from 'styled-system';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { TouchableOpacity, Image } from 'react-native';
import { elevation1, elevation4, elevation6, elevation8 } from '../../../helpers/styles';
import { TextXxs, TextXs, TextS, TextM, TextL, TextXl } from '../../../components/Text';
import NewButton from '../../../components/Button/NewButton';
import CheckImage from '../../../components/CheckImage';

const favoriteChecked = require('./assets/icon-favorites.png');
const favoriteUnchecked = require('./assets/icon-favorites-default.png');

export const Touchable = styled.TouchableWithoutFeedback``;

export const Root = styled.View.attrs({
  flex: 1,
  bg: 'bgPrimary',
})`
  flex-direction: column-reverse;
  ${color}
  ${flex}
`;

export const ContentContainer = styled(KeyboardAwareScrollView).attrs({
  bg: 'bgPrimary',
  keyboardShouldPersistTaps: 'handled',
  contentContainerStyle: { paddingBottom: 12 },
})`
  ${color}
  ${flex}
`;

export const LoadingContainer = styled.View.attrs({
  flex: 1,
  bg: 'white',
})`
  justify-content: center;
  align-items: center;
  ${color}
  ${flex}
`;

export const LoadingIndicator = styled.ActivityIndicator.attrs({
  size: 'large',
})``;

export const HeaderContainer = styled.View.attrs({
  pb: 6,
  bg: 'white',
  ...elevation4,
})`
  justify-content: center;
  align-items: center;
  ${space}
  ${color}
  ${flex}
`;

export const HeaderText = styled(TextL).attrs({
  fontFamily: 'medium',
})``;

export const InfoContainer = styled.View.attrs({
  bg: 'bgSecondary',
  pt: 6,
  ...elevation1,
})`
  align-items: center;
  ${color}
  ${space}
  ${flex}
`;

export const LogoContainer = styled.View.attrs({
  borderRadius: 65,
  borderWidth: 1,
  borderColor: 'basic30',
  p: 3,
  bg: 'white',
  width: 130,
  height: 130,
})`
  align-items: center;
  ${color}
  ${border}
  ${space}
  ${layout}
  ${flex}
`;

export const Logo = styled.Image.attrs({
  flex: 1,
  resizeMode: 'contain',
  width: 130,
  height: 130,
})`
  ${layout}
  ${flex}
`;

export const PriceContainer = styled.View.attrs({
  borderTopRightRadius: 6,
  borderBottomLeftRadius: 6,
  pt: 1,
  pb: 1,
  pl: 3,
  pr: 3,
  bg: 'green',
  position: 'absolute',
  right: 110,
  top: 8,
  ...elevation4,
})`
  justify-content: center;
  align-items: center;
  ${layout}
  ${space}
  ${color}
  ${flex}
`;

export const PriceText = styled(TextS).attrs({
  color: 'white',
})``;

export const TypeImage = styled.Image.attrs({
  width: 80,
  height: 80,
  position: 'absolute',
  right: -44,
  bottom: -10,
  resizeMode: 'contain',
})`
  ${layout}
  ${flex}
`;

export const OwnerName = styled(TextL).attrs({
  fontFamily: 'medium',
  mt: 20,
})``;

export const VoucherName = styled(TextM).attrs({
  color: 'green',
  mt: 1,
})``;

export const ProductNameContainer = styled.View.attrs({
  bg: 'grey',
  p: 2,
  mt: 7,
})`
  align-self: stretch;
  align-items: center;
  justify-content: center;
  ${color}
  ${space}
  ${layout}
  ${flex}
`;

export const ProductName = styled(TextS).attrs({
  color: 'white',
})``;

export const DetailsRow = styled.View.attrs({
  pt: 6,
  pb: 6,
})`
  flex-direction: row;
  align-self: stretch;
  align-items: flex-start;
  ${layout}
  ${space}
  ${flex}
`;

export const DetailContainer = styled.View.attrs({
  flex: 1,
})`
  align-items: center;
  justify-content: center;
  ${flex}
`;

export const DetailImageContainer = styled.View.attrs({
  width: 30,
  height: 30,
})`
  justify-content: flex-end;
  align-items: center;
  ${layout}
  ${flex}
`;

export const DetailImage = styled.Image.attrs({
  resizeMode: 'contain',
})`
  ${layout}
  ${flex}
`;

export const DetailText = styled(TextXs).attrs({
  color: 'textBlack',
  textAlign: 'center',
  marginTop: 4,
})``;

export const VoucherBubbleContainer = styled.View.attrs({
  bg: 'bubbleRed',
  borderRadius: 10,
  width: 20,
  height: 20,
  position: 'absolute',
  top: -2,
  right: -14,
  ...elevation6,
})`
  align-items: center;
  justify-content: center;
  ${border}
  ${layout}
  ${color}
  ${flex}
`;

export const VoucherBubbleText = styled(TextXxs).attrs({
  fontFamily: 'semibold',
  color: 'white',
  fontSize: 9,
})`
  ${flex}
`;

export const VoucherContainer = styled.View.attrs({
  bg: 'bgSecondary',
  mt: 4,
  pt: 6,
  pb: 8,
  ...elevation1,
})`
  align-items: center;
  ${space}
  ${color}
  ${flex}
`;

export const VoucherNumber = styled(TextS).attrs({
  color: 'gull_gray',
  fontFamily: 'italic',
  mb: 4,
})`
  ${flex}
`;

export const VoucherInfo = styled(TextS).attrs({
  mb: 4,
})``;

export const VoucherListContainer = styled.View`
  flex-direction: row;
  align-self: stretch;
`;

export const VoucherItemContainer = styled.View.attrs({})`
  align-items: center;
  justify-content: center;
  align-items: center;
`;

export const VoucherBarcode = styled(Image).attrs({
  resizeMode: 'stretch',
  flex: 1,
  width: 180,
  height: 82,
  ml: 6,
  mr: 6,
})`
  ${layout}
  ${space}
  ${flex}
`;

export const BarcodeArrowContainer = styled.View.attrs(({ gravity }) => ({
  flex: 1,
  pr: gravity === 'left' ? 6 : 0,
  pl: gravity === 'left' ? 0 : 6,
  alignItems: gravity === 'left' ? 'flex-start' : 'flex-end',
  height: 82,
}))`
  justify-content: center;
  ${space}
  ${color}
  ${layout}
`;

export const BarcodeArrow = styled.Image.attrs(({ direction, enabled }) => ({
  resizeMode: 'contain',
  height: 26,
  transform: [{ rotate: `${direction === 'rtl' ? 0 : 180}deg` }],
  opacity: enabled ? 1 : 0.15,
}))`
  ${layout}
`;

export const BarcodeText = styled(TextXl).attrs({
  fontFamily: 'medium',
  letterSpacing: 4.62,
  mt: 5,
  mb: 5,
})``;

export const TransferButton = styled(NewButton)``;
export const RedeemButton = styled(TouchableOpacity).attrs({
  m: 2,
  p: 2,
  mb: 8,
  bg: 'button',
  ...elevation8,
})`
  height: 150px;
  width: 95%;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  ${space}
  ${color}
`;

export const RedeemText = styled(TextXl).attrs({
  fontFamily: 'medium',
  textAlign: 'center',
  color: 'white',
})``;

export const TimeLeft = styled(TextXl).attrs({
  fontFamily: 'italic',
  color: 'white',
  fontSize: 12,
})``;

export const FavoritesCheck = styled(CheckImage).attrs({
  iconChecked: favoriteChecked,
  iconUnchecked: favoriteUnchecked,
  width: 22,
  height: 22,
  mr: 3,
})``;

export const VoucherActive = styled.View.attrs({
  backgroundColor: 'green',
  pt: 4,
  pb: 4,
  pl: 4,
  pr: 4,
})`
  flex-direction: column;
  justify-content: space-around;
  ${color}
  ${space}
`;

export const VoucherActiveText = styled(TextXl).attrs({ color: 'white', pt: 7 })`
  text-align: center;
  ${space}
  ${color};
`;
export const VoucherActiveTimeLeftText = styled(TextM).attrs({ color: 'white', pb: 7 })`
  text-align: center;
  ${space}
  ${color};
`;

export const ShowIfRequired = styled(TextS).attrs({ color: 'white', fontFamily: 'italic' })`
  text-align: center;
  ${space}
  ${color};
`;
