const getLocation = () => {
  const { hostname } = window.location;

  switch (hostname) {
    case 'dev.golfmore.club':
    case 'golfmore.club':
    case 'localhost.en':
      return 'english';

    case 'dev.golfmore.dk':
    case 'localhost.dk':
    case 'golfmore.dk':
      return 'danish';

    case 'localhost.no':
    case 'no.golfmore.dk':
    case 'dev.no.golfmore.dk':
      return 'norwegian';

    case 'localhost.de':
    case 'golfmore.de':
    case 'dev.golfmore.de':
    case 'stage.golfmore.de':
      return 'german';

    case 'localhost.sv':
    case 'golfmore.se':
    case 'dev.golfmore.se':
      return 'swedish';
    default:
      return 'english';
  }
};

export default getLocation;
