// @flow
import { StyleSheet } from 'react-native';
import styles from '../../constants/styles';

const { colors, font } = styles;

export default StyleSheet.create({
  picker: {
    // marginTop: 15,
    height: 44,
    justifyContent: 'center',
    paddingLeft: 20,
    paddingRight: 8,
    borderWidth: 1,
    borderColor: styles.colors.basic30,
    borderRadius: 5,
    color: styles.colors.basic,
    backgroundColor: styles.colors.white,
  },
  inputText: {
    color: colors.textBlack,
    fontSize: font.size.normal,
    ...font.style.regular,
  },
  placeholderText: {
    color: styles.colors.slate_gray,
    fontSize: font.size.normal,
    ...font.style.regular,
  },
  label: {
    marginTop: 20,
    color: colors.grey,
    fontSize: font.size.xSmall,
    marginBottom: 8,
  },
  carret: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 8,
    paddingLeft: 0,
  },
  mainView: {
    backgroundColor: '#00000011',
    flex: 1,
    width: '100%',
    zIndex: 1,
  },
  dropDownIcon: {
    width: 16,
    height: 16,
    resizeMode: 'contain',
    transform: [
      {
        rotate: '90deg',
      },
    ],
  },
});
