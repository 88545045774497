// @flow
import { Platform } from 'react-native';
import AsyncStorage from '@react-native-community/async-storage';
import { takeEvery, put, select, call, putResolve } from 'redux-saga/effects';
import get from 'lodash/get';
import { navigate } from '../../store/modules/navigation';

import api from '../../api';
import { promisify } from '../../store/apiAction';
import {
  LOGIN,
  LOGOUT_SUCCESS,
  LOGIN_IMPORTED_TOKEN,
  LOGIN_AND_UPDATE_ACCOUNT,
  UPDATE_WITH_SESSION_ID,
  loginFailed,
  loginSuccess,
  loginAutoSuccess,
  loginAutoFailed,
} from '../modules/session';
import { loadUser, loadLocalUser, updateUser } from '../../user/modules';
import { loadMyClubs } from '../../clubs/modules';
// import { loadNewNotifications } from '../../notifications/modules/notification';
import { USER_KEY } from '../../user/sagas/user';
// import { getToken } from '../../notifications/sagas/pushNotifications';
import history from '../../../history';
import { handleLogIn } from '../../../domains/auth/actions';

const TOKEN_KEY = 'golfMore@authToken';

const getConnectionState = ({ connection }) => connection.hasConnection;
// const loadNewNotificationsP = promisify(loadNewNotifications);
const loadUserP = promisify(loadUser);

function* login(token: string, autoLogin = false) {
  api().setToken(token);

  yield put(loginSuccess());
  if (autoLogin) {
    yield put(loginAutoSuccess());
  }

  yield AsyncStorage.setItem(TOKEN_KEY, token);

  const hasConnection = yield select(getConnectionState);
  if (hasConnection) {
    // yield putResolve(loadNewNotificationsP(true));
    const userResponse = yield putResolve(loadUserP());

    if (!userResponse.data.email_confirmed) {
      yield call(navigate, { routeName: 'VerifyAccount' });
      return;
    }
  } else {
    const jsonString = yield AsyncStorage.getItem(USER_KEY);
    const user = JSON.parse(jsonString);
    yield put(loadLocalUser());

    if (!user.email_confirmed) {
      yield call(navigate, { routeName: 'VerifyAccount' });
      return;
    }
  }

  if (Platform.OS === 'web') {
    webGetToken(token);
    yield put(handleLogIn(true));
  } else {
    yield call(navigate, { routeName: 'Tabs' });
  }
}

function* loginAuto(importedToken?: string) {
  try {
    const token = importedToken || (yield AsyncStorage.getItem(TOKEN_KEY));
    if (token) {
      yield* login(token, true);
    } else {
      yield put(loginAutoFailed('No token'));
    }
  } catch (error) {
    const status = get(error, 'response.status');
    if (status === 403) {
      AsyncStorage.removeItem(TOKEN_KEY);
      return;
    }

    yield put(loginAutoFailed(get(error, 'response.data.message', String(error))));
  }
}

function* loginWithImportedToken(action) {
  try {
    const token = action.payload;
    if (token) {
      yield* login(token, true);
    } else {
      yield put(loginAutoFailed('No token'));
    }
  } catch (error) {
    const status = get(error, 'response.status');
    if (status === 403) {
      AsyncStorage.removeItem(TOKEN_KEY);
      return;
    }

    yield put(loginAutoFailed(get(error, 'response.data.message', String(error))));
  }
}

function webGetToken(sessionId) {
  localStorage.setItem('userToken', sessionId);
  const transferProcess = localStorage.getItem('transferProcess');
  if (transferProcess) {
    setTimeout(() => {
      localStorage.removeItem('transferProcess');
      window.location = `/voucher-subtransfer/${transferProcess}`;
    }, 3000);
  } else {
    if (!window.location.pathname.startsWith('/voucher-subtransfer')) {
      history.replace('/vouchers');
    }
  }
}

function* createSession({ data }) {
  try {
    const result = yield api().session.create({
      ...data, // email & password
      language: 'EN',
      device_platform: Platform.OS,
    });

    yield AsyncStorage.setItem(TOKEN_KEY, result.data.session_id);
    yield* login(result.data.session_id);
  } catch (error) {
    const message = get(error, 'response.data.message', String(error));
    const status = get(error, 'response.status', 0);
    yield put(loginFailed(message, status));
  }
}

function* updateWithSessionId({ payload: { updateSessionId, accountUpdateInfo } }) {
  try {
    yield AsyncStorage.setItem(TOKEN_KEY, updateSessionId);

    yield* login(updateSessionId);
    console.log('Login success', accountUpdateInfo);
    yield put(updateUser(accountUpdateInfo));
    console.log('UpdateUser success');
    yield put(loadMyClubs());
  } catch (error) {
    yield put(loginFailed(get(error, 'response.data.message', String(error))));
  }
}

function* loginAndUpdateAccount({ payload: { email, password, accountUpdateInfo } }) {
  try {
    const result = yield api().session.create({
      email,
      password,
      language: 'EN',

      device_platform: Platform.OS,
    });

    yield AsyncStorage.setItem(TOKEN_KEY, result.data.session_id);

    yield* login(result.data.session_id);
    console.log('Login success');
    yield put(updateUser(accountUpdateInfo));
    console.log('UpdateUser success');
    yield put(loadMyClubs());
  } catch (error) {
    yield put(loginFailed(get(error, 'response.data.message', String(error))));
  }
}

function* logout() {
  yield call(AsyncStorage.removeItem, TOKEN_KEY);
  yield call(AsyncStorage.removeItem, USER_KEY);
  yield call(navigate, { routeName: 'Auth' });
}

export default function* (): Generator<*, *, *> {
  yield takeEvery(LOGIN, createSession);
  yield takeEvery(LOGIN_IMPORTED_TOKEN, loginWithImportedToken);
  yield takeEvery(LOGIN_AND_UPDATE_ACCOUNT, loginAndUpdateAccount);
  yield takeEvery(UPDATE_WITH_SESSION_ID, updateWithSessionId);
  // yield takeEvery(LOGIN_AUTO, loginAuto);
  yield takeEvery(LOGOUT_SUCCESS, logout);
  yield call(loginAuto); // call loginAuto on every initialization attempt
}
