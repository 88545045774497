// @flow
import { API_MOREBOX } from '../../constants/config';
import axios from 'axios';

export type OAuthData =
  | {
      birthDate: string,
      clubId: number,
      clubName: string,
      country: string,
      email: string,
      firstName: string,
      golfId: string,
      golfUnionId: number,
      lastName: string,
      memberGuid: string,
      memberType: string,
      sex: 'male' | 'female',
      existing_golf_ids?: string,
      updateSessionId?: boolean,
      nfcCardNumber?: string,
    }
  | {
      created_at: number,
      device_platform?: string,
      device_token?: string,
      expires: number,
      language: string,
      last_activity_time: number,
      session_id: string,
      user_id: number,
      user_type: string,
    };

const gitUrl = (country: string, golfId: string, password: string) =>
  `${API_MOREBOX}/login/git/do-signin?countryCode=${country}&password=${password}&golfId=${golfId}`;

export const loginGit = async (login: string, password: string) => {
  const url = gitUrl('SE', login, password);
  try {
    const resp = await axios.get(url);
    const t = resp.data;
    console.log('GOT text', t);
    try {
      const error = JSON.parse(t);

      return error;
    } catch (err) {
      // not JSON, try parse html now
      const json = extractJson(t);
      console.log('GOT json', json);
      return json;
    }
  } catch (err) {
    if (err.response.data) {
      return err.response.data;
    }
    console.warn(err);
  }
};

const sampleHtml =
  '<html><script type="text/javascript">window.ReactNativeWebView.postMessage(JSON.stringify({"golfId":"910804-003","firstName":"Adam","lastName":"Bj\u00f6rkegren","email":"adam.bjorkegren@gmail.com","phoneNumber":" 46707100221","country":"SE","birthDate":"1991-08-04","sex":"m","golfUnionId":1}));</script></html>';

function extractJson(html: string) {
  const re = new RegExp(/JSON.stringify\((.+)\)\)/gm);
  const m = re.exec(html);

  if (m && m[1]) {
    return JSON.parse(m[1]);
  } else {
    throw new Error('Can not extract JSON');
  }
}
