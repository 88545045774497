import LocalizedStrings from 'react-localization';
import english from './english';
import denmark from './denmark';
import norwegian from './norwegian';
import svenska from './svenska';
import deutsch from './de/de.json';
import store from '../../store';
import { localeSelector } from '../../selectors/locale';

const texts = new LocalizedStrings({
  en: english,
  da: denmark,
  no: norwegian,
  sv: svenska,
  de: deutsch,
});

const state = store.getState();
const locale = localeSelector(state);
texts.setLanguage(locale);

export default texts;
