// @flow
import React from 'react';
import Input from '../Input/Input';
import { Text, StyleSheet } from 'react-native';
import styles from '../../constants/styles';

const { colors, font } = styles;

const iStyle = StyleSheet.create({
  inputText: {
    marginTop: 20,
    color: colors.grey,
    fontSize: font.size.xSmall,
    marginBottom: 8,
  },
  errorMessage: {
    top: 10,
    color: colors.errorRed,
    alignSelf: 'flex-start',
  },
});

type PropTypes = {
  error?: Boolean,
  errorText?: string,
};

const FormInput = props => {
  const {
    labelStyle,
    style,
    children,
    value,
    label,
    onChangeText,
    theme = 'light',
    error,
    errorText,
    ...restProps
  } = props;
  return (
    <>
      {label && <Text style={iStyle.inputText}>{label.toUpperCase()}</Text>}
      <Input
        style={[style, error ? { borderColor: colors.errorRed } : {}]}
        keyboardType="email-address"
        value={value}
        onChangeText={onChangeText}
        theme={theme}
        {...restProps}
      />
      {error && <Text style={iStyle.errorMessage}>{errorText}</Text>}
    </>
  );
};

export default FormInput;
