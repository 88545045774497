import React from 'react';

import urls from '../../../../constants/config/urls';

const privacyPolicyGerman = () => (
  <div className="b-privacy-policy__content grid-xl">
    <h1 className="b-privacy-policy__title">DATENSCHUTZRICHTLINIE (VERSION 1.1, AKTUALISIERT JUNI 2018)</h1>
    <p className="b-privacy-policy__paragraph">
      In diesem Dokument beziehen sich "GolfMore", "wir", "uns" und "unser" auf ProBox24 ApS mit Sitz in Rugaardsvej 5,
      8680 Ry, Dänemark und seine verbundenen Unternehmen.
    </p>
    <p className="b-privacy-policy__paragraph">
      Durch die Nutzung der GolfMore-App oder anderweitige Nutzung unserer Websites, einschließlich, aber nicht
      beschränkt auf
      <a className="b-privacy-policy__link" href={urls.german}>
        {' '}
        www.golfmore.de
      </a>
      , oder unserer Anwendungen (zusammen als „die Website“ bezeichnet), stimmen Sie hiermit zu, dass wir Ihre
      personenbezogenen Daten verarbeiten („die Richtlinie“ ) wie in dieser Richtlinie beschrieben.
    </p>
    <ul className="b-privacy-policy__list">
      <li className="b-privacy-policy__item">
        <h2 className="b-privacy-policy__sub-title">
          1 Arten von Informationen, die wir sammeln und wofür wir sie verwenden
        </h2>
        <div className="b-privacy-policy__sub-section">
          <h3 className="b-privacy-policy__sub-inner-title">1.1 Stammdaten</h3>
          <p className="b-privacy-policy__paragraph">
            Bei der Erstellung eines Profils erheben wir folgende Stammdaten über Sie („Stammdaten“):
          </p>
          <ul className="b-privacy-policy__sub-inner-list">
            <li className="b-privacy-policy__sub-inner-item">Ihr Vorname,</li>
            <li className="b-privacy-policy__sub-inner-item">Ihr Nachname</li>
            <li className="b-privacy-policy__sub-inner-item">Ihre E-Mail-Adresse</li>
            <li className="b-privacy-policy__sub-inner-item">Deine Telefonnummer.</li>
            <li className="b-privacy-policy__sub-inner-item">Dein Geburtsdatum</li>
            <li className="b-privacy-policy__sub-inner-item">Dein Land</li>
            <li className="b-privacy-policy__sub-inner-item">Dein Geschlecht</li>
            <li className="b-privacy-policy__sub-inner-item">Ihre Mitgliedsnummer bei einem Golfclub</li>
            <li className="b-privacy-policy__sub-inner-item">Dein Heimatclub</li>
            <li className="b-privacy-policy__sub-inner-item">Ihr Mitgliedstyp</li>
            <li className="b-privacy-policy__sub-inner-item">Ihre Zugangsdaten</li>
          </ul>
        </div>
        <div className="b-privacy-policy__sub-section">
          <h3 className="b-privacy-policy__sub-inner-title">
            1.2 Informationen im Zusammenhang mit der Nutzung von GolfMore
          </h3>
          <p className="b-privacy-policy__paragraph">
            Im Zusammenhang mit der Nutzung von GolfMore erheben wir Daten von verschiedenen Golfbuchungssystemen (z.B.
            GolfBox und GolfWorks) und speichern notwendige Informationen wie:
          </p>
          <ul className="b-privacy-policy__sub-inner-list">
            <li className="b-privacy-policy__sub-inner-item">
              Wo, wann, mit wem und zu welchem Preis sollten Sie Golf spielen
            </li>
            <li className="b-privacy-policy__sub-inner-item">
              Welche Rabatte haben Sie erhalten, als Sie zu welchem Preis bezahlt haben, mit welcher Zahlungsmethode im
              Zusammenhang mit Greenfeeding
            </li>
            <li className="b-privacy-policy__sub-inner-item">
              Welche Gutscheine Sie wo, wann und was eingelöst haben und zu welchem Neupreis
            </li>
            <li className="b-privacy-policy__sub-inner-item">Von wem/von wem Sie Gutscheine überweisen/erhalten</li>
            <li className="b-privacy-policy__sub-inner-item">
              Welche Gutscheine Sie über die Upload-Funktion digitalisiert haben
            </li>
            <li className="b-privacy-policy__sub-inner-item">Welche Gutscheine Sie PDFs generiert haben.</li>
            <li className="b-privacy-policy__sub-inner-item">Ihr Standort</li>
          </ul>
        </div>
        <div className="b-privacy-policy__sub-section">
          <h3 className="b-privacy-policy__sub-inner-title">1.3 Cookies</h3>
          <p className="b-privacy-policy__paragraph">
            Die Seite verwendet Cookies. Cookies sind digitale Informationen, die auf Ihrem Computer gespeichert werden.
            Cookies identifizieren Ihren Computer und nicht Sie als einzelnen Benutzer und werden für verschiedene
            Zwecke verwendet. Lesen Sie unten mehr darüber, welche Cookies die Website verwendet und zu welchen Zwecken
            (Abschnitt 7).
          </p>
        </div>
        <div className="b-privacy-policy__sub-section">
          <h3 className="b-privacy-policy__sub-inner-title">1.4 Ihre IP-Adresse, Browsereinstellungen und Standort</h3>
          <p className="b-privacy-policy__paragraph">
            Wenn Sie die Website besuchen, registrieren wir die IP-Adresse und die Browsereinstellungen Ihres Computers.
            Die IP-Adresse ist die numerische Adresse des Computers, mit dem die Site besucht wird. Zu den
            Browsereinstellungen können der von Ihnen verwendete Browsertyp, die Browsersprache und die Zeitzone
            gehören. Wir sammeln diese Informationen, um Missbrauch oder rechtswidrige Handlungen im Zusammenhang mit
            dem Besuch oder der Nutzung der Website zu verhindern und aufzuklären. Darüber hinaus verwenden wir die
            IP-Adresse, um Ihren Standort (auf Stadtebene) zu schätzen.
          </p>
        </div>
        <div className="b-privacy-policy__sub-section">
          <h3 className="b-privacy-policy__sub-inner-title">1.5 Angaben bei der Newsletter-Anmeldung</h3>
          <p className="b-privacy-policy__paragraph">
            Wir erheben die Informationen, die Sie uns bei der Anmeldung zum Erhalt unseres Newsletters mitteilen, wie
            beispielsweise Ihren Namen und Ihre E-Mail-Adresse. Wenn Sie unseren Newsletter nicht mehr erhalten möchten,
            können Sie sich abmelden, indem Sie sich in Ihr Benutzerprofil einloggen und Ihre persönlichen Einstellungen
            ändern.
          </p>
        </div>
        <div className="b-privacy-policy__sub-section">
          <h3 className="b-privacy-policy__sub-inner-title">1.6 Verwendung dieser Informationen</h3>
          <p className="b-privacy-policy__paragraph">
            Wir können die Informationen, die Sie uns zur Verfügung stellen, verwenden, um den Vertrag mit Ihnen zu
            erfüllen, einschließlich:
          </p>
          <ul className="b-privacy-policy__sub-inner-list">
            <li className="b-privacy-policy__sub-inner-item">
              Bereitstellung unserer Dienstleistungen für Sie und Bereitstellung des Zugangs zu unserer Website.
            </li>
            <li className="b-privacy-policy__sub-inner-item">
              Identifizieren Sie sich als registrierter Benutzer, wenn Sie sich auf der Site anmelden.
            </li>
            <li className="b-privacy-policy__sub-inner-item">
              Beantworten Sie Ihre Fragen und bieten Sie den entsprechenden Kundenservice an.
            </li>
            <li className="b-privacy-policy__sub-inner-item">
              Kontaktieren Sie uns, wenn wir einen Missbrauch Ihres Kontos feststellen.
            </li>
          </ul>
          <p className="b-privacy-policy__paragraph">
            Darüber hinaus können wir Ihre Daten verwenden, um berechtigte Interessen zu verfolgen, einschließlich:
          </p>
          <ul className="b-privacy-policy__sub-inner-list">
            <li className="b-privacy-policy__sub-inner-item">
              Verbessern Sie die Website und unsere Dienstleistungen.
            </li>
            <li className="b-privacy-policy__sub-inner-item">Laden Sie ein, unsere Dienste und Angebote zu nutzen.</li>
            <li className="b-privacy-policy__sub-inner-item">Senden Sie uns unsere Newsletter.</li>
            <li className="b-privacy-policy__sub-inner-item">
              Durchführung verschiedener interner Geschäftsziele, wie Datenanalyse, Audits, Überwachung und Verhinderung
              von Missbrauch, Entwicklung neuer Produkte und Dienstleistungen, Verbesserung oder Änderung der Site oder
              unserer Dienstleistungen, Identifizierung von Nutzungstrends, Ermittlung der Wirksamkeit unserer
              Werbekampagnen sowie Durchführung und Erweiterung unsere Geschäftstätigkeit.
            </li>
            <li className="b-privacy-policy__sub-inner-item">
              Geben Sie Ihre Informationen wie in Abschnitt 2 beschrieben weiter.
            </li>
          </ul>
          <p className="b-privacy-policy__paragraph">
            Sie haben das Recht, der Verarbeitung Ihrer Daten zu den oben genannten Zwecken zu widersprechen. Weitere
            Informationen zu Ihren Rechten finden Sie in Abschnitt 8.
          </p>
          <p className="b-privacy-policy__paragraph">
            Schließlich können wir Ihre Daten verarbeiten, um einen Rechtsanspruch festzustellen oder zu verteidigen,
            einschließlich:
          </p>
          <ul className="b-privacy-policy__sub-inner-list">
            <li className="b-privacy-policy__sub-inner-item">
              Befolgen Sie geltende rechtliche Anforderungen und rechtliche Verfahren, befolgen Sie behördliche und
              behördliche Anforderungen und halten Sie sich an relevante Branchenstandards und unsere internen
              Richtlinien.
            </li>
            <li className="b-privacy-policy__sub-inner-item">
              Setzen Sie unsere Allgemeinen Geschäftsbedingungen durch.
            </li>
            <li className="b-privacy-policy__sub-inner-item">
              Schützen Sie unsere Geschäftsaktivitäten oder die eines unserer verbundenen Unternehmen.
            </li>
            <li className="b-privacy-policy__sub-inner-item">
              Schützen Sie unsere Rechte, Privatsphäre, Sicherheit oder Eigentum und/oder die unserer verbundenen
              Unternehmen, Sie oder andere.
            </li>
            <li className="b-privacy-policy__sub-inner-item">
              Erlauben Sie uns, verfügbare Rechtsmittel zu verfolgen oder jegliche Haftung zu begrenzen, die uns
              möglicherweise entsteht.
            </li>
          </ul>

          <p className="b-privacy-policy__paragraph">
            Für andere Zwecke verwenden wir Ihre Daten nur in dem Umfang, in dem wir Sie zum Zeitpunkt der Erhebung
            darüber informiert haben.
          </p>

          <h3 className="b-privacy-policy__sub-inner-title">1.7 Solange wir Ihre Daten aufbewahren</h3>
          <p className="b-privacy-policy__paragraph">
            Wir bewahren Ihre Daten für den Zeitraum auf, in dem sie für die oben genannten Zwecke erforderlich sind,
            einschließlich solange Ihr Profil bei uns aktiv bleibt. Wir löschen Ihre Informationen, wenn Sie Ihr Profil
            länger als 24 Monate nicht verwendet oder sich nicht angemeldet haben und Ihr Konto keine aktiven Gutscheine
            enthält. In anderen Fällen können wir Ihre Daten wie in Abschnitt 8.2 beschrieben löschen.
          </p>
        </div>

        <h2 className="b-privacy-policy__sub-title">2 Offenlegung personenbezogener Daten</h2>
        <div className="b-privacy-policy__sub-section">
          <h3 className="b-privacy-policy__sub-inner-title">2.1 Offenlegung personenbezogener Daten auf der Site</h3>
          <p className="b-privacy-policy__paragraph">
            In Verbindung mit Ihrer Nutzung der Site werden wir bestimmte Informationen über Sie preisgeben.
            Zahlungsdetails. Die notwendigen Daten zur Nutzung von Gutscheinen leiten wir an das Golfbuchungssystem des
            jeweiligen Clubs weiter.
          </p>
        </div>
        <div className="b-privacy-policy__sub-section">
          <h3 className="b-privacy-policy__sub-inner-title">2.2 Übermittlung an andere Dienste und Unternehmen</h3>
          <p className="b-privacy-policy__paragraph">
            Einer der Hauptzwecke von GolfMore ist es, Golfclubs bei der Verwaltung ihrer Gutscheine zu unterstützen.
            Daher gestatten wir Clubs den Zugriff auf Informationen, die für diejenigen relevant sind, die in ihrem Club
            verwendet werden. Wenn Sie Gutscheine von einem Club erhalten oder GolfMore in einem Club genutzt haben,
            stehen Ihnen folgende Daten zur Verfügung
          </p>
          <ul className="b-privacy-policy__sub-inner-list">
            <li className="b-privacy-policy__sub-inner-item">Dein Vorname, Nachname, Mitgliedsnummer, Heimatverein</li>
            <li className="b-privacy-policy__sub-inner-item">
              Welche Gutscheine Sie verwendet haben und welcher Originalpreis zum Zeitpunkt der Einlösung war,
              Einlösedatum, wie viel Nutzungsdauer auf dem Gutschein.
            </li>
          </ul>
        </div>
        <div className="b-privacy-policy__sub-section">
          <h3 className="b-privacy-policy__sub-inner-title">2.3 Sonstige Angaben</h3>
          <p className="b-privacy-policy__paragraph">
            Darüber hinaus können wir Ihre personenbezogenen Daten unter den folgenden Umständen an die folgenden
            Parteien weitergeben:
          </p>
          <ul className="b-privacy-policy__sub-inner-list">
            <li className="b-privacy-policy__sub-inner-item">
              Um Drittanbietern, Beratern und anderen Dienstleistern zu ermöglichen, Dienstleistungen in unserem Namen
              zu erbringen.
            </li>
            <li className="b-privacy-policy__sub-inner-item">
              An Tochtergesellschaften von ProBox24 ApS und andere Unternehmen der ProBox24-Gruppe.
            </li>
            <li className="b-privacy-policy__sub-inner-item">
              {' '}
              Um Gesetze einzuhalten oder auf Ansprüche zu reagieren, rechtliche Verfahren (einschließlich, aber nicht
              beschränkt auf Anträge und Gerichtsbeschlüsse) und um Anfragen von Regierungen und Regierungsbehörden
              nachzukommen.
            </li>
            <li className="b-privacy-policy__sub-inner-item">
              An Dritte im Zusammenhang mit der Durchsetzung unserer Allgemeinen Geschäftsbedingungen.
            </li>
            <li className="b-privacy-policy__sub-inner-item">
              An Dritte, um verfügbare Rechtsmittel geltend zu machen oder die Haftung zu begrenzen, die uns
              möglicherweise entstehen.
            </li>
            <li className="b-privacy-policy__sub-inner-item">
              To third parties to pursue available remedies, or limit liability, as we may incur.
            </li>
            <li className="b-privacy-policy__sub-inner-item">
              An Dritte, um vermutete oder tatsächlich verbotene Aktivitäten zu untersuchen, zu verhindern oder zu
              stören, einschließlich, aber nicht beschränkt auf den Missbrauch unserer Website.
            </li>
          </ul>
        </div>
      </li>

      <li className="b-privacy-policy__item">
        <h2 className="b-privacy-policy__sub-title">3 Datenmanager</h2>
        <div className="b-privacy-policy__sub-section">
          <p className="b-privacy-policy__paragraph">
            Wir sind der Datenverantwortliche für Stammdaten, die Sie im Zusammenhang mit der Erstellung eines Profils
            eingeben, das unter anderem Ihren Namen, Ihr Passwort und Ihre E-Mail-Adresse sowie die Registrierung Ihrer
            IP-Adresse umfasst.
          </p>
          <p className="b-privacy-policy__paragraph">
            Wir sind auch der Datenverantwortliche für die Informationen, die an andere Dienste übermittelt werden
            (siehe Abschnitt 2.2). Die Erhebung Ihrer personenbezogenen Daten durch GolfMore unterliegt dem derzeit in
            Dänemark geltenden Gesetz über personenbezogene Daten.
          </p>
        </div>
      </li>

      <li className="b-privacy-policy__item">
        <h2 className="b-privacy-policy__sub-title">4 Links zu Websites</h2>
        <div className="b-privacy-policy__sub-section">
          <p className="b-privacy-policy__paragraph">
            Unsere Site enthält Links zu anderen Sites. Die Aufnahme solcher Links spiegelt nicht unsere Zustimmung zu
            den betreffenden Websites wider. Wir haben keine Kontrolle über den Inhalt dieser Websites Dritter und
            übernehmen keine Verantwortung für die Dritten oder deren Richtlinien oder Praktiken. Wir empfehlen Ihnen,
            die Datenschutzrichtlinien dieser Drittanbieter-Websites zu überprüfen, da sich deren Verfahren zur
            Erfassung, Handhabung und Verarbeitung personenbezogener Daten von unseren unterscheiden können.
          </p>
        </div>
      </li>

      <li className="b-privacy-policy__item">
        <h2 className="b-privacy-policy__sub-title">5 Datenverarbeitung und Weitergabe personenbezogener Daten</h2>
        <div className="b-privacy-policy__sub-section">
          <p className="b-privacy-policy__paragraph">
            Wir setzen externe Lieferanten ein, um den technischen Betrieb der Website und unserer Dienste
            aufrechtzuerhalten. Diese Unternehmen sind Datenadministratoren für die personenbezogenen Daten, für die wir
            die Datenverantwortlichen sind. Die Datenserver dürfen nur nach unseren Weisungen handeln. Wir werden daher
            den Datenservern solche Anweisungen erteilen, Daten in Übereinstimmung mit der Richtlinie zu verarbeiten und
            die Site zu nutzen.
          </p>
          <p className="b-privacy-policy__paragraph">
            Die Datenserver haben angemessene technische und organisatorische Maßnahmen getroffen, um die Informationen
            vor versehentlicher oder rechtswidriger Zerstörung, Verlust oder Verschlechterung zu schützen und die
            Informationen vor Offenlegung durch unbefugten Missbrauch oder andere Verarbeitung unter Verletzung des
            Gesetzes über personenbezogene Daten zu schützen.
          </p>
        </div>
      </li>

      <li className="b-privacy-policy__item">
        <h2 className="b-privacy-policy__sub-title">6 Vorsichtsmaßnahmen</h2>
        <div className="b-privacy-policy__sub-section">
          <p className="b-privacy-policy__paragraph">
            Wir bemühen uns, angemessene organisatorische, technische und administrative Maßnahmen zum Schutz Ihrer
            personenbezogenen Daten in unserer Organisation zu ergreifen und überprüfen regelmäßig unser
            Schwachstellensystem. Da das Internet jedoch keine 100% sichere Umgebung ist, können wir die Sicherheit der
            Informationen, die Sie an GolfMore senden, nicht garantieren oder garantieren. Über die Site gesendete
            E-Mails werden nicht verschlüsselt und wir empfehlen Ihnen, keine vertraulichen oder sensiblen Informationen
            in Ihre E-Mails an uns aufzunehmen.
          </p>
        </div>
      </li>

      <li className="b-privacy-policy__item">
        <h2 className="b-privacy-policy__sub-title">7 Cookies</h2>
        <div className="b-privacy-policy__sub-section">
          <p className="b-privacy-policy__paragraph">
            Die Website verwendet Cookies und ähnliche Technologien ("Cookies"). Durch die Nutzung unserer Website
            erklären Sie sich damit einverstanden, dass wir Cookies wie unten beschrieben verwenden.
          </p>
          <div className="b-privacy-policy__sub-section">
            <h3 className="b-privacy-policy__sub-inner-title">7.1 Welche Arten von Cookies verwenden wir?</h3>
            <p className="b-privacy-policy__paragraph">
              Cookies sind kleine Informationseinheiten, die die Site auf der Festplatte Ihres Computers, auf Ihrem
              Tablet oder auf Ihrem Smartphone ablegt. Cookies enthalten Informationen, die die Website verwendet, um
              die Kommunikation zwischen Ihnen und Ihrem Webbrowser effektiver zu gestalten. Cookies identifizieren
              Ihren Computer und nicht Sie als einzelnen Benutzer.
            </p>
            <p className="b-privacy-policy__paragraph">
              Wir verwenden Sitzungscookies und dauerhafte Cookies sowie Technologien, die den Sitzungscookies und
              dauerhaften Cookies ähneln. Sitzungscookies sind temporäre Auszüge von Informationen, die gelöscht werden,
              wenn Sie Ihren Webbrowser verlassen. Dauerhafte Cookies sind dauerhaftere Auszüge von Informationen, die
              gespeichert werden und auf Ihrem Computer verbleiben, bis sie gelöscht werden. Dauerhafte Cookies löschen
              sich nach einer bestimmten Zeit selbst, erneuern sich jedoch bei jedem Besuch der Website.
            </p>
            <p className="b-privacy-policy__paragraph">
              Wir verwenden Technologien, die Sitzungscookies und fortlaufenden Cookies ähneln, zum Speichern und
              Zugreifen auf Informationen im Browser oder Gerät, das lokale Geräte und lokalen Speicher verwendet, wie
              HTML5-Cookies, Flash und andere Methoden. Diese Technologien können für alle von Ihnen funktionierendeine
              Browser. In manchen Fällen ist die Nutzung dieser Technologien nicht durch den Browser kontrollierbar,
              sondern erfordert spezielle Tools. Wir verwenden diese Technologien, um Informationen zu speichern, um
              Unregelmäßigkeiten bei der Nutzung der Website zu erkennen.
            </p>
          </div>

          <div className="b-privacy-policy__sub-section">
            <h3 className="b-privacy-policy__sub-inner-title">7.2 Wofür verwenden wir Cookies?</h3>
            <p className="b-privacy-policy__paragraph">Wir verwenden Cookies für:</p>
            <ul className="b-privacy-policy__sub-inner-list">
              <li className="b-privacy-policy__sub-inner-item">Erstellung von Statistiken</li>
            </ul>
            <p className="b-privacy-policy__paragraph">
              Messung des Site-Traffics, wie z. B. die Anzahl der Besuche auf der Site, von welchen Domänen die Besucher
              kommen, welche Seiten Besucher die Site besuchen und in welchen geografischen Gebieten sich die Besucher
              insgesamt befinden.
            </p>
            <ul className="b-privacy-policy__sub-inner-list">
              <li className="b-privacy-policy__sub-inner-item">
                Überwachung der Website-Leistung und Ihrer Nutzung unserer Website
              </li>
            </ul>
            <p className="b-privacy-policy__paragraph">
              Überwachung der Website, unserer Anwendungen und Widgets sowie der Nutzung unserer Website, Anwendungen
              und Widgets.
            </p>
            <ul className="b-privacy-policy__sub-inner-list">
              <li className="b-privacy-policy__sub-inner-item">
                Genehmigung und Verbesserung der Funktionalität unserer Website
              </li>
            </ul>
            <p className="b-privacy-policy__paragraph">
              Optimieren Sie Ihre Erfahrung mit der Site, einschließlich der Speicherung Ihres Benutzernamens und Ihres
              Passworts bei der Rückkehr zur Site sowie der Speicherung Ihrer Browser- und Präferenzinformationen (z. B.
              welche Sprache Sie bevorzugen).
            </p>
          </div>
          <div className="b-privacy-policy__sub-section">
            <h3 className="b-privacy-policy__sub-inner-title">7.3 Cookies von Drittanbietern</h3>
            <p className="b-privacy-policy__paragraph">Unsere Website verwendet Cookies der folgenden Drittanbieter:</p>
            <ul className="b-privacy-policy__sub-inner-list">
              <li className="b-privacy-policy__sub-inner-item">
                Google Analytics: Um den Verkehr auf unserer Website zu überwachen, wie allgemeine Besuche, Conversions
                und dergleichen.
              </li>
              <li className="b-privacy-policy__sub-inner-item">
                Fabric Crashlytics: Um Fehler und Abstürze auf unserer Seite zu verfolgen.
              </li>
              <li className="b-privacy-policy__sub-inner-item">
                Javascript: Für Überwachungszwecke, einschließlich der Verwendung unserer Website, Widgets und
                Anwendungen und um umfangreiche Websites und Widgets zu erstellen.
              </li>
            </ul>
          </div>
          <div className="b-privacy-policy__sub-section">
            <h3 className="b-privacy-policy__sub-inner-title">7.4 Cookies löschen</h3>
            <p className="b-privacy-policy__paragraph">
              Wenn Sie bereits auf Ihrem Gerät gespeicherte Cookies löschen möchten, können Sie: Wenn Sie einen PC und
              einen neueren Browser verwenden, drücken Sie bei geöffnetem und aktivem Browser gleichzeitig STRG +
              UMSCHALT + ENTF. Wenn die Verknüpfungen in Ihrem Browser nicht funktionieren, besuchen Sie bitte die
              Support-Seite für diesen Browser. Bitte beachten Sie jedoch, dass es bei der Nutzung der Site zu
              Nachteilen kommen kann, wenn Sie keine Cookies von unserer Site akzeptieren, und dass Sie möglicherweise
              nicht auf alle ihre Funktionen zugreifen können.
            </p>
          </div>
        </div>
      </li>

      <li className="b-privacy-policy__item">
        <h2 className="b-privacy-policy__sub-title">8 Zugriff, Berichtigung und Löschung</h2>
        <div className="b-privacy-policy__sub-section">
          <h3 className="b-privacy-policy__sub-inner-title">
            8.1 Zugriff auf Informationen zu Ihren personenbezogenen Daten
          </h3>
          <p className="b-privacy-policy__paragraph">
            Auf Ihre Anfrage teilen wir Ihnen mit, zu welchem Zweck wir Ihre personenbezogenen Daten verarbeiten, welche
            personenbezogenen Daten wir über Sie haben, wer Ihre personenbezogenen Daten erhält und von wem Ihre
            personenbezogenen Daten stammen. Eine diesbezügliche Anfrage wird zunächst nur bearbeitet, wenn eine
            vorherige Anfrage von Ihnen mehr als sechs (6) Monate vergangen ist, es sei denn, Sie können ein besonderes
            Interesse an der Einholung der Informationen bereits vorher nachweisen.
          </p>
          <h3 className="b-privacy-policy__sub-inner-title">
            8.2 Berichtigung und Löschung Ihrer personenbezogenen Daten
          </h3>
          <p className="b-privacy-policy__paragraph">
            Bitte teilen Sie uns mit, wenn personenbezogene Daten, die wir als Datenverantwortlicher über Sie
            verarbeitet haben, unrichtig, unvollständig oder irreführend sind, und wir werden sie berichtigen. Sie
            können die meisten dieser Informationen auf unserer Website korrigieren. Wenn möglich, empfehlen wir Ihnen,
            Ihre Angaben zu korrigieren.
          </p>
          <p className="b-privacy-policy__paragraph">
            Sie können jederzeit Inhalte und personenbezogene Daten auf der Site korrigieren oder löschen. Wenn sich
            Ihre persönlichen Daten ändern oder Sie nicht mehr auf der Site erscheinen möchten, können Sie die Daten
            aktualisieren oder löschen, indem Sie sich bei Ihrem Benutzerprofil anmelden.
          </p>
          <p className="b-privacy-policy__paragraph">
            Sobald Ihr Profil gelöscht ist, werden alle mit Ihrem Benutzerprofil verbundenen Informationen gelöscht,
            einschließlich Ihrer Stammdaten und Bewertungen auf der Website.
          </p>
          <p className="b-privacy-policy__paragraph">
            Wir behalten uns das Recht vor, den Zugriff auf Ihr Profil zu sperren und / oder Ihr Profil zu löschen, wenn
            das Profil oder der Inhalt, der mit Ihrem Profil oder Ihrer Bewertung(en) auf der Website verbunden ist,
            unserer Meinung nach diskriminierend, rassistisch, sexuell orientiert, unethisch, bedrohlich ist ,
            beleidigend, belästigend oder anderweitig gegen geltende Gesetze, Rechte Dritter, Benutzerrichtlinien
            verstoßen oder mit dem Zweck der Website unvereinbar. Wenn wir den Zugang zu Ihrem Benutzerprofil sperren
            oder löschen, informieren wir Sie über den Grund für die Sperrung oder Löschung Ihres Profils per E-Mail an
            die Adresse, die Sie bei der Erstellung Ihres Kontos angegeben haben.
          </p>
        </div>
      </li>
      <li className="b-privacy-policy__item">
        <h2 className="b-privacy-policy__sub-title">9 Änderungen der Richtlinie</h2>
        <p className="b-privacy-policy__paragraph">
          Wir können diese Richtlinie jederzeit ändern. Das am Anfang dieser Richtlinie angegebene Datum gibt an, wann
          sie zuletzt überarbeitet wurde. Alle Änderungen werden wirksam, wenn wir die überarbeitete Richtlinie auf der
          Website veröffentlichen. Sie stimmen zu, dass Ihre fortgesetzte Nutzung der Site nach veröffentlichten
          Änderungen Ihre Zustimmung zur neuen Version der Richtlinie darstellt.
        </p>
      </li>
      <li className="b-privacy-policy__item">
        <h2 className="b-privacy-policy__sub-title">10 Kommentare zur Website oder den Richtlinien</h2>
        <p className="b-privacy-policy__paragraph">
          Wenn Sie Fragen oder Bedenken bezüglich unserer Politik haben, wie wir mit Ihren persönlichen Daten umgehen
          oder möchten, dass wir Ihre persönlichen Daten korrigieren, kontaktieren Sie uns bitte unter: GolfMore A / S,
          Rugaardsvej 5, 8680 Ry, Dänemark, CVR-Nr.: 33593082, Telefon: +45 7070 7999, E-Mail:
          <a className="b-privacy-policy__link" href="mailto:info@probox24.com">
            {' '}
            info@probox24.com
          </a>
          .
        </p>
      </li>
    </ul>
  </div>
);

export default privacyPolicyGerman;
