// @flow
import React, { useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import { connect } from 'react-redux';
import i18n from 'react-native-i18n';
import type { NavigationState, NavigationScreenProp } from 'react-navigation';

import Button from '../../../components/Button';
import incStyles from '../../../constants/styles';
import { abortRegistration } from '../../../user/modules/registering';

const { colors } = incStyles;

const SignUpSelect = ({
  navigation,
  abortRegistration,
}: {
  navigation: NavigationScreenProp<NavigationState>,
  abortRegistration: typeof abortRegistration,
}) => {
  useEffect(() => {
    // clear out all previous data from the registration
    abortRegistration();
  }, []);
  return (
    <View style={styles.container}>
      <Button
        onPress={() => {
          console.log('Initiate union login');
          navigation.navigate('UnionSelect', {
            steps: 3,
            step: 1,
            title: i18n.t('signUpSelect.unionMember'),
          });
        }}
        title={i18n.t('signUpSelect.unionMember').toUpperCase()}
        style={styles.unionButton}
      />
      <Button
        onPress={() => {
          console.log('Initiate non-union login');
          navigation.navigate('SignUp', {
            nonUnion: true,
            steps: 2,
            step: 1,
            title: i18n.t('signUpSelect.nonUnionMember'),
          });
        }}
        title={i18n.t('signUpSelect.nonUnionMember').toUpperCase()}
        style={styles.nonUnionMember}
        textStyle={{ color: colors.black }}
      />
    </View>
  );
};

SignUpSelect.navigationOptions = () => ({
  title: i18n.t('signUpSelect.register'),
});

export default connect(null, { abortRegistration })(SignUpSelect);

const styles = StyleSheet.create({
  nonUnionMember: { marginVertical: 10, backgroundColor: colors.white },
  unionButton: { marginVertical: 10, backgroundColor: '#4F627A' },
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    maxWidth: 500,
    flex: 1,
    padding: 16,
  },
});
