// @flow
import React, { useCallback } from 'react';
import { Linking, Image, Platform } from 'react-native';
import { bindActionCreators } from 'redux';
// import Toast from 'react-native-simple-toast';
import { connect } from 'react-redux';
import i18n from 'react-native-i18n';
import { logOut } from '../../modules/session';
import api from '../../../api';
import { loadUser } from '../../../user/modules';
import { handleLogIn } from '../../../../domains/auth/actions';

import {
  Touchable,
  Root,
  ContentContainer,
  ContentScrollView,
  EmailRow,
  EmailIcon,
  VerticalContainer,
  Title,
  EmailText,
  Rationale,
  StepContainer,
  StepNumberContainer,
  StepNumberText,
  StepText,
  ContactSupportText,
  Button,
  FooterContainer,
  FooterLabel,
  SignInLink,
  FooterShadow,
} from './styles';
import withModal from '../../../components/Modal/withModal';
import { supportEmail } from '../../../constants/support';

export type VerifyAccountType = {
  navigation: Object,
  email: string,
  loadUser: typeof loadUser,
  showModal: Function,
};

const VerifyAccount = ({ navigation, email, showModal, loadUser, logOut }: VerifyAccountType) => {
  const handleContactPress = useCallback(() => {
    Linking.openURL(`mailto:${supportEmail}`);
  }, []);
  const handleSignInPress = useCallback(() => {
    logOut();
    navigation.navigate('Tabs');
  }, []);

  const handleResendPress = useCallback(() => {
    api()
      .user.resendEmail(email)
      .then((result) => {
        if (!result) {
          showModal(i18n.t('verifyAccount.emailSent'), i18n.t('verifyAccount.emailSentMessage'), {
            options: [
              {
                text: i18n.t('verifyAccount.ok'),
              },
            ],
          });
        }
      })
      .catch(() => {
        // TODO show an error or something
      });
  }, []);

  const checkVerified = () => {
    loadUser().then((userResponse) => {
      if (userResponse.data?.email_confirmed) {
        if (Platform.OS === 'web') {
          handleLogIn(true);
        }
        navigation.navigate({ routeName: 'Tabs' });
      } else {
        // Toast.show('Email not verified yet');
      }
    });
  };

  return (
    <Root>
      <ContentContainer>
        <ContentScrollView>
          <EmailRow>
            <EmailIcon />
            <VerticalContainer>
              <Title text={i18n.t('verifyAccount.checkEmail')} />
              <EmailText text={email} />
            </VerticalContainer>
          </EmailRow>
          <Rationale text={i18n.t('verifyAccount.rationale')} />
          <StepContainer>
            <StepNumberContainer>
              <StepNumberText text="1" />
            </StepNumberContainer>
            <StepText text={i18n.t('verifyAccount.stepSpam')} />
          </StepContainer>
          <StepContainer>
            <StepNumberContainer>
              <StepNumberText text="2" />
            </StepNumberContainer>
            <StepText text={i18n.t('verifyAccount.stepResend')} />
          </StepContainer>
          <StepContainer>
            <StepNumberContainer>
              <StepNumberText text="3" />
            </StepNumberContainer>
            <StepText text={i18n.t('verifyAccount.stepContact')} />
            <Touchable onPress={handleContactPress}>
              <ContactSupportText text={i18n.t('verifyAccount.contactSupportLink')} />
            </Touchable>
          </StepContainer>
          <Button text={i18n.t('verifyAccount.buttonResend')} onPress={handleResendPress} />
          <Button text={i18n.t('verifyAccount.buttonPressAfterConfirm')} onPress={checkVerified} />
        </ContentScrollView>
        <FooterShadow />
      </ContentContainer>
      <FooterContainer>
        <FooterLabel text={'Wrong account?'} />
        <Touchable onPress={handleSignInPress}>
          <SignInLink text={'Log out'} />
        </Touchable>
      </FooterContainer>
    </Root>
  );
};

const iconBack = require('../../../assets/icon-back.png');
const renderBackImage = () => <Image source={iconBack} style={{ width: 24, height: 24 }} resizeMode="contain" />;

VerifyAccount.navigationOptions = ({ navigation }) => ({
  title: i18n.t('verifyAccount.title'),
  headerLeft: () => <HeaderLeft navigation={navigation} />,
  backTitleVisible: false,
});

export default withModal(
  connect(
    ({ user }) => ({
      email: user.registering.data?.email || user.user?.data?.email,
    }),
    { loadUser, logOut },
  )(VerifyAccount),
);
