// @flow
import { func } from 'prop-types';

export const LOCATION_UPDATED = 'geo/LOCATION_UPDATE';

export function updateLocation(lat, lng) {
  return {
    type: LOCATION_UPDATED,
    payload: { lat, lng },
  };
}

export type LocationState = {
  lat: ?number,
  lng: ?number,
  hasLocation: boolean,
};

export default function(
  state: LocationState = {
    hasLocation: false,
    lat: undefined,
    lng: undefined,
  },
  action,
) {
  if (action.type === LOCATION_UPDATED) {
    return {
      hasLocation: true,
      lat: action.payload.lat,
      lng: action.payload.lng,
    };
  }
  return state;
}
