// @flow
import React from 'react';
import { StyleSheet, TouchableOpacity, Text, Image } from 'react-native';
import PropTypes from 'prop-types';

import cstyles from '../../constants/styles';
const { colors, font } = cstyles;

type Props = {
  title: string,
  style?: Object,
  textStyle?: Object,
  image?: string,
  disabled?: boolean,
};

const styles = StyleSheet.create({
  button: {
    backgroundColor: colors.green,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 12,
    borderRadius: 8,
    flexDirection: 'row',
    shadowColor: '#000',
    shadowOffset: {
      width: 2,
      height: 4,
    },
    shadowOpacity: 0.13,
    shadowRadius: 2.62,

    elevation: 2,
  },
  title: {
    color: 'white',
    ...font.style.medium,
    fontSize: font.size.normal,
  },
  image: {
    marginRight: 10,
    height: 24,
    width: 24,
  },
});

const CustomButton = (props: Props) => {
  const { title, style, textStyle, image, disabled } = props;

  return (
    <TouchableOpacity {...props} style={[styles.button, style, disabled ? { opacity: 0.5 } : {}]}>
      {image && <Image source={image} style={styles.image} />}
      <Text style={[styles.title, textStyle]}>{title}</Text>
    </TouchableOpacity>
  );
};

CustomButton.propTypes = {
  title: PropTypes.string.isRequired,
};

export default CustomButton;
