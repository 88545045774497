import { connect } from 'react-redux';
import faqActions from '../../faqActions';
import { localeSelector } from '../../../../selectors/locale';

import Faq from './faq';
import { getFaqsByCategory } from '../../../app/appActions';

const mapStateToProps = (state) => {
  const { faqReducer } = state;
  return {
    faqsItems: faqReducer.get('faqsItems'),
    faqsCategories: faqReducer.get('faqsCategories'),
    faqsByCategory: faqReducer.get('faqsByCategory'),
    locale: localeSelector(state),
  };
};

const mapDispatchToProps = {
  getFaqsItems: faqActions.getFaqsItems,
  getFaqsCategories: faqActions.getFaqsCategories,
  getFaqsByCategory,
};

export default connect(mapStateToProps, mapDispatchToProps)(Faq);
