import React from 'react';
import { connect } from 'react-redux';

import privacyPolicyDenmark from './privacyPolicyContent/privacyPolicyDenmark';
import privacyPolicyEnglish from './privacyPolicyContent/privacyPolicyEnglish';
import privacyPolicyNorway from './privacyPolicyContent/privacyPolicyNorway';
import privacyPolicySweden from './privacyPolicyContent/privacyPolicySweden';
import privacyPolicyGerman from './privacyPolicyContent/privacyPolicyGerman';

import './privacyPolicy.css';

class PrivacyPolicy extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="b-privacy-policy g-auth-content">
        <div className="b-privacy-policy__container g-container container grid-xl">
          <div className="columns">
            <div className="column col-12">
              {this.props.location === 'danish' && privacyPolicyDenmark()}
              {this.props.location === 'english' && privacyPolicyEnglish()}
              {this.props.location === 'norwegian' && privacyPolicyNorway()}
              {this.props.location === 'swedish' && privacyPolicySweden()}
              {this.props.location === 'german' && privacyPolicyGerman()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    location: state.appReducer.get('location'),
  };
}

export default connect(mapStateToProps, null)(PrivacyPolicy);
