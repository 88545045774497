// @flow
import { StyleSheet } from 'react-native';
import styles from '../../constants/styles';

const { colors, font, size } = styles;

export default StyleSheet.create({
  container: {
    flex: 1,
    //  marginBottom: 70,
    //  marginTop: 50,
    margin: 16,
  },

  contents: {
    flex: 1,
    // backgroundColor: colors.white,
    padding: 20,
  },
});
