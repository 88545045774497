// @flow
import React from 'react';
import i18n from 'react-native-i18n';

import intersectionWith from 'lodash/intersectionWith';

import type { VoucherType } from '../../../../api/voucher';

import {
  FooterContainer,
  VoucherRestrictionsLabel,
  VoucherUseCase,
  VoucherUseLabel,
  VoucherTimeContainer,
  VoucherWeekdayContainer,
  VoucherTimeRangeContainer,
} from './styles';

export type VoucherConstraintsProps = {
  voucher: VoucherType,
  departments?: Array<Object>,
};

const VoucherConstraints = ({ voucher, departments }: VoucherConstraintsProps) => {
  console.log('DPT', departments);
  const {
    where,
    valid_time_from: validTimeFrom,
    valid_time_to: validTimeTo,
    valid_weekdays: validWeekdays,
    valid_times_per_club: validTimesPerClub,
    requires_member_number: requiresMemberNumber,
    valid_within_hours: validWithinHours,
    valid_times_per_day: validTimesPerDay,
    playing_with_member: playingWithMember,
  } = voucher;

  const clubsToUse = !departments
    ? where
    : intersectionWith(
        departments,
        where,
        ({ department_id: departmentId }, id) => String(departmentId) === String(id),
      );
  const whereToUse = !clubsToUse
    ? null
    : clubsToUse.map(({ department_name }) => <VoucherUseCase text={department_name} />);

  let weekDaysRestriction = null;
  let withMember = null;
  let hasRestrictions = false; // To avoid big condition in if statement
  if (validWeekdays && Object.keys(validWeekdays).length < 7 && Object.keys(validWeekdays).length > 0) {
    const weekDays = i18n.t('voucherDetails.weekDays');
    weekDaysRestriction = Object.entries(validWeekdays).map(([key, hours]) => {
      const weekName = weekDays[key];
      const { from, to } = hours;
      const hoursText = `${from || '00:00'} - ${to || '24:00'}`;
      return (
        <VoucherTimeContainer>
          <VoucherWeekdayContainer>
            <VoucherUseCase text={weekName} />
          </VoucherWeekdayContainer>
          <VoucherTimeRangeContainer>
            <VoucherUseCase text={hoursText} />
          </VoucherTimeRangeContainer>
        </VoucherTimeContainer>
      );
    });
    hasRestrictions = true;
  }
  let validTimeRestriction = null;
  if (validTimeFrom && validTimeTo) {
    const prefix = i18n.t('voucherDetails.canOnlyBeUsedBetween');
    validTimeRestriction = <VoucherUseCase text={`${prefix} ${validTimeFrom} - ${validTimeTo}`} />;
    hasRestrictions = true;
  }
  let timesPerClubRestriction = null;
  if (validTimesPerClub) {
    const prefix = i18n.t('voucherDetails.canOnlyBeUsedTimesPartOne');
    const postfix = i18n.t('voucherDetails.canOnlyBeUsedTimesPartTwo');
    timesPerClubRestriction = <VoucherUseCase text={`${prefix} ${validTimesPerClub} ${postfix}`} />;
    hasRestrictions = true;
  }
  let requiredMembersRestriction = null;
  if (requiresMemberNumber) {
    const prefix = i18n.t('voucherDetails.canOnlyBeUsedByMemberNo');
    requiredMembersRestriction = <VoucherUseCase text={`${prefix} ${requiresMemberNumber}`} />;
    hasRestrictions = true;
  }
  let withinHoursRestriction = null;
  if (validWithinHours) {
    const text = i18n.t('voucherDetails.canFirstBeUsedAfterHoursAgain', { data: validWithinHours });
    withinHoursRestriction = <VoucherUseCase text={text} />;
    hasRestrictions = true;
  }
  let timesPerDayRestriction = null;
  if (validTimesPerDay) {
    const text = i18n.t('voucherDetails.canOnlyBeUsedTimesPrDay', { data: validTimesPerDay });
    timesPerDayRestriction = <VoucherUseCase text={text} />;
    hasRestrictions = true;
  }
  if (playingWithMember) {
    const text = `${playingWithMember} ${i18n.t('voucherDetails.mustPlayWithGuest')}`;
    withMember = <VoucherUseCase text={text} />;
    hasRestrictions = true;
  }

  const whereToUseView =
    !whereToUse || !whereToUse.length ? null : (
      <>
        <VoucherUseLabel text={i18n.t('voucherDetails.useAt')} />
        <>{whereToUse}</>
      </>
    );

  const restrictionsView = !hasRestrictions ? null : (
    <>
      <VoucherRestrictionsLabel text={i18n.t('voucherDetails.restrictions')} />
      {weekDaysRestriction}
      {validTimeRestriction}
      {timesPerClubRestriction}
      {requiredMembersRestriction}
      {withinHoursRestriction}
      {timesPerDayRestriction}
      {withMember}
    </>
  );

  return !whereToUseView && !restrictionsView ? null : (
    <FooterContainer>
      {whereToUseView}
      {restrictionsView}
    </FooterContainer>
  );
};

export default VoucherConstraints;
