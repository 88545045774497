export const LOGO_NOT_LOGIN = require('../../images/logo.png');
export const LOGO_LOGIN = require('../../images/logo-auth.png');
export const FOOTER_LOGO = require('../../images/footer-logo.png');
export const CELL_PHONE = require('../../images/cell-phone.png');
export const APP_STORE = require('../../images/app-store.png');
export const GOOGLE_PLAY = require('../../images/google-play.png');
export const SIGN_UP_CONFIRM = require('../../images/sign-up-confirm.png');
export const EMAIL_VERIFY = require('../../images/email-verify.png');
export const DEFAULT_VOUCHER_IMG = require('../../images/default-voucher-img.png');
export const ARROW_DOWN = require('../../images/arrow.png');
