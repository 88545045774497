import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import browserHistory from '../../../../history';
import { getGeoIp } from '../../../../react-native/register/modules/country';
import golfboxUnions from '../../../../react-native/constants/unions';
import getImageForLocale from '../../../../react-native/screens/More/languageIcon';
import { setLocale, setGolfBoxCountry } from '../../../../react-native/store/modules/locale';
import Picker from '../../../../react-native/components/Picker/Picker';
import i18n from 'react-native-i18n';
import { redirectGolfBox } from '../../api/auth';

import {
  EmailContainer,
  GolfBoxContainer,
  ButtonIconContainer,
  GolfBoxIcon,
  ButtonText,
  ContentContainer,
  ButtonsContainer,
  BlackPane,
  RightPanel,
  Touchable,
  GolfRow,
  PickerContainer,
  SGFButtonIconContainer,
  SGFContainer,
  SGFIcon,
} from './styles';

const RegisterMethodSelect = ({ navigation, getGeoIp, setGolfBoxCountry, geoIp, country }) => {
  const handleGolfBoxPress = () => {
    if (country == 'sv') {
      navigation.navigate('LogInSweden', {});
    } else {
      redirectGolfBox(country);
    }
  };

  useEffect(() => {
    getGeoIp();
  }, []);

  const { country_code: countryCode } = geoIp.data;

  useEffect(() => {
    // Detect by country code
    if (countryCode) {
      const ccLow = countryCode.toLowerCase();
      const union = golfboxUnions.find(({ locale, isoCode }) => locale === ccLow || isoCode === ccLow);
      console.log('CCLOW', countryCode, union);
      if (union) {
        setGolfBoxCountry(union.locale);
      } else {
        setGolfBoxCountry('da'); // Default
      }
    }
  }, [countryCode]);

  const availableTranslations = golfboxUnions.map(({ displayName, locale }) => {
    return {
      title: displayName,
      key: locale,
      icon: getImageForLocale(locale),
    };
  });

  console.error(country);

  return (
    <ButtonsContainer>
      <GolfRow>
        <PickerContainer>
          <Picker
            keyName="key"
            labelName="title"
            data={availableTranslations}
            value={country}
            onChange={(country) => {
              setGolfBoxCountry(country.key);
            }}
            defaultText={''}
            style={{ height: 64 }}
          />
        </PickerContainer>

        <Touchable onPress={handleGolfBoxPress}>
          {country == 'sv' ? (
            <SGFContainer>
              <SGFButtonIconContainer>
                <SGFIcon />
              </SGFButtonIconContainer>
              <ButtonText text={'MinGolf'} />
            </SGFContainer>
          ) : (
            <GolfBoxContainer>
              <ButtonIconContainer>
                <GolfBoxIcon />
              </ButtonIconContainer>
              <ButtonText text={i18n.t('logIn.buttonGolfbox')} />
            </GolfBoxContainer>
          )}
        </Touchable>
      </GolfRow>

      <Touchable onPress={() => browserHistory.push('/register/email')}>
        <EmailContainer>
          <ButtonIconContainer>
            <GolfBoxIcon />
          </ButtonIconContainer>
          <ButtonText text="EMAIL ADDRESS" />
        </EmailContainer>
      </Touchable>
    </ButtonsContainer>
  );
};

const mapStateToProps = ({ country, locale }) => ({
  geoIp: country.geoIp,
  country: locale.golfBoxCountry,
});

const mapDispatchToProps = {
  getGeoIp,
  setGolfBoxCountry,
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterMethodSelect);
