// @flow
import API from '../../api/base/api';
import type { ReqType } from '../../api/index';

type WeekdaysNumber = 1 | 2 | 3 | 4 | 5 | 6 | 7;

export type RedeemOptions = {
  club_guid: string,
  resource_guid: string,
  voucher_code: string,
  unique_id: string,
  member_number: string,
  member_name: string,
  original_price: string,
  tee_time: string,
  validate_reservation: boolean,
};

export type VoucherType = {
  voucher_id: number,
  name: string,
  product_name: string,
  user_id: number,
  voucher_number: string,
  reduction_type: string,
  value: string,
  where: Array<null>,
  valid_times: number,
  valid_from: number,
  valid_to: number,
  valid_weekdays: Array<WeekdaysNumber>,
  valid_time_from: string,
  valid_time_to: string,
  valid_times_per_club: number,
  valid_within_hours: number,
  valid_times_per_day: number,
  requires_member_number: string,
  type: string,
  info: string,
  order_id: string,
  active: boolean,
  last_updated: string,
  owner_name: string,
  logo_url: string,
  image_url: string,
  barcode: string,
  pdf: ?string,
  transferable: boolean,
  favourite: boolean,
  can_be_self_redeemed: boolean,
  active_minutes: number,
  playing_with_member: boolean,
};

export type TransferT = {|
  target_email: string,
  target_phone: string,
  target_member_number: string,
  message: string,
|};

export type TransferRespT = {
  message: string,
};

class VoucherAPI extends API {
  get() {
    return this.r({
      method: 'GET',
      url: '/vouchers?active=true&with_pdf=0&only_unused=1',
    });
  }

  transferVouchers(data, voucherIds) {
    return this.r({
      method: 'POST',
      url: `/voucher/transfer/${voucherIds}`,
      data,
    });
  }

  upload(voucherNumber) {
    return this.r({
      method: 'POST',
      url: '/voucher/upload',
      data: {
        voucher_number: voucherNumber,
      },
    });
  }

  redeem(data: RedeemOptions) {
    return this.r({
      method: 'POST',
      url: '/voucher/redeem',
      data,
    });
  }

  toggleFavorite(favourite, voucherId) {
    return this.r({
      method: 'PATCH',
      url: `/voucher/${voucherId}`,
      data: {
        favourite,
      },
    });
  }
}

export default function(r: ReqType): VoucherAPI {
  return new VoucherAPI(r);
}
