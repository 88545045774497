// @flow
import { takeEvery, put, select } from 'redux-saga/effects';
import i18n from 'react-native-i18n';

import notify from '../../helpers/notify';
import { API_REQUEST } from '../apiAction';

const getConnectionState = ({ connection }) => connection.hasConnection;

function* api({ types, call, deferred, ...rest }) {
  // const hasConnection = yield select(getConnectionState);
  // if (!hasConnection) {
  //   notify(i18n.t('common.noInternetConnection'));
  //   return;
  // }

  const [REQUEST, SUCCESS, FAILURE] = types;

  yield put({ ...rest, type: REQUEST });

  try {
    const result = yield call();
    yield put({ ...rest, type: SUCCESS, result });
    if (deferred && typeof deferred.resolve === 'function') {
      deferred.resolve(result);
    }
  } catch (error) {
    yield put({ ...rest, type: FAILURE, error });
    if (deferred && typeof deferred.reject === 'function') {
      deferred.reject(error);
    }
  }
}

// f(): Generator<Yield, Return, Next>
export default function*(): Generator<*, *, *> {
  yield takeEvery(API_REQUEST, api);
}
