// @flow
import styled from 'styled-components/native';
import styles from '../../../../../constants/styles';

const { colors, font } = styles;

export const Root = styled.TouchableOpacity`
  border-radius: 3px;
  background-color: ${colors.white};
  elevation: 3;
  shadowColor: #0000001F;
  shadowOffset: {
    width: 0,
    height: 2,
  };
  shadowRadius: 4px;
  padding-horizontal: 10px;
  padding-vertical: 4px;
  align-items: center;
`;

export const Title = styled.Text`
  font-size: ${font.size.xSmall}px;
  color: ${colors.textBlack};
  text-transform: uppercase;
  ${font.style.medium}
`;
