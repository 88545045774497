import React from 'react';
import propTypes from 'prop-types';

import './fieldBtn.css';

const FieldBtn = (props) => {
  return (
    <div className="b-field-btn">
      <label className="b-field-btn__label">{props.label}</label>
      <div
        className="b-field-btn__input-container"
        onClick={props.action}
      >
        <input
          className="b-field-btn__input"
          value={props.value ? props.value : ''}
          placeholder={props.placeholder ? props.placeholder : ''}
          disabled
        />
        {
          props.btn
          ?
          (<button className="b-field-btn__btn">{props.btnText}</button>)
          :
          (<div className="b-field-btn__arrow"></div>)
        }
      </div>
    </div>
  );
};

export default FieldBtn;

FieldBtn.propsTypes = {
  label: propTypes.string.isRequired,
  value: propTypes.string,
  btn: propTypes.bool,
  action: propTypes.func.isRequired,
};
