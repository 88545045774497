// @flow
import styled from 'styled-components/native';
import {space, color, flex, layout} from 'styled-system';

const mailIcon = require('./assets/mail.png')

const { colors } = styles;

import InputComponent from '../../../components/FormInput'
import styles from "../../../constants/styles";
import NewButton from "../../../components/Button/NewButton";
import {TextN, TextS} from "../../../components/Text";

export const Root = styled.View``

export const Text = TextS;

export const Touchable = styled.TouchableWithoutFeedback``;

export const ContentContainer = styled.View.attrs({
  pl: 25,
  pr: 25,
  pb: 25,
})`
  ${space}
`

export const EmailImageContainer = styled.View`
  justify-content: center;
  align-items: center;
  background-color: #f6f6f7;
  height: 90px;
`;

export const EmailImage = styled.Image.attrs({
  width: 58,
  height: 58,
  source: mailIcon,
  flex: 1,
  resizeMode: 'contain',
})`
  ${flex}
  ${layout}
`;

export const Input = styled(InputComponent).attrs({
  theme: 'light',
  placeholderTextColor: colors.grey,
})``

export const ErrorText = styled(TextN).attrs({
  color: 'errorRed',
  mt: 4,
})`
  ${space}
`
export const LogInButton = styled(NewButton).attrs({
  color: 'green',
  mt: 25,
  mb: 30,
})`
  border-radius: 5px;
  box-shadow: 0 4px 10px #0000003D;
`

export const LinkText = styled(TextS).attrs({
  fontFamily: 'medium',
  color: 'green',
  ml: 3,
  mr: 3,
})`
  align-self: center;
  text-decoration: underline;
  text-decoration-color: #759a58;
  ${color}
  ${space}
`;

export const CreateAccountContainer = styled.View.attrs({
  mt: 15,
})`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  ${space}
`
