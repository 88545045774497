import React, { Component } from 'react';
import propTypes from 'prop-types';
import texts from '../../../../constants/languages';
import { patterns } from '../../../../patterns/patterns';
import TextField from '../../../templates/form/textField';
import ReactSelect from '../../../templates/form/reactSelect';
import './addUnion.css';

class AddUnion extends Component {

  state = {
    fieldsPatterns: {
      member_number: patterns.unionNumber,
    },
    unionsSelectData: [],
    currentSelectedDate: {
      union_name: '',
    },
    userData: {
      union_id: '',
      member_number: '',
    },
    fieldsIsEmpty: {
      member_number: false,
    },
    fieldsIsInvalid: {
      member_number: false,
    },
    fieldsHaveServerErrors: {
      member_number: false,
    },
    serverErrorsMessages: {
      member_number: '',
    },
  }

  requiredFields = ['member_number'];

  componentDidMount() {
    this.setDefaultValues();
  }

  setDefaultValues = () => {
    if (this.props.isUnionMember) {
      this.setDefaultUnionsForUnionMember(this.props.unions, this.props.authUserData.union_id);
      this.changeInputsState('userData', 'member_number', this.props.authUserData.member_number);
    } else {
      this.setDefaultUnionsForNonUnionMember(this.props.unions);
    }
  }

  setDefaultUnionsForUnionMember = (unions, unionId) => {
    unions.forEach((item) => {
      if (item.union_id === unionId) {
        this.changeInputsState('userData', 'union_name', item.name);
        this.changeInputsState('userData', 'union_id', item.union_id);
      }
    });
  }

  setDefaultUnionsForNonUnionMember = (unions) => {
    if (this.props.location === 'danish') {
      this.changeInputsState('userData', 'union_name', unions[1].name);
      this.changeInputsState('userData', 'union_id', unions[1].union_id);
    } else {
      this.changeInputsState('userData', 'union_name', unions[0].name);
      this.changeInputsState('userData', 'union_id', unions[0].union_id);
    }
  }

  changeInputsState = (dataName, valueName, value) => {
    this.setState((state) => {
      const obj = { ...state };
      if (value === undefined) {
        obj[dataName][valueName] = '';
      } else {
        obj[dataName][valueName] = value;
      }
      return obj;
    });
  };

  validateField = (fieldName, fieldsValueData, fieldsIsEmptyData, fieldsIsInvalidData, patternsData, errorsCounter) => {
    if (this.requiredFields.indexOf(fieldName) >= 0) {
      if (fieldsValueData[fieldName].length) {
        this.changeInputsState('fieldsIsEmpty', fieldName, false);// field is not empty
        if (!patternsData[fieldName].test(fieldsValueData[fieldName])) {
          this.changeInputsState('fieldsIsInvalid', fieldName, true);// field is invalid
          errorsCounter.push(fieldName);
        } else {
          this.changeInputsState('fieldsIsInvalid', fieldName, false);// field is valid
        }
      } else {
        this.changeInputsState('fieldsIsEmpty', fieldName, true);// field is empty
        errorsCounter.push(fieldName);
      }
    }
  };

  cleanServerErrors = (serverErrorsData, serverErrorsDataName) => {
    for (let field in serverErrorsData) {
      this.changeInputsState(serverErrorsDataName, field, false);
    }
  };

  setServerErrors = (errorData) => {
    this.changeInputsState('fieldsHaveServerErrors', 'member_number', true);
    this.changeInputsState('serverErrorsMessages', 'member_number', errorData[0].message);
  };

  validateForm = (fieldsValueData, fieldsIsEmptyData, fieldsIsInvalidData, patternsData, errorsCounter) => {
    for (let field in fieldsValueData) {
      this.validateField(field, fieldsValueData, fieldsIsEmptyData, fieldsIsInvalidData, patternsData, errorsCounter);
    }
    this.cleanServerErrors(this.state.fieldsHaveServerErrors, 'fieldsHaveServerErrors');
  };

  submitForm = (event) => {
    event.preventDefault();

    const errorsCounter = [];
    this.validateForm(this.state.userData, this.state.fieldsIsEmpty, this.state.fieldsIsInvalid, this.state.fieldsPatterns, errorsCounter);
    if (!errorsCounter.length) {
      this.props.changeClub(
        this.state.userData.union_id,
        this.state.userData.member_number,
        this.props.authUserData.birth_date,
        this.setServerErrors,
      );
    }
  };

  closePopupOnClickOutSide = (event) => {
    if (!this.popup.contains(event.target)) {
      this.props.togglePopup('addUnionPopupIsOpen', false);
    }
  }

  render() {
    return (
      <div className="g-popup-wrap"
           onClick={(event) => this.closePopupOnClickOutSide(event)} // close popup
      >
        <div
          className="g-popup-overlay"
        >
          &nbsp;
        </div>
        <div className="g-popup-wrap">
          <table className="g-popup-wrap__container">
            <tbody>
            <tr>
              <td className="g-popup-wrap__container-inner">
                <div className="g-popup"
                     ref={(popup) => this.popup = popup}
                >
                  <div
                    className="g-popup__close-btn"
                    onClick={() => this.props.togglePopup('addUnionPopupIsOpen', false)} // close popup
                  >
                    &nbsp;
                  </div>
                  <div className="g-popup__content">
                    <form className="g-form" action="#">
                      <h2 className="g-form__title">{this.props.isUnionMember ? texts.CHANGE_CLUB : texts.ADD_UNION}</h2>
                      <ReactSelect
                        label={texts.UNION}
                        data={this.props.unions}
                        nameOfValueProp="union_id"
                        nameOfTitleProp="name"
                        changeNameOfValueProp={(value) => this.changeInputsState('userData', 'union_id', value)}
                        defaultValue={this.state.userData.union_id}
                      />
                      <TextField
                        valueName="member_number"
                        value={this.state.userData.member_number}
                        label={texts.UNION_MEMBER_NO}
                        type="text"
                        placeholder={texts.ENTER_YOUR_UNION_MEMBER_NO}
                        onChange={(name, value) => this.changeInputsState('userData', name, value)}
                        errorText={[
                          texts.PLEASE_ENTER_YOUR_UNION_MEMBER_NUMBER,
                          texts.PLEASE_ENTER_VALID_UNION_MEMBER_NUMBER,
                          this.state.serverErrorsMessages.member_number,
                        ]}
                        isEmpty={this.state.fieldsIsEmpty.member_number}
                        isInvalid={this.state.fieldsIsInvalid.member_number}
                        haveServerError={this.state.fieldsHaveServerErrors.member_number}
                      />
                      <div className="g-form__options g-clear-fix">
                        <div className="g-form__btn-holder m-form__btn-holder_right">
                          <button
                            className="g-form__btn g-submit-btn"
                            onClick={this.submitForm}
                          >{texts.REQUEST}</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default AddUnion;

AddUnion.propTypes = {
  togglePopup: propTypes.func.isRequired,
  changeClub: propTypes.func.isRequired,
  unions: propTypes.array.isRequired,
  isUnionMember: propTypes.bool.isRequired,
};
