// @flow
import React from 'react';
import { Text as TextRN } from 'react-native';
import styled from 'styled-components/native';
import { typography, color, space } from 'styled-system';
import { fontFamilyHelper } from '../../helpers/styles';

const TextWithAttr = ({ text, children, ...restProps }: { text: string, children: string }) => (
  <TextRN {...restProps}>{text || children}</TextRN>
);

export const Text = styled(TextWithAttr).attrs(({ color }) => ({
  color: color || 'textBlack',
}))`
  ${fontFamilyHelper}
  ${typography}
  ${color}
  ${space}
`;

export const TextXxs = styled(Text).attrs({
  fontSize: 0,
})``;

export const TextXs = styled(Text).attrs({
  fontSize: 1,
})``;

export const TextS = styled(Text).attrs({
  fontSize: 2,
})``;

export const TextN = styled(Text).attrs({
  fontSize: 3,
})``;

export const TextM = styled(Text).attrs({
  fontSize: 4,
})``;

export const TextL = styled(Text).attrs({
  fontSize: 5,
})``;

export const TextXl = styled(Text).attrs({
  fontSize: 6,
})``;

export const TextXxl = styled(Text).attrs({
  fontSize: 7,
})``;
