// @flow
import styled from 'styled-components/native';
import { color, space, flex } from 'styled-system';

import { elevation1 } from '../../../../../helpers/styles';
import { TextS, TextXs } from '../../../../../components/Text';

export const FooterContainer = styled.View.attrs({
  bg: 'bgSecondary',
  p: 6,
  mt: 4,
  ...elevation1,
})`
  ${color}
  ${space}
  ${flex}
`;

export const VoucherUseLabel = styled(TextXs).attrs({
  fontFamily: 'medium',
  color: 'green',
  mt: 3,
  mb: 3,
})`
  ${flex}
`;

export const VoucherTimeContainer = styled.View`
  flex-direction: row;
  ${flex}
`;

export const VoucherWeekdayContainer = styled.View.attrs({})`
  flex-grow: 1;
  ${flex};
`;

export const VoucherTimeRangeContainer = styled.View.attrs({})`
  flex-grow: 1;
`;

export const VoucherUseCase = styled(TextS).attrs({
  fontFamily: 'light',
})`
  ${flex}
`;

export const VoucherRestrictionsLabel = styled(TextXs).attrs({
  fontFamily: 'medium',
  color: 'bubbleRed',
  mt: 4,
  mb: 3,
})`
  ${flex}
`;
