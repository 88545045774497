// @flow
// import { NavigationActions } from 'react-navigation';
import browserHistory from '../../../history';

// eslint-disable-next-line no-underscore-dangle
let _navigator: ?Object;

export function setTopLevelNavigator(navigatorRef: Object) {
  _navigator = navigatorRef;
}

export const navigate = (route: Object) => {
  console.log('Navigate', route);
  switch (route.routeName) {
    case 'VerifyAccount':
      browserHistory.push('/register/verify');
      break;
    case 'Tabs':
      browserHistory.push('/');
      break;
    default:
      console.error(`Route mock "${route.routeName}" is not defined, pls define it in navigation.web.js`);
  }
};

export const navigateBack = () => {
  browserHistory.goBack();
};
