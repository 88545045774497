// @flow

const unionData = [
  {
    displayName: 'DK',
    locale: 'da',
    isoCode: 'dk',
    phonePrefix: '+45',
    countryName: 'Danmark',
  },
  {
    displayName: 'NO',
    locale: 'no',
    isoCode: 'no',
    phonePrefix: '+47',
    countryName: 'Norge',
  },
  {
    displayName: 'IS',
    locale: 'is',
    isoCode: 'is',
    phonePrefix: '+354',
    countryName: 'Ísland',
  },
  {
    displayName: 'FI',
    locale: 'fi',
    isoCode: 'fi',
    phonePrefix: '+358',
    countryName: 'Suomi',
  },
  {
    displayName: 'SE',
    locale: 'sv',
    isoCode: 'se',
    phonePrefix: '+46',
    countryName: 'Sverige',
  },
];

export const unionByLocaleId = unionData.reduce((acc, union) => {
  acc[union.locale] = union;
  return acc;
}, {});

export const unionByIsoCode = unionData.reduce((acc, union) => {
  acc[union.isoCode] = union;
  return acc;
}, {});

export default unionData;
