import React, { Component } from 'react';
import propTypes from 'prop-types';

import { EMAIL_VERIFY } from '../../../../constants/config/images';
import texts from '../../../../constants/languages';

class EmailVerifySuccess extends Component {
  closePopupOnClickOutSide = (event) => {
    if (!this.popup.contains(event.target)) {
      this.props.togglePopup('emailVerifySuccessPopupIsOpen', false);
    }
    localStorage.removeItem('registerName');
  };

  checkPlatform = () => {
    const { userAgent } = navigator;
    return /mobile/gi.test(userAgent.toString()) ? 'mobile' : 'web';
  };

  handleClickByPlatform = () => {
    const { togglePopup } = this.props;
    const platform = this.checkPlatform();

    localStorage.removeItem('registerName');

    if (platform === 'web') {
      togglePopup('emailVerifySuccessPopupIsOpen', false);
      togglePopup('logInPopupIsOpen', true);
    } else {
      togglePopup('emailVerifySuccessPopupIsOpen', false);
    }
  };

  render() {
    const registerName = localStorage.getItem('registerName');

    return (
      <div
        className="g-popup-wrap"
        onClick={(event) => this.closePopupOnClickOutSide(event)} // close popup
      >
        <div className="g-popup-overlay">&nbsp;</div>
        <div className="g-popup-wrap">
          <table className="g-popup-wrap__container">
            <tbody>
              <tr>
                <td className="g-popup-wrap__container-inner">
                  <div className="g-popup m-popup_wide" ref={(popup) => (this.popup = popup)}>
                    <div
                      className="g-popup__close-btn"
                      onClick={() => this.props.togglePopup('emailVerifySuccessPopupIsOpen', false)} // close popup
                    >
                      &nbsp;
                    </div>
                    <div className="g-popup__content">
                      <form className="g-form" action="#">
                        <h2 className="g-form__title m-form__title_small remove-capitalize">{texts.MAIL_CONFIRMED}</h2>
                        <img className="g-form__img" src={EMAIL_VERIFY} width={86} height={86} alt="" />
                        <p className="g-form__paragraph">
                          {texts.WELCOME}
                          {registerName != null ? ', ' : ''}
                          {registerName}!<br />
                          {texts.YOUR_ACCOUNT_IS_NOW_ACTIVATED}.
                        </p>
                        <div className="g-form__btn-holder m-form__btn-holder_confirm g-clear-fix">
                          <button
                            className="g-form__btn m-form__btn_confirm g-submit-btn"
                            onClick={this.handleClickByPlatform}
                          >
                            {texts.GREAT}!
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default EmailVerifySuccess;

EmailVerifySuccess.propTypes = {
  togglePopup: propTypes.func.isRequired,
  registerData: propTypes.shape({ first_name: propTypes.string.isRequired }),
};
