import { Map } from 'immutable';
import authConstants from './authConstants';
import appConstants from '../app/appConstants';

const initialState = Map({
  unions: [],
  authUserData: {},
  logInPopupIsOpen: false,
  updateNotificationErrorPopup: false,
  newsletterPopup: false,
  emailForgotPassword: '',
  userIsAuthenticated: false,
  logOutWarn: false,
});

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case appConstants.TOGGLE_POPUP:
      return state.set(action.popup.popupName, action.popup.status);
    case authConstants.HANDLE_LOG_IN:
      return state.set('userIsAuthenticated', action.userIsAuthenticated);
    case authConstants.SET_UNIONS:
      return state.set('unions', action.unions);
    case authConstants.SET_UNION_MEMBER_STATUS:
      return state.set('isUnionMember', action.status);
    case authConstants.SET_UNION_MEMBER_DATA:
      return state.set('unionMemberData', action.unionMemberData);
    case authConstants.SET_DATA_OF_AUTH_USER:
      return state.set('authUserData', action.data);
    case authConstants.TOGGLE_LOG_OUT_WARN: {
      return state.set('logOutWarn', !state.get('logOutWarn'));
    }
    case authConstants.SET_REFERRAL: {
      return state.set('referral', action.payload);
    }
    default:
      return state;
  }
}
