import React from 'react';
import RegisterSteps from "../RegisterSteps";

import { StepsContainer, root } from './styles';
import { useMediaQuery } from "react-responsive";

const RegisterStepsPane = ({ stepCount, step }) => {
  const isNarrow = useMediaQuery({ maxWidth: 1000 });
  return (
    <div style={root}>
      <StepsContainer isNarrow={isNarrow}>
        <RegisterSteps stepCount={stepCount} step={step} />
      </StepsContainer>
    </div>
  );
};

export default RegisterStepsPane;
