// @flow
import type { ReqType } from '../../store/index';
import API from '../../api/base/api';
import moment from 'moment';

export type User = {
  user_id: number,
  user_guid: string,
  country: string,
  language_default: string, // DEPRECATED
  union_id: number,
  member_number: string,
  first_name: string,
  last_name: string,
  birth_date: string,
  sex: string,
  member_type: string,
  email_confirmed: boolean,
  phone_confirmed: boolean,
  phone_confirmed2: boolean,
  member_status: boolean,
  email: string,
  union_email: string,
  phone: string,
  phone2: ?string,
  hcp: string,
  push_notifications_enabled_default: boolean,
  club_name: string,
  print_rules_accepted: boolean,
};

export type ChangeUserClubDataT = {
  union_id: number,
  member_number: string,
  birth_date: string,
};

class UserAPI extends API {
  recoverPassword(email: string) {
    return this.r({
      method: 'POST',
      url: '/user/recover-password',
      data: {
        email,
      },
    });
  }

  checkUnion(unionId: string, memberNumber: string, birthDate: Date, locale) {
    const date = moment(birthDate).format('YYYY-MM-DD');
    return this.r({
      method: 'GET',
      url: `/user/member/${unionId}/${memberNumber}/${date}?locale=${locale}`,
    });
  }

  register(data) {
    const result = this.r({
      method: 'POST',
      url: '/user',
      data,
    });
    result.then((r) => console.log('register data', data, 'result', r));
    return result;
  }

  get() {
    return this.r({
      method: 'GET',
      url: '/user',
    });
  }

  delete(id) {
    console.log('trying to delete ', id);
    return this.r({
      method: 'DELETE',
      url: `/user/delete?member_id=${id}`,
    });
  }

  update(data: $Shape<User>) {
    return this.r({
      method: 'PATCH',
      url: '/user',
      data,
    });
  }

  changeClub(data: ChangeUserClubDataT) {
    return this.r({
      method: 'PATCH',
      url: '/user/club',
      data,
    });
  }

  resendEmail(email: string) {
    return this.r({
      method: 'POST',
      url: '/user/resend/email',
      data: {
        email,
      },
    });
  }

  getGolfIds(email, password) {
    return this.r({
      method: 'POST',
      url: '/user/golfids',
      data: {
        email,
        password,
      },
    });
  }

  transferReferral(referral) {
    return this.r({
      method: 'POST',
      url: '/transfer-referral/',
      data: {
        referral,
      },
    });
  }
}

export default function userApi(request: ReqType) {
  return new UserAPI(request);
}
