// @flow
import axios from 'axios';
import base64 from 'base-64';
import i18n from 'react-native-i18n';
import session from '../auth/api/session';
import user from '../user/api/user';
import club from '../clubs/api/club';
import voucher from '../vouchers/api/voucher';
import country from '../register/api/country';
import pinCode from './modules/pinCode';
import history from './modules/history';

export type ReqType = (config: Object) => Promise<*>;

class Client {
  token: ?string;
  req: ReqType;
  // modules
  session: *;
  user: *;
  club: *;
  voucher: *;
  notification: *;
  country: *;
  pinCode: *;
  history: *;

  constructor(baseURL: ?string = '') {
    this.req = axios.create({
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      baseURL,
    });

    // driving range requests have different endpoint
    this.drreq = axios.create({
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      baseURL: baseURL.replace('/v1', ''), // use this hack to produce driving range URL
    });

    const interceptor = (config) => {
      // eslint-disable-next-line no-param-reassign
      config.headers = Object.assign({}, config.headers, {
        'Accept-language': i18n.currentLocale(),
      });

      if (!this.token) {
        return config;
      }

      // eslint-disable-next-line no-param-reassign
      config.headers = Object.assign({}, config.headers, {
        Authorization: this.token,
      });
      return config;
    };

    this.req.interceptors.request.use(interceptor);
    this.drreq.interceptors.request.use(interceptor);

    /* this.req.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        const originalRequest = error.config;
        console.log('response.use error.', error, originalRequest?.url);

        if (error.response?.status === 403) {
          console.error('Error 403, not auth');

          return Promise.reject(error);
        }
      },
    );*/

    this.session = session(this.req);
    this.user = user(this.req);
    this.club = club(this.req);
    this.voucher = voucher(this.req);
    this.country = country(this.req);
    this.pinCode = pinCode(this.req);
    this.history = history(this.req);
  }

  setToken(sessionId: string): void {
    this.token = `Basic ${base64.encode(`${sessionId}:`)}`;
  }
}

let instance;

export default function api(baseUrl: ?string) {
  if (!instance) {
    instance = new Client(baseUrl);
  }

  return instance;
}
