// @flow
import React from 'react';
import { View } from 'react-native';
import styles from './styles';

type Props = {
  children: Array,
  style?: ?Object,
  innerStyle?: ?Object,
};

const BigPane = ({ style, innerStyle, children }: Props) => {
  return (
    <View style={style || styles.container}>
      <View style={innerStyle || styles.contents}>{children}</View>
    </View>
  );
};

export default BigPane;
