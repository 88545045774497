import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import appActions from '../../../../domains/app/appActions';
import authActions from '../../../../domains/auth/actions';

import LogIn from './logInNew';

const mapStateToProps = ({ authReducer }) => ({});

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators(
    {
      togglePopup: appActions.togglePopup,
      logIn: authActions.logIn,
    },
    dispatch,
  ),
)(LogIn);
