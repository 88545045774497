// @flow
import AsyncStorage from '@react-native-community/async-storage';
import { takeEvery, put } from 'redux-saga/effects';
import { logOut } from '../../auth/modules/session';
import {
  LOAD_LOCAL_USER,
  LOAD_USER_SUCCESS,
  loadLocalUserSuccess,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILED,
} from '../modules/user';

export const USER_KEY = 'golfMore@user';

function* storeUser(action) {
  try {
    const resultJson = JSON.stringify(action.result.data);
    yield AsyncStorage.setItem(USER_KEY, resultJson);
  } catch (error) {
    console.log(error);
  }
}

function* getLocalUser() {
  try {
    const jsonString = yield AsyncStorage.getItem(USER_KEY);
    const json = JSON.parse(jsonString);

    yield put(loadLocalUserSuccess(json));
  } catch (error) {
    console.log(error);
  }
}

function* deleteFailed() {
  alert('Try again later');
}

function* deleteSuccess() {
  yield put(logOut());
}

export default function*(): Generator<*, *, *> {
  yield takeEvery(LOAD_USER_SUCCESS, storeUser);
  yield takeEvery(LOAD_LOCAL_USER, getLocalUser);
  yield takeEvery(DELETE_USER_FAILED, deleteFailed);
  yield takeEvery(DELETE_USER_SUCCESS, deleteSuccess);
}
