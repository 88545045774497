// @flow
import get from 'lodash/get';

import type { Country } from '../api/country';
import { API_REQUEST } from '../../store/apiAction';
import api from '../../api';

const GET_COUNTRIES: 'country/GET_COUNTRIES' = 'country/GET_COUNTRIES';
export const GET_COUNTRIES_SUCCESS: 'country/GET_COUNTRIES_SUCCESS' =
  'country/GET_COUNTRIES_SUCCESS';
export const GET_COUNTRIES_FAILED: 'country/GET_COUNTRIES_FAILED' = 'country/GET_COUNTRIES_FAILED';
export const GET_COUNTRIES_LOCAL: 'country/GET_COUNTRIES_LOCAL' = 'country/GET_COUNTRIES_LOCAL';
export const GET_COUNTRIES_LOCAL_SUCCESS: 'country/GET_COUNTRIES_LOCAL_SUCCESS' =
  'country/GET_COUNTRIES_LOCAL_SUCCESS';

const GET_GEO_IP: 'country/GET_GEO_IP' = 'country/GET_GEO_IP';
export const GET_GEO_IP_SUCCESS: 'country/GET_GEO_IP_SUCCESS' = 'country/GET_GEO_IP_SUCCESS';
export const GET_GEO_IP_FAILED: 'country/GET_GEO_IP_FAILED' = 'country/GET_GEO_IP_FAILED';
export const GET_GEO_IP_LOCAL: 'country/GET_GEO_IP_LOCAL' = 'country/GET_GEO_IP_LOCAL';
export const GET_GEO_IP_LOCAL_SUCCESS: 'country/GET_GEO_IP_LOCAL_SUCCESS' =
  'country/GET_GEO_IP_LOCAL_SUCCESS';

type GetCountryAction = {|
  type: typeof GET_COUNTRIES,
|};

type GetCountrySuccessAction = {|
  type: typeof GET_COUNTRIES_SUCCESS,
  result: {
    data: Array<Country>,
  },
|};

type GetCountryFailedAction = {|
  type: typeof GET_COUNTRIES_FAILED,
  error: {
    response: {
      data: {
        message: string,
      },
    },
  },
|};

type GetCountryLocalAction = {|
  type: typeof GET_COUNTRIES_LOCAL,
|};

type GetCountryLocalSuccessAction = {|
  type: typeof GET_COUNTRIES_LOCAL_SUCCESS,
  result: {
    data: Array<Country>,
  },
|};

type GetGeoIpAction = {|
  type: typeof GET_GEO_IP,
|};

type GetGeoIpSuccessAction = {|
  type: typeof GET_GEO_IP_SUCCESS,
  result: {
    data: Country,
  },
|};

type GetGeoIpFailedAction = {|
  type: typeof GET_GEO_IP_FAILED,
  error: {
    message: string,
  },
|};

type GetGeoIpLocalAction = {|
  type: typeof GET_GEO_IP_LOCAL,
|};

type GetGeoIpLocalSuccessAction = {|
  type: typeof GET_GEO_IP_LOCAL_SUCCESS,
  result: {
    data: Country,
  },
|};

type CountryActions =
  | GetCountryAction
  | GetCountrySuccessAction
  | GetCountryFailedAction
  | GetCountryLocalAction
  | GetCountryLocalSuccessAction
  | GetGeoIpAction
  | GetGeoIpSuccessAction
  | GetGeoIpFailedAction
  | GetGeoIpLocalAction
  | GetGeoIpLocalSuccessAction;

type CountriesState = {
  data: Array<Country>,
  loading: boolean,
  error: ?string,
};

type GeoIpState = {
  data: Country,
  loading: boolean,
  error: ?string,
};

export type State = {
  countries: CountriesState,
  geoIp: GeoIpState,
};

const initialState: State = {
  countries: {
    data: [],
    loading: false,
    error: null,
  },
  geoIp: {
    data: {},
    loading: false,
    error: null,
  },
};

export default function countryReducer(state: State = initialState, action: CountryActions): State {
  switch (action.type) {
    case GET_COUNTRIES:
      return {
        ...state,
        countries: {
          ...initialState.countries,
          loading: true,
        },
      };

    case GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: {
          ...initialState.countries,
          data: action.result.data,
        },
      };

    case GET_COUNTRIES_FAILED:
      return {
        ...state,
        countries: {
          ...initialState.countries,
          error: get(action.error, 'response.data.message', String(action.error)),
        },
      };

    case GET_COUNTRIES_LOCAL:
      return {
        ...state,
        countries: {
          ...initialState.countries,
          loading: true,
        },
      };

    case GET_COUNTRIES_LOCAL_SUCCESS:
      return {
        ...state,
        countries: {
          ...initialState.countries,
          data: action.result.data,
        },
      };

    case GET_GEO_IP:
      return {
        ...state,
        geoIp: {
          ...initialState.geoIp,
          loading: true,
        },
      };

    case GET_GEO_IP_SUCCESS:
      return {
        ...state,
        geoIp: {
          ...initialState.geoIp,
          data: action.result.data,
        },
      };

    case GET_GEO_IP_FAILED:
      return {
        ...state,
        geoIp: {
          ...initialState.geoIp,
          data: {
            country_code: 'DK',
            country_name: 'Denmark',
            calling_code: '45',
          },
          error: get(action.error, 'response.data.message', String(action.error)),
        },
      };

    case GET_GEO_IP_LOCAL:
      return {
        ...state,
        geoIp: {
          ...initialState.geoIp,
          loading: true,
        },
      };

    case GET_GEO_IP_LOCAL_SUCCESS:
      return {
        ...state,
        geoIp: {
          ...initialState.geoIp,
          data: action.result.data,
        },
      };

    default:
      return state;
  }
}

export function getCountries() {
  return {
    type: API_REQUEST,
    types: [GET_COUNTRIES, GET_COUNTRIES_SUCCESS, GET_COUNTRIES_FAILED],
    call: () => api().country.getCountries(),
  };
}

export function getGeoIp() {
  return {
    type: API_REQUEST,
    types: [GET_GEO_IP, GET_GEO_IP_SUCCESS, GET_GEO_IP_FAILED],
    call: () => api().country.getGeoIp(),
  };
}

export function getLocalCountries() {
  return {
    type: GET_COUNTRIES_LOCAL,
  };
}

export function getLocalCountriesSuccess(data) {
  return {
    type: GET_COUNTRIES_LOCAL_SUCCESS,
    result: {
      data,
    },
  };
}

export function getLocalGeoIpSuccess(data) {
  return {
    type: GET_GEO_IP_LOCAL_SUCCESS,
    result: {
      data,
    },
  };
}
