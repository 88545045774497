import React, { Component } from 'react';

import { SIGN_UP_CONFIRM } from '../../../../constants/config/images';
import texts from '../../../../constants/languages';

class EmailVerifyError extends Component {
  closePopupOnClickOutSide = (event) => {
    if (!this.popup.contains(event.target)) {
      this.props.togglePopup('emailVerifyErrorPopupIsOpen', false);
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
  };

  render() {
    // const registerName = localStorage.getItem('registerName'); // unused const

    return (
      <div
        className="g-popup-wrap"
        onClick={(event) => this.closePopupOnClickOutSide(event)} // close popup
      >
        <div className="g-popup-overlay">&nbsp;</div>
        <div className="g-popup-wrap">
          <table className="g-popup-wrap__container">
            <tbody>
              <tr>
                <td className="g-popup-wrap__container-inner">
                  <div className="g-popup m-popup_wide" ref={(popup) => (this.popup = popup)}>
                    <div
                      className="g-popup__close-btn"
                      onClick={() => this.props.togglePopup('emailVerifyErrorPopupIsOpen', false)} // close popup
                    >
                      &nbsp;
                    </div>
                    <div className="g-popup__content">
                      <form className="g-form" action="#" onSubmit={(e) => this.handleSubmit(e)}>
                        <h2 className="g-form__title m-form__title_small remove-capitalize">{texts.MAIL_VERIFY}</h2>
                        <img className="g-form__img" src={SIGN_UP_CONFIRM} width={86} height={86} alt="verify" />
                        <p className="g-form__paragraph">
                          {texts.MAIL_VERIFY_DETAILS_1}
                          <span></span>
                          {texts.MAIL_VERIFY_DETAILS_2}
                        </p>
                        <div className="g-form__btn-holder m-form__btn-holder_confirm g-clear-fix">
                          <button
                            className="g-form__btn m-form__btn_confirm g-submit-btn"
                            onClick={() => {
                              const userData = JSON.parse(sessionStorage.getItem('loginData'));

                              this.props.logIn(userData);
                            }}
                          >
                            {texts.SEND_AGAIN}!
                          </button>
                        </div>
                        <div className="g-form__btn-holder m-form__btn-holder_confirm g-clear-fix">
                          <button
                            className="g-form__btn m-form__btn_confirm g-submit-btn m-form__btn_large m-form__btn_size"
                            onClick={() => {
                              this.props.togglePopup('emailVerifyErrorPopupIsOpen', false);
                              this.props.togglePopup('logInPopupIsOpen', true);
                            }}
                          >
                            {texts.PRESS_HERE}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default EmailVerifyError;
