import { connect } from 'react-redux';

import appActions from '../../../../domains/app/appActions';
import authActions from '../../../../domains/auth/actions';

import AddUnion from './addUnion';

function mapStateToProps(state) {
  return {
    location: state.appReducer.get('location'),
    unions: state.authReducer.get('unions'),
    isUnionMember: state.authReducer.get('isUnionMember'),
    authUserData: state.authReducer.get('authUserData'),
  };
}

const mapDispatchToProps = (dispatch) => ({
  togglePopup: (popupName, status) => dispatch(appActions.togglePopup(popupName, status)),
  changeClub: (unionID, memberNumber, birthDate, setServerErrors) =>
    dispatch(authActions.changeClub(unionID, memberNumber, birthDate, setServerErrors)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUnion);
