import { fromJS } from 'immutable';
import faqConstants from './faqConstants';

const initialState = fromJS({
  faqsItems: [],
  faqsCategories: [],
  faqsByCategory: [],
});

export default function faqReducer(state = initialState, action) {
  switch (action.type) {
    case faqConstants.SET_FAQS_ITEMS: {
      return state.set('faqsItems', action.faqsItems);
    }
    case faqConstants.SET_FAQS_CATEGORIES: {
      return state.set('faqsCategories', action.faqsCategories);
    }
    case faqConstants.SET_FAQS_BY_CATEGORY:
      return state.set('faqsByCategory', action.faqsByCategory);
    default:
      return state;
  }
}
