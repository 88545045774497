// @flow
import { StyleSheet } from 'react-native';
import styles from '../../../../../react-native/constants/styles';
import { elevation6 } from '../../../../../react-native/helpers/styles';

const { colors, font } = styles;

export const headerHeight = 48; // as per design

export default StyleSheet.create({
  secondHeader: {
    flexDirection: 'row',
    //  justifyContent: 'center',
    backgroundColor: colors.white,
    width: '100%',
    height: headerHeight,
  },
  tab: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  activeTab: {
    width: '50%',
    borderColor: colors.textBlack,
    borderBottomWidth: 2,
  },
  tabText: {
    color: colors.textBlack,
    ...font.style.regular,
    fontSize: font.size.normal,
    textAlign: 'center',
  },
  activeTabText: {
    color: colors.textBlack,
  },

  leftBtn: {
    width: 50,
    justifyContent: 'center',
    alignItems: 'center',
  },
  leftIcon: {
    resizeMode: 'contain',
  },
  rightBtn: {
    width: 50,
    justifyContent: 'center',
    alignItems: 'center',
  },
  rightIcon: {
    resizeMode: 'contain',
  },
  title: {
    flex: 1,
    justifyContent: 'center',
  },
  titleText: {
    textAlign: 'center',
    ...font.style.medium,
    color: colors.basic,
    fontSize: font.size.normal,
  },
});
