// @flow
import styled from 'styled-components/native';
import { color, layout } from 'styled-system';
import { TextL } from '../Text';
import { closeX } from '../../assets/images';
import { elevation4 } from '../../helpers/styles';

const HEADER_HEIGHT = 56;

export const Touchable = styled.TouchableWithoutFeedback``;

export const Modal = styled.Modal``;

export const Root = styled.SafeAreaView.attrs({
  flex: 1,
  bg: 'bgDark',
})`
  flex-direction: column-reverse;
  ${color}
`;

export const HeaderContainer = styled.View.attrs({
  height: HEADER_HEIGHT,
  bg: 'bgDark',
  ...elevation4,
})`
  flex-direction: row;
  ${layout}
  ${color}
`;

export const TitleContainer = styled.View`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
`;

export const Title = styled(TextL).attrs({
  fontFamily: 'medium',
  color: 'white',
})``;

export const CloseContainer = styled.View.attrs({
  width: HEADER_HEIGHT,
  height: HEADER_HEIGHT,
})`
  justify-content: center;
  align-items: center;
  ${layout}
`;

export const CloseImage = styled.Image.attrs({
  width: 13,
  height: 13,
  source: closeX,
  resizeMode: 'cover', // Because designer probably forgot to crop the icon
})`
  ${layout}
`;

export const ContentContainer = styled.View.attrs({
  flex: 1,
  bg: 'bgPrimary',
})`
  ${color}
`;
