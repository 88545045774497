// @flow
import AsyncStorage from '@react-native-community/async-storage';
import i18n from 'react-native-i18n';
import { takeEvery, put } from 'redux-saga/effects';

import { SET, initLocale } from '../modules/locale';

const LOCALE_KEY = 'golfMore@locale';

function maybeSetLocale(locale: string): void {
  if (locale in i18n.translations) {
    i18n.locale = locale;
    AsyncStorage.setItem(LOCALE_KEY, locale);
  }
}

export default function*(): Generator<*, *, *> {
  yield takeEvery(SET, ({ locale }) => maybeSetLocale(locale));

  let locale = yield AsyncStorage.getItem(LOCALE_KEY);
  if (!locale) {
    locale = i18n
      .currentLocale()
      .split('-')
      .shift();
  }

  i18n.locale = locale in i18n.translations ? locale : i18n.defaultLocale;
  yield put(initLocale(locale));
}
