// @flow
import React, { Component } from 'react';
import { View, Text, TouchableOpacity, Image } from 'react-native';
import Calendar from 'rc-calendar';
// import DateTimePicker from 'react-native-modal-datetime-picker';
import i18n from 'react-native-i18n';
import danskLocale from 'rc-calendar/lib/locale/da_DK';
import englishLocale from 'rc-calendar/lib/locale/en_US';
import moment from 'moment';

import 'rc-calendar/assets/index.css';

import styles from './style';

const iconCalendar = require('../../assets/calendar.png');

type Props = {
  onChange: (date: Date) => void,
  onOpen: () => void,
  value: ?Date,
  defaultText?: string,
  disable: boolean,
  children: ?React$Node,
  defaultDate?: string,
};

type State = {
  isVisible: boolean,
};

export default class ModalPicker extends Component<Props, State> {
  static defaultProps = {
    onChange: () => {},
    onOpen: () => {},
    value: undefined,
    defaultText: 'Please select',
    disable: false,
    children: null,
    defaultDate: undefined,
  };

  state = {
    isVisible: false,
  };

  onChange = (date: Date) => {
    console.log('CHG', date);
    this.props.onChange(date.toDate());
    this.close();
  };

  close = () => {
    this.setState({
      isVisible: false,
    });
  };

  open = () => {
    this.setState({
      isVisible: true,
    });
    this.props.onOpen();
  };

  isVisible = () => {
    return this.state.isVisible;
  };

  renderChildren = () => {
    if (this.props.children) {
      return this.props.children;
    }

    const label = this.props.value ? i18n.l('date.formats.defaultFormat', this.props.value) : this.props.defaultText;

    return (
      <View style={styles.selectStyle}>
        <Text style={[this.props.value ? styles.selectedTextStyle : styles.selectTextStyle, styles.flex]}>{label}</Text>
        <Image source={iconCalendar} style={styles.carret} />
      </View>
    );
  };

  render() {
    const { onChange, value, defaultText, disable, children, defaultDate, label, ...pickerProps } = this.props;

    const Btn = disable ? View : TouchableOpacity;

    const date = value || (defaultDate ? new Date(defaultDate) : undefined);

    return (
      <>
        {this.state.isVisible && (
          <View style={{ position: 'absolute', zIndex: 100000 }}>
            <Calendar
              onSelect={this.blur}
              onChange={this.onChange}
              showDateInput={false}
              defaultValue={moment(date)}
              locale={englishLocale}
            />
          </View>
        )}
        {label && <Text style={styles.label}>{label.toUpperCase()}</Text>}
        <View style={styles.input}>
          <Btn style={styles.flex} onPress={this.open}>
            {this.renderChildren()}
          </Btn>

          {/* <DateTimePicker
            {...pickerProps}
            date={date}
            datePickerModeAndroid="spinner"
            isVisible={this.state.isVisible}
            onConfirm={this.onChange}
            onCancel={this.close}
            cancelTextIOS={i18n.t('picker.cancel')}
            confirmTextIOS={i18n.t('picker.confirm')}
            titleIOS={i18n.t('picker.pick_a_date')}
         />*/}
        </View>
      </>
    );
  }
}
