// @flow
import React, { useCallback } from 'react';

import { Touchable, Image } from './styles';

export type CheckImageProps = {
  checked: boolean,
  iconChecked: any, // asset (number) or { uri: PATH }
  iconUnchecked: any,  // asset (number) or { uri: PATH }
  onCheckChange: boolean => void,
};

const CheckImage = ({ checked, iconChecked, iconUnchecked, onCheckChange, ...restProps }: CheckImageProps) => {
  const handlePress = useCallback(() => onCheckChange(!checked), [checked]);
  return (
    <Touchable onPress={handlePress}>
      <Image
        source={checked ? iconChecked : iconUnchecked}
        {...restProps}
      />
    </Touchable>
  );
};

export default CheckImage;
