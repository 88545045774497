// @flow
/* eslint-disable global-require */
export const golfUse = require('./golf_use.png');
export const voucherIcon = require('./voucher_icon.png');
export const calendar = require('./calendar.png');
export const arrow = require('./arrow.png');
export const closeX = require('./close_x.png');
export const categoryDrivingRange = require('./categories/driving_range.png');
export const categoryVoucher = require('./categories/voucher.png');
export const categoryBundle = require('./categories/bundle.png');
export const bgCategoryDrivingRange = require('./categories/bg_driving_range.png');
export const bgCategoryVoucher = require('./categories/bg_voucher.png');
export const bgCategoryBundle = require('./categories/bg_bundle.png');
export const check = require('./check.png');
export const cart = require('./cart.png');
export const arrowBack = require('./icon-back.png');
export const trash = require('./trash.png');
export const email = require('./email.png');
export const star = require('./star.png');
export const arrowNext = require('./arrow_next.png');
export const frownEmoji = require('./frown_emoji.png');
