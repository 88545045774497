import { takeEvery, put, select, call, putResolve } from 'redux-saga/effects';
import { LOGIN_SUCCESS } from '../../react-native/auth/modules/session';
import appActions from '../../domains/app/appActions';
const { togglePopup } = appActions;

function* closeLoginModal() {
  yield put(togglePopup('logInPopupIsOpen', false));
}

export default function* () {
  yield takeEvery(LOGIN_SUCCESS, closeLoginModal);
}
