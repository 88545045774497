import React from 'react';
import { connect } from 'react-redux';

import TermsDenmark from './termsContent/termsDenmark';
import TermsNorway from './termsContent/termsNorway';
import TermsEnglish from './termsContent/termsEnglish';
import TermsSweden from './termsContent/termsSweden';
import TermsGerman from './termsContent/termsGerman';

import './terms.css';

class Terms extends React.Component {
  render() {
    return (
      <div className="b-terms g-auth-content">
        <div className="b-terms__container g-container">
          {this.props.location === 'danish' && TermsDenmark()}
          {this.props.location === 'english' && TermsEnglish()}
          {this.props.location === 'norwegian' && TermsNorway()}
          {this.props.location === 'swedish' && TermsSweden()}
          {this.props.location === 'german' && TermsGerman()}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    location: state.appReducer.get('location'),
  };
}

export default connect(mapStateToProps)(Terms);
