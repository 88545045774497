// @flow
import styled from 'styled-components/native';
import styles from '../../../../../constants/styles';

const { colors, font } = styles;

export const Root = styled.TouchableOpacity`
  border-radius: 7px;
  border-width: 1px;
  border-color: ${(props) => props.selected ? colors.greenLight : colors.white };
  background-color: ${(props) => props.selected ? colors.bgGreen : colors.white};
  margin-vertical: 5.5px;
  padding-vertical: 14px;
  padding-horizontal: 20px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  elevation: 4;
  shadowColor: #0000000A;
  shadowOffset: {
    width: 0,
    height: 2,
  };
  shadowRadius: 5px;
`;

export const Title = styled.Text`
  font-size: ${font.size.normal}px;
  color: ${colors.textBlack};
  ${font.style.regular}
`;

export const CheckIcon = styled.Image`
  width: 21px;
  height: 24px;
`;
