// @flow
import type { User } from '../api/user';
import api from '../../api';
import { API_REQUEST } from '../../store/apiAction';

const UPDATE_USER: 'update/UPDATE_USER' = 'update/UPDATE_USER';
export const UPDATE_USER_SUCCESS: 'update/UPDATE_USER_SUCCESS' = 'update/UPDATE_USER_SUCCESS';
const UPDATE_USER_FAILED: 'update/UPDATE_USER_FAILED' = 'update/UPDATE_USER_FAILED';

type UpdateUserSuccess = {|
  type: typeof UPDATE_USER_SUCCESS,
  result: {
    data: User,
  },
|};

type UpdateUserFailed = {|
  type: typeof UPDATE_USER_FAILED,
  error: {
    message: string,
  },
|};

export type UpdatingState = {
  error: ?string,
  loading: boolean,
};

type State = {
  update: UpdatingState,
};

export const initUpdatingState: State = {
  update: {
    error: null,
    loading: false,
  },
};

export default {
  [UPDATE_USER](state: State) {
    return {
      ...state,
      update: {
        ...initUpdatingState.update,
        loading: true,
      },
    };
  },
  [UPDATE_USER_SUCCESS](state: State, action: UpdateUserSuccess) {
    return {
      ...state,
      update: {
        ...initUpdatingState.update,
      },
      user: {
        data: action.result.data,
        errors: null,
        loading: false,
      },
    };
  },
  [UPDATE_USER_FAILED](state: State, action: UpdateUserFailed) {
    return {
      ...state,
      update: {
        ...initUpdatingState.update,
        error: action.error.message,
      },
    };
  },
};

export function updateUser(data: $Shape<User>) {
  return {
    type: API_REQUEST,
    types: [UPDATE_USER, UPDATE_USER_SUCCESS, UPDATE_USER_FAILED],
    promisified: true,
    call: () => api().user.update(data),
  };
}
