// @flow
import { put, take, call, takeEvery, fork, cancel } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import haversine from 'haversine';
import { updateLocation } from '../modules/geolocation';
import checkPermissions from './permissions';
import { LOGIN_AUTO_SUCCESS, LOGIN_SUCCESS, LOGOUT_SUCCESS } from '../../auth/modules/session';
import { loadNearbyClubs, setWaitingForNearbyFalse } from '../../clubs/modules/myclubs';

const geolocation = navigator.geolocation;

const NEARBY_CLUB_UPDATE_DISTANCE = 500; // Meters

function iniLocationChannel() {
  let geoWatch;
  let n = 0;
  let prevLocation = { latitude: 0, longitude: 0 };
  return eventChannel((emitter) => {
    const reportPosition = (position) => {
      try {
        const { latitude, longitude } = position.coords;

        emitter(updateLocation(latitude, longitude));
        const haversineOptions = { threshold: NEARBY_CLUB_UPDATE_DISTANCE, unit: 'meter' };
        if (!prevLocation || !haversine(prevLocation, position.coords, haversineOptions)) {
          prevLocation = position.coords;
          emitter(loadNearbyClubs({ latitude, longitude }));
        }
        n++; // to overcome this crash on app reload, stop receiving location updates temporarily
      } catch (err) {
        emitter({
          type: 'geo/ERROR',
          payload: err,
        });
      }
    };
    checkPermissions()
      .then((result) => {
        if (result) {
          geolocation.getCurrentPosition(reportPosition);
          geoWatch = geolocation.watchPosition(reportPosition, null, {
            timeout: 10000,
            maximumAge: 10000,
            enableHighAccuracy: true,
            distanceFilter: 20,
          });
        } else {
          emitter(setWaitingForNearbyFalse());
        }
      })
      .catch(() => {
        emitter({
          type: 'geo/ERROR',
          payload: 'Permission error',
        });
        emitter(setWaitingForNearbyFalse());
      });

    // unsubscribe function
    return () => {
      geolocation.clearWatch(geoWatch);
    };
  });
}

const getPosition = () => {
  return new Promise((resolve, reject) => {
    const reportPosition = (position) => {
      resolve(position.coords);
    };

    geolocation.getCurrentPosition(reportPosition);
  });
};

function* updatePosition() {
  try {
    const position = yield call(getPosition);
    if (!position || !position.latitude || !position.longitude) {
      return;
    }
    yield put(updateLocation(position.latitude, position.longitude));
    // yield put(loadNearbyClubs(position));
  } catch (err) {
    console.warn('Error in updateposition', err);
  }
}

export default function* geoSagas(): Generator<*, *, *> {
  yield takeEvery(LOGIN_AUTO_SUCCESS, updatePosition);
  yield takeEvery(LOGIN_SUCCESS, updatePosition);

  while (yield take(LOGIN_SUCCESS)) {
    const channel = yield call(iniLocationChannel);
    const watcher = yield fork(function* locationWatcherTask() {
      while (true) {
        const action = yield take(channel);
        yield put(action);
      }
    });

    yield take(LOGOUT_SUCCESS);

    channel.close();
    yield cancel(watcher);
  }
}
