import React, { Component } from 'react';
import propTypes from 'prop-types';
import texts from '../../../../constants/languages';

class AddUnionSuccess extends Component {

  closePopupOnClickOutSide = (event) => {
    if (!this.popup.contains(event.target)) {
      this.props.togglePopup('addUnionSuccessPopupIsOpen', false);
    }
  };

  render() {
    return (
      <div className="g-popup-wrap"
           onClick={(event) => this.closePopupOnClickOutSide(event)} // close popup
      >
        <div
          className="g-popup-overlay"
        >
          &nbsp;
        </div>
        <div className="g-popup-wrap">
          <table className="g-popup-wrap__container">
            <tbody>
            <tr>
              <td className="g-popup-wrap__container-inner">
                <div className="g-popup"
                     ref={(popup) => this.popup = popup}
                >
                  <div
                    className="g-popup__close-btn"
                    onClick={() => this.props.togglePopup('addUnionSuccessPopupIsOpen', false)} // close popup
                  >
                    &nbsp;
                  </div>
                  <div className="g-popup__content">
                    <form className="g-form" action="#">
                      <h2 className="g-form__title m-form__title_small">{texts.SUCCESS}!</h2>
                      <p className="g-form__paragraph">{texts.UNION_HAS_BEEN_CHANGED}.</p>
                      <div className="g-form__btn-holder m-form__btn-holder_confirm g-clear-fix">
                        <button
                          className="g-form__btn m-form__btn_confirm g-submit-btn"
                          onClick={() => this.props.togglePopup('addUnionSuccessPopupIsOpen', false)}
                        >{texts.GOT_IT}</button>
                      </div>
                    </form>
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default AddUnionSuccess;

AddUnionSuccess.propTypes = {
  togglePopup: propTypes.func.isRequired,
};
