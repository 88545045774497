// @flow

import type { ApiRequest } from '../../store/apiAction';
import type { ClubType, Department, CurrentClubType } from '../api/club';
import { API_REQUEST } from '../../store/apiAction';
import type { GlobalState } from '../../store/modules';
import api from '../../api';

const LOAD_MY_CLUBS: 'club/LOAD_MY_CLUBS' = 'club/LOAD_MY_CLUBS';
export const LOAD_MY_CLUBS_SUCCESS: 'club/LOAD_MY_CLUBS_SUCCESS' = 'club/LOAD_MY_CLUBS_SUCCESS';
export const LOAD_MY_CLUBS_FAILURE: 'club/LOAD_MY_CLUBS_FAILURE' = 'club/LOAD_MY_CLUBS_FAILURE';
export const SET_WAITING_FOR_NEARBY_FALSE: 'club/SET_WAITING_FOR_NEARBY_FALSE' =
  'club/SET_WAITING_FOR_NEARBY_FALSE';

const LOAD_NEARBY_CLUB: 'club/LOAD_NEARBY_CLUB' = 'club/LOAD_NEARBY_CLUB';
export const LOAD_NEARBY_CLUB_SUCCESS: 'club/LOAD_NEARBY_CLUB_SUCCESS' =
  'club/LOAD_NEARBY_CLUB_SUCCESS';
const LOAD_NEARBY_CLUB_FAILURE: 'club/LOAD_NEARBY_CLUB_FAILURE' = 'club/LOAD_NEARBY_CLUB_FAILURE';

export const SET_CURRENT_CLUB = 'clubs/SET_CURRENT_CLUB';
export const UPDATE_CURRENT_CLUB_DETAILS = 'club/UPDATE_CURRENT_CLUB_DETAILS';

const LOAD_CLUB_LOCATION_DETAILS = 'club/LOAD_CLUB_LOCATION_DETAILS';
const LOAD_CLUB_LOCATION_DETAILS_SUCCESS = 'club/LOAD_CLUB_LOCATION_DETAILS_SUCCESS';
const LOAD_CLUB_LOCATION_DETAILS_FAILURE = 'club/LOAD_CLUB_LOCATION_DETAILS_FAILURE';

type LoadMyClubs = {
  type: typeof LOAD_MY_CLUBS,
};
type LoadMyClubsSuccess = {
  type: typeof LOAD_MY_CLUBS_SUCCESS,
  result: {
    data: Array<ClubType>,
  },
};
type LoadMyClubsFailure = {
  type: typeof LOAD_MY_CLUBS_FAILURE,
  error: {
    message: string,
  },
};

type SetCurrentClub = {
  type: typeof SET_CURRENT_CLUB,
  currentClub: CurrentClubType,
};

type ClubActions = LoadMyClubs | LoadMyClubsSuccess | LoadMyClubsFailure | SetCurrentClub;

export type State = {
  data: ?Array<Department>,
  currentClub: ?CurrentClubType,
  dataById: { [string | number]: Department },
  loaded: boolean,
  error: boolean,
  waitingForNearbyClub: boolean,
};

const initialState: State = {
  data: null,
  currentClub: null,
  dataById: {},
  loaded: false,
  error: false,
  waitingForNearbyClub: true,
};

export default function myClubReducer(state: State = initialState, action: ClubActions): State {
  switch (action.type) {
    case LOAD_CLUB_LOCATION_DETAILS_SUCCESS: {
      // Sometimes vouchers and driving_range are not coming from back end.
      // We extract them manually to overwrite previous club
      const { vouchers, driving_range, has_products } = action.result.data;
      const { club_id } = action.result.config.params;
      if (club_id !== state.currentClub?.clubDetails?.club_id) {
        // Request was for another club, ignore.
        return state;
      }
      const currentClub = {
        ...state.currentClub,
        vouchers,
        driving_range,
        has_products,
      };
      return {
        ...state,
        currentClub,
      };
    }

    case LOAD_CLUB_LOCATION_DETAILS_FAILURE: {
      return state;
    }

    case LOAD_MY_CLUBS_SUCCESS: {
      return {
        ...state,
        data: action.result.data, // Replace with new ones
        dataById: action.result.data.reduce(
          (acc, val) => {
            acc[val.club_id] = val;
            return acc;
          },
          { ...state.dataById },
        ),
        loaded: true,
      };
    }

    case LOAD_NEARBY_CLUB_SUCCESS: {
      // Saga will take care of current club
      return {
        ...state,
        waitingForNearbyClub: false,
      };
    }

    case LOAD_NEARBY_CLUB_FAILURE: // Do the same in both cases
    case SET_WAITING_FOR_NEARBY_FALSE: {
      return !state.waitingForNearbyClub
        ? state
        : {
            // Don't trigger an update if not necessary
            ...state,
            waitingForNearbyClub: false,
          };
    }

    case SET_CURRENT_CLUB: {
      const { currentClub } = action;
      return {
        ...state,
        currentClub,
      };
    }

    default:
      return state;
  }
}

export function loadMyClubs(params): ApiRequest<Array<ClubType>> {
  return {
    type: API_REQUEST,
    types: [LOAD_MY_CLUBS, LOAD_MY_CLUBS_SUCCESS, LOAD_MY_CLUBS_FAILURE],
    promisified: true,
    call: () => api().club.getMyClubs(params),
  };
}

export function loadNearbyClubs(params): ApiRequest<Array<ClubType>> {
  return {
    type: API_REQUEST,
    types: [LOAD_NEARBY_CLUB, LOAD_NEARBY_CLUB_SUCCESS, LOAD_NEARBY_CLUB_FAILURE],
    call: () => api().club.getNearbyClubs(params),
  };
}

export const loadClubLocationDetails = (clubId: number) => (
  dispatch: Function,
  getState: () => GlobalState,
) => {
  const { location } = getState();
  if (!location?.hasLocation) {
    return;
  }
  const params = {
    club_id: clubId,
    latitude: location.lat,
    longitude: location.lng,
  };

  dispatch({
    type: API_REQUEST,
    types: [
      LOAD_CLUB_LOCATION_DETAILS,
      LOAD_CLUB_LOCATION_DETAILS_SUCCESS,
      LOAD_CLUB_LOCATION_DETAILS_FAILURE,
    ],
    call: () => api().club.getClubLocationDetails(params),
  });
};

export const updateCurrentClubDetails = () => ({ type: UPDATE_CURRENT_CLUB_DETAILS });
export const setCurrentClub = currentClub => ({ type: SET_CURRENT_CLUB, currentClub });

/**
 * There is a special requirement for nearby club.
 * When user opens the app, sometimes clubs are loaded faster, than nearby club.
 * And if there is no home club and other don't have vouchers, app redirects to shop.
 * We have to wait for nearby club and show it anyway (instead of redirecting to shop).
 * This flag is set to true by default.
 * When nearby club is loaded or user has denied permission for location, it's set to false.
 * @returns {{type: "club/SET_WAITING_FOR_NEARBY_FALSE"}}
 */
export const setWaitingForNearbyFalse = () => ({ type: SET_WAITING_FOR_NEARBY_FALSE });
