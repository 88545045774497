import { connect } from 'react-redux';

import appActions from '../../../domains/app/appActions';
import vouchersActions from '../module.js';

import VoucherDetails from './voucherDetails';

function mapStateToProps(state) {
  return {
    clubs: state.voucherReducer.get('clubs'),
    currentVoucher: state.voucherReducer.get('currentVoucher').toJS(),
    departments: state.club.departments.data,
  };
}

const mapDispatchToProps = (dispatch) => ({
  togglePopup: (popupName, status) => dispatch(appActions.togglePopup(popupName, status)),
  toggleFavourites: (value, voucherId) => dispatch(vouchersActions.toggleFavourites(value, voucherId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VoucherDetails);
