import axios from 'axios';

import host from '../../../constants/config/host';
import { unionByLocaleId } from '../../../react-native/constants/unions';

import base64Encode from 'base-64';
import { API_MOREBOX } from '../../../react-native/constants/config';

const updateLoggedUserInfo = (data, session_id) =>
  axios.patch(`${host}/user`, data, {
    headers: {
      Authorization: `Basic ${base64Encode.encode(`${session_id}:`)}`,
    },
  });

const getUserData = (sessionId) =>
  axios.get(`${host}/user`, {
    headers: {
      Authorization: `Basic ${base64Encode.encode(`${sessionId}:`)}`,
    },
  });

const getUnions = () => axios.get(`${host}/unions`);

const checkUnionMember = (unionId, memberNumber, birthDate, locale) =>
  axios.get(`${host}/user/member/${unionId}/${memberNumber}/${birthDate}`, {
    params: { locale },
  });

const getSessionData = (sessionId) =>
  axios.get(`${host}/session`, {
    headers: {
      Authorization: `Basic ${base64Encode.encode(`${sessionId}:`)}`,
    },
  });

const getCountries = (locale) =>
  axios.get(`${host}/countries`, {
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Accept-language': locale,
    },
  });

const logIn = (data, language) =>
  axios({
    url: `${host}/session`,
    method: 'post',
    data: {
      ...data,
      language,
    },
  });

const recoverPassword = (data) => axios.post(`${host}/user/recover-password`, data);

const changePassword = (data) => axios.post(`${host}/user/change-password`, data);

const registerNewUser = (data, locale) =>
  axios.post(`${host}/user`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      locale,
    },
  });

const changeClub = (unionID, memberNumber, birthDate) =>
  axios.patch(`${host}/user/club`, {
    union_id: unionID,
    member_number: memberNumber,
    birth_date: birthDate,
  });

const sendEmailToVerify = (token) =>
  axios.post(`${host}/user/email-verify`, {
    code: token,
  });

function getIsoCode(country) {
  const union = unionByLocaleId[country.toLowerCase()];
  console.log('Union', country);
  if (union) {
    return union.isoCode;
  }
  return country;
}

export function redirectGolfBox(country) {
  window.location.href = `${API_MOREBOX}/login/oauth2/do-signin?countryCode=${getIsoCode(
    country,
  )}&callback=${encodeURIComponent(window.location.origin + '/register/oauth-consumer')}&consumer=WEB`;
}

export default {
  updateLoggedUserInfo,
  getUnions,
  checkUnionMember,
  registerNewUser,
  getUserData,
  getSessionData,
  getCountries,
  logIn,
  recoverPassword,
  changePassword,
  changeClub,
  sendEmailToVerify,
};
