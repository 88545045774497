import _ from 'lodash';
import { fromJS } from 'immutable';
import constants from './voucherConstants';
import appConstants from '../app/appConstants';
import vouchersAPI from './api/vouchers';

export const setVouchers = (data) => ({
  type: constants.SET_VOUCHERS,
  data,
});

export const setAllVouchers = (vouchers) => ({
  type: constants.SET_ALL_VOUCHERS,
  vouchers,
});

export const setFavouriteVouchers = (vouchers) => ({
  type: constants.SET_FAVOURITE_VOUCHERS,
  vouchers,
});

export const setPrintedVouchers = (vouchers) => ({
  type: constants.SET_PRINTED_VOUCHERS,
  vouchers,
});

export const getFavouriteVouchers = () => (dispatch) =>
  vouchersAPI
    .getFavouriteVouchers()
    .then((res) => {
      dispatch(setFavouriteVouchers(res.data));
    })
    .catch(() => {
      dispatch(setFavouriteVouchers([]));
    });

export const sortVouchers = () => ({
  type: constants.SORT_VOUCHERS_VIEW,
});

export const setCurrentVoucher = (currentVoucher) => ({
  type: constants.SET_CURRENT_VOUCHER,
  currentVoucher,
});

export const toggleTabFilter = (value) => ({
  type: constants.TOGGLE_TAB_FILTER,
  value,
});

export const setClubs = (clubs) => ({
  type: constants.SET_CLUBS,
  clubs,
});

export const getClubs = () => (dispatch) => {
  vouchersAPI
    .getClubs()
    .then((response) => {
      dispatch(setClubs(response));
    })
    .catch(() => {});
};

export const getAllVouchers = () => (dispatch) => {
  vouchersAPI
    .getAllVouchers()
    .then((response) => {
      dispatch(setAllVouchers(response.data));
    })
    .catch(() => {
      dispatch(setAllVouchers([]));
    });
};

export const getPrintedVouchers = () => (dispatch) => {
  vouchersAPI
    .getPrintedVouchers()
    .then((response) => {
      dispatch(setPrintedVouchers(response.data));
    })
    .catch(() => {
      dispatch(setPrintedVouchers([]));
    });
};

export const getClubsInfo = (vouchers) => () => {
  let clubsIDs = [];

  vouchers.map((element) => clubsIDs.push(...element.where));

  clubsIDs = clubsIDs.filter((item, pos) => clubsIDs.indexOf(item) === pos);
  const requestParams = clubsIDs.reduce(
    (previousValue, currentValue) => `${previousValue}club_id[]=${currentValue}&`,
    '',
  );
  vouchersAPI
    .getClubs(requestParams.substring(0, requestParams.length - 1))
    .then((response) => {
      const clubInfo = {};
      response.data.forEach((element) => {
        clubInfo[element.club_id] = {
          latitude: element.latitude,
          longitude: element.longitude,
        };
      });
      localStorage.setItem('clubInfo', JSON.stringify(clubInfo));
    })
    .catch(() => {});
};

export const setVouchersCount = (count) => ({
  type: constants.SET_VOUCHERS_COUNT,
  payload: count,
});

export const getVouchersCount = (searchText) => (dispatch) =>
  vouchersAPI
    .getVouchersCount(searchText)
    .then((data) => dispatch(setVouchersCount(data.data.count)))
    .catch(() => dispatch(setVouchersCount(0)));

export const getVouchers = (offset, searchText) => (dispatch) => {
  vouchersAPI
    .getPrintedVouchers()
    .then((response) => {
      dispatch(sortAlphabet(response.data));
      dispatch(getClubsInfo(response.data));
      dispatch(getVouchersCount(searchText));
      return response.data;
    })
    .catch(() => []);
};

export const changeSearchText = (text) => (dispatch) => {
  dispatch(getVouchers(0, text));
  dispatch({
    type: constants.CHANGE_SEARCH_TEXT,
    payload: text,
  });
};

export const toggleFavourites = (value, voucherId) => (dispatch) => {
  vouchersAPI
    .toggleFavourites(value, voucherId)
    .then(() => {
      dispatch({
        type: constants.STAR_VOUCHER,
        payload: voucherId,
        value,
      });
    })
    .catch(() => {});
  return {
    type: constants.STAR_VOUCHER,
  };
};

export const sortNearest = (coords, vouchers) => (dispatch) => {
  const clubsInfo = JSON.parse(localStorage.getItem('clubInfo'));
  const calculateDistance = (dotOne, dotTwo) =>
    Math.sqrt((+dotOne.longitude - dotTwo.longitude) ** 2 + (+dotOne.latitude - dotTwo.latitude) ** 2);

  const calculateCloserClub = (array) => {
    const a = array.sort((x, y) => {
      if (clubsInfo[x]) {
        return -1;
      } else if (clubsInfo[y]) {
        return 1;
      }
      return calculateDistance(clubsInfo[x], coords) - calculateDistance(clubsInfo[y], coords);
    });
    return a[0];
  };

  const newOrder = vouchers.sort(
    (a, b) =>
      calculateDistance(clubsInfo[calculateCloserClub(a.where)], coords) -
      calculateDistance(clubsInfo[calculateCloserClub(b.where)], coords),
  );
  dispatch(setVouchers(newOrder));
};

export const sortAlphabet = (vouchers) => {
  const newOrder = _.sortBy(vouchers, ['owner_name', 'name']);

  return setVouchers(newOrder);
};

export const sortByValidTime = (vouchers) => ({
  type: constants.SET_VOUCHERS,
  data: vouchers.sort((a, b) => a.valid_to - b.valid_to),
});

export const onDonePress = (selectedOption, vouchers) => (dispatch) => {
  const texts = require('../../constants/languages').default;
  switch (selectedOption) {
    case texts.SEARCH_NEAREST:
      return navigator.geolocation.getCurrentPosition(
        (response) => dispatch(sortNearest(response.coords, vouchers)),
        () => [],
      );
    case texts.SEARCH_CLUB_A_Z:
      return dispatch(sortAlphabet(vouchers));
    case texts.SEARCH_VALID_UNTIL:
      return dispatch(sortByValidTime(vouchers));
    default:
      return dispatch(sortAlphabet(vouchers));
  }
};

export const printPDF = (voucherID) => () =>
  vouchersAPI
    .generatePDF(voucherID)
    .then((response) => {
      window.open(response.data.pdf, '_self');
      return response.data;
    })
    .catch(() => false);

export const uploadVoucher = (voucherID) => () => {
  vouchersAPI
    .uploadVoucher(voucherID)
    .then(() => {})
    .catch(() => {});
};

export const getAccountEmail = (token, locale) => (dispatch) => {
  vouchersAPI
    .getTransferProcess(token, locale)
    .then((response) => {
      dispatch({
        type: constants.GET_ACCOUNT_EMAIL,
        data: response.data.target_email,
      });
    })
    .catch(() => {});
};

export const sendTransferProcess = (token, locale, data, setServerErrors, openPopup) => () => {
  vouchersAPI
    .sendTransferProcess(token, locale, data)
    .then(() => {
      openPopup('voucherTransferPopupIsOpen', true);
      return true;
    })
    .catch((error) => {
      setServerErrors(error.response.data);
      return false;
    });
};

export const setSpinnerFlag = (flag) => ({
  type: constants.SET_SPINNER_FLAG,
  flag,
});

export const setSortOption = (option) => ({
  type: constants.SET_SORT_OPTION,
  option,
});

const initialState = fromJS({
  allVouchers: [],
  favouriteVouchers: [],
  printedVouchers: [],
  vouchers: [],
  clubs: [],
  historyVouchers: [],
  currentVoucher: {},
  // currentTab: 'printed',
  searchInput: '',
  // sortView: false,
  sortType: '',
  // fetchingVouchers: false,
  voucherWasUploadedSuccessfully: false,
  voucherDetailsPopupIsOpen: false,
  voucherTransferPopupIsOpen: false,
  voucherPDFPopUpIsOpen: false,
  voucherPrintNotification: false,
  vouchersCount: 0,
  accountEmail: '',
  showSpinner: false,
  vouchersSortOption: 'Club A-Z',
});

export default function voucherReducer(state = initialState, action) {
  switch (action.type) {
    case appConstants.TOGGLE_POPUP:
      return state.set(action.popup.popupName, action.popup.status);
    case constants.SET_VOUCHERS:
      return state.set('vouchers', fromJS(action.data));
    case constants.SET_ALL_VOUCHERS:
      return state.set('allVouchers', fromJS(action.vouchers));
    case constants.SET_FAVOURITE_VOUCHERS:
      return state.set('favouriteVouchers', fromJS(action.vouchers));
    case constants.SET_PRINTED_VOUCHERS:
      return state.set('printedVouchers', fromJS(action.vouchers));
    case constants.SET_VOUCHERS_COUNT: {
      return state.set('vouchersCount', action.payload);
    }
    case constants.CHANGE_SEARCH_TEXT: {
      return state.set('searchInput', action.payload);
    }
    case constants.TOGGLE_TAB_FILTER: {
      return state.set('currentTab', action.value);
    }
    case constants.CURRENT_TAB_FILTER: {
      return state.set('currentTab', action.value);
    }
    case constants.TOGGLE_UPLOAD_SWITCHER: {
      return state.set('voucherWasUploadedSuccessfully', action.payload);
    }
    case constants.SET_CURRENT_VOUCHER: {
      return state.set('currentVoucher', fromJS(action.currentVoucher));
    }
    case constants.SET_SORT_OPTION:
      return state.set('vouchersSortOption', action.option);
    case constants.STAR_VOUCHER: {
      const newVouchers = state
        .get('vouchers')
        .toJS()
        .map((element) => {
          if (element.voucher_id === action.payload) {
            element.favourite = action.value;
            return element;
          }
          return element;
        });

      return state.set('vouchers', fromJS(newVouchers)).setIn(['currentVoucher', 'favourite'], action.value);
    }
    case constants.SET_CLUBS: {
      return state.set('clubs', action.clubs);
    }
    case constants.GET_ACCOUNT_EMAIL: {
      return state.set('accountEmail', action.data);
    }
    case constants.SET_SPINNER_FLAG:
      return state.set('showSpinner', action.flag);
    default:
      return state;
  }
}
