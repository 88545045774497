// @flow
import styled from 'styled-components/native';
import styles from '../../../constants/styles';

const { colors, font } = styles;

export const Root = styled.View`
  flex: 1;
  padding-vertical: 57px;
  padding-horizontal: 20px;
  background-color: 'rgba(27, 29, 32, 0.64)';
`;

export const InnerContainer = styled.View`
  justify-content: center;
  flex: 1;
`;

export const Content = styled.ScrollView`
  border-radius: 28px;
  background-color: ${colors.white};
  padding-top: 35px;
  padding-horizontal: 30px;
  padding-bottom: 20px;
`;

export const Title = styled.Text`
  font-size: ${font.size.medium}px;
  color: ${colors.basic};
  margin-bottom: 15px;
  ${font.style.medium}
`;

export const ButtonText = styled.Text`
  color: ${colors.greenLight};
  font-size: ${font.size.normal}px;
  text-transform: uppercase;
  ${font.style.semibold};
`;

export const Buttons = styled.View`
  margin-vertical: 40px;
  align-items: flex-end;
`;

export const Button = styled.TouchableOpacity`
  margin-vertical: 10px;
`;

export const PopupText = styled.Text`
  ${font.style.regular}
  font-size: ${font.size.small}px;
  margin-top: ${(props) => props.mt || 0}px;
`;

export const BlueText = styled.Text`
  color: ${colors.golfBox};
  ${font.style.bold};
`;

export const GreenText = styled.Text`
  color: ${colors.greenLight};
  ${font.style.bold}
`;

export const GolfId = styled.Text`
  color: ${colors.greenLight};
  font-size: ${font.size.small}px;
  ${font.style.bold}
`;
