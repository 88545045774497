import _ from 'lodash';

const countVouchers = (vouchers, printed, groupBy) =>
  _.chain(vouchers)
    .filter(item => !!item.pdf === printed)
    .countBy(groupBy)
    .value();

const getPrintedVouchers = (vouchers, printed) =>
  _.chain(vouchers)
    .filter(item => !!item.pdf === printed)
    .map(item => ({ ...item, count: 1 }))
    .tap(console.log)
    .value();

const notPrintedCounts = (vouchers, groupBy) => countVouchers(vouchers, false, groupBy);

const collectCountedVouchers = (vouchers, countedVouchers) =>
  _.chain(vouchers, countedVouchers)
    .filter(item => _.includes(_.keys(countedVouchers), item.order_id))
    .map(voucher => Object.assign({}, voucher, { count: countedVouchers[voucher.order_id] }))
    .groupBy('order_id')
    .toPairs()
    .map(item => _.find(item[1], (voucher) => !voucher.pdf))
    .value();

// pass through printed vouchers without counting
export const printedVouchersToDisplay = vouchers => getPrintedVouchers(vouchers, true);

export const notPrintedVouchersToDisplay = vouchers =>
  collectCountedVouchers(vouchers, notPrintedCounts(vouchers, 'order_id')); // order_id will group similar vouchers by id
