// @flow
import { PixelRatio, Platform, Dimensions } from 'react-native';

const isIphoneX = false;

export function normalize(size: number): number {
  if (PixelRatio.get() <= 2) {
    return Math.round(PixelRatio.roundToNearestPixel(size)) - 3;
  }

  return Math.round(PixelRatio.roundToNearestPixel(size));
}

export function appFontSize(sizeOfFont) {
  return Platform.select({
    ios: {
      fontSize: sizeOfFont,
    },
    android: {
      fontSize: sizeOfFont - 2,
    },
  });
}

const statusBartHeightIOS = isIphoneX ? 40 : 20;

const mainBlack = '#35393F';

const styles = {
  colors: {
    white: '#fff',
    black: '#000',
    basic: mainBlack,
    maingBg: '#eef0f5',
    bgItemPrimary: '#e6eaed',
    bgItemSecondary: '#acafb2',
    bgPrimary: '#eef0f5',
    bgSecondary: '#f6f7f9',
    bgDark: '#2d3034',
    bgGreen: '#f6faf2',
    bgEven: '#f2f4f6', // For even rows
    basic30: '#4f627a4c',
    button: '#4f627a',
    green: '#759a58',
    greenLight: '#6b8c50',
    greenText: '#9DC27E',
    greenDark: '#658c55',
    pastel_green: '#4ce67d',
    slate_gray: '#7a8899',
    gull_gray: '#9fa9b7',
    athens_gray: '#f5f7f9',
    geyser: '#dfe3e7',
    darkGreyBg: '#35393F',
    grey: '#9fa9b7',
    red: '#b7170b',
    bubbleRed: '#f11944',
    errorRed: '#FF5C65',
    regent_grey: '#8e98a8',
    bg_color: '#f5f7f9',
    background_light: 'rgba(79, 98, 122, 0.4)',
    background_dark: 'rgba(79, 98, 122, 0.9)',
    orange: '#ffca52',
    loadingImage: '#273949',
    tabBarGrey: '#47576d',
    textBlack: '#35393f',
    stepDisabled: '#cfd4db',
    golfBox: '#0093be',
    starUnselected: '#d2d8df',
  },
  font: {
    size: {
      xxSmall: 9,
      xSmall: 11,
      small: 14,
      normal: 16,
      medium: 18,
      large: 20,
      xLarge: 24,
      xxLarge: 28,
    },
    style: {
      semibold: {
        fontFamily: 'Poppins-Semibold',
      },
      bold: {
        fontFamily: 'Poppins-Bold',
      },
      regular: {
        fontFamily: 'Poppins-Regular',
      },
      italic: {
        // TODO add italic
        fontFamily: 'Poppins-Regular',
      },
      light: {
        fontFamily: 'Poppins-Light',
      },
      medium: {
        fontFamily: 'Poppins-Medium',
      },
      mediumItalic: {
        // TODO add italic
        fontFamily: 'Poppins-Medium',
      },
      semiboldItalic: {
        // TODO add italic
        fontFamily: 'Poppins-Semibold',
      },
    },
  },
  size: {
    borderRadius: 10,
    statusbarHeight: Platform.OS === 'ios' ? statusBartHeightIOS : 0,
    appbarHeight: Platform.OS === 'ios' ? 44 : 56,
    screenWidth: Dimensions.get('window').width,
    screenHeight: Dimensions.get('window').height,
  },
};

styles.iosBlackShadow = {
  shadowColor: styles.colors.darkGray,
  shadowOpacity: 0.15,
  shadowRadius: 2,
};

export default styles;
