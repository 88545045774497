import styled from 'styled-components/native';

export const root = {
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'stretch',
  boxShadow: '-8px 8px 8px -8px #aaa',
}

export const StepsContainer = styled.View`
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  max-width: 500px;
  ${({ isNarrow }) => `align-self: ${isNarrow ? 'center' : 'flex-start'};`}
`;
