// @flow
import api from '../../api';
import type { User } from '../api/user';
import { API_REQUEST } from '../../store/apiAction';

const SET_REGISTERING_DATA: 'user/SET_REGISTERING_DATA' = 'user/SET_REGISTERING_DATA';
const ABORT_REGISTRATION: 'user/ABORT_REGISTRATION' = 'user/ABORT_REGISTRATION';

const TRANSFER_REFERRAL: 'user/TRANSFER_REFERRAL' = 'user/TRANSFER_REFERRAL';
const TRANSFER_REFERRAL_SUCCESS: 'user/TRANSFER_REFERRAL_FAILURE' = 'user/TRANSFER_REFERRAL_FAILURE';
const TRANSFER_REFERRAL_FAILURE: 'user/TRANSFER_REFERRAL_FAILURE' = 'user/TRANSFER_REFERRAL_FAILURE';

const REGISTER_USER: 'user/REGISTER_USER' = 'user/REGISTER_USER';
export const REGISTER_USER_SUCCESS: 'user/REGISTER_USER_SUCCESS' = 'user/REGISTER_USER_SUCCESS';
const REGISTER_USER_FAILED: 'user/REGISTER_USER_FAILED' = 'user/REGISTER_USER_FAILED';

type RegisteringData = $Shape<User>;

type RegistrationError = {|
  message: string,
  field: string,
|};

type AbortRegistration = {|
  type: typeof ABORT_REGISTRATION,
|};

type SetRegisteringData = {|
  type: typeof SET_REGISTERING_DATA,
  data: $Shape<RegisteringData>,
|};

type RegisterUser = {|
  type: typeof REGISTER_USER,
  data: $Shape<RegisteringData>,
|};

type RegisterUserFailed = {|
  type: typeof REGISTER_USER_FAILED,
  error: {
    response: {
      data: ?Array<RegistrationError>,
    },
  },
|};

export type RegisteringState = {
  data: $Shape<RegisteringData>,
  errors: ?string,
  loading: boolean,
};

type State = {
  registering: RegisteringState,
};

export const initRegisteringState = {
  registering: {
    data: {},
    errors: null,
    loading: false,
  },
};

export default {
  [ABORT_REGISTRATION](state: State): State {
    return {
      ...state,
      registering: {
        data: {},
        errors: null,
        loading: false,
      },
    };
  },
  [SET_REGISTERING_DATA](state: State, action: SetRegisteringData): State {
    return {
      ...state,
      registering: {
        ...state.registering,
        data: {
          ...state.registering.data,
          ...action.data,
        },
      },
    };
  },
  [REGISTER_USER](state: State, action: RegisterUser) {
    return {
      ...state,
      registering: {
        ...initRegisteringState.registering,
        data: {
          ...state.registering.data,
          ...action.data,
        },
        loading: true,
      },
    };
  },
  [REGISTER_USER_SUCCESS](state: State) {
    return {
      ...state,
      registering: {
        ...initRegisteringState.registering,
        data: state.registering.data,
      },
    };
  },
  [REGISTER_USER_FAILED](state: State, action: RegisterUserFailed) {
    return {
      ...state,
      registering: {
        ...state.registering,
        errors: action.error.response.data,
        loading: false,
      },
    };
  },
};

export function setRegisteringData(data: $Shape<RegisteringData>): SetRegisteringData {
  return {
    type: SET_REGISTERING_DATA,
    data,
  };
}

export function registerUser(data: RegisteringData) {
  return {
    type: API_REQUEST,
    types: [REGISTER_USER, REGISTER_USER_SUCCESS, REGISTER_USER_FAILED],
    call: () => api().user.register(data),
    promisified: true,
    data,
  };
}

export function transferReferral(code: string) {
  return {
    type: API_REQUEST,
    types: [TRANSFER_REFERRAL, TRANSFER_REFERRAL_SUCCESS, TRANSFER_REFERRAL_FAILURE],
    call: () => api().user.transferReferral(code),
    promisified: true,
    code,
  };
}

export function abortRegistration() {
  return {
    type: ABORT_REGISTRATION,
  };
}
