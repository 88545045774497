import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import EmailVerifySuccess from '../emailVerifySuccess/emailVerifySuccess';
import EmailVerifyError from '../emailVerifyError/emailVerifyError';
import authAPI from '../../../../domains/auth/api/auth';
import { handleLogIn } from '../../../../domains/auth/actions';

const getTokenFromUrl = (url: string) => {
  const urlArray = url.split('?key=');
  return urlArray[1];
};

// this component need only for redirect
const SignUpEmailVerify = ({ history, handleLogIn }) => {
  const [emailVerifyErrorPopupIsOpen, setEmailVerifyErrorPopupIsOpen] = useState(false);
  const [emailVerifySuccessPopupIsOpen, setEmailVerifySuccessPopupIsOpen] = useState(false);

  const checkOnVerifyPage = () => {
    const token = getTokenFromUrl(window.location.href);
    if (token) {
      authAPI
        .sendEmailToVerify(token)
        .then(() => {
          handleLogIn(false);
          setEmailVerifySuccessPopupIsOpen(true);
        })
        .catch((err) => {
          alert('Invalid code' + err);
          history.push('/');
        });
    }
  };
  useEffect(() => {
    checkOnVerifyPage();
  }, []);

  return (
    <div style={{ height: '100%' }}>
      {emailVerifySuccessPopupIsOpen && <EmailVerifySuccess />}
      {emailVerifyErrorPopupIsOpen && <EmailVerifyError />}
    </div>
  );
};

export default connect(null, { handleLogIn })(SignUpEmailVerify);
