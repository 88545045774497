// @flow
import { StyleSheet, Dimensions } from 'react-native';
import styles, { appFontSize } from '../../../constants/styles';

const { colors, font, size } = styles;
const { width } = Dimensions.get('window');

export default StyleSheet.create({
  mainView: {
    width: '100%',
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
  voucherView: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 140,
    width: '100%',
    backgroundColor: colors.white,
    borderRadius: size.borderRadius,
    elevation: 16,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.15,
    shadowRadius: 4.65,
  },
  marginWrapper: {
    marginLeft: 20,
  },
  image: {
    width: 100,
    height: 100,
    borderRadius: 50,
  },
  statusWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.white,
    borderColor: '#bbb',
    borderWidth: 0.5,
    borderRadius: 5,
    width: '90%',
    position: 'absolute',
    bottom: 8,
    marginHorizontal: 5,
  },
  voucherStatus: {
    ...font.style.regular,
    fontSize: 12,
    textAlign: 'center',
    color: colors.basic,
  },
  voucherTitle: {
    ...font.style.medium,
    color: colors.basic,
    ...appFontSize(width === 320 ? 13 : 16),
  },
  ticketDesk: {
    ...font.style.regular,
    color: colors.green,
    ...appFontSize(width === 320 ? 11 : 14),
  },
  validationTime: {
    ...font.style.regular,
    ...appFontSize(width === 320 ? 12 : 12),
    color: colors.gull_gray,
    marginTop: 2,
  },
  textContainer: {
    flexDirection: 'column',
    justifyContent: 'space-around',
    padding: 5,
    marginLeft: 10,
    flexGrow: 1,
  },
  price: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    padding: 2,
    width: 60,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.green,
    borderBottomRightRadius: 8,
    borderTopLeftRadius: 8,
  },
  transferPrice: {
    backgroundColor: colors.green,
  },
  priceText: {
    color: colors.white,
    ...font.style.regular,
    fontSize: 11,
  },
  rightBlockWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  validTimes: {
    position: 'absolute',
    top: 6,
    left: 14,
    backgroundColor: '#F11944',
    borderRadius: 40,
    width: 24,
    height: 24,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  validTimesText: {
    ...font.style.bold,
    fontSize: 11,
    color: colors.white,
    textAlign: 'center',
  },
});
