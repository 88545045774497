// @flow
import uniqBy from 'lodash/uniqBy';
import api from '../../api';
import type { ClubType } from '../api/club';
import { API_REQUEST } from '../../store/apiAction';

const LOAD_CLUBS: 'club/LOAD_CLUBS' = 'club/LOAD_CLUBS';
const LOAD_CLUBS_SUCCESS: 'club/LOAD_CLUBS_SUCCESS' = 'club/LOAD_CLUBS_SUCCESS';
const LOAD_CLUBS_FAILURE: 'club/LOAD_CLUBS_FAILURE' = 'club/LOAD_CLUBS_FAILURE';

const initialState = {
  data: [],
  dataById: {},
  loaded: false,
  error: null,
};

type LoadClubs = {
  type: typeof LOAD_CLUBS,
};
type LoadClubsSuccess = {
  type: typeof LOAD_CLUBS_SUCCESS,
  result: {
    data: Array<ClubType>,
  },
};
type LoadClubsFailure = {
  type: typeof LOAD_CLUBS_FAILURE,
  error: {
    message: string,
  },
};

type ClubAction = LoadClubs | LoadClubsFailure | LoadClubsSuccess;

export type ClubsState = {
  data: Array<ClubType>,
  dataById: { [string]: ClubType },
  loaded: boolean,
  error: ?string,
};

export default function(state: ClubsState = initialState, action: ClubAction) {
  switch (action.type) {
    case LOAD_CLUBS: // let's disable this setting to avoid loading flicker
      return state;

    case LOAD_CLUBS_FAILURE:
      return {
        ...initialState,
        error: action.error.message,
      };

    case LOAD_CLUBS_SUCCESS: {
      const newState = {
        ...initialState,
        data: uniqBy([...state.data, ...action.result.data], 'club_id'),
        dataById: action.result.data.reduce(
          (acc, val) => {
            acc[val.club_id] = val;
            return acc;
          },
          { ...state.dataById },
        ),
        loaded: true,
      };
      return newState;
    }
    default: {
      return state;
    }
  }
}

export function loadClubs(params): ApiRequest<Array<ClubType>> {
  return {
    type: API_REQUEST,
    types: [LOAD_CLUBS, LOAD_CLUBS_SUCCESS, LOAD_CLUBS_FAILURE],
    call: () => api().club.getClubs(params),
  };
}
