// @flow
import React, { useCallback } from 'react';
import i18n from 'react-native-i18n';
import { connect } from 'react-redux';
import { View } from 'react-native';
import type { NavigationScreenProp, NavigationStateRoute } from 'react-navigation';
import {
  Root,
  InnerContainer,
  Content,
  Title,
  PopupText,
  BlueText,
  GreenText,
  GolfId,
  Button,
  Buttons,
  ButtonText,
} from './styles';
import { registerUser } from '../../../user/modules/registering';
import password from '../../../user/modules/password';

type Params = {
  params: {
    data: {
      golfId: string,
      clubId: string,
      memberGuid: string,
      memberType: string,
    },
    userCredentials: {
      email: string,
      password: string,
    },
    ids: Array<string>,
  },
};

type Props = {
  navigation: NavigationScreenProp<NavigationStateRoute & Params>,
  registerUser: typeof registerUser,
};

const UpdateGolfIdModal = ({ navigation, registerUser }: Props) => {
  const data = navigation.getParam('data');
  const userCredentials = navigation.getParam('userCredentials');
  const ids = navigation.getParam('ids');
  const updateSessionId = navigation.getParam('updateSessionId');

  console.log('Render updateGolfIdModal', ids, data);

  const handleClose = useCallback(() => {
    navigation.navigate('LoginSelect');
  }, [navigation]);

  const handleAutoRegister = () => {
    try {
      if (userCredentials && userCredentials.password) {
        registerUser({ ...data, email: userCredentials.email, password: userCredentials.password });
      } else {
        registerUser(data);
      }
    } catch (err) {
      console.log('Registration error', err.response.data);
      if (err?.response?.data) {
        const reason = err?.response?.data;
        reason.forEach(({ message }) => alert(message));
      }
    }
  };

  const handleReplace = useCallback(() => {
    navigation.navigate('GolfIdSelectModal', { ids, data, updateSessionId, userCredentials });
  }, [ids]);

  return (
    <Root>
      <InnerContainer>
        <View>
          <Content overScrollMode="never">
            <Title>{i18n.t('register.updateAccount')}</Title>
            <PopupText>
              {i18n.t('register.updateAccountPartRegisteredWith')}
              &nbsp;
              <BlueText>{i18n.t('register.updateAccountPartGolfId', { golfboxId: data.member_number })}</BlueText>
              &nbsp;
              {i18n.t('register.updateAccountPartInYour')}
              &nbsp;
              <BlueText>{i18n.t('register.updateAccountPartGolfbox')}</BlueText>
              &nbsp;
              {i18n.t('register.updateAccountPartAccount')}.
            </PopupText>
            <PopupText mt={30}>
              {i18n.t('register.updateAccountYouHavePart')}
              &nbsp;
              <GreenText>{i18n.t('register.updateAccountGolfMorePart')}</GreenText>
              &nbsp;
              {i18n.t('register.updateAccountPartAccount')}:
            </PopupText>
            {ids.map((id) => (
              <GolfId key={id}>{id}</GolfId>
            ))}
            <PopupText mt={30}>
              {i18n.t('register.updateAccountToDoPart')}
              &nbsp;
              <BlueText>{i18n.t('register.updateAccountPartGolfId', { golfboxId: data.member_number })}</BlueText>?
            </PopupText>
            <Buttons>
              <Button>
                <ButtonText onPress={handleAutoRegister}>
                  {i18n.t('register.addToGolfMore', { ids: data.member_number })}
                </ButtonText>
              </Button>
              <Button onPress={handleReplace}>
                <ButtonText>{i18n.t('register.replaceGolfmore')}</ButtonText>
              </Button>
              <Button onPress={handleClose}>
                <ButtonText>{i18n.t('register.ignoreIt')}</ButtonText>
              </Button>
            </Buttons>
          </Content>
        </View>
      </InnerContainer>
    </Root>
  );
};

UpdateGolfIdModal.navigationOptions = () => ({
  headerShown: false,
  title: 'Modal!',
  cardStyle: { backgroundColor: 'transparent' },
  animationEnabled: false,
});

const mapDispatchToProps = { registerUser };

export default connect(null, mapDispatchToProps)(UpdateGolfIdModal);
