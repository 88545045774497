// @flow
import en from './en/en.json';
import da from './da/da.json';
import no from './no/no.json';
import sv from './sv/sv.json';
import is from './is_IS/is_IS.json';

const locales = {
  da,
  en,
  no,
  sv,
  is,
};

export default locales;
