import axios from 'axios';
import base64Encode from 'base-64';
import browserHistory from '../../history';
import authConstants from './authConstants';
import * as vouchersActions from '../vouchers/module.js';
import { transferReferral } from '../../react-native/user/modules/registering';
import authAPI from './api/auth';
import appActions from '../app/appActions';
import api from '../../react-native/api';
import { logOut as logOutRN } from '../../react-native/auth/modules/session';

const setDataOfAuthUser = (data) => ({
  type: authConstants.SET_DATA_OF_AUTH_USER,
  data,
});

const setRegisterUnion = (registerUnion) => ({
  type: authConstants.SET_REGISTER_UNION,
  registerUnion,
});

const setUnionMemberStatus = (flag) => ({
  type: authConstants.SET_UNION_MEMBER_STATUS,
  status: flag,
});

const setUnionMemberData = (unionMemberData) => ({
  type: authConstants.SET_UNION_MEMBER_DATA,
  unionMemberData,
});

const getUserData = (sessionId) => (dispatch) => {
  authAPI
    .getUserData(sessionId)
    .then((response) => {
      dispatch(setDataOfAuthUser(response.data));
      if (response.data.union_id) {
        dispatch(setUnionMemberStatus(true));
      } else {
        dispatch(setUnionMemberStatus(false));
      }
    })
    .catch((error) => {});
};
const checkUnionMember = (unionId, memberNumber, birthDate, setServerErrors, registerUnion, locale) => (dispatch) => {
  authAPI
    .checkUnionMember(unionId, memberNumber, birthDate, locale)
    .then((response) => {
      dispatch(setUnionMemberStatus(true));
      dispatch(setUnionMemberData(response.data));
      dispatch(setRegisterUnion(registerUnion));
      dispatch(appActions.togglePopup('signUpStepOnePopupIsOpen', false));
      dispatch(appActions.togglePopup('signUpStepTwoPopupIsOpen', true));
    })
    .catch((error) => {
      setServerErrors(error.response.data);
    });
};
const setUnions = () => (dispatch) => {
  authAPI
    .getUnions()
    .then((response) => {
      dispatch({ type: authConstants.SET_UNIONS, unions: response.data });
    })
    .catch((error) => {});
};
export const handleLogIn = (flag) => async (dispatch) => {
  api().setToken(localStorage.getItem('userToken'));
  const referral = localStorage.getItem('referral');

  if (referral) {
    console.log('Consumed referral', referral);
    try {
      await dispatch(transferReferral(referral));
      console.log('Transfer referral success');
    } catch (err) {
      console.log('Transfer referral failed', err);
    }

    localStorage.removeItem('referral');
  }

  if (flag) {
    dispatch(appActions.getPDFsCount());
    dispatch(appActions.getPDFs());
    dispatch(getUserData(localStorage.getItem('userToken')));
    dispatch(vouchersActions.getVouchers());
  }
  dispatch({
    type: authConstants.HANDLE_LOG_IN,
    userIsAuthenticated: flag,
  });
};

const checkLoginInfo = () => (dispatch) => {
  const userToken = localStorage.getItem('userToken');
  console.log('CLI', userToken);
  if (userToken != null && userToken !== 'undefined') {
    authAPI
      .getSessionData(localStorage.getItem('userToken'))
      .then((response) => {
        axios.defaults.headers.common.Authorization = `Basic ${base64Encode.encode(`${response.data.session_id}:`)}`;

        dispatch(handleLogIn(true));

        if (window.location.pathname === '/') {
          // browserHistory.goBack();
          browserHistory.push('vouchers');
        }
      })
      .catch((error) => {
        dispatch(handleLogIn(false));
        browserHistory.push('/');
        dispatch(logOutRN());
        localStorage.removeItem('userToken');
      });
  } else {
    if (userToken === 'undefined') {
      dispatch(handleLogIn(false));
      dispatch(logOutRN());
      localStorage.removeItem('userToken');
      browserHistory.push('/');
    }

    const REG = /\/voucher-subtransfer\/.+/g;

    if (REG.test(window.location.pathname)) {
      browserHistory.push(window.location.pathname);
      return;
    }
  }
};

const logIn = (data, setServerErrors, language) => (dispatch) => {
  let isErrorLogin = false;
  authAPI
    .logIn(data, language)
    .then((response) => {
      checkVerifyEmail(response.data.session_id, (isVerify) => {
        if (isVerify) {
          axios.defaults.headers.common.Authorization = `Basic ${base64Encode.encode(`${response.data.session_id}:`)}`;
          localStorage.setItem('userToken', response.data.session_id);

          dispatch(vouchersActions.getVouchers());
          dispatch(getUserData(response.data.session_id));
          dispatch(handleLogIn(true));
          browserHistory.push('vouchers');
        } else {
          const sessionId = response.data.session_id;

          isErrorLogin = true;
          authAPI
            .getUserData(sessionId)
            .then((resp) => {
              sessionStorage.setItem('loginData', JSON.stringify(data));
              dispatch(setDataOfAuthUser(resp.data));
            })
            .then(() => {
              dispatch(appActions.togglePopup('emailVerifyErrorPopupIsOpen', true));
            });
        }
        dispatch(appActions.togglePopup('logInPopupIsOpen', false));
        dispatch(appActions.togglePopup('emailVerifyErrorPopupIsOpen', isErrorLogin));
      });
    })
    .catch((error) => {
      setServerErrors(error.response.data);
    });
};

const toggleLogOutWarn = () => ({
  type: authConstants.TOGGLE_LOG_OUT_WARN,
});
const updateLoggedUserInfo = (data, setServerErrors) => (dispatch) => {
  authAPI
    .updateLoggedUserInfo(data, localStorage.getItem('userToken'))
    .then((response) => {
      dispatch(setDataOfAuthUser(response.data));
      dispatch(appActions.togglePopup('changeProfileSuccessPopupIsOpen', true));
    })
    .catch((error) => {
      setServerErrors(error.response.data);
    });
};

const logOut = (page) => (dispatch) => {
  dispatch(handleLogIn(false));
  dispatch(logOutRN());

  if (page !== 'email-verify') {
    dispatch(toggleLogOutWarn());
  }

  localStorage.removeItem('userToken');
  browserHistory.push('/');
};

const changeClub = (unionID, memberNumber, birthDate, setServerErrors) => (dispatch) => {
  authAPI
    .changeClub(unionID, memberNumber, birthDate)
    .then((response) => {
      dispatch(appActions.togglePopup('addUnionSuccessPopupIsOpen', true));
      setDataOfAuthUser(response.data);
    })
    .catch((error) => {
      setServerErrors(error.response.data);
    });
};

const setReferral = (referral) => ({
  type: authConstants.SET_REFERRAL,
  payload: referral,
});

const changePassword = (data, setServerErrors) => (dispatch) => {
  authAPI
    .changePassword(data)
    .then((response) => {
      dispatch(appActions.togglePopup('changePasswordConfirmPopupIsOpen', true));
    })
    .catch((error) => {
      setServerErrors(error.response.data);
    });
};

export default {
  changePassword,
  updateLoggedUserInfo,
  getUserData,
  setDataOfAuthUser,
  setUnionMemberData,
  setUnionMemberStatus,
  setRegisterUnion,
  setUnions,
  logIn,
  checkLoginInfo,
  handleLogIn,
  logOut,
  changeClub,
  toggleLogOutWarn,
  setReferral,
};
