import React from 'react';
import urls from '../../../../constants/config/urls';

const termsDenmark = () => (
  <div className="b-terms__content container grid-xl">
    <div className="columns">
      <div className="column">
        <h1 className="b-terms__title">Vilkår og Betingelser</h1>
        <h2 className="b-terms__sub-title">
          <span className="g-uppercase">BRUKERVILKÅR FOR GOLFMORE.DK </span> (Oppdatert juni 2018)
        </h2>
        <p className="b-terms__paragraph">
          Disse brukervilkårene (heretter "Brukervilkår") gjelder for ethvert bruk av GolfMores
          nettsteder, samt applikasjoner (heretter "Nettstedet"), inklusive - men ikke begrenset ti
          -
          <a
            className="b-privacy-policy__link"
            href={urls.norwegian}
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            no.GolfMore.dk
          </a>
          .
        </p>
        <p className="b-terms__paragraph">
          Nettstedet eies av ProBox24 ApS, Rugaardsvej 5, 8680 Ry, Danmark, info@probox24.com. I
          Brukervilkårene referer ordene "vi", "oss" og "våre" til GolfMore.
        </p>
        <p className="b-terms__paragraph">
          Disse Brukervilkårene omfatter alle regler og retningslinjer, som det henvises til i dette
          dokumentet, eller som vi på annen måte publiserer på Nettstedet (reglene og
          retningslinjene kan endres fra tid til annen), inklusive uten unntakelse:
        </p>
        <ul className="b-terms__sub-inner-list">
          <li className="b-terms__sub-inner-item">
            • Vår Personvernerklæring ("Privacy Policy"), som kan finnes på{' '}
            <a
              className="b-privacy-policy__link"
              href={urls.norwegianPrivacyPolicy}
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              no.golfmore.dk/privacy-policy
            </a>
          </li>
        </ul>
        <p className="b-terms__paragraph">
          Ved bruk av Nettstedet aksepterer du å være underlagt Brukervilkårene. Hvis du ikke kan
          akseptere vilkårene, bes du om å unnlate ytterligere bruk av Nettstedet. Din opprettelse
          som bruker forutsetter din uttrykkelige aksept av Brukervilkårene.
        </p>
        <h2 className="b-terms__sub-title">
          <span className="g-uppercase">INNHOLD PÅ NETTSTEDET</span>
        </h2>
        <h3 className="b-terms__sub-title">1 Registrert Bruker</h3>
        <p className="b-terms__paragraph">
          1.1 For å få adgang til å gjøre fullt bruk av Nettstedet må du opprette en profil og
          registrere deg som bruker (heretter "Registrert Bruker").
        </p>
        <p className="b-terms__paragraph">
          1.2 Det er kun tillatt å opprette én profil pr. person på Nettstedet. Profilen er
          personlig og må ikke overdras til andre.
        </p>
        <p className="b-terms__paragraph">
          1.3 For å bli Registrert Bruker må du opprette ett passord. Du velger selv ditt passord
          som skal benyttes sammen med din e-postadresse når du logger på Nettstedet. Alternativt
          sender vi passordet til deg. Passordet er personlig og må ikke overdras eller på annen
          måte gjøres tilgjengelig for andre. Det er ditt ansvar å sørge for at passordet ikke
          kommer tredjepart i hende. Om du får kjennskap til at passordet er, eller kan være
          kompromittert, er du forpliktet til å informere oss. Vi kan og vil endre passordet om det
          er risiko for at det er kompromittert eller anvendes i strid med Brukervilkårene.
        </p>
        <p className="b-terms__paragraph">
          1.4 Vi har rett til når som helst, uten varsel og uten ansvar, å slette, suspendere eller
          endre din profil, hvis du handler i strid med Brukervilkårene eller gjeldende lovgivning.
          Ved sletting av din profil har du ikke adgang til innhold på Nettstedet som forutsetter
          din opprettelse og/eller innlogging som Registrert Bruker.
        </p>
        <p className="b-terms__paragraph">
          1.5 Ved mistanke om misbruk eller lignende forbeholder vi oss videre retten til på ethvert
          tidspunkt og uten varsel eller begrunnelse å slette din profil. Vår ansvarsfraskrivelse
          vil i slike tilfeller gjelde ubegrenset.
        </p>
        <p className="b-terms__paragraph">
          1.6 Vi forbeholder oss videre retten til på ethvert tidspunkt og uten varsel eller
          begrunnelse å slette din profil. Vår ansvarsfraskrivelse vil i slike tilfeller gjelde
          ubegrenset.
        </p>
        <p className="b-terms__paragraph">
          1.7 Det er ikke tillatt å skaffe eller forsøke å skaffe seg adgang til å bruke de deler av
          Nettstedet som forutsetter brukerregistrering, hvis man ikke er Registrert Bruker.
        </p>
        <h2 className="b-terms__title">
          <span className="g-uppercase">generelt</span>
        </h2>
        <h3 className="b-terms__sub-title">2 Rettigheter</h3>
        <p className="b-terms__paragraph">
          2.1 Nettstedets innhold som, men ikke begrenset til, immaterielle rettigheter, tekst,
          kjennetegn, grafikk, ikoner, bilder, beregninger, henvisninger og software tilhører enten
          GolfMore eller tredjeperson (utover den Registrerte Bruker) og er beskyttet av dansk og
          internasjonal lovgivning, herunder opphavsrettsloven, varemerkeloven, markedsføringsloven
          m.fl. Bidrar du med feedback, ideer eller forslag til Nettstedet eller de services som er
          tilgjengelige via Nettstedet ("Feedback"), kan vi fritt og uten begrensning bruke denne
          Feedback.
        </p>
        <p className="b-terms__paragraph">
          2.2 Uautorisert kopiering, distribusjon, fremvisning, fremførelse eller annen anvendelse
          av Nettstedet eller deler av Nettstedet er i strid med dansk og/eller annen lovgivning, og
          kan medføre sivilrettslige såvel som strafferettslige sanksjoner.
        </p>
        <p className="b-terms__paragraph">
          2.3 Nedlasting og annen digital kopiering av Nettstedets innhold eller deler av
          Nettstedets innhold er kun tillatt til personlig ikke kommersiell bruk, medmindre annet er
          skriftlig avtalt med oss eller er tillatt ifølge gjeldende preseptorisk lovgivning.
        </p>
        <p className="b-terms__paragraph">
          2.4 Alle virksomhetsnavn, varemerker og andre forretningskjennetegn på Nettstedet tilhører
          enten GolfMore eller tredjeperson (utover den Registrerte Bruker) og kan kun anvendes
          forretningsmessig etter forutgående tillatelse fra henholdsvis oss eller tredjeperson.
        </p>
        <h3 className="b-terms__sub-title">3 Personopplysninger</h3>
        <p className="b-terms__paragraph">
          3.1 Vi foretar forskjellige former for behandling av personopplysninger i forbindelse med
          bruken av Nettstedet. Vår behandling av personopplysninger skjer i henhold til
          persondataloven (DK) samt vår Privacy Policy, som du kan finne her:{' '}
          <a
            className="b-privacy-policy__link"
            href={urls.norwegianPrivacyPolicy}
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            no.golfmore.dk/privacy-policy
          </a>
          .
        </p>
        <p className="b-terms__paragraph">
          Ved å akseptere Brukervilkårene erklærer du, at du har lest og forstått vår Privacy
          Policy.
        </p>
        <h3 className="b-terms__sub-title">4 Ansvarsfraskrivelse og skadesløsholdelse</h3>
        <p className="b-terms__paragraph">
          4.1 I det omfang det er mulig efter dansk rett, kan vi i intet tilfelle gjøres
          erstatningsansvarlig for bruk av Nettstedet, herunder for (i) tap av inntekter, driftstap,
          forretningsmuligheter, tap eller ødeleggelse av data eller gjenskapelse av data, tap av
          goodwill, sikkerhetsbrudd forårsaket av tredjeparts brudd på telekommunikasjon og/eller
          internett, omsetningstap (uansett om dette er direkte, indirekte eller følgetap), (ii) tap
          eller skade som følge av ansvar overfor tredjeparter (både direkte og indirekte tap eller
          følgeskade) eller (iii) ethvert indirekte tap.
        </p>
        <p className="b-terms__paragraph">
          4.2 Intet i Brukervilkårene utelukker eller begrenser vårt ansvar for død eller
          personskade som følge av uaktsomhet eller ansvar for bevisst uriktige opplysninger eller
          uriktige opplysninger av viktig karakter, eller annet ansvar, som ikke kan begrenses eller
          fraskrives ifølge gjeldende preseptorisk lovgivning.
        </p>
        <h3 className="b-terms__sub-title">5 Andre vilkår</h3>
        <p className="b-terms__paragraph">
          5.1 Vi kan til enhver tid og uten varsel revidere eller endre disse Brukervilkårene eller
          etter eget skjønn og uten varsel nedlegge, endre eller omstrukturere Nettstedet. Som
          Registrert Bruker aksepterer du å være omfattet av de til enhver tid gjeldende
          Brukervilkår. Revidering eller endring av Vilkårene vil bli omtalt på Nettstedet. GolfMore
          bestreber seg videre på å informere Registrerte Brukere om endringer i Brukervilkårene.
          Som Registrert Bruker aksepterer du, at den fortsatte bruk av Nettstedet etter
          offentliggjørelsen av de endrede Brukervilkår utgjør en aksept av de endrede Brukervilkår.
        </p>
        <p className="b-terms__paragraph">
          5.2 Hvis noen del av disse Brukervilkårene skulle bli ansett for rettsstridige eller
          ugyldige og dermed ikke kan håndheves, påvirker dette ikke gyldigheten og håndhevelsen av
          den resterende del av Brukervilkårene.
        </p>
        <h3 className="b-terms__sub-title">6 Varighet og oppsigelse</h3>
        <p className="b-terms__paragraph">
          6.1 Vi kan til enhver tid si opp din rett til å få tilgang til og bruke de services som
          tilbys via Nettstedet, av en hvilken som helst årsak og uten ansvar. Hvis vi gjør det,
          eller hvis du velger å slette din profil, vil alle rettigheter du måttet ha oppnådd i så
          henseende opphøre øyeblikkelig.{' '}
        </p>
        <h3 className="b-terms__sub-title">7 Lovvalg og verneting</h3>
        <p className="b-terms__paragraph">
          7.1 Brukervilkårene er underlagt dansk rett, og hvor annet ikke er anført, gjelder dansk
          retts alminnelige regler i ethvert innbyrdes forhold mellom GolfMore og Registrerte
          Brukere samt brukere av Nettstedet. Eventuelle tvister og uoverensstemmelser skal bringes
          inn for domstolen ved GolfMores danske hjemting, medmindre dette strider mot preseptoriske
          vernetingsregler.
        </p>
      </div>
    </div>
  </div>
);

export default termsDenmark;
