import React, { Component } from 'react';

import './sort.css';

import texts from '../../../constants/languages';

class Sort extends Component {

  state = {
    isOpen: false,
    selectedOption: null,
  };

  blur = () => {
    this.setState({ isOpen: false });
    document.removeEventListener('click', this.closeOnBlur);
  };

  closeOnBlur = (event) => {
    let eventObj = event || window.event;// fix bug with event - undefined
    if (!this.dropDown.contains(eventObj.target) && !this.dropDownTrigger.contains(eventObj.target)) {
      this.blur();
    }
  };

  handleDropDown = () => {
    this.setState({ isOpen: true });
    document.addEventListener('click', this.closeOnBlur);
  };

  onDonePressActivate = (selectedOption, event) => {
    this.props.onDonePress(selectedOption);
    this.closeOnBlur(event);
    this.setState({
      isOpen: false,
    });
  };

  onChange = (event, selectedOption) => {
    this.props.setSortOption(selectedOption);
    this.onDonePressActivate(selectedOption, event);

  };

  setActiveClass = (selectedOption, item, defaultItem) => {
    if (selectedOption) {
      if (selectedOption === item) {
        return 'active';
      } else {
        return '';
      }
    } else {
      if (item === defaultItem) {
        return 'active';
      } else {
        return '';
      }
    }
  };

  renderSortList = (items) => {
    const list = items.map((item, index) => {
      return (
        <li
          className={`b-sort__item ${this.setActiveClass(this.setOption(), item.name, texts.SEARCH_CLUB_A_Z)}`}
          onClick={(event, selectedOption) => this.onChange(event, item.name)}
          key={index}
        >
          {item.name}
        </li>
      );
    });
    return list;
  }

  setOption = () => {
    // hard-code during bug fix
    switch (this.props.selectedOption) {
      case 'Nearest':
      case 'Nærmeste':
        return texts.SEARCH_NEAREST;
      case 'Valid Until':
      case 'Gyldig indtil':
        return texts.SEARCH_VALID_UNTIL;
      default:
        return texts.SEARCH_CLUB_A_Z;
    }
  };

  render() {
    const items = [
      { name: texts.SEARCH_NEAREST },
      { name: texts.SEARCH_CLUB_A_Z },
      { name: texts.SEARCH_VALID_UNTIL },
    ];

    return (
      <div className="b-sort">
        <div
          className="b-sort__trigger"
          ref={(dropDownTrigger) => this.dropDownTrigger = dropDownTrigger}
          onClick={this.handleDropDown}
        >
          <div className="b-sort__label">{this.setOption()}</div>
          <div className="b-sort__arrow"></div>
        </div>
        <div
          className={`b-sort__drop-down ${this.state.isOpen ? 'opened' : ''}`}
          ref={(dropDown) => this.dropDown = dropDown}
        >
          <ul className="b-sort__list">
            {this.renderSortList(items)}
          </ul>
        </div>
      </div>
    );
  }
}

export default Sort;
