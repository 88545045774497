// @flow
import { StyleSheet, Dimensions } from 'react-native';
import styles from '../../../constants/styles';

const { colors, font, size } = styles;

export default StyleSheet.create({
  information: {
    top: 50,
    paddingRight: 70,
    paddingLeft: 70,
    textAlign: 'center',
    color: colors.basic,
    fontSize: font.size.normal,
    ...font.style.regular,
  },
  screen: {
    backgroundColor: colors.bg_color,
    width: '100%',
    maxWidth: 500,
    flex: 1,
  },
  emailForm: {
    padding: 40,
    marginRight: 20,
    marginTop: 140,
  },
  btnSend: {
    marginTop: 32,
    backgroundColor: colors.basic,
  },
  BtnTextSend: {
    color: colors.basic,
    ...font.style.medium,
    fontSize: font.size.normal,
  },
  bottomField: {
    flex: 1,
    minHeight: 50,
  },
  errorMessage: {
    top: 10,
    color: colors.red,
    alignSelf: 'flex-start',
    paddingBottom: 10,
  },
  inputText: {
    color: colors.slate_gray,
    fontSize: font.size.normal,
    ...font.style.regular,
  },
  modalStyle: {
    flex: 1,
    marginLeft: 0,
  },
  modalContent: {
    backgroundColor: colors.white,
    width: size.screenWidth * 0.9,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    padding: 50,
    shadowOffset: { width: 5, height: 0 },
    shadowRadius: 15,
  },
  modalText: {
    textAlign: 'center',
    color: colors.basic,
    fontSize: font.size.medium,
    letterSpacing: 2,
  },
  modalButton: {
    right: 0,
    top: -30,
    marginLeft: size.screenWidth - 132,
    width: 132,
    height: 56,
    backgroundColor: colors.green,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 0,
  },
  modalBtnText: {
    textAlign: 'center',
    color: colors.basic,
    fontSize: font.size.medium,
    letterSpacing: 2,
  },
});

export const colorGray = colors.grey;
