import React from 'react';
import propTypes from 'prop-types';

import './popup.css';

import texts from '../../../constants/languages';

const { func, shape, any, string } = propTypes;

class NewsLetterNotification extends React.Component {
  static propTypes = {
    togglePopup: func.isRequired,
    updateData: func.isRequired,
    authUserData: shape(any).isRequired,
    locale: string.isRequired,
  };

  state = { accept_newsletter: false, accept_offers: false, step1: true };

  closePopupOnClickOutSide = (event) => {
    if (!this.popup.contains(event.target)) {
      this.props.togglePopup('newsletterPopup', false);
    }
  };

  setNotifications = async (selectedAnswer, event) => {
    event.preventDefault();

    if (this.state.step1) {
      this.setState({
        step1: false,
        accept_newsletter: selectedAnswer,
      });
    } else {
      this.setState({ accept_offers: selectedAnswer }, async () => {
        const { togglePopup, authUserData, updateData } = this.props;

        const data = {
          accept_newsletter: this.state.accept_newsletter,
          accept_offers: this.state.accept_offers,
          newsletter_asked: true,
        };
        await updateData(data); // TODO: 2nd parameter is setServerErrors;

        togglePopup('newsletterPopup', false);
      });
    }
  };

  render() {
    const { togglePopup, locale } = this.props;

    return (
      <div
        className="g-popup-wrap"
        onClick={event => this.closePopupOnClickOutSide(event)} // close popup
      >
        <div className="g-popup-overlay">&nbsp;</div>
        <div className="g-popup-wrap">
          <table className="g-popup-wrap__container">
            <tbody>
              <tr>
                <td className="g-popup-wrap__container-inner">
                  <div
                    className="b-transfer-success g-popup m-popup_more_wide"
                    ref={popup => (this.popup = popup)}
                  >
                    <div
                      className="g-popup__close-btn"
                      onClick={() => togglePopup('voucherPrintNotification', false)} // close popup
                    >
                      &nbsp;
                    </div>
                    <div className="b-transfer-success__content g-popup__content">
                      <form className="b-transfer-success__form g-form">
                        <h3 className="b-transfer-success__title g-form__title m-form__title_transfer notification-text remove-capitalize">
                          {this.state.step1
                            ? texts.newsletter.step1.header
                            : texts.newsletter.step2.header}
                        </h3>
                        <p className="b-transfer-success__title g-form__title m-form__title_transfer notification-text remove-capitalize">
                          {this.state.step1
                            ? texts.newsletter.step1.text
                            : texts.newsletter.step2.text}
                        </p>
                        <div className="b-transfer-success__btn-holder g-form__btn-holder g-clear-fix btn_container">
                          <button
                            className="b-transfer-success__btn printNotification cancel-btn"
                            style={locale === 'danish' ? { left: '-10%' } : { left: '-15%' }}
                            onClick={event => this.setNotifications(false, event)}
                          >
                            {texts.newsletter.no}
                          </button>
                          <button
                            className="b-transfer-success__btn printNotification accept-btn"
                            style={locale === 'danish' ? { right: '-10%' } : { right: '-15%' }}
                            onClick={event => this.setNotifications(true, event)}
                          >
                            {texts.newsletter.yes}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default NewsLetterNotification;
