// @flow
import React, { Component } from 'react';
import { View, Text, Keyboard } from 'react-native';
import i18n from 'react-native-i18n';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import type { NavigationState, NavigationScreenProp } from 'react-navigation';
import DoneBar from 'done-bar';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import yup from 'yup';
import compose from 'lodash/fp/compose';
import memoize from 'lodash/memoize';
import throttle from 'lodash/throttle';

import withModal from '../../../components/Modal/withModal';
import Button from '../../../components/Button';
import type { User } from '../../../user/api/user';
import { promisify } from '../../../store/apiAction';
import { registerUser } from '../../../user/modules/registering';
import { getGolfIds } from '../../../user/modules/user';
import Input from '../../../components/FormInput';
import styles, { Separator } from './styles';

type Dict = { [key: string]: * };

type InputProps = {
  field: string,
  label: string,
  placeholder?: string,
  keyboardType?: string,
  autoCorrect?: string,
};

type Params = NavigationState & {
  step: number,
  steps: number,
  title: string,
  nonUnion: boolean,
};

type Props = {
  navigation: NavigationScreenProp<Params>,
  registeringData: $Shape<User>,
  setFieldValue: (name: string, value: *) => void,
  setFieldTouched: (name: string, value: *) => void,
  handleSubmit: (event: Event<>) => void,
  values: Dict,
  errors: Dict,
  touched: Dict,
};

type State = {
  flagKeyboardShown: boolean,
  keyboardType: string,
  submitClicked: boolean,
};

class ImportGolfmoreUser extends Component<Props, State> {
  keyboardDidShowListener: Function;
  keyboardDidHideListener: Function;
  showErrorMessage: boolean;
  emailInput: ?React$ElementRef<typeof Input>;
  passwordInput: ?React$ElementRef<typeof Input>;
  confirmationInput: ?React$ElementRef<typeof Input>;
  scroll: Object;

  static navigationOptions = () => {
    return {
      title: i18n.t('signUp.importGolfmoreAccount'),
    };
  };

  static schema = yup.object().shape({
    email: yup.string().email('signUpStepTwo.invalidEmailField').required('signUpStepTwo.emptyEmailField'),

    password: yup.string().required('signUpStepTwo.emptyPasswordField'),
  });

  // eslint-disable-next-line react/sort-comp
  fieldsPositions = {};
  scrollEl: ?KeyboardAwareScrollView;

  state = {
    flagKeyboardShown: false,
    keyboardType: 'default',
    submitClicked: false,
  };

  componentDidMount() {
    this.keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', this.onKeyboardDidShow);
    this.keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', this.onKeyboardDidHide);
  }

  componentWillReceiveProps(props: Props) {
    this.showErrorMessage = false;
    if (Object.keys(props.errors).length > 0) {
      for (const errorKey of Object.keys(props.errors)) {
        if (props.touched[errorKey]) {
          this.showErrorMessage = true;
        }
      }
    }

    if (this.state.submitClicked && this.showErrorMessage && !this.state.flagKeyboardShown) {
      // console.log('props.errors', this.props.errors, props.errors);
      this.scroll.scrollTo({ x: 0, y: 0, animated: true });
    }

    if (Object.keys(this.props.errors).length || !Object.keys(props.errors).length) {
      return;
    }
    const firstError = Object.keys(props.errors).find((field) => props.touched[field]);
    if (!firstError) {
      return;
    }

    const y = this.fieldsPositions[firstError];
    if (y && this.scrollEl && this.scrollEl.scrollToPosition) {
      // this.scrollEl.scrollToPosition(0, y, true);
    }
  }
  componentWillUnmount() {
    this.keyboardDidShowListener.remove();
    this.keyboardDidHideListener.remove();
  }
  onKeyboardDidShow = () => {
    this.setState({ flagKeyboardShown: true });
  };

  onKeyboardDidHide = () => {
    this.setState({ flagKeyboardShown: false });
  };

  updateField = memoize((field: string, path: string) => {
    return (value) => {
      const val = path ? value[path] : value;
      this.props.setFieldValue(field, val);
      this.props.setFieldTouched(field, true);
    };
  });

  signUp = throttle(
    (evt) => {
      Keyboard.dismiss();
      this.setState({ submitClicked: true });
      this.props.handleSubmit(evt);
    },
    5000,
    { trailing: false },
  );

  setInputRef = (field: string, input) => {
    // console.log("SET INPUT REF", field);
    if (field === 'email') {
      this.emailInput = input;
    } else if (field === 'password') {
      this.passwordInput = input;
    } else if (field === 'confirmation') {
      this.confirmationInput = input;
    }
  };

  focusNextInput = (field: string) => {
    if (field === 'email') {
      this.passwordInput?.focus();
    } else if (field === 'password') {
      this.confirmationInput?.focus();
    } else if (field === 'confirmation') {
      Keyboard.dismiss();
    }
  };

  handleInputFocus = () => {};

  renderInput(props: InputProps) {
    const { values, errors, touched } = this.props;
    const { label, placeholder, field, keyboardType, autoCorrect, ...inputProps } = props;
    return (
      <View
        onLayout={({ nativeEvent }) => {
          this.fieldsPositions[field] = nativeEvent.layout.y;
        }}
      >
        <Input
          label={i18n.t(label)}
          value={String(values[field])}
          onChangeText={this.updateField(field)}
          returnKeyType="next"
          innerRef={(input) => {
            this.setInputRef(field, input);
          }}
          onSubmitEditing={() => {
            this.focusNextInput(field);
          }}
          blurOnSubmit={false}
          keyboardType={keyboardType || 'default'}
          autoCorrect={autoCorrect || false}
          onFocus={() => {
            this.handleInputFocus(field);
          }}
          onBlur={() => {
            this.setState({ keyboardType: 'default' });
          }}
          {...inputProps}
        />
        {this.state.submitClicked && touched[field] && errors[field] ? (
          <Text style={styles.errorMessage}>{i18n.t(errors[field], { defaultValue: errors[field] })}</Text>
        ) : null}
      </View>
    );
  }

  renderLoginButton() {
    // TODO dont forget about loadingStyle
    return (
      <View style={{ marginHorizontal: 20 }}>
        <Button
          onPress={this.signUp}
          title={i18n.t('signUp.linkGolfMoreAccount')}
          disabled={this.props.registeringData.loading}
          style={styles.buttonStyle}
        />
      </View>
    );
  }

  render() {
    const step = this.props.navigation.getParam('step');
    const steps = this.props.navigation.getParam('steps');
    const title = this.props.navigation.getParam('title');

    return (
      <View style={styles.screen}>
        <KeyboardAwareScrollView
          ref={(el) => {
            this.scrollEl = el;
          }}
          innerRef={(ref) => {
            this.scroll = ref;
          }}
          style={styles.screen}
          keyboardShouldPersistTaps="handled"
          extraHeight={150}
          enableOnAndroid
        >
          {this.state.submitClicked && this.showErrorMessage ? (
            <Text style={styles.errorMessage}>{i18n.t('signUpStepTwo.errorFields')}</Text>
          ) : null}

          <Separator>
            {this.renderInput({
              field: 'email',
              label: 'register.email',

              keyboardType: 'email-address',
              autoCapitalize: 'none',
            })}

            {this.renderInput({
              field: 'password',
              label: 'signUpStepTwo.password',

              secureTextEntry: true,
            })}
          </Separator>

          {this.renderLoginButton()}
        </KeyboardAwareScrollView>

        <DoneBar keyboardType={this.state.keyboardType} text={i18n.t('signUpStepTwo.done')} onDone={() => {}} />
      </View>
    );
  }
}

export default compose(
  connect(
    ({ user }) => ({
      registeringData: user.registering.data,
    }),
    {
      registerUser: promisify(registerUser),
      getGolfIds: promisify(getGolfIds),
    },
  ),
  withModal,
  withFormik({
    mapPropsToValues({ registeringData }) {
      return {
        email: registeringData.email || '',
        emailconfirm: registeringData.email || '',
        password: '',
        confirmation: '',
      };
    },
    validationSchema: ImportGolfmoreUser.schema,
    validateOnChange: true,
    async handleSubmit(values, { props, setFieldError }) {
      try {
        const golfIds = await props.getGolfIds(values.email, values.password);
        const ids = golfIds.data.golf_ids;
        const data = props.navigation.getParam('data');
        console.log(golfIds);

        if (ids && ids.length > 0) {
          props.navigation.navigate('UpdateGolfIdModal', {
            ids,
            data,
            userCredentials: values,
          });
        } else {
          const updateData = data;
          console.log('====== OATUH ', data, updateData);
          props.loginAndUpdateAccount(values.email, values.password, updateData);
        }
      } catch (err) {
        console.log('error', err);
        props.showModal('', i18n.t('register.cantlogin'));
      }
    },
  }),
)(ImportGolfmoreUser);
