// @flow

export const INIT: 'locale/INIT' = 'locale/INIT';
export const SET: 'locale/SET' = 'locale/SET';
export const SET_GOLFBOX_COUNTRY = 'locale/SET_GOLFBOX_COUNTRY';

type InitLocaleActionT = {
  type: typeof INIT,
  locale: string,
};

type SetLocaleActionT = {
  type: typeof SET,
  locale: string,
};

type ActionsTypesT = InitLocaleActionT | SetLocaleActionT;

export type StateT = {
  locale: string,
  golfBoxCountry: string,
};

const initialState = {
  locale: '',
  golfBoxCountry: '',
};

export function setGolfBoxCountry(country: string) {
  return {
    type: SET_GOLFBOX_COUNTRY,
    payload: country,
  };
}

export default function localeReducer(state: StateT = initialState, action: ActionsTypesT): StateT {
  switch (action.type) {
    case INIT:
    case SET:
      return {
        ...state,
        locale: action.locale,
      };
    case SET_GOLFBOX_COUNTRY:
      return { ...state, golfBoxCountry: action.payload };
    default:
      return state;
  }
}

export function initLocale(locale: string): InitLocaleActionT {
  return {
    type: INIT,
    locale,
  };
}

export function setLocale(locale: string): SetLocaleActionT {
  return {
    type: SET,
    locale,
  };
}
