const TOGGLE_POPUP = 'TOGGLE_POPUP';
const SET_LOCATION = 'SET_LOCATION';
const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
const SET_ARCHIVES_COUNT = 'SET_ARCHIVES_COUNT';
const SET_CURRENT_PDF_PAGE = 'SET_CURRENT_PDF_PAGE';
const SET_PDF_ARRAY = 'SET_PDF_ARRAY';
const SET_USER_LOCATION = 'SET_USER_LOCATION';
const SET_GEO_IP = 'SET_GEO_IP';
const VIEW_DATE_FORMAT = 'DD-MM-YYYY';
const SERVER_DATE_FORMAT = 'YYYY-MM-DD';

export default {
  TOGGLE_POPUP,
  SET_LOCATION,
  SET_CURRENT_PAGE,
  SET_ARCHIVES_COUNT,
  SET_CURRENT_PDF_PAGE,
  SET_PDF_ARRAY,
  SET_USER_LOCATION,
  SET_GEO_IP,
  VIEW_DATE_FORMAT,
  SERVER_DATE_FORMAT,
};
