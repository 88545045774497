const HANDLE_LOG_IN = 'HANDLE_LOG_IN';
const SET_REGISTER_DATA = 'SET_REGISTER_DATA';
const SET_UNIONS = 'SET_UNIONS';
const SET_UNION_MEMBER_STATUS = 'SET_UNION_MEMBER_STATUS';
const SET_UNION_MEMBER_DATA = 'SET_UNION_MEMBER_DATA';
const SET_COUNTRIES = 'SET_COUNTRIES';
const SET_REGISTER_UNION = 'SET_REGISTER_UNION';
const SET_DATA_OF_AUTH_USER = 'SET_DATA_OF_AUTH_USER';
const SET_EMAIL_FORGOT_PASSWORD = 'SET_EMAIL_FORGOT_PASSWORD';
const TOGGLE_LOG_OUT_WARN = 'TOGGLE_LOG_OUT_WARN';
const SET_REFERRAL = 'SET_REFERRAL';

export default {
  HANDLE_LOG_IN,
  SET_REGISTER_DATA,
  SET_DATA_OF_AUTH_USER,
  SET_REGISTER_UNION,
  SET_COUNTRIES,
  SET_UNION_MEMBER_DATA,
  SET_UNION_MEMBER_STATUS,
  SET_UNIONS,
  SET_EMAIL_FORGOT_PASSWORD,
  TOGGLE_LOG_OUT_WARN,
  SET_REFERRAL,
};
