// @flow
import styled from 'styled-components/native';
import { color, layout, space, border } from 'styled-system';
import { TextM } from '../../components/Text';
import { elevation1 } from '../../helpers/styles';
import HexagonMaskComponent from '../../components/HexagonMask';

const checkIcon = require('./assets/ok.png');

export const Touchable = styled.TouchableOpacity``;

export const Root = styled.View.attrs({
  p: 2,
})`
  ${space}
`;

export const ItemContainer = styled.View.attrs(({ selected }) => ({
  height: 50,
  borderRadius: 7,
  borderWidth: selected ? 1 : 0,
  borderColor: selected ? 'green' : undefined,
  bg: selected ? 'bgGreen' : 'white',
  pl: 6,
  pr: 5,
  m: 2,
  ...elevation1,
}))`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${color}
  ${border}
  ${space}
  ${layout}
`;

export const ItemContentContainer = styled.View.attrs({
  flex: 1,
})`
  flex-direction: row;
  align-items: center;
`;

export const ItemText = TextM;

export const HexagonImageContainer = styled.View.attrs({
  height: 30,
  width: 30,
  mr: 5,
})`
  ${layout}
  ${space}
`;

export const HexagonMask = styled(HexagonMaskComponent).attrs({
  color: 'white',
})``;

export const HexagonImage = styled.Image.attrs({
  height: 30,
  width: 30,
  resizeMode: 'cover',
})`
  ${layout}
`;

export const ItemImage = styled.Image.attrs({
  width: 28,
  height: 28,
  mr: 5,
  resizeMode: 'contain',
})`
  ${space}
  ${layout}
`;

export const SelectedIcon = styled.Image.attrs({
  width: 21,
  height: 21,
  resizeMode: 'cover', // Because designer probably forgot to crop the icon
  source: checkIcon,
})`
  ${layout}
`;
