// @flow

import { Platform } from 'react-native';
import { system } from 'styled-system';
import styles from '../constants/styles';

const colorExtConfig = {
  tintColor: {
    property: 'tintColor',
    scale: 'colors',
  },
};

export const colorExt = system(colorExtConfig);

// ------- Styled system helpers -------
export const fontFamilyHelper = ({ fontFamily = 'regular' }) => {
  const fontsObject = styles.font.style[fontFamily];
  if (!fontsObject) {
    return `font-family: ${fontFamily};`;
  }
  return `font-family: ${fontsObject.fontFamily};${Platform.OS === 'ios' ? `font-weight: ${fontsObject.fontWeight};` : ''}`;
};

export const elevation1 = {
  elevation: 1,
  shadowColor: '#000',
  shadowOffset: {
    width: 0,
    height: 1,
  },
  shadowOpacity: 0.18,
  shadowRadius: 1.00,
};

export const elevation2 = {
  elevation: 2,
  shadowColor: '#000',
  shadowOffset: {
    width: 0,
    height: 1,
  },
  shadowOpacity: 0.20,
  shadowRadius: 1.41,
};

export const elevation4 = {
  elevation: 4,
  shadowColor: '#000',
  shadowOffset: {
    width: 0,
    height: 2,
  },
  shadowOpacity: 0.23,
  shadowRadius: 2.62,
};

export const elevation6 = {
  elevation: 6,
  shadowColor: '#000',
  shadowOffset: {
    width: 0,
    height: 3,
  },
  shadowOpacity: 0.27,
  shadowRadius: 4.65,
};

export const elevation8 = {
  elevation: 8,
  shadowColor: '#000',
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.30,
  shadowRadius: 4.65,
};

export const elevation16 = {
  elevation: 16,
  shadowColor: '#000',
  shadowOffset: {
    width: 0,
    height: 8,
  },
  shadowOpacity: 0.44,
  shadowRadius: 10.32,
};
