// @flow
import { Alert } from 'react-native';
import AsyncStorage from '@react-native-community/async-storage';
import { takeEvery, put, call } from 'redux-saga/effects';
import {
  GET_COUNTRIES_LOCAL,
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_FAILED,
  GET_GEO_IP_LOCAL,
  GET_GEO_IP_SUCCESS,
  GET_GEO_IP_FAILED,
  getLocalCountriesSuccess,
  getLocalGeoIpSuccess,
} from '../modules/country';
import i18n from 'react-native-i18n';

export const COUNTRIES_KEY = 'golfMore@countries';
export const GEOIP_KEY = 'golfMore@geoip';

function* storeCountries(action) {
  try {
    const resultJson = JSON.stringify(action.result.data);
    yield AsyncStorage.setItem(COUNTRIES_KEY, resultJson);
  } catch (error) {
    console.log(error);
  }
}

function* storeGeoIps(action) {
  try {
    const resultJson = JSON.stringify(action.result.data);
    yield AsyncStorage.setItem(GEOIP_KEY, resultJson);
  } catch (error) {
    console.log(error);
  }
}

function* loadLocalCountries() {
  try {
    const jsonString = yield AsyncStorage.getItem(COUNTRIES_KEY);
    const json = JSON.parse(jsonString);

    yield put(getLocalCountriesSuccess(json));
  } catch (error) {
    console.log(error);
  }
}

function* loadLocalGeoIp() {
  try {
    const jsonString = yield AsyncStorage.getItem(GEOIP_KEY);
    const json = JSON.parse(jsonString);

    yield put(getLocalGeoIpSuccess(json));
  } catch (error) {
    console.log(error);
  }
}

const msgAlert = () =>
  new Promise((resolve, reject) => {
    Alert.alert(i18n.t('signUpStepTwo.error'), i18n.t('signUpStepTwo.noInternet'), [{ text: 'OK', onPress: resolve }], {
      cancelable: false,
    });
  });

function* geoFailure() {
  yield call(msgAlert);
  //  yield put(NavigationActions.back());
}

export default function* (): Generator<*, *, *> {
  yield takeEvery(GET_COUNTRIES_SUCCESS, storeCountries);
  yield takeEvery(GET_GEO_IP_SUCCESS, storeGeoIps);
  yield takeEvery(GET_GEO_IP_FAILED, geoFailure);
  yield takeEvery(GET_COUNTRIES_LOCAL, loadLocalCountries);
  yield takeEvery(GET_GEO_IP_LOCAL, loadLocalGeoIp);
  yield takeEvery(GET_COUNTRIES_FAILED, geoFailure);
}
