// @flow
import api from '../../api';
import { API_REQUEST } from '../../store/apiAction';
import type { User } from '../api/user';

const LOAD_USER: 'user/LOAD_USER' = 'user/LOAD_USER';
export const LOAD_USER_SUCCESS: 'user/LOAD_USER_SUCCESS' = 'user/LOAD_USER_SUCCESS';
const LOAD_USER_FAILED: 'user/LOAD_USER_FAILED' = 'user/LOAD_USER_FAILED';
export const LOAD_LOCAL_USER: 'user/LOAD_LOCAL_USER' = 'user/LOAD_LOCAL_USER';
export const LOAD_LOCAL_USER_SUCCESS: 'user/LOAD_LOCAL_USER_SUCCESS' =
  'user/LOAD_LOCAL_USER_SUCCESS';

export const DELETE_USER = 'user/DELTE_USER';
export const DELETE_USER_SUCCESS = 'user/DELTE_USER_SUCCESS';
export const DELETE_USER_FAILED = 'user/DELTE_USER_FAILURE';

export const GET_GOLF_ID = 'user/GET_GOLF_ID';
export const GET_GOLF_ID_SUCCESS = 'user/GET_GOLF_ID_SUCCESS';
export const GET_GOLF_ID_FAILURE = 'user/GET_GOLF_ID_FAILURE';

type LoadUserSuccess = {|
  type: typeof LOAD_USER_SUCCESS,
  result: {
    data: User,
  },
|};

type LoadUserFailed = {|
  type: typeof LOAD_USER_FAILED,
  error: {
    response: {
      data: {
        message: string,
      },
    },
  },
|};

type LoadLocalUserSuccess = {|
  type: typeof LOAD_LOCAL_USER_SUCCESS,
  result: {
    data: User,
  },
|};

export type UserState = {
  data: ?User,
  errors: ?string,
  loading: boolean,
};

type State = {
  user: UserState,
};

export const initUserState: State = {
  user: {
    data: null,
    errors: null,
    loading: false,
  },
};

export default {
  [LOAD_USER](state: State) {
    return {
      ...state,
      user: {
        ...initUserState.user,
        loading: true,
      },
    };
  },
  [LOAD_USER_SUCCESS](state: State, action: LoadUserSuccess) {
    return {
      ...state,
      user: {
        ...initUserState.user,
        data: action.result.data,
      },
    };
  },
  [LOAD_USER_FAILED](state: State, action: LoadUserFailed) {
    let errorMessage = '';
    if (action.error.response) {
      errorMessage = action.error.response.data.message;
    } else {
      errorMessage = action.error.message;
    }
    return {
      ...state,
      user: {
        ...initUserState.user,
        errors: errorMessage,
      },
    };
  },
  [LOAD_LOCAL_USER_SUCCESS](state: State, action: LoadLocalUserSuccess) {
    return {
      ...state,
      user: {
        ...initUserState.user,
        data: action.result.data,
      },
    };
  },
};

export function loadUser() {
  return {
    type: API_REQUEST,
    types: [LOAD_USER, LOAD_USER_SUCCESS, LOAD_USER_FAILED],
    call: () => api().user.get(),
    promisified: true,
  };
}

export function getGolfIds(email, password) {
  return {
    type: API_REQUEST,
    types: [GET_GOLF_ID, GET_GOLF_ID_SUCCESS, GET_GOLF_ID_FAILURE],
    call: () => api().user.getGolfIds(email, password),
    promisified: true,
  };
}

export function deleteUser(id) {
  return {
    type: API_REQUEST,
    types: [DELETE_USER, DELETE_USER_SUCCESS, DELETE_USER_FAILED],
    call: () => api().user.delete(id),
  };
}

export function loadLocalUser() {
  return {
    type: LOAD_LOCAL_USER,
  };
}
export function loadLocalUserSuccess(data) {
  return {
    type: LOAD_LOCAL_USER_SUCCESS,
    result: {
      data,
    },
  };
}
