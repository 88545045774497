// @flow

import { eventChannel } from 'redux-saga';
import { call, take, put } from 'redux-saga/effects';

import { CONNECTION_CHANGE } from '../modules/connection';

type ConnectionInfo = {|
  type: 'none' | 'wifi' | 'cellular' | 'unknown' | 'bluetooth' | 'ethernet' | 'wimax',
  effectiveType: '2g' | '3g' | '4g' | 'unknown',
|};

export const ONLINE_STATUSES = ['wifi', 'cellular'];

function initConnectionChannel() {
  return eventChannel((emitter) => {
    function handleConnectionChange(connectionInfo: ConnectionInfo) {
      emitter({
        type: CONNECTION_CHANGE,
        status: ONLINE_STATUSES.includes(connectionInfo.type),
      });
    }

    const unsubscribe = NetInfo.addEventListener(handleConnectionChange);

    // unsubscribe function
    return () => {
      unsubscribe();
    };
  });
}

export default function* connectionSagas(): Generator<*, *, *> {}
