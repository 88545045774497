import React from 'react';
import urls from '../../../../constants/config/urls';

const termsEnglish = () => (
  <div className="b-terms__content container grid-xl">
    <div className="columns">
      <div className="column">
        <h1 className="b-terms__title">GESCHÄFTSBEDINGUNGEN</h1>
        <h2 className="b-terms__sub-title">
          <span className="g-uppercase">NUTZUNGSBEDINGUNGEN FÜR GOLFMORE.DK </span> (Aktualisiert Juni 2018)
        </h2>
        <p className="b-terms__paragraph">
          Die Website wird angeboten von ProBox24 ApS, Rugaardsvej 5, 8680 Ry, Dänemark, info@probox24.com. In den
          Nutzungsbedingungen beziehen sich die Wörter „wir“, „uns“ und „unser“ auf die Wörter „GolfMore“.
          <a className="b-privacy-policy__link" href={urls.german} target="_blank" rel="noopener noreferrer nofollow">
            {' '}
            www.golfmore.de
          </a>
          .
        </p>
        <p className="b-terms__paragraph">
          Die Website wird angeboten von ProBox24 ApS, Rugaardsvej 5, 8680 Ry, Dänemark, info@probox24.com. In den
          Nutzungsbedingungen beziehen sich die Wörter „wir“, „uns“ und „unser“ auf die Wörter „GolfMore“.
        </p>
        <p className="b-terms__paragraph">
          Diese Nutzungsbedingungen umfassen alle Regeln, Richtlinien und Richtlinien, auf die in diesem Dokument Bezug
          genommen wird oder die anderweitig auf der Site veröffentlicht werden (solche Richtlinien und Richtlinien
          können von Zeit zu Zeit geändert werden), einschließlich ausnahmslos:
        </p>
        <ul className="b-terms__sub-inner-list">
          <li className="b-terms__sub-inner-item">
            Unsere Datenschutzrichtlinie, die Sie unter Our Privacy Policy, which can be found at
            <a
              className="b-privacy-policy__link"
              href="https://golfmore.de/privacy policy"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              {' '}
              https://golfmore.de/privacy-policy{' '}
            </a>
            policy finden können
          </li>
        </ul>
        <p className="b-terms__paragraph">
          Durch die Nutzung der Site erklären Sie sich mit den Nutzungsbedingungen einverstanden. Wenn Sie die
          Bedingungen nicht akzeptieren können, verwenden Sie die Site bitte nicht weiter. Ihr Anlegen als Nutzer
          erfordert Ihre ausdrückliche Zustimmung zu den Nutzungsbedingungen.
        </p>
        <h2 className="b-terms__sub-title">
          <span className="g-uppercase">DIE LIEFERUNGEN AUF DER WEBSITE</span>
        </h2>
        <h3 className="b-terms__sub-title">1 Registrierter Benutzer</h3>
        <p className="b-terms__paragraph">
          1.1 Um Zugang zur vollständigen Nutzung der Site zu erhalten, müssen Sie ein Profil erstellen und sich als
          Benutzer registrieren (im Folgenden „registrierter Benutzer“).
        </p>
        <p className="b-terms__paragraph">
          1.2 Es darf nur ein Profil pro Person erstellt werden. Person auf der Website. Das Profil ist personenbezogen
          und darf nicht auf andere übertragen werden.
        </p>
        <p className="b-terms__paragraph">
          1.3 Um ein registrierter Benutzer zu werden, benötigen Sie ein Passwort. Sie wählen Ihr Passwort, das mit
          Ihrer E-Mail-Adresse verwendet wird, wenn Sie sich auf der Website anmelden. Alternativ senden wir Ihnen das
          Passwort zu. Das Passwort ist persönlich und darf nicht übertragen oder anderweitig zugänglich gemacht werden.
          Es liegt in Ihrer Verantwortung, sicherzustellen, dass das Passwort nicht an Dritte gelangt. Wenn Sie
          feststellen, dass das Passwort kompromittiert wurde oder sein könnte, müssen Sie uns dies benachrichtigen. Wir
          können und werden das Passwort ändern, wenn die Gefahr besteht, dass es kompromittiert oder unter Verstoß
          gegen die Nutzungsbedingungen verwendet wird.
        </p>
        <p className="b-terms__paragraph">
          1.4 Wir können Ihr Profil jederzeit ohne Vorankündigung und ohne Verantwortung löschen, sperren oder ändern,
          wenn Sie gegen die Nutzungsbedingungen oder geltendes Recht verstoßen. Durch das Löschen Ihres Profils haben
          Sie keinen Zugriff auf die Dienste auf der Site, die Ihre Erstellung und / oder Anmeldung als registrierter
          Benutzer erfordern.
        </p>
        <p className="b-terms__paragraph">
          1.5 Bei Verdacht auf Missbrauch oder ähnlichem behalten wir uns das Recht vor, Ihr Profil jederzeit und ohne
          Angabe von Gründen zu löschen. In diesen Fällen gilt unser Haftungsausschluss uneingeschränkt.
        </p>
        <p className="b-terms__paragraph">
          1.6 Es ist nicht gestattet, sich Zugang zu den Teilen der Website zu verschaffen, die eine
          Benutzerregistrierung erfordern, wenn Sie kein registrierter Benutzer sind.
        </p>
        <p className="b-terms__paragraph">
          1.7 Es ist nicht gestattet, sich Zugang zu den Teilen der Website zu verschaffen, die eine
          Benutzerregistrierung erfordern, wenn Sie kein registrierter Benutzer sind.
        </p>
        <h2 className="b-terms__title">
          <span className="g-uppercase">IM ALLGEMEINEN</span>
        </h2>
        <h3 className="b-terms__sub-title">2 Rechte</h3>
        <p className="b-terms__paragraph">
          2.1 Der Inhalt der Website, wie - aber nicht beschränkt auf - geistige Eigentumsrechte, Texte, Merkmale,
          Grafiken, Symbole, Bilder, Berechnungen, Verweise und Software gehören entweder GolfMore oder Dritten (neben
          dem registrierten Benutzer). ) und ist durch dänisches und internationales Recht geschützt, einschließlich
          Urheberrechtsgesetz, Markengesetz, Marketinggesetz usw. Wenn Sie Feedback, Ideen oder Vorschläge zur Website
          oder den über die Website verfügbaren Diensten ("Feedback") geben, können wir dies verwenden Feedback frei und
          ohne Einschränkung.
        </p>
        <p className="b-terms__paragraph">
          2.2 Das unbefugte Kopieren, Verteilen, Anzeigen, Darstellen oder sonstige Verwenden der Site oder eines Teils
          davon verstößt gegen dänische und/oder andere Gesetze und kann zivil- und strafrechtliche Konsequenzen nach
          sich ziehen.
        </p>
        <p className="b-terms__paragraph">
          2.3 Das Herunterladen und sonstige digitale Kopieren des Inhalts der Website oder Teilen davon ist nur für den
          persönlichen, nicht-kommerziellen Gebrauch gestattet, sofern nichts anderes schriftlich mit uns vereinbart
          oder durch geltende zwingende Gesetze gestattet ist.
        </p>
        <p className="b-terms__paragraph">
          2.4 Alle Firmennamen, Warenzeichen und andere geschäftliche Merkmale der Website gehören entweder GolfMore
          oder Dritten (zusätzlich zum registrierten Benutzer) und dürfen nur nach vorheriger Genehmigung durch uns oder
          Dritte kommerziell verwendet werden.
        </p>
        <h3 className="b-terms__sub-title">3 Persönliche Daten</h3>
        <p className="b-terms__paragraph">
          3.1 Wir nehmen verschiedene Formen der Verarbeitung personenbezogener Daten im Zusammenhang mit der Nutzung
          der Website vor. Unsere Verarbeitung personenbezogener Daten unterliegt der Einhaltung des Gesetzes über
          personenbezogene Daten und unserer Datenschutzrichtlinie, die Sie hier finden:
          <a
            className="b-privacy-policy__link"
            href="https://golfmore.de/privacy-policy"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            https://golfmore.de/privacy-policy
          </a>
          policyDurch die Annahme der Nutzungsbedingungen erklären Sie, dass Sie unsere . gelesen und verstanden haben
          Datenschutz-Bestimmungen.
        </p>
        <h3 className="b-terms__sub-title">4 Haftungsausschluss und Schadloshaltung</h3>
        <p className="b-terms__paragraph">
          4.1 Soweit dies nach dänischem Recht möglich ist, haften wir in keinem Fall für die Nutzung der Website,
          einschließlich (i) Einnahmeverlust, Betriebsverlust, Geschäftsmöglichkeiten, Verlust oder Zerstörung von Daten
          oder Datenwiederherstellung, Verlust des Firmenwerts , Sicherheitsverletzung durch Verletzung der
          Telekommunikation und/oder des Internets durch Dritte, Umsatzeinbußen (direkt, indirekt oder Folgeschäden),
          (ii) Verluste oder Schäden aufgrund der Haftung gegenüber Dritten (entweder direkt, indirekt oder
          Folgeschäden) jeder indirekte Verlust.
        </p>
        <p className="b-terms__paragraph">
          4.2 Nichts in den Nutzungsbedingungen schließt unsere Haftung für Tod oder Körperverletzung aufgrund von
          Fahrlässigkeit oder Verantwortung für vorsätzlich falsche Informationen aus oder beschränkt sie oder falsche
          Informationen wichtiger Art oder eine andere Haftung, die gemäß den geltenden zwingenden Gesetzen nicht
          beschränkt oder ausgeschlossen werden kann.
        </p>
        <h3 className="b-terms__sub-title">5 Andere Begriffe</h3>
        <p className="b-terms__paragraph">
          5.1 Wir können diese Nutzungsbedingungen jederzeit und ohne Vorankündigung oder nach unserem alleinigen
          Ermessen ohne Vorankündigung überarbeiten oder ergänzen, um die Website zu beenden, zu modifizieren oder
          umzustrukturieren. Als registrierter Benutzer erklären Sie sich mit den aktuellen Nutzungsbedingungen
          einverstanden. Überarbeitungen oder Änderungen der Bedingungen werden auf der Website veröffentlicht. GolfMore
          bemüht sich auch, registrierte Nutzer über die Änderung der Nutzungsbedingungen zu informieren. Als
          registrierter Benutzer erklären Sie sich damit einverstanden, dass die fortgesetzte Nutzung der Website nach
          der Veröffentlichung der geänderten Nutzungsbedingungen die Annahme der geänderten Nutzungsbedingungen
          darstellt.
        </p>
        <p className="b-terms__paragraph">
          5.2 Sollte ein Teil dieser Nutzungsbedingungen als rechtswidrig oder ungültig und somit nicht durchsetzbar
          erachtet werden, berührt dies nicht die Gültigkeit und Durchsetzung des restlichen Teils der
          Nutzungsbedingungen.
        </p>
        <h3 className="b-terms__sub-title">6 Dauer und Kündigung</h3>
        <p className="b-terms__paragraph">
          6.1 Wir können Ihr Recht auf Zugriff auf und Nutzung aller auf der Website angebotenen Dienste aus beliebigem
          Grund und ohne Haftung kündigen. Wenn wir dies tun oder Sie Ihr Profil löschen möchten, erlöschen alle Rechte,
          die Sie dadurch hätten erlangen sollten.
        </p>
        <h3 className="b-terms__sub-title">7 Recht und Gerichtsstand</h3>
        <p className="b-terms__paragraph">
          7.1 Die Nutzungsbedingungen unterliegen dänischem Recht und sofern nicht anders angegeben, gilt dänisches
          Recht für alle Beziehungen zwischen GolfMore und registrierten Benutzern sowie Website-Benutzern. Alle
          Streitigkeiten und Unstimmigkeiten müssen vor dem Gericht am dänischen Wohnsitz von GolfMore vorgebracht
          werden, es sei denn, dies steht einer zwingenden Gerichtsbarkeit entgegen.
        </p>
      </div>
    </div>
  </div>
);

export default termsEnglish;
