// @flow
import React from 'react';
import { View } from 'react-native';
import ModalAlert from './ModalAlert';

/**
 * High order component, that enhances component with modal box.
 * new Prop is passed to the component - showModal
 *
 * const Test = ({showModal}) => {
 *  return (<TouchableOpacity onPress={showModal}>
 *      <Text>Show modal</Text>
 *  </TouchableOpacity>);
 * }
 *
 * export default withModal(Test);
 *
 *
 * showModal(title, text, dialogOptions) - Show modal alert
 * @param {*} title - title of the modal
 * @param {*} text - text of the modal
 * @param {*} dialogOptions - additional options of the modal:
 * {
 *      type: Color scheme of the dialog box: 'success' | 'warning' | 'error'
 *      options: option buttons, avalilable to user, array
 *       [{
 *           text: 'Cancel',
 *           style:  style: 'white' | 'orange' | 'red' | 'green',
 *           onPress: Callback function
 *       }]
 *      icon: optional icon of the dialog box
 *      cancelable: boolean
 *
 * }
 */

const withModal = WrappedComponent => {
  class WrapperComponent extends React.Component {
    static navigationOptions = WrappedComponent.navigationOptions;

    constructor(props) {
      super(props);
      this.state = {
        visible: false,
        modalOptions: { title: '', text: '' },
      };
    }

    render() {
      const { modalOptions } = this.state;
      return (
        <View style={{ flex: 1 }}>
          <ModalAlert
            {...modalOptions}
            visible={this.state.visible}
            closeCallback={() => {
              this.setState({ modalOptions: { title: '', text: '' }, visible: false });
              if (this.onWindowClosed) {
                this.onWindowClosed();
                this.onWindowClosed = undefined;
              }
            }}
          />
          <WrappedComponent
            {...this.props}
            ref={this.props.forwardedRef}
            showModal={(title, text, dialogOptions = {}) => {
              this.setState({
                modalOptions: { title, text, ...dialogOptions },
                visible: true,
              }); //eslint-disable-line
              if (this.onWindowClosed) {
                // if modal was open already, fullfill previous promise first
                this.onWindowClosed();
              }
              // showModal will fullfill a promise when modal is closed
              return new Promise(resolve => {
                this.onWindowClosed = resolve;
              });
            }}
          />
        </View>
      );
    }
  }

  return WrapperComponent;
};

export default withModal;
